import React from 'react';
import Card from 'react-bootstrap/Card';
import { ImPointRight } from 'react-icons/im';

export default function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: 'justify' }}>
            <span className="purple">Akademik - mobil dastur</span>
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.action.omr&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              width={300}
              alt="Google Play orqali oling"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/uz_badge_web_generic.png"
            />
          </a>
          {/* <ul>
            
            <li className="about-activity">
              <ImPointRight /> Writting Coding
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul> */}

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}
