/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import formats from './ToolbarOptions';

const renderOptions = (formatData, a) => {
  const { className, options } = formatData;
  return (
    <select key={a} className={className}>
      {/* <option selected="selected" /> */}
      {options.map((value, r) => {
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        return <option key={`op-${r + 1}`} value={value} />;
      })}
    </select>
  );
};
const renderSingle = (formatData, a) => {
  const { className, value } = formatData;
  return <button key={a} className={className} value={value} />;
};
const CustomToolbar = ({ id }) => (
  <div key={id} id={`toolbar-${id}`}>
    {formats.map((classes, i) => {
      return (
        <span key={`${i + 1}`} className="ql-formats">
          {classes.map((formatData, a) => {
            return formatData.options
              ? renderOptions(formatData, a)
              : renderSingle(formatData, a);
          })}
        </span>
      );
    })}
  </div>
);
export default CustomToolbar;
