import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  CardMedia,
  colors,
  FormHelperText,
  Switch,
  CircularProgress,
  Modal,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import usersApi from '../../../../services/users';
import { setProfileImage } from '../../../../redux/modules/store/actions';
import { useStyles } from './style';
import UploadIcon from '../../../../assets/icons/upload.svg';

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const theme = useTheme();

  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const { t } = useTranslation();
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
    gender: Yup.string().required(t('required')),
    // date_birth: Yup.date().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      full_name: '',
      phone: '',
      gender: '',
      date_birth: new Date(),
      notes: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const data = new FormData();

      data.append('name', values.full_name);
      data.append(
        'phone',
        values.phone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '')
      );
      data.append('photo', photo);
      data.append('gender', values.gender);
      data.append('date_birth', values.date_birth);
      data.append('notes', values.notes);

      usersApi
        .updateAuth(decodedToken.user_id, data)
        .then((res) => {
          dispatch(setProfileImage(new Date()));
          setSnackbarMessage(t('successful_updated'));
          setOpen(true);
          formik.setSubmitting(false);
        })
        .catch((err) => console.log(err));
    },
  });

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setPreviewImg(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
      });
    }
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  const loadingData = () => {
    usersApi
      .getOneUserAuth(decodedToken.user_id)
      .then((res) => {
        formik.setValues({
          ...formik.values,
          full_name: res.name,
          phone: res.phone,
          gender: res.gender,
          date_birth:
            res.date_birth === null || res.date_birth === ''
              ? new Date()
              : res.date_birth,
          notes: res.notes,
        });
        if (res.photo) {
          setPreviewImg(res.photo);
        }
      })
      .catch((err) => {
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, [state]);

  const handleGender = (event) => {
    formik.setFieldValue('gender', event.target.name);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        minHeight: '100vh',
        p: 3,
      }}
    >
      <Container>
        <Modal hideBackdrop open={openCrop} onClose={handleCropModal}>
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">{t('edit_image')}</h2>
            <div style={{ width: '100%' }}>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleCropModal()}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                }
                onClick={() => {
                  getCropData();
                  handleCropModal();
                }}
              >
                {t('ready')}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <MuiAlert
              elevation={6}
              // ref={ref}
              variant="filled"
              onClose={handleClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        </Stack>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} md={12} sm={12}>
            <Typography variant="h2" color="textPrimary" mt={2}>
              {t('personal_informations')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={3} xs={12}>
            <Box
              sx={{
                py: 2,
              }}
            >
              <CardMedia
                component="img"
                style={{
                  width: 210,
                  height: 210,
                  borderRadius: 5,
                  objectFit: 'fill',
                }}
                image={previewImg || UploadIcon}
                alt=""
              />
              <input
                id="selectFile"
                type="file"
                accept=".png,.jpg"
                style={{ display: 'none' }}
                onChange={imgSelectHandler.bind(this)}
              />
              <Box
                sx={{
                  py: 0.5,
                }}
              />
              <Button
                type="file"
                fullWidth
                size="small"
                variant="contained"
                sx={{
                  width: 210,
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
                color="error"
                onClick={() => {
                  document.getElementById('selectFile').click();
                }}
              >
                {previewImg ? t('change') : t('select_image')}
              </Button>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={6}>
                  <TextField
                    className={classes.search}
                    name="name"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    label={t('enter_full_name')}
                    value={formik.values.full_name}
                    error={
                      formik.touched.full_name &&
                      Boolean(formik.errors.full_name)
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.full_name &&
                      formik.touched.full_name &&
                      formik.errors.full_name}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} my={1}>
                <Grid item xl={12} md={6} xs={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        name="date_birth"
                        openTo="year"
                        mask="__/__/____"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.date_birth}
                        onChange={(e) => formik.setFieldValue('date_birth', e)}
                        error={
                          formik.touched.date_birth &&
                          Boolean(formik.errors.date_birth)
                        }
                        InputProps={{
                          style: {
                            backgroundColor: theme.palette.background.paper,
                          },
                        }}
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        helperText={
                          formik.touched.date_birth && formik.errors.date_birth
                        }
                        label={t('date_of_birth')}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            label={t('date_of_birth')}
                            {...params}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('gender')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 4,
                      }}
                    >
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          name="male"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            height: '100%',
                            backgroundColor:
                              formik.values.gender === 'male'
                                ? colors.white
                                : '#fff',
                            color: '#000',
                          }}
                          variant="contained"
                          onClick={handleGender}
                        >
                          {t('male')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          name="female"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            backgroundColor:
                              formik.values.gender === 'female'
                                ? colors.white
                                : '#fff',
                            color: '#000',
                          }}
                          variant="contained"
                          onClick={handleGender}
                        >
                          {t('female')}
                        </Button>
                      </Grid>
                    </Box>
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.gender &&
                        formik.touched.gender &&
                        formik.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('phone')}
                </Typography>
                <InputMask
                  size="small"
                  className={classes.inputmask}
                  onChange={formik.handleChange}
                  name="phone"
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  placeholder="+998 (_ _) _ _ _ - _ _ - _ _"
                  mask="+\9\98 (99) 999-99-99"
                  maskChar="_"
                  style={{
                    border: '1px solid #c4c4c4',
                    outline: '1px solid #F7F8FA',
                    paddingLeft: '14px',
                  }}
                />
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.phone &&
                    formik.touched.phone &&
                    formik.errors.phone}
                </FormHelperText>
              </Grid>
              <Grid item xl={12} md={12} xs={12} my={1}>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="notes"
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  label={t('enter_notes')}
                  value={formik.values.notes}
                />
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => console.log()}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(decodedToken.user_id, formik.isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
