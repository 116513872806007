import { Helmet } from 'react-helmet';
import { React, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  SvgIcon,
  TextField,
  InputLabel,
  IconButton,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Search as SearchIcon } from 'react-feather';
import { AnimatePresence } from 'framer-motion';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../../../../components/Loading';
import { toolTips } from './helper';
import { useStyles } from './style';
import Table from '../../../../components/Table';
import { scienceHeader } from '../../../../redux/modules/table/common';
import { headerMaker } from '../../../../components/Table/helper';
import { fetchData } from '../../../../redux/modules/science/actions';
import { setTotals } from '../../../../redux/modules/store/actions';
import FilterTools from '../../../../components/FilteredBody/Science';
import AnimationDiv from '../../../../components/Animations/Transition';
import { useQuery } from '../../../useQuery';
import locations from '../../../../services/locations';
import ModalWindow from '../../../../components/Modals';
import ChangeStatusModal from '../../../../components/Modals/ConfirmStatus';
import science from '../../../../services/science';
import ToastMessage from '../../../../components/ToastMessage';

export default ({ ...rest }) => {
  const classes = useStyles();
  const {
    query,
    search,
    status,
    setSearch,
    setStatus,
    handleOnTableChange,
    setLocationId,
  } = useQuery({ fetchData });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const [locationList, setLocationList] = useState([]);
  const [allLocation, setAllLocation] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { data, total } = useSelector((state) => state.scienceReducer);
  localStorage.setItem('total_data', total);
  const [modal, setModal] = useState(false);

  const titleItem = useSelector((state) => state.ordersReducer.item);

  const language = useSelector((state) => state.langsReducer.language);
  const headers = useMemo(() => headerMaker(scienceHeader), [scienceHeader]);
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: 'all',
    },
    validationSchema,
  });

  const addScience = () => {
    if (locationList.length === 0) {
      setSnackbarMessage(t('not_fount_location'));
      setOpen(true);
    } else {
      navigate('/app/science/create');
    }
  };

  const doubleClick = (id) => {
    navigate('/app/science/create', { state: id });
  };

  const getStaffTitleByLocationId = (id) => {
    setLoading(true);
    formik.setFieldValue('locations', id);
    if (id === 'all') {
      const locationIds = locationList.map((item) => item.location_id);
      const query = `${locationIds.join('&locations=')}`;
      setLocationId(query);
    } else {
      setLocationId(id);
    }
    setLoading(false);
  };

  const loadingData = () => {
    dispatch(setTotals(total));
    if (decodedToken.role === 'Supervisor') {
      navigate('/app/class');
    } else {
      setLoading(true);
      const query = `user_id=${decodedToken.user_id}`;
      locations
        .getAll(query)
        .then((res) => {
          setLocationList(res.results);

          setAllLocation(
            res.results
              .filter((item) => item.location_name !== '')
              .map((e) => e.location_id)
              .join(',')
          );

          const locationIds = res.results.map((item) => item.location_id);
          const query = `${locationIds.join('&locations=')}`;
          setLocationId(query);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          try {
            if (err.response.status === 401) {
              setTimeout(() => {
                loadingData();
              }, 5000);
            }
          } catch (error) {
            //
          }
        });
    }
  };

  useEffect(() => {
    loadingData();
  }, [language]);

  const handleModal = () => {
    setModal(!modal);
  };

  const changeStatus = () => {
    setLoading(true);
    const data = new FormData();
    data.append('status', titleItem.status === 1 ? 0 : 1);

    science
      .changeStatus(titleItem.employee_title_id, data)
      .then((resp) => {
        setModal(!modal);
        setLoading(false);
        setSearch(titleItem.name);
        setSearch('');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title> {t('science')} </title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 1,
          minHeight: '100%',
        }}
      >
        <Container maxWidth={false}>
          <ToastMessage
            open={open}
            handleClose={handleClose}
            snackbarMessage={snackbarMessage}
            severity="info"
          />
          <ModalWindow
            title={t('change_status')}
            modal={modal}
            handleModal={handleModal}
            maxWidth="sm"
            body={
              <ChangeStatusModal
                handleModal={handleModal}
                changeStatus={changeStatus}
                yes={t('yes')}
                cancel={t('cancel')}
                text={t('ask_change_status')}
              />
            }
          />
          <Loading loading={loading} />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            mb={4}
            mt={2}
          >
            {t('science')}
          </Typography>
          <Grid container spacing={1} my={1}>
            <Grid item xs={11} md={11} sm={11}>
              <div className={classes.navFilterSection}>
                <TextField
                  className={classes.search}
                  size="small"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  label={t('search')}
                  variant="outlined"
                />
              </div>
            </Grid>

            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={addScience}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={1} my={0.5}>
            <Grid item xs={11} md={11} sm={11}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="locations">{t('location')}</InputLabel>
                <Select
                  labelId="locations"
                  label={t('location')}
                  size="small"
                  className={classes.input}
                  fullWidth
                  name="locations"
                  value={formik.values.locations}
                  onBlur={formik.handleBlur}
                  onChange={(e) => getStaffTitleByLocationId(e.target.value)}
                >
                  <MenuItem size="small" key="all" value="all">
                    {t('all')}
                  </MenuItem>
                  {locationList.map((item, index) => (
                    <MenuItem
                      size="small"
                      key={`${index + 1}`}
                      value={item.location_id}
                    >
                      {item.location_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                color="primary"
                aria-label="delete"
                size="medium"
                onClick={() => {
                  setIsShow(!isShow);
                }}
              >
                <FilterListIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
          <AnimatePresence className={classes.filters}>
            {isShow ? (
              <AnimationDiv>
                <FilterTools status={status} setStatus={setStatus} />
              </AnimationDiv>
            ) : null}
          </AnimatePresence>
          <Box sx={{ pt: 2 }}>
            <div className={classes.root}>
              <Card {...rest}>
                <Table
                  data={data}
                  headers={headers}
                  toolTips={toolTips}
                  onChange={handleOnTableChange}
                  modal={handleModal}
                  doubleClick={doubleClick}
                />
              </Card>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};
