import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { colors, Box, Container } from '@mui/material';
import Aboutcard from './AboutCard';
import laptopImg from '../../../assets/main/about.png';

function About() {
  return (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        <Container>
          <Row style={{ justifyContent: 'center', padding: '10px' }}>
            <Col
              md={7}
              style={{
                justifyContent: 'center',
                paddingTop: '30px',
                paddingBottom: '50px',
              }}
            >
              <Aboutcard />
            </Col>
            <Col
              md={5}
              style={{ paddingTop: '120px', paddingBottom: '50px' }}
              className="about-img"
            >
              <img src={laptopImg} alt="about" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </Container>
    </Box>
  );
}

export default About;
