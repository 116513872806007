import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'react-bootstrap';
import { Box, Container } from '@mui/material';
import Preloader from '../Pre';
import Navbar from '../Navbar';
import AcademicCard from './Card';
import ScrollToTop from '../ScrollToTop';
import '../style.css';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Akademik</title>
      </Helmet>
      <Preloader load={load} />
      <div className="App" id={load ? 'no-scroll' : 'scroll'}>
        {/* <Navbar />
        <ScrollToTop /> */}
        <Box
          sx={{
            // backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Container maxWidth="md">
            <Container>
              <Row style={{ justifyContent: 'center', padding: '10px' }}>
                <Col
                  md={7}
                  style={{
                    justifyContent: 'center',
                    paddingTop: '30px',
                    paddingBottom: '50px',
                  }}
                >
                  <AcademicCard />
                </Col>
              </Row>
            </Container>
          </Container>
        </Box>
      </div>
    </>
  );
}
