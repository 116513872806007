import { service } from '.';

export default {
  getAll: (query) => service.get(`/customers/?${query}`),
  getOne: (id) => service.get(`/customers/${id.customer_id}/`),
  getByLocationId: (query) => service.get(`/customers/?locations=${query}`),
  create: (data) => service.post('/customers/', data),
  update: (id, data) => service.patch(`/customers/${id.customer_id}/`, data),
  changeStatus: (id, data) => service.patch(`/customers/status/${id}/`, data),
};
