import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import jwtDecode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import Preloader from './Pre';
import Navbar from './Navbar';
import Home from './Home';
import ScrollToTop from './ScrollToTop';
import './style.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [load, upadateLoad] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      navigate('/app/dashboard');
    } catch (error) {
      console.log(error);
    }
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Asosiy</title>
      </Helmet>
      <Preloader load={load} />
      <div className="App" id={load ? 'no-scroll' : 'scroll'}>
        {/* <Navbar />
        <ScrollToTop /> */}
        <Home />
      </div>
    </>
  );
}

export default App;
