import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  colors,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  CardMedia,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TimePicker from '@mui/lab/TimePicker';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loading from '../../../../components/Loading';
import MultiChipSelect from '../../../../components/FormElements/MultiSelect';
import packages from '../../../../services/exam';
import locations from '../../../../services/locations';
import employees from '../../../../services/employees';
import classApi from '../../../../services/class';
import { useStyles } from './style';
import UploadIcon from '../../../../assets/icons/upload.svg';
import { IOSSwitch } from '../../../../components/GlobalStyles';
import { localeMap } from '../../../../constants';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState([]);
  // const [selectedPackages, setSelectedPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('#2A455F');

  const [locationList, setLocationList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [locationSchema, setLocationSchema] = useState([]);
  const [employeeSchema, setEmployeeSchema] = useState([]);

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.array().min(1, t('required')),
    employees: Yup.array().min(1, t('required')),
    class_name: Yup.string().required(t('required')),
    class_duration: Yup.string().required(t('required')),
    begin_date: Yup.date().required(t('required')),
    end_date: Yup.date().required(t('required')),
    price: Yup.string().required(t('required')),
    repeat: Yup.number().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: [],
      class_name: '',
      class_duration: '',
      begin_date: new Date(),
      end_date: new Date(),
      employees: [],
      price: '',
      repeat: 1,
      notes: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const data = new FormData();
      data.append('locations', JSON.stringify(values.locations));
      data.append('employees', JSON.stringify(values.employees));
      // data.append('packages', JSON.stringify(values.packages));
      data.append('class_name', values.class_name);
      data.append('class_duration', values.class_duration);
      data.append('begin_date', values.begin_date.toISOString());
      data.append('end_date', values.end_date.toISOString());
      data.append('price', values.price);
      data.append('notes', values.notes);
      data.append('repeat', values.repeat);
      data.append('photo', photo);
      data.append('status', status ? 1 : 0);

      if (state) {
        data.append('delete_payment_date', 'false');
        classApi
          .update(state, data)
          .then((res) => {
            setLoading(false);
            navigate('/app/class');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      } else {
        classApi
          .create(data)
          .then((res) => {
            setLoading(false);
            navigate('/app/class');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const getByLocationId = (id) => {
    setLoading(true);
    employees
      .getAll(id + '&is_coach=1')
      .then((resp) => {
        if (resp.results.length === 0) {
          setSnackbarMessage('Empty staffs');
          setOpen(true);
        } else {
          setEmployeeSchema(
            resp.results.map((item) => ({
              name: item.user_id.name,
              id: item.employee_id,
            }))
          );
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              getByLocationId(id);
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  const handleChangeLocationChips = (event, values) => {
    setSelectedLocation(values);
    formik.setFieldValue(
      'locations',
      values.map((item) => item.id)
    );
    const query = `status=1&locations=${values
      .map((item) => item.id)
      .join('&locations=')}`;

    getByLocationId(query);
  };

  const handleChangeEmployeeChips = (event, values) => {
    setSelectedStaffs(values);
    formik.setFieldValue(
      'employees',
      values.map((item) => item.id)
    );
  };

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
    }
  };

  const uploadImg = () => {
    document.getElementById('selectFile').click();
  };

  const loadingData = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationSchema(
          res.results.map((item) => ({
            name: item.location_name,
            id: item.location_id,
          }))
        );
        if (state) {
          classApi
            .getOne(state)
            .then((resp) => {
              const locationIds = [];
              resp.locations.forEach((item) => {
                locationIds.push(item.location_id);
              });
              const query = `status=1&locations=${locationIds.join(
                '&locations='
              )}`;
              employees
                .getAll(query)
                .then((respS) => {
                  if (respS.results.length === 0) {
                    setSnackbarMessage('Empty staffs');
                    setOpen(true);
                  } else {
                    setEmployeeSchema(
                      respS.results.map((item) => ({
                        name: item.user_id.name,
                        id: item.employee_id,
                      }))
                    );
                    const arr2 = [];

                    respS.results.forEach((value) => {
                      resp.employees.forEach((item) => {
                        if (value.employee_id === item.employee_id) {
                          arr2.push({
                            id: value.employee_id,
                            name: value.user_id.name,
                          });
                        }
                      });
                    });
                    setSelectedStaffs(arr2);
                  }
                })
                .catch((err) => console.log(err));

              formik.setValues({
                ...formik.values,
                locations: resp.locations.map((item) => item.location_id),
                class_name: resp.class_name,
                class_duration: resp.class_duration,
                price: resp.price,
                repeat: resp.repeat,
                notes: resp.notes,
                begin_date: new Date(resp.begin_date),
                end_date: new Date(resp.end_date),
                employees: resp.employees.map((item) => item.employee_id),
                // packages: resp.packages.map((item) => item.package_id),
              });
              const arr1 = [];

              res.results.forEach((value) => {
                resp.locations.forEach((item) => {
                  if (value.location_id === item.location_id) {
                    arr1.push({
                      id: value.location_id,
                      name: value.location_name,
                    });
                  }
                });
              });
              setSelectedLocation(arr1);

              if (resp.photo) {
                setPreviewImg(resp.photo);
              }
              if (resp.status === 1) {
                setStatus(true);
              } else {
                setStatus(false);
              }
            })
            .catch((err) => console.log(err));
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  // const packageSchema = packageList.map((item) => ({
  //   name: item.package_name,
  //   id: item.package_id,
  // }));

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        position: 'relative',
        backgroundColor: '#E5E8EC',
        height: '100vh',
        p: 3,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {state ? t('edit') : t('add')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container>
          {/* <Grid item md={3} xs={12}>
            <Box
              sx={{
                py: 2,
              }}
            >
              <CardMedia
                component="img"
                style={{
                  width: 210,
                  height: 210,
                  borderRadius: 5,
                  objectFit: 'fill',
                }}
                image={previewImg || UploadIcon}
                alt=""
              />

              <input
                id="selectFile"
                type="file"
                accept=".png,.jpg"
                style={{ display: 'none' }}
                onChange={imgSelectHandler.bind(this)}
              />
              <Box
                sx={{
                  py: 0.5,
                }}
              />
              <Button
                type="file"
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  width: 210,
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
                color="error"
                onClick={uploadImg}
              >
                {previewImg ? t('change') : t('select_image')}
              </Button>
            </Box>
          </Grid> */}

          <Grid item md={12} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <MultiChipSelect
                      labelText={t('location')}
                      name="locations"
                      items={locationSchema}
                      handleChangeChips={handleChangeLocationChips}
                      selectedItems={selectedLocation}
                      optionText={t('no_option')}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.locations &&
                        formik.touched.locations &&
                        formik.errors.locations}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    name="class_name"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    label={t('class_name')}
                    value={formik.values.class_name}
                    error={
                      formik.touched.class_name &&
                      Boolean(formik.errors.class_name)
                    }
                    helperText={
                      formik.touched.class_name && formik.errors.class_name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    name="class_duration"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    label={t('class_duration')}
                    value={formik.values.class_duration}
                    error={
                      formik.touched.class_duration &&
                      Boolean(formik.errors.class_duration)
                    }
                    helperText={
                      formik.touched.class_duration &&
                      formik.errors.class_duration
                    }
                  />
                </Grid>
              </Grid>
              <Grid spacing={2} container my={1}>
                <Grid item xl={6} md={6} xs={6}>
                  <TextField
                    size="small"
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    name="price"
                    type="number"
                    pattern="[0,9]*"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    label={t('price')}
                    value={formik.values.price}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} my={1}>
                <Grid item md={6} xs={6} xl={6} sm={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.input}>
                      <DatePicker
                        label={t('begin_date')}
                        name="begin_date"
                        openTo="year"
                        mask="__/__/____"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.begin_date}
                        onChange={(e) => formik.setFieldValue('begin_date', e)}
                        error={
                          formik.touched.begin_date &&
                          Boolean(formik.errors.begin_date)
                        }
                        helperText={
                          formik.touched.begin_date && formik.errors.begin_date
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.input}>
                      <DatePicker
                        label={t('end_date')}
                        name="end_date"
                        openTo="year"
                        mask="__/__/____"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.end_date}
                        onChange={(e) => formik.setFieldValue('end_date', e)}
                        error={
                          formik.touched.end_date &&
                          Boolean(formik.errors.end_date)
                        }
                        helperText={
                          formik.touched.end_date && formik.errors.end_date
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} sm={12} my={2}>
                <FormControl size="small" sx={{ width: '100%' }}>
                  <MultiChipSelect
                    labelText={t('coaches')}
                    name="employees"
                    items={employeeSchema}
                    handleChangeChips={handleChangeEmployeeChips}
                    selectedItems={selectedStaffs}
                    optionText={t('no_option')}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.staffs &&
                      formik.touched.employees &&
                      formik.errors.employees}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={12} sm={12} my={2}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('club_info.classes.include_membership')}
                </Typography>
                <FormControl size="small" sx={{ width: '100%' }}>
                  <MultiChipSelect
                    name="packages"
                    items={packageSchema}
                    deleteChips={handleDeleteChipsPackages}
                    handleChangeChips={handleChangeChips}
                    selectedItems={selectedPackages}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.packages &&
                      formik.touched.packages &&
                      formik.errors.packages}
                  </FormHelperText>
                </FormControl>
              </Grid> */}
              <Grid item xl={12} md={12} xs={12}>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="notes"
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  label={t('description')}
                  value={formik.values.notes}
                />
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}
                my={3}
              >
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={() => navigate('/app/class')}
                >
                  {t('cancel')}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => console.log()}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(state, formik.isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
