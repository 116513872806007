import { React, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Card,
  Container,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  Button,
  InputLabel,
  IconButton,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Filter as FilterIcon, Search as SearchIcon } from 'react-feather';
import { AnimatePresence } from 'framer-motion';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Loading from '../../../../components/Loading';
import ModalWindow from '../../../../components/Modals';
import { toolTips } from './helper';
import Table from '../../../../components/Table';
import { ordersHeader } from '../../../../redux/modules/table/common';
import { headerMaker } from '../../../../components/Table/helper';
import {
  fetchData,
  setOrderData,
} from '../../../../redux/modules/orders/actions';
import { setTotals } from '../../../../redux/modules/store/actions';
import FilterTools from '../../../../components/FilteredBody/PaymentDetails';
import AnimationDiv from '../../../../components/Animations/Transition';
import { useQuery } from '../../../useQuery';
import locations from '../../../../services/locations';
import MakePaymentModal from '../Modals/MakePayment';
import { useStyles } from '../../../../components/GlobalStyles/modals';

export default () => {
  const classes = useStyles();
  const {
    query,
    search,
    status,
    setSearch,
    setStatus,
    handleOnTableChange,
    setLocationId,
  } = useQuery({ fetchData });

  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [locationList, setLocationList] = useState([]);
  const { data, total } = useSelector((state) => state.ordersReducer);
  localStorage.setItem('total_data', total);
  const language = useSelector((state) => state.langsReducer.language);
  const [loading, setLoading] = useState(false);
  const headers = useMemo(() => headerMaker(ordersHeader), [ordersHeader]);
  const [modal, setModal] = useState(false);

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: 'all',
    },
    validationSchema,
  });

  const getByLocationId = (id) => {
    setLoading(true);
    formik.setFieldValue('locations', id);
    if (id === 'all') {
      const locationIds = [];
      locationList.forEach((item) => {
        locationIds.push(item.location_id);
      });
      const query = `${locationIds.join('&locations=')}`;
      setLocationId(query);
    } else {
      setLocationId(id);
    }
    setLoading(false);
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const loadingData = () => {
    dispatch(setTotals(total));
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        if (
          res.results.filter((item) => item.location_name !== '').length !== 0
        ) {
          setLocationList(
            res.results.filter((item) => item.location_name !== '')
          );

          const locationIds = res.results.map((item) => item.location_id);
          const query = `${locationIds.join('&locations=')}`;
          setLocationId(query);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    loadingData();
  }, [language]);

  const handleModal = () => {
    setModal(!modal);
  };

  const loaded = () => {
    setSearch('a');
    setSearch('');
  };

  const doubleClick = (id) => {
    dispatch(setOrderData(id));
    handleModal();
  };

  return (
    <>
      <Helmet>
        <title> {t('payments')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ModalWindow
            title={t('make_payment')}
            modal={modal}
            handleModal={handleModal}
            maxWidth="80%"
            fullScreen={true}
            body={<MakePaymentModal load={loaded} onClose={handleModal} />}
          />
          <Loading loading={loading} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} sm={12}>
              <div className={classes.navFilterSection}>
                <TextField
                  className={classes.search}
                  size="small"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  label={t('search')}
                  // onChange={(e) => setSearch(e.target.value)}
                  variant="outlined"
                />
              </div>
            </Grid>
            {/* <Grid item xs={6} md={6} sm={6}>
              <div className={classes.topBtns}>
                <Button
                  sx={{
                    backgroundColor: 'white',
                    color: '#161616',
                    textTransform: 'none',
                    borderColor: '#F3F3F3',
                    border: 1,
                  }}
                  variant="contained"
                  startIcon={<OpenInNewIcon />}
                  // onClick={() => setIsShow(!isShow)}
                >
                  {t('export')}
                </Button>
                
              </div>
            </Grid> */}
          </Grid>
          <Grid container spacing={1} my={0.5}>
            <Grid item xs={11} md={11} sm={11}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="locations">{t('location')}</InputLabel>
                <Select
                  labelId="locations"
                  label={t('location')}
                  className={classes.input}
                  fullWidth
                  name="locations"
                  value={formik.values.locations}
                  onBlur={formik.handleBlur}
                  onChange={(e) => getByLocationId(e.target.value)}
                >
                  <MenuItem key="all" value="all">
                    {t('all')}
                  </MenuItem>
                  {locationList.map((item, index) => (
                    <MenuItem key={`${index + 1}`} value={item.location_id}>
                      {item.location_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                color="primary"
                aria-label="delete"
                size="medium"
                onClick={() => {
                  setIsShow(!isShow);
                }}
              >
                <FilterListIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>

          <AnimatePresence className={classes.filters}>
            {isShow ? (
              <AnimationDiv>
                <FilterTools status={status} setStatus={setStatus} />
              </AnimationDiv>
            ) : null}
          </AnimatePresence>
          <Box sx={{ pt: 2 }}>
            <div className={classes.root}>
              <Card>
                <Table
                  data={data}
                  headers={headers}
                  toolTips={toolTips}
                  onChange={handleOnTableChange}
                  load={loadingData}
                  modal={handleModal}
                  doubleClick={doubleClick}
                />
              </Card>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};
