import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useTranslation } from 'react-i18next';

export default ({ rest }) => {
  // console.log(rest);
  const { t } = useTranslation();
  const photoUrl = process.env.REACT_APP_BASE_URL.replace('/api/v1', '');

  return (
    <Card sx={{ height: rest.length === 0 ? 110 : 350 }}>
      <CardHeader title={t('coaches')} />
      {rest.length !== 0 ? (
        <CardContent>
          <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Typography color="textPrimary" variant="h6">
                {t('coaches')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textPrimary" variant="h6">
                {t('members')}
              </Typography>
            </Grid>
          </Grid>

          <Box>
            <List
              dense
              sx={{
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                height: 270,
                '&::-webkit-scrollbar': {
                  width: 5,
                },
                '&::-webkit-scrollbar-track': {
                  // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'darkgrey',
                  // outline: '1px solid slategrey',
                  borderRadius: 2,
                },
              }}
            >
              {rest.map((value, index) => {
                return (
                  <div key={`${index + 1}`}>
                    <ListItem
                      key={value}
                      secondaryAction={
                        <Typography edge="end" color="textPrimary" variant="h5">
                          {`${value.customers}`}
                        </Typography>
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          {value.user_id.photo ? (
                            <Avatar alt="" src={`${value.user_id.photo}`} />
                          ) : (
                            <Avatar />
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={`${value.user_id.name}`} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
            </List>
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Typography variant="h6">{t('no_data')}</Typography>
        </CardContent>
      )}
    </Card>
  );
};
