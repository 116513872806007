import { service } from '.';

export default {
  getAll: (query) => service.get(`/classes/?${query}`),
  getOne: (id) => service.get(`/classes/${id.class_id}/`),
  getByLocationId: (query) => service.get(`/classes/?locations=${query}`),
  create: (data) => service.post('/classes/', data),
  update: (id, data) => service.patch(`/classes/${id.class_id}/`, data),
  changeStatus: (id, data) => service.patch(`/classes/status/${id}/`, data),
};
