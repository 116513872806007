import types from '../../../constants/action-types';

export const setData = (payload) => ({
  type: types.TABLE_ROOMS_SET_DATA,
  payload,
});

export const setTotal = (payload) => ({
  type: types.TABLE_ROOMS_SET_TOTAL,
  payload,
});
export const setError = (payload) => ({
  type: types.TABLE_ROOMS_ERROR,
  payload,
});
export const setLoading = (payload) => ({
  type: types.TABLE_ROOMS_SET_LOADING,
  payload,
});
export const fetchData = (payload) => ({
  type: types.TABLE_ROOMS_FETCH_DATA,
  payload,
});

export const onSearch = (payload) => ({
  type: types.TABLE_ROOMS_SAGA_ONSEARCH_ACTION,
  payload,
});
