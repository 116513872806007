import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';
import TimePicker from '@mui/lab/TimePicker';
import IconButton from '@mui/material/IconButton';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  CardMedia,
  colors,
  FormHelperText,
  Switch,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import Loading from '../../../../../components/Loading';
import MultiChipSelect from '../../../../../components/FormElements/MultiSelect';
import { IOSSwitch } from '../../../../../components/FormElements/IOSSwitch/style';
import locations from '../../../../../services/locations';
import ordersHistory from '../../../../../services/orders_history';
import customers from '../../../../../services/customers';
import styles from './style.module.css';
import { useStyles } from './style';
import UploadIcon from '../../../../../assets/icons/upload.svg';
import { useHideModal } from '../../../../../hooks';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function valuetext(value) {
  return `${value}`;
}

export default ({ load, onClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [classList, setClassList] = useState([]);
  const language = useSelector((state) => state.langsReducer.language);
  const { t } = useTranslation();
  const { state } = useLocation();
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const paymentItem = useSelector((state) => state.ordersHistoryReducer.item);
  const { hideModal } = useHideModal();
  const [loading, setLoading] = useState(false);
  const [serviceType, setServiceType] = useState('');
  const [amount, setAmount] = useState(0);
  const [allAmount, setAllAmount] = useState(0);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    load();
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    payment_date: Yup.date().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      order_number: '',
      customer_name: '',
      phone: '',
      service_type: '',
      service_name: '',
      payed_date: new Date(),
      promo: '123456789',
      success_payment_dates: [],
      amount: 0,
      notes: '',
      reason_free: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const data = new FormData();

      if (!isFree && paymentMethod === '') {
        setSnackbarMessage('Select payment method');
        setOpen(true);
        setIsSubmitting(false);
      } else {
        setLoading(true);
        data.append('order_id', paymentItem.order_uuid_id);
        data.append('locations', JSON.stringify(paymentItem.locations));
        data.append('order_number', paymentItem.order_number);
        data.append('amount', paymentItem.amount);
        data.append('state', 1);
        data.append('customer', JSON.stringify(paymentItem.customer));
        data.append('booking', JSON.stringify(paymentItem.booking));
        data.append('classes', JSON.stringify(paymentItem.classes));
        data.append('package', JSON.stringify(paymentItem.package));
        data.append('service_type', paymentItem.service_type);
        data.append('payment_date', values.payment_date);
        data.append('payed_date', values.payed_date.toISOString());
        data.append('payment_type', paymentMethod);
        data.append('promo', values.promo);
        data.append('notes', values.notes);
        data.append('is_free', isFree ? 1 : 0);
        data.append('reason_free', values.reason_free);

        ordersHistory
          .create(data)
          .then((res) => {
            setLoading(false);
            hideModal();
            // navigate('/app/customers');
          })
          .catch((err) => {
            setLoading(false);
            setIsSubmitting(false);
            console.log(err);
          });
        // if (state) {
        //   ordersHistory
        //     .update(state, data)
        //     .then((res) => {
        //       // navigate('/app/customers');
        //     })
        //     .catch((err) => console.log(err));
        // } else {

        // }
      }
    },
  });

  const handleNamer = (item) => {
    if (item.service_type === 'Booking') {
      return item.booking.service_name;
    }
    if (item.service_type === 'Package') {
      return item.package.package_name;
    }
    if (item.service_type === 'Class') {
      return item.classes.class_name;
    }
    return '';
  };

  const handleAmount = (item) => {
    if (item.service_type === 'Booking') {
      return item.booking.price;
    }
    if (item.service_type === 'Package') {
      return item.package.price;
    }
    if (item.service_type === 'Class') {
      return item.classes.price;
    }
    return '';
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      order_number: paymentItem.order_number,
      customer_name: paymentItem.customer.user_id.fields.name,
      phone: paymentItem.customer.user_id.fields.phone,
      service_type: paymentItem.service_type,
      service_name: handleNamer(paymentItem),
      payment_date: paymentItem.payment_date,
      amount: paymentItem.amount,
      success_payment_dates:
        paymentItem.success_payment_dates !== 0
          ? paymentItem.success_payment_dates
          : [],
      notes: '',
      reason_free: '',
    });
    const free = paymentItem.is_free === 1;
    setIsFree(free);
    setPaymentMethod(paymentItem.payment_type);
    setAmount(paymentItem.amount);
    setAllAmount(handleAmount(paymentItem));
    setServiceType(t(`${paymentItem.service_type.toLowerCase()}`));
  }, [paymentItem]);

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>

        <Grid container>
          <Grid item md={9} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid item xl={12} md={6} xs={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('order_number')}: {formik.values.order_number}
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('customer')}
                  </Typography>
                  <TextField
                    disabled
                    label=""
                    className={classes.search}
                    name="customer_name"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    value={formik.values.customer_name}
                  />
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('phone')}
                  </Typography>
                  <InputMask
                    disabled
                    size="small"
                    className={classes.inputmask}
                    onChange={formik.handleChange}
                    name="phone"
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="+998 (_ _) _ _ _ - _ _ - _ _"
                    mask="+\9\98 (99) 999-99-99"
                    maskChar="_"
                    style={{
                      border: '1px solid #c4c4c4',
                      outline: '1px solid #F7F8FA',
                      paddingLeft: '14px',
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </FormHelperText>
                </Grid>
              </Grid>
              {amount !== 0 ? (
                <Grid container spacing={2} my={1}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('payment_date')}
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={localeMap[language]}
                    >
                      <Stack spacing={3}>
                        <DatePicker
                          disabled={amount === 0}
                          name="payed_date"
                          // openTo="year"
                          mask="__/__/____"
                          views={['year', 'month', 'day']}
                          // minDate={new Date()}
                          value={formik.values.payed_date}
                          onChange={(e) =>
                            formik.setFieldValue('payed_date', e)
                          }
                          error={
                            formik.touched.payed_date &&
                            Boolean(formik.errors.payed_date)
                          }
                          InputProps={{
                            style: {
                              backgroundColor: theme.palette.background.paper,
                            },
                          }}
                          InputLabelProps={{
                            style: { color: 'black' },
                          }}
                          helperText={
                            formik.touched.payed_date &&
                            formik.errors.payed_date
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={2} my={1}>
                <Grid item xs={12} md={6} sm={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('service_type')}: {serviceType}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 4,
                      }}
                    >
                      <Grid item xs={12} md={6} sm={12}>
                        <Button
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            height: '100%',
                            backgroundColor: '#E5EEF7',
                            color: colors.grey[700],
                          }}
                          variant="contained"
                          onClick={() => {}}
                        >
                          {formik.values.service_name}
                        </Button>
                      </Grid>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xl={12} md={12} xs={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('description')}
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="notes"
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={t('enter_notes')}
                  value={formik.values.notes}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('sub_total')}
                </Typography>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {amount === 0
                    ? allAmount + ' ' + t('sum')
                    : formik.values.amount + ' ' + t('sum')}
                </Typography>
              </Grid>
              {formik.values.success_payment_dates.length !== 0 ? (
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('old_pay')}
                  </Typography>
                </Grid>
              ) : null}
              {formik.values.success_payment_dates.map((item, index) => {
                return (
                  <Grid key={`${index + 1}`} item md={12} xs={12}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {`${index + 1}`}. To`lov: {item.amount + ' ' + t('sum')};
                      Sana: {item.date}
                    </Typography>
                  </Grid>
                );
              })}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('promo')}
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="promo"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    value={formik.values.promo}
                  />
                </Grid>
              </Grid>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={amount === 0}
                      name="is_free"
                      checked={isFree}
                      value={isFree}
                      onChange={() => {
                        setIsFree(!isFree);
                      }}
                      color="success"
                    />
                  }
                  variant="standard"
                  label={t('is_free')}
                />
              </Grid>
              {isFree ? (
                <Grid item xl={12} md={12} xs={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('reason_free')}
                  </Typography>
                  <TextField
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    name="reason_free"
                    fullWidth
                    multiline
                    rows={4}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_reason_free')}
                    value={formik.values.reason_free}
                  />
                </Grid>
              ) : null}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={(event) => {
                    onClose();
                    load();
                  }}
                >
                  {t('cancel')}
                </Button>
                {/* <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => setIsSubmitting(true)}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(state, isSubmitting)}
                </Button> */}
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
