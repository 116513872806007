import { service } from '.';

export default {
  getAll: (query) => service.get(`/employees/?${query}`),
  getOne: (id) => service.get(`/employees/${id.employee_id}/`),
  getByLocationId: (query) => service.get(`/employees/?locations=${query}`),
  create: (data) => service.post('/employees/', data),
  update: (id, data) => service.patch(`/employees/${id.employee_id}/`, data),
  changeStatus: (id, data) => service.patch(`/employees/status/${id}/`, data),
};
