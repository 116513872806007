import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  colors,
  Grid,
  TextField,
  Typography,
  Card,
  Divider,
  CircularProgress,
  FormControlLabel,
  Container,
  Select,
  MenuItem,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useFormik } from 'formik';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/Loading';
import exam from '../../../../services/exam';
import locations from '../../../../services/locations';
import classesApi from '../../../../services/class';
import staffs from '../../../../services/employees';
import { useStyles } from './style';
import { IOSSwitch, Alert } from '../../../../components/GlobalStyles';
import { omrType, scienceList } from '../../../../constants';

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [locationSchema, setLocationSchema] = useState([]);
  const [employeeSchema, setEmployeeSchema] = useState([]);
  const [classesSchema, setClassesSchema] = useState([]);

  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
    employee: Yup.string().required(t('required')),
    classes: Yup.string().required(t('required')),
    name: Yup.string().required(t('required')),
    omr_type: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: '',
      name: '',
      employee: '',
      classes: '',
      omr_type: '',
      blok_ball: [],
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const data = new FormData();

      data.append('locations', values.locations);
      data.append('employee', values.employee);
      data.append('classes', values.classes);
      data.append('name', values.name);
      data.append('omr_type', values.omr_type);
      data.append('blok_ball', JSON.stringify(values.blok_ball));
      data.append('notes', values.notes);
      data.append('status', status ? 1 : 0);

      if (state) {
        exam
          .update(state, data)
          .then((res) => {
            setLoading(false);
            navigate('/app/exam');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      } else {
        exam
          .create(data)
          .then((res) => {
            setLoading(false);
            navigate('/app/exam');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const getByLocationId = (id) => {
    setLoading(true);
    staffs
      .getAll(id + '&is_coach=1')
      .then((resp) => {
        if (resp.results.length === 0) {
          setSnackbarMessage('Empty staffs');
          setOpen(true);
        } else {
          setEmployeeSchema(
            resp.results.map((item) => ({
              name: item.user_id.name,
              id: item.employee_id,
            }))
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    classesApi
      .getAll(id)
      .then((ress) => {
        if (ress.results.length === 0) {
          setSnackbarMessage('Empty rooms');
          setOpen(true);
        } else {
          setClassesSchema(
            ress.results.map((item) => ({
              name: item.class_name,
              id: item.class_id,
            }))
          );
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const loadingData = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationSchema(
          res.results.map((item) => ({
            name: item.location_name,
            id: item.location_id,
          }))
        );

        if (state) {
          exam
            .getOne(state)
            .then((resp) => {
              const query = `status=1&locations=${resp.locations.location_id}`;
              staffs
                .getAll(query + '&is_coach=1')
                .then((respS) => {
                  if (respS.results.length === 0) {
                    setSnackbarMessage('Empty staffs');
                    setOpen(true);
                  } else {
                    setEmployeeSchema(
                      respS.results.map((item) => ({
                        name: item.user_id.name,
                        id: item.employee_id,
                      }))
                    );
                  }
                })
                .catch((err) => console.log(err));

              classesApi
                .getAll(query)
                .then((ressR) => {
                  if (ressR.results.length === 0) {
                    setSnackbarMessage('Empty rooms');
                    setOpen(true);
                  } else {
                    setClassesSchema(
                      ressR.results.map((item) => ({
                        name: item.class_name,
                        id: item.class_id,
                      }))
                    );
                  }
                })
                .catch((err) => console.log(err));

              formik.setValues({
                ...formik.values,
                locations: resp.locations.location_id,
                name: resp.name,
                notes: resp.notes,
                employee: resp.employee.employee_id,
                classes: resp.classes.class_id,
                omr_type: resp.omr_type,
                blok_ball: resp.blok_ball,
              });

              if (resp.status === 1) {
                setStatus(true);
              } else {
                setStatus(false);
              }
            })
            .catch((err) => console.log(err));
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        p: 2,
        minHeight: '100%',
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography
              my={1}
              variant="h2"
              color="textPrimary"
              gutterBottom
              mt={2}
            >
              {state ? t('edit_exam') : t('add_exam')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('location')}
                </Typography>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <Select
                    className={classes.input}
                    fullWidth
                    name="locations"
                    value={formik.values.locations}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.setFieldValue('locations', event.target.value);
                      const query = `status=1&locations=${event.target.value}`;
                      getByLocationId(query);
                    }}
                  >
                    {locationSchema.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('name')}
                </Typography>
                <TextField
                  size="small"
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="name"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={t('enter_exam_name')}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('class')}
                </Typography>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <Select
                    className={classes.input}
                    fullWidth
                    name="classes"
                    value={formik.values.classes}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.setFieldValue('classes', event.target.value);
                    }}
                  >
                    {classesSchema.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('teacher')}
                </Typography>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <Select
                    className={classes.input}
                    fullWidth
                    name="employee"
                    value={formik.values.employee}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.setFieldValue('employee', event.target.value);
                    }}
                  >
                    {employeeSchema.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('omr_type')}
                </Typography>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <Select
                    className={classes.input}
                    fullWidth
                    name="omr_type"
                    value={formik.values.omr_type}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.setFieldValue('omr_type', event.target.value);
                      if (event.target.value === '90') {
                        const item = [
                          {
                            blok_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            blok_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            blok_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            blok_name: scienceList[0],
                            ball: 1,
                          },
                          {
                            blok_name: scienceList[0],
                            ball: 1,
                          },
                        ];
                        formik.setFieldValue('blok_ball', item);
                      }
                    }}
                  >
                    {omrType.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {formik.values.omr_type === '90' ? (
              <div key="90">
                <Grid key="90" container spacing={2} my={1}>
                  <Grid item md={12} xs={12}>
                    <Card sx={{ padding: 2, marginBottom: 5 }}>
                      <Grid key="90" container>
                        <Grid item md={6} sm={6}>
                          <Typography
                            my={1}
                            variant="h5"
                            align="left"
                            color={colors.grey[700]}
                          >
                            90
                          </Typography>
                        </Grid>
                        {formik.values.blok_ball.map((items, i) => (
                          <Grid key={`${i + 1}`} container spacing={2}>
                            <Grid item md={6} xs={12} sm={12}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {i + 1 + '. ' + t('blok')}
                              </Typography>
                              <FormControl
                                size="small"
                                sx={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                }}
                              >
                                <Select
                                  className={classes.input}
                                  fullWidth
                                  name="blok"
                                  value={items.blok_name}
                                  onChange={(event) => {
                                    console.log(event.target);
                                    formik.values.blok_ball[i].blok_name =
                                      event.target.value;
                                    formik.setFieldValue(
                                      'blok_ball',
                                      formik.values.blok_ball
                                    );
                                  }}
                                >
                                  {scienceList.map((item, index) => (
                                    <MenuItem key={`${index + 1}`} value={item}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {i + 1 + '. ' + t('ball')}
                              </Typography>
                              <TextField
                                size="small"
                                type="number"
                                InputProps={{
                                  style: {
                                    backgroundColor:
                                      theme.palette.background.paper,
                                  },
                                }}
                                InputLabelProps={{
                                  style: { color: 'black' },
                                }}
                                name="ball"
                                fullWidth
                                onBlur={formik.handleBlur}
                                onChange={(event) => {
                                  console.log(event.target);
                                  formik.values.blok_ball[i].ball =
                                    event.target.value;
                                  formik.setFieldValue(
                                    'blok_ball',
                                    formik.values.blok_ball
                                  );
                                }}
                                variant="outlined"
                                placeholder={t('enter_ball')}
                                value={items.ball}
                              />
                            </Grid>
                            <Divider />
                          </Grid>
                        ))}
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            ) : null}
            <Grid item xl={12} md={12} xs={12}>
              <Typography
                my={1}
                variant="h5"
                align="left"
                color={colors.grey[700]}
              >
                {t('description')}
              </Typography>
              <TextField
                size="small"
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                name="notes"
                fullWidth
                multiline
                rows={4}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('enter_notes')}
                value={formik.values.notes}
                // error={formik.touched.notes && Boolean(formik.errors.notes)}
              />
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 3,
              }}
            >
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => navigate('/app/exam')}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => console.log()}
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size="0.9rem" />
                  ) : undefined
                }
              >
                {renderButton(state, formik.isSubmitting)}
              </Button>
            </Box>
          </form>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
