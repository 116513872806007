export function dataSelector(dataResponse) {
  const handleNamer = (item) => {
    if (item.service_type === 'Booking') {
      return item.booking.service_name;
    }
    if (item.service_type === 'Package') {
      return item.package.package_name;
    }
    if (item.service_type === 'Class') {
      return item.classes.class_name;
    }
    return '';
  };
  const filtered = dataResponse.results.map((item) => ({
    id: item,
    customer_name: item.customer.user_id.fields.name,
    location: item.location,
    customer: item.customer,
    order_number: item.order_number,
    amount: item.amount,
    discount: item.discount,
    state: item.state,
    service_type: item.service_type,
    service_name: handleNamer(item),
    payment_type: item.payment_type,
    payment_date:
      (new Date(item.payment_date).getDate() < 10
        ? '0' + new Date(item.payment_date).getDate()
        : new Date(item.payment_date).getDate()) +
      '/' +
      (new Date(item.payment_date).getMonth() < 10
        ? '0' + (new Date(item.payment_date).getMonth() + 1)
        : new Date(item.payment_date).getMonth() + 1) +
      '/' +
      new Date(item.payment_date).getFullYear(),
    created_time:
      (new Date(item.createdAt).getDate() < 10
        ? '0' + new Date(item.createdAt).getDate()
        : new Date(item.createdAt).getDate()) +
      '/' +
      (new Date(item.createdAt).getMonth() < 10
        ? '0' + (new Date(item.createdAt).getMonth() + 1)
        : new Date(item.createdAt).getMonth() + 1) +
      '/' +
      new Date(item.createdAt).getFullYear(),
  }));
  return { total: dataResponse.count, data: filtered };
}
