import React from 'react';
import { Col, Row } from 'react-bootstrap';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import Typography from '@mui/material/Typography';

export default function Toolstack({ navigate }) {
  return (
    <Row style={{ justifyContent: 'center', paddingBottom: '50px' }}>
      <Col
        onClick={() => {
          navigate('/tests');
        }}
        xs={4}
        md={2}
        className="tech-icons"
      >
        <QuizIcon size="large" />
        <Typography variant="h2" gutterBottom>
          Testlar
        </Typography>
      </Col>
      <Col
        onClick={() => {
          navigate('/impel');
        }}
        xs={4}
        md={2}
        className="tech-icons"
      >
        <SchoolIcon />
        <Typography variant="h3" gutterBottom>
          Impel English Learning
        </Typography>
      </Col>
      {/* <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSpringboot />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiIntellijidea />
      </Col> */}
    </Row>
  );
}
