import {
  colors,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Container,
  Divider,
  Chip,
  FormHelperText,
  CardMedia,
  FormControl,
  Modal,
  Snackbar,
  Stack,
  InputLabel,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import 'quill-mention';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';
import { array, boolean, number, object, string, ValidationError } from 'yup';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { makeStyles, useTheme } from '@mui/styles';
import { React, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { TextField } from 'formik-mui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'quill-mention/dist/quill.mention.css';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SwipeableViews from 'react-swipeable-views';
import Tab from '@mui/material/Tab';
import MuiAlert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import jwtDecode from 'jwt-decode';
import Loading from '../../../components/Loading';
import { useStyles } from './style';
import club from '../../../services/club';
import location from '../../../services/locations';
import locations from '../../../constants/actions/locations';
import FacebookIcon from '../../../assets/icons/facebook.svg';
import InstagramIcon from '../../../assets/icons/instagram.svg';
import styles from './style.module.css';
import TelegramIcon from '../../../assets/icons/telegram.svg';
import UploadIcon from '../../../assets/icons/upload.svg';
import ModalWindow from '../../../components/Modals';
import AddLocationModal from './addLocation';
import CustomImage from '../../../components/QuillEditor/CustomImage';
import CustomImageSpec from '../../../components/QuillEditor/CustomImageSpec';
import CustomToolbar from '../../../components/QuillEditor/CustomToolbar';

// const Alert = React.forwardRef((props, ref) => {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

// Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register({
  // ... other formats
  'formats/image': CustomImage,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { state } = useLocation();

  const [registerClub, setRegisterClub] = useState({
    club_name: '',
    contact_person: '',
    email: '',
    website: '',
    locations: [],
  });
  const [terms, setTerms] = useState([]);
  const [socialNetworks, setSocialNetworks] = useState({
    networks: {
      facebook: '',
      instagram: '',
      telegram: '',
    },
  });

  const [value, setValue] = useState(0);
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [countryIndex, setCountryIndex] = useState(0);
  const [regionIndex, setRegionIndex] = useState(0);
  const [locationId, setLocationId] = useState('');
  const [selectLocationId, setSelectLocationId] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationList, setLocationList] = useState([]);

  const moduless = {
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize'],
    // },
    toolbar: {
      container: '#toolbar-1',
    },
    blotFormatter: {
      specs: [CustomImageSpec],
      overlay: {
        style: {
          border: '2px solid red',
        },
      },
    },
  };
  const formats = [
    'data-align',
    'float',
    'overlay',
    'height',
    'width',
    'class',
    'style',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'indent',
    'list',
    'direction',
    'align',
    'link',
    'image',
    'video',
    'formula',
  ];

  const countryList = [
    {
      country_name: 'uzbekistan',
      regions: [
        {
          region_name: 'tashkent_city',
          districts: [
            'bektemir',
            'chilonzor',
            'hamza',
            'mirobod',
            'mirzo_ulugbek',
            'sergeli',
            'shayxontohur',
            'olmazor',
            'uchtepa',
            'yakkasaroy',
            'yunusobod',
          ],
        },
        {
          region_name: 'tashkent',
          districts: [
            'bekobod',
            'bostonliq',
            'buka',
            'chinoz',
            'qibray',
            'ohangaron',
            'oqqorgon',
            'parkent',
            'piskent',
            'quyi_chirchiq',
            'orta_chirchiq',
            'yangiyol',
            'yuqori_chirchiq',
            'zangiota',
          ],
        },
        {
          region_name: 'fargona',
          districts: [
            'oltiariq',
            'bogdod',
            'beshariq',
            'buvayda',
            'dangara',
            'fargona',
            'furqat',
            'qoshtepa',
            'quva',
            'rishton',
            'sox',
            'toshloq',
            'uchkoprik',
            'uzbekistan',
            'yozyovon',
          ],
        },
        {
          region_name: 'andijon',
          districts: [
            'andijon',
            'asaka',
            'baliqchi',
            'boz',
            'buloqboshi',
            'izboskan',
            'jalaquduq',
            'xojaobod',
            'qorgontepa',
            'marhamat',
            'oltinkol',
            'paxtaobod',
            'shahrixon',
            'ulugnor',
          ],
        },
        {
          region_name: 'namangan',
          districts: [
            'chortoq',
            'chust',
            'kosonsoy',
            'mingbuloq',
            'namangan',
            'norin',
            'pop',
            'toraqorgon',
            'uchqorgon',
            'uychi',
            'yangiqorgon',
          ],
        },
        {
          region_name: 'jizzax',
          districts: [
            'arnasoy',
            'baxmal',
            'dostlik',
            'forish',
            'gallaorol',
            'sharof_rashidov',
            'mirzachol',
            'paxtakor',
            'yangiobod',
            'zomin',
            'zafarobod',
            'zarbdor',
          ],
        },
        {
          region_name: 'sirdaryo',
          districts: [
            'oqoltin',
            'boyovut',
            'guliston',
            'xovos',
            'mirzaobod',
            'sayxunbobod',
            'sardoba',
            'sirdaryo',
          ],
        },
        {
          region_name: 'qashqadaryo',
          districts: [
            'chiroqchi',
            'dehqonobod',
            'guzor',
            'qamashi',
            'qarxhi',
            'koson',
            'kasbi',
            'kitob',
            'mirishkor',
            'muborak',
            'nishon',
            'shahrisabz',
            'yakkabog',
          ],
        },
        {
          region_name: 'surxondaryo',
          districts: [
            'angor',
            'boysun',
            'denov',
            'jarqorgon',
            'qiziriq',
            'qumqorgon',
            'muzrabot',
            'oltinsoy',
            'sariosiyo',
            'sherobod',
            'shorchi',
            'termiz',
            'uzun',
          ],
        },
        {
          region_name: 'samarqand',
          districts: [
            'bulungur',
            'ishtixon',
            'jomboy',
            'kattaqorgon',
            'qoshrabot',
            'narpay',
            'nurobod',
            'oqdaryo',
            'paxtachi',
            'payariq',
            'pastdargom',
            'samarqand',
            'toyloq',
            'urgut',
          ],
        },
        {
          region_name: 'buxoro',
          districts: [
            'olot',
            'buxoro',
            'gijduvon',
            'jondor',
            'kogon',
            'qorakol',
            'qoravulbozor',
            'peshku',
            'romitan',
            'shofirkon',
            'vobkent',
          ],
        },
        {
          region_name: 'navoiy',
          districts: [
            'konimex',
            'karmana',
            'qiziltepa',
            'xatirchi',
            'navbahor',
            'nurota',
            'tomdi',
            'uchquduq',
          ],
        },
        {
          region_name: 'xorazm',
          districts: [
            'bogot',
            'gurlan',
            'xonqa',
            'hazorasp',
            'xiva',
            'qoshkopir',
            'shovot',
            'urganch',
            'yangiariq',
            'yangibozor',
            'tuproqqala',
          ],
        },
        {
          region_name: 'qoraqalpogiston',
          districts: [
            'amudaryo',
            'beruniy',
            'chimboy',
            'ellikqala',
            'kegeyli',
            'moynoq',
            'nukus',
            'qanlikol',
            'qongirot',
            'qoraozak',
            'shumanay',
            'taxtakopir',
            'tortkol',
            'xojayli',
          ],
        },
      ],
    },
  ];

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (registerClub.locations.length > 0) {
      const query = `user_id=${decodedToken.user_id}&location=${registerClub.locations[0].location_id}`;
      if (newValue === 1) {
        if (registerClub.locations.length > 0) {
          setSelectLocationId(registerClub.locations[0].location_id);
        }
        setLoading(true);
        club
          .getTerms(query)
          .then((resp) => {
            setTerms(resp);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else if (newValue === 2) {
        setLoading(true);
        club
          .getSocialNetworks(query)
          .then((resp) => {
            const data = resp[0];
            const networks = {
              facebook: resp[0].networks.facebook
                ? resp[0].networks.facebook
                : '',
              instagram: resp[0].networks.instagram
                ? resp[0].networks.instagram
                : '',
              telegram: resp[0].networks.telegram
                ? resp[0].networks.telegram
                : '',
            };
            data.networks = networks;
            setSocialNetworks(data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    } else {
      setSnackbarMessage(t('not_fount_location'));
      setOpen(true);
    }
  };

  const handleChangeLocation = (event, index) => {
    const nameValue = event.target.name;
    const i = event.target.value;
    setSnackbarMessage(t('location_info_update_successfully'));
    if (nameValue === 'region') {
      setRegionIndex(i);
      registerClub.locations[index].region = i;
      registerClub.locations[index].district = 0;
    } else if (nameValue === 'district') {
      registerClub.locations[index].district = i;
      setOpen(true);
    }
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
    }
  };

  const uploadImg = () => {
    document.getElementById('selectFile').click();
  };

  const renderButton = (isSubmitting) => {
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const getTermsByLocationId = (id) => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}&location=${id}`;
    club
      .getTerms(query)
      .then((resp) => {
        console.log(resp);
        setTerms(resp);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadingLocation = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    club
      .getRegisterClub(query)
      .then((resp) => {
        setRegisterClub(resp[0]);
        setLoading(false);
        if (resp[0].photo) {
          setPreviewImg(resp[0].photo);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingLocation();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingLocation();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleLocationModal = () => {
    setLocationModal(!locationModal);
  };

  return (
    <>
      <Helmet>
        <title>Akademik</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#E5E8EC',
          p: 1,
          minHeight: '100%',
        }}
      >
        <Container>
          <ModalWindow
            title={t('add_location')}
            modal={locationModal}
            handleModal={handleLocationModal}
            maxWidth="md"
            body={
              <AddLocationModal
                load={loadingLocation}
                onClose={handleLocationModal}
                countryList={countryList}
              />
            }
          />
          <Loading loading={loading} />
          <Modal hideBackdrop open={modal} onClose={handleModal}>
            <Box sx={{ ...style }}>
              <h2 id="child-modal-title">{t('is_delete')}</h2>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={() => handleModal()}
                >
                  {t('cancel')}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                  onClick={() => {
                    setIsSubmitting(true);
                    location.delete(locationId).then((res) => {
                      loadingLocation();
                      handleModal();
                    });
                  }}
                >
                  {renderButton(state, true)}
                </Button>
              </Box>
            </Box>
          </Modal>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <MuiAlert
                elevation={6}
                // ref={ref}
                variant="filled"
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </Stack>
          <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
            {t('register_center')}
          </Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={t('register_center')} />
            <Tab label={t('terms')} />
            {/* <Tab label={t('social_networks')} /> */}
          </Tabs>

          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            // onChangeIndex={handleChangeIndex}
          >
            <Box
              sx={{
                py: 6,
              }}
            >
              <Formik
                initialValues={{
                  club_name: registerClub.club_name,
                  contact_person: registerClub.contact_person,
                  email: registerClub.email,
                  website: registerClub.website,
                  locations: registerClub.locations,
                }}
                validationSchema={object({
                  locations: array().of(
                    object().shape({
                      location_name: string().required(t('required')),
                      // address: string().required(t('required')),
                      region: string().required(t('required')),
                      country: string().required(t('required')),
                      district: string().required(t('required')),
                      zipcode: string(),
                      status: number().required(t('required')),
                    })
                  ),
                  club_name: string().required(t('required')),
                  contact_person: string().required(t('required')),
                  email: string().required(t('required')),
                  // website: string().required(t('required')),
                })}
                onSubmit={async (values) => {
                  setLoading(true);
                  const data = new FormData();
                  data.append('club_name', values.club_name);
                  data.append('contact_person', values.contact_person);
                  data.append('email', values.email);
                  data.append('website', values.website);
                  data.append('locations', JSON.stringify(values.locations));
                  data.append('photo', photo);

                  club
                    .updateRegisterClub(registerClub.reg_id, data)
                    .then(() => {
                      setLoading(false);
                      setSnackbarMessage(t('center_info_update_successfully'));
                      setOpen(true);
                      setIsSubmitting(false);
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    });
                  // return new Promise((res) => setTimeout(res, 2500));
                }}
                enableReinitialize={true}
              >
                {({ values, errors, isValid, initialValues }) => (
                  <Grid container>
                    <Grid item md={3} xs={12}>
                      <Box
                        sx={
                          {
                            // py: 2,
                          }
                        }
                      >
                        <CardMedia
                          component="img"
                          style={{
                            width: 210,
                            height: 210,
                            borderRadius: 5,
                            objectFit: 'fill',
                          }}
                          image={previewImg || UploadIcon}
                          alt=""
                        />
                        <input
                          id="selectFile"
                          type="file"
                          accept=".png,.jpg"
                          style={{ display: 'none' }}
                          onChange={imgSelectHandler.bind(this)}
                        />
                        <Button
                          type="file"
                          fullWidth
                          size="small"
                          variant="contained"
                          sx={{
                            width: 210,
                            textTransform: 'none',
                            fontSize: 20,
                            marginTop: '0.5rem',
                            backgroundColor: '#2A455F',
                            transition: 'all 0.3s ease-in-out',
                            '&:hover': {
                              backgroundColor: '#2A455F',
                              opacity: 0.8,
                            },
                          }}
                          color="error"
                          onClick={uploadImg}
                        >
                          {previewImg ? t('change') : t('select_image')}
                        </Button>
                      </Box>
                    </Grid>

                    <Grid item md={9} xs={12} my={1}>
                      <Form autoComplete="off">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} sm={12}>
                            <Field
                              fullWidth
                              size="small"
                              name="club_name"
                              component={TextField}
                              label={t('center_name')}
                              InputProps={{
                                style: {
                                  backgroundColor:
                                    theme.palette.background.paper,
                                },
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Field
                              fullWidth
                              size="small"
                              name="contact_person"
                              component={TextField}
                              // onBlur={formik.handleBlur}
                              // label="Contact Person"
                              label={t('contact_person')}
                              InputProps={{
                                style: {
                                  backgroundColor:
                                    theme.palette.background.paper,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} my={1}>
                          <Grid item xs={12} md={6} sm={12}>
                            <Field
                              fullWidth
                              size="small"
                              name="email"
                              component={TextField}
                              label={t('email')}
                              InputProps={{
                                style: {
                                  backgroundColor:
                                    theme.palette.background.paper,
                                },
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Field
                              fullWidth
                              size="small"
                              name="website"
                              component={TextField}
                              label={t('website')}
                              InputProps={{
                                style: {
                                  backgroundColor:
                                    theme.palette.background.paper,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>

                        <FieldArray name="locations">
                          {({ push, remove }) => (
                            <div>
                              {values.locations.map((item, index) => (
                                <div
                                  key={item.location_id}
                                  style={{ margin: '20px 0' }}
                                >
                                  <Divider>
                                    <Chip
                                      label={`${t('location')} ${index + 1}`}
                                    />
                                    {decodedToken.owner * 1 === 1 ? (
                                      <IconButton
                                        className={styles.btn}
                                        aria-label="delete a location"
                                        component="span"
                                        onClick={() => {
                                          setLocationId(item.location_id);
                                          handleModal();
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    ) : null}
                                  </Divider>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} sm={12}>
                                      <Field
                                        InputProps={{
                                          style: {
                                            backgroundColor:
                                              theme.palette.background.paper,
                                          },
                                        }}
                                        fullWidth
                                        size="small"
                                        name={`locations.${index}.location_name`}
                                        component={TextField}
                                        label={t('location_name')}
                                        // label="Location name"
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={2} my={1}>
                                    <Grid item xs={12} md={12} sm={12}>
                                      <FormControl
                                        size="small"
                                        sx={{
                                          width: '100%',
                                          backgroundColor: 'white',
                                        }}
                                      >
                                        <InputLabel id="country">
                                          {t('country')}
                                        </InputLabel>
                                        <Select
                                          labelId="country"
                                          disabled
                                          MenuProps={MenuProps}
                                          fullWidth
                                          value="uzbekistan"
                                          name="country"
                                        >
                                          {countryList.map((item, i) => (
                                            <MenuItem
                                              key={`${i + 1}`}
                                              value={item.country_name}
                                            >
                                              {t(`places.${item.country_name}`)}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={6}>
                                      <FormControl
                                        size="small"
                                        sx={{
                                          width: '100%',
                                          backgroundColor: 'white',
                                        }}
                                      >
                                        <InputLabel id="region-label">
                                          {t('region')}
                                        </InputLabel>
                                        <Select
                                          label={t('region')}
                                          labelId="region-label"
                                          // className={classes.input}
                                          fullWidth
                                          onChange={(event) =>
                                            handleChangeLocation(event, index)
                                          }
                                          value={item.region}
                                          name="region"
                                          id="region"
                                        >
                                          {countryList[
                                            countryIndex
                                          ].regions.map((items, i) => (
                                            <MenuItem
                                              key={`${i + 1}`}
                                              value={i}
                                            >
                                              {t(
                                                `places.uzbekistan_regions.${items.region_name}`
                                              )}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={6}>
                                      <FormControl
                                        size="small"
                                        sx={{
                                          width: '100%',
                                          backgroundColor: 'white',
                                        }}
                                      >
                                        <InputLabel id="district">
                                          {t('district')}
                                        </InputLabel>
                                        <Select
                                          label={t('district')}
                                          labelId="district"
                                          className={classes.input}
                                          fullWidth
                                          onChange={(event) =>
                                            handleChangeLocation(event, index)
                                          }
                                          value={item.district}
                                          name="district"
                                        >
                                          {countryList[countryIndex].regions[
                                            item.region
                                          ].districts.map((items, i) => (
                                            <MenuItem
                                              key={`${i + 1}`}
                                              value={i}
                                            >
                                              {t(
                                                `places.uzbekistan_regions.${
                                                  countryList[countryIndex]
                                                    .regions[item.region]
                                                    .region_name
                                                }_districts.${items}`
                                              )}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6} sm={6}>
                                      <Field
                                        type="number"
                                        InputProps={{
                                          style: {
                                            backgroundColor:
                                              theme.palette.background.paper,
                                          },
                                          inputMode: 'numeric',
                                          pattern: '[0-9]*',
                                        }}
                                        // onChange={(event) => {
                                        //   console.log(event);
                                        // }}
                                        fullWidth
                                        size="small"
                                        name={`locations.${index}.latitude`}
                                        component={TextField}
                                        label="Latitude"
                                        // label="Location name"
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={6}>
                                      <Field
                                        type="number"
                                        InputProps={{
                                          style: {
                                            backgroundColor:
                                              theme.palette.background.paper,
                                          },
                                          inputMode: 'numeric',
                                          pattern: '[0-9]*',
                                        }}
                                        fullWidth
                                        size="small"
                                        name={`locations.${index}.longitude`}
                                        component={TextField}
                                        label="Longitude"
                                        // label="Location name"
                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                      <Field
                                        InputProps={{
                                          style: {
                                            backgroundColor:
                                              theme.palette.background.paper,
                                          },
                                        }}
                                        fullWidth
                                        size="small"
                                        multiline
                                        rows={4}
                                        name={`locations.${index}.address`}
                                        component={TextField}
                                        label={t('address')}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}

                              {/* <Divider /> */}
                              {values.locations.length <
                              decodedToken.locations_count ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 2,
                                    py: 2,
                                  }}
                                >
                                  <Grid item>
                                    <Button
                                      sx={{
                                        backgroundColor: 'transparent',
                                        color: colors.grey[700],
                                      }}
                                      name="add"
                                      variant="dashed"
                                      onClick={() => {
                                        console.log(
                                          decodedToken.locations_count
                                        );
                                        console.log(values.locations);
                                        if (
                                          values.locations.length <
                                          decodedToken.locations_count
                                        ) {
                                          setLocationModal(true);
                                        } else {
                                          setSnackbarMessage(
                                            t('end_locations_count')
                                          );
                                          setOpen(true);
                                        }
                                      }}
                                    >
                                      + {t('add_location')}
                                    </Button>
                                  </Grid>
                                </Box>
                              ) : null}
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  gap: 2,
                                }}
                              >
                                <Button
                                  sx={{
                                    backgroundColor: '#2A455F',
                                    color: colors.white,
                                    textTransform: 'none',
                                  }}
                                  name="add"
                                  variant="contained"
                                  disabled={isSubmitting}
                                  type="submit"
                                  color="primary"
                                  onClick={() => {
                                    console.log();
                                  }}
                                  startIcon={
                                    isSubmitting ? (
                                      <CircularProgress size="0.9rem" />
                                    ) : undefined
                                  }
                                >
                                  {isSubmitting ? t('submitting') : t('submit')}
                                </Button>
                              </Box>
                            </div>
                          )}
                        </FieldArray>
                      </Form>
                    </Grid>
                  </Grid>
                )}
              </Formik>
            </Box>
            <Box
              sx={{
                py: 2,
              }}
            >
              <Formik
                initialValues={{
                  location: selectLocationId,
                  term: terms.length === 0 ? '' : terms[0].term,
                }}
                validationSchema={object({
                  location: string().required(t('required')),
                  term: string().required(t('required')),
                })}
                onSubmit={async (values) => {
                  setLoading(true);
                  console.log(terms);
                  club
                    .updateTerms(terms[0].term_id, values)
                    .then(() => {
                      setLoading(false);
                      setSnackbarMessage(t('term_updated'));
                      setOpen(true);
                    })
                    .catch((err) => {
                      setLoading(false);
                      console.log(err);
                    });
                }}
                enableReinitialize={true}
              >
                {({ values, errors, touched, isSubmitting, isValid }) => (
                  <Form autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            py: 2,
                          }}
                        >
                          <Grid container spacing={2}>
                            {registerClub.locations.length !== 0 ? (
                              <Grid item xs={12} md={12} sm={12}>
                                <Field name="location">
                                  {({ field }) => (
                                    <div>
                                      <FormControl
                                        size="small"
                                        sx={{
                                          width: '100%',
                                          backgroundColor: 'white',
                                        }}
                                      >
                                        <InputLabel id="location">
                                          {t('location')}
                                        </InputLabel>
                                        <Select
                                          label={t('location')}
                                          labelId="location"
                                          size="small"
                                          // className={classes.input}
                                          fullWidth
                                          name="location"
                                          value={field.value}
                                          // onBlur={formik.handleBlur}
                                          onChange={(e) => {
                                            setSelectLocationId(e.target.value);
                                            getTermsByLocationId(
                                              e.target.value
                                            );
                                          }}
                                        >
                                          {registerClub.locations.map(
                                            (item, index) => (
                                              <MenuItem
                                                size="small"
                                                key={`${index + 1}`}
                                                value={item.location_id}
                                              >
                                                {item.location_name}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  )}
                                </Field>
                              </Grid>
                            ) : null}
                            <Grid item xs={12} sm={12}>
                              <Field name="term">
                                {({ field }) => (
                                  <div>
                                    <CustomToolbar id="1" />
                                    <ReactQuill
                                      value={field.value}
                                      onChange={field.onChange(field.name)}
                                      className={classes.search}
                                      modules={moduless}
                                      formats={formats}
                                      theme="snow"
                                    />
                                  </div>
                                )}
                              </Field>
                              <FormHelperText
                                sx={{
                                  color: colors.red[700],
                                }}
                              >
                                {errors.term && touched.term && errors.term}
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 2,
                          }}
                        >
                          <Button
                            sx={{
                              backgroundColor: '#2A455F',
                              color: colors.white,
                              textTransform: 'none',
                            }}
                            name="add"
                            variant="contained"
                            disabled={isSubmitting}
                            type="submit"
                            color="primary"
                            onClick={() => console.log()}
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="0.9rem" />
                              ) : undefined
                            }
                          >
                            {isSubmitting ? t('submitting') : t('submit')}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </SwipeableViews>
        </Container>
      </Box>
    </>
  );
};
