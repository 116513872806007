/* eslint-disable no-prototype-builtins */
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  InputLabel,
  Divider,
  IconButton,
  TextField,
  Avatar,
} from '@mui/material';
import TelegramLoginButton from 'react-telegram-login';
import MuiAlert from '@mui/material/Alert';
// import { styled } from '@mui/material/styles';
import GroupIcon from '@mui/icons-material/Group';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import { styled, lighten, darken } from '@mui/system';
import Loading from '../../../../../components/Loading';
import {
  setSelectTests,
  setCoin,
} from '../../../../../redux/modules/store/actions';
import { setToken } from '../../../../../redux/modules/auth/actions';
import { useStyles } from './style';
import ModalWindow from '../../../../../components/Modals';
import ModalWindowFullScreen from '../../../../../components/Modals/FullScreen';
import SignUpModal from '../../../../../components/Modals/SignUpModal';
import topicTest from '../../../../../services/topic_tests';
import testsApi from '../../../../../services/tests';
import authApi from '../../../../../services/auth';
import Results from '../../Groups/Modals/Results';
import exam from '../../../../../constants/actions/exam';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#ffffff' }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#000000',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default ({ ...rest }) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [groupTestList, setGroupTestList] = useState([]);
  const [examTestList, setExamTestList] = useState([]);

  const [exams, setExams] = useState({ bloks: [] });
  const [resultId, setResultId] = useState();

  const [examId, setExamId] = useState();
  const [examResults, setExamResults] = useState([]);
  const [examAnswers, setExamAnswers] = useState({ bloks: [] });
  const [showTestKeys, setShowTestKeys] = useState(false);

  const [durationTime, setDurationTime] = useState(0);

  const [selectKeyList, setSelectKeyList] = useState([]);

  const language = useSelector((state) => state.langsReducer.language);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [modalTelegram, setModalTelegram] = useState(false);
  const [modalNewGroup, setModalNewGroup] = useState(false);
  const [modalResults, setModalResults] = useState(false);
  const [expanded, setExpanded] = useState('groupInfo');

  const [modalStartEnd, setModalStartEnd] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [startEnd, setStartEnd] = useState(0);

  const [startExam, setStartExam] = useState(false);

  const [groupId, setGroupId] = useState();

  const Ref = useRef(null);
  const [timer, setTimer] = useState('00:00:00');

  const token = localStorage.getItem('token');

  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    group: Yup.string().required(t('required')),
    exam: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      group: '',
      exam: '',
    },
    validationSchema,
  });

  const timeConvert = (status, seconds) => {
    if (status) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
      setTimer(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    return 'Cheklanmagan';
  };

  const getTestsByExamId = (id) => {
    // setTestList([]);
    setLoading(false);
    setExamAnswers({ bloks: [] });
    setExamResults([]);

    testsApi
      .getOneExam(id)
      .then((res) => {
        setDurationTime(timeConvert(res.duration_status, res.duration_time));
        setExams(res);
        setExamId(res.test_exam_id);

        formik.setFieldValue('group', res.group);

        const arr = [];
        res.bloks.forEach((val) => {
          val.test_data.forEach((val2) => {
            const item = {
              scienceId: val.scienceId,
              key: 0,
              testId: val2.test_id,
            };
            arr.push(item);
          });
        });
        setSelectKeyList(arr);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getTestsByExamId2 = (id) => {
    setLoading(false);

    testsApi
      .getOneExamResult(resultId)
      .then((res2) => {
        const arr = [];
        setExamAnswers(res2);

        res2.bloks.forEach((val) => {
          val.test_data.forEach((val2) => {
            res2.keys.forEach((val3) => {
              if (
                val3.scienceId === val.scienceId &&
                val3.testId === val2.test_id
              ) {
                const item = {
                  scienceId: val.scienceId,
                  key: val3.key,
                  testId: val2.test_id,
                };
                arr.push(item);
              }
            });
            const item = {
              scienceId: val.scienceId,
              key: 0,
              testId: val2.test_id,
            };
            arr.push(item);
          });
        });
        setSelectKeyList(arr);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadingData = () => {
    setLoading(true);
    testsApi
      .getAllGroups('page_size=10')
      .then((res) => {
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        const options = res.map((option) => {
          return {
            group:
              option.group_owner.user_id === decodedToken.user_id
                ? 'Mening gruppalarim'
                : 'Gruppalar',
            ...option,
          };
        });
        setGroupTestList(options);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getTestsByExamId(id);
  }, [language]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleTelegramResponse = (response) => {
    authApi
      .authTelegram(`id=${response.id}&name=${response.first_name}`)
      .then((res) => {
        dispatch(setToken(res.data));
        dispatch(setCoin(res.data.coin));
        setModalTelegram(false);
      });
  };

  const getScore = () => {
    setLoading(true);
    const data = new FormData();
    data.append('exam', exams.test_exam_id);
    data.append('keys', JSON.stringify(selectKeyList));
    data.append('test_state', JSON.stringify(exams.test_state));
    data.append('status', 1);
    testsApi
      .addExamResult(data)
      .then((res) => {
        if (res.status) {
          setResultId(res.id);
          setExamResults(res.message);
          setExams({
            bloks: [],
            access: exams.access,
            name: exams.name,
            group: { name: exams.group ? exams.group.name : '' },
          });
          setStartExam(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const selectOption = (item, status, scienceId, key) => {
    const arr = selectKeyList;

    if (status) {
      const newArr = arr.map((obj) =>
        obj.testId === item.test_id ? { ...obj, key } : obj
      );

      setSelectKeyList(newArr);
    } else {
      arr.push({ scienceId, testId: item.test_id, key });
      setSelectKeyList(arr);
    }
    setStartExam(true);
  };

  const handleModalTelegram = () => {
    setModalTelegram(!modalTelegram);
  };

  const handleModalResults = () => {
    setModalResults(!modalResults);
  };

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    if (exams.access) {
      if (!isStart && exams.duration_status) {
        setModalStartEnd(true);
      } else {
        setExpanded(isExpanded ? panel : false);
      }
    } else {
      setSnackbarMessage(
        "Kechirasiz, sizga test yechish uchun ruxsat berilmagan. Test yechish uchun gruppaga qo'shilish kerak."
      );
      setOpen(true);
    }
  };

  const getTimeRemaining = (e, timer) => {
    const total = Date.parse(e) - Date.parse(new Date());
    if (total > 0) {
      const hours = Math.floor((total / 1000 / 3600) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const seconds = Math.floor((total / 1000) % 60);

      // const hours = Math.floor(total / 3600);
      // const minutes = Math.floor((total % 3600) / 60);
      // const seconds = total % 60;

      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');
      setTimer(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
    } else {
      clearInterval(timer);
      getScore();
    }
  };

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const timer = setInterval(() => {
      if (window.location.pathname === '/app/tests/groups') {
        getTimeRemaining(e, timer);
      } else {
        clearInterval(timer);
      }
    }, 1000);
    Ref.current = timer;
  };

  const getDeadTime = (time) => {
    const deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + time);
    return deadline;
  };

  const getKeyColorVariant = (item, optionKey) => {
    if (
      selectKeyList.some(
        (value) =>
          value.testId === item.test_id &&
          value.key === item.key &&
          item.key === optionKey
      )
    ) {
      return { color: 'success', variant: 'contained' };
    }
    if (
      selectKeyList.some(
        (value) =>
          value.testId === item.test_id &&
          value.key !== item.key &&
          value.key === optionKey
      )
    ) {
      return { color: 'error', variant: 'contained' };
    }
    return { color: 'warning', variant: 'outlined' };
  };

  const createNotification = () => {
    setLoading(true);
    const data = new FormData();
    data.append('message_type', 'group_join');
    data.append('receiver', exams.exam_owner.user_id);
    data.append('group', exams.group.test_group_id);
    data.append('request_type', 'new');
    data.append(
      'message',
      JSON.stringify({ group_id: exams.group.test_group_id })
    );
    data.append('status', 1);
    testsApi
      .createNotification(data)
      .then((res) => {
        setSnackbarMessage("So'rov yuborildi.");
        setOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleGroupInfoChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getGroupInfoData = () => {
    try {
      return JSON.parse(formik.values.group.info).data;
    } catch (error) {
      return formik.values.group.info;
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('Gruppa')}</title>
      </Helmet>
      <ModalWindow
        title={t('Eslatma')}
        modal={modalTelegram}
        handleModal={handleModalTelegram}
        maxWidth="sm"
        body={<SignUpModal handleTelegramResponse={handleTelegramResponse} />}
      />

      <ModalWindowFullScreen
        title={t('Natijalar')}
        modal={modalResults}
        handleModal={handleModalResults}
        maxWidth="lg"
        body={<Results id={examId} />}
      />
      <ModalWindow
        title={t('Boshlash/Tugatish')}
        modal={modalStartEnd}
        handleModal={() => {
          if (isStart) {
            setStartEnd(1);
          } else {
            setStartEnd(0);
          }

          setModalStartEnd(false);
        }}
        maxWidth="sm"
        body={
          <div>
            <Container>
              <Box className={classes.modalBody}>
                <Typography variant="h4" color="error">
                  {startEnd === 0
                    ? 'Boshlashni xoxlaysizmi ?'
                    : 'Tugatishni xoxlaysizmi ?'}
                </Typography>
              </Box>
              <Box className={classes.actionBtns}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setModalStartEnd(false)}
                >
                  Qaytish
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setModalStartEnd(false);

                    if (isStart) {
                      setStartEnd(0);

                      clearTimer(getDeadTime(0));
                      setIsStart(false);
                      getScore();
                    } else {
                      setStartEnd(1);

                      clearTimer(getDeadTime(exams.duration_time));
                      setIsStart(true);
                    }
                  }}
                >
                  Ha
                </Button>
              </Box>
            </Container>
          </div>
        }
      />
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3,
          px: 1,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>

          <TelegramLoginButton
            dataOnauth={handleTelegramResponse}
            botName={process.env.REACT_APP_TELEGRAM_BOT}
          />

          <Loading loading={loading} />
          <Card sx={{ marginRight: 1, marginBottom: 1 }}>
            <CardContent>
              <Grid container spacing={2}>
                {!exams.access ? (
                  <>
                    <Grid item xs={6} md={6} sm={6}>
                      <Typography variant="h6" color="red" mb={1} align="left">
                        Sizga test yechish uchun ruxsat berilmagan. Test yechish
                        uchun gruppaga qo`shilish kerak.
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} sm={6}>
                      <Button
                        sx={{
                          backgroundColor: '#2A455F',
                          color: '#FFFFFF',
                          textTransform: 'none',
                        }}
                        variant="contained"
                        startIcon={<GroupIcon />}
                        onClick={() => {
                          createNotification();
                        }}
                        // color="info"
                      >
                        {t("Gruppaga qo'shilish")}
                      </Button>
                    </Grid>
                  </>
                ) : null}
              </Grid>
              <Typography
                variant="h3"
                color="textPrimary"
                gutterBottom
                mb={1}
                mt={1}
              >
                Gruppa: {exams.group ? exams.group.name : ''}
              </Typography>

              <Typography variant="h3" color="textPrimary" gutterBottom mt={1}>
                Imtihon: {exams.name}
              </Typography>
            </CardContent>
          </Card>
          <Grid item xs={12} md={12} sm={12} my={1}>
            <Accordion
              expanded={expanded === 'groupInfo'}
              onChange={handleGroupInfoChangeExpanded('groupInfo')}
            >
              <AccordionSummary
                aria-controls="groupInfo-content"
                id="groupInfo-header"
              >
                <Grid container>
                  <Grid item xs={12} md={11} sm={12}>
                    <Typography sx={{ color: '#ffffff' }}>
                      Guruh haqida
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                  <CardContent>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: getGroupInfoData(),
                      }}
                    />
                    <Divider size="medium" color="primary" />
                  </CardContent>
                </Card>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {exams.bloks.length > 0 || examResults.length > 0 ? (
            <Grid container spacing={1}>
              <Grid item xs={6} md={6} sm={6}>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleModalResults();
                  }}
                >
                  Natijalar
                </Button>
              </Grid>
            </Grid>
          ) : null}
          {exams.bloks.length > 0 ? (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  gutterBottom
                  mb={1}
                  mt={1}
                >
                  Imtihon vaqti: {durationTime}
                </Typography>
              </Grid>
              {exams.duration_status ? (
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    gutterBottom
                    mb={1}
                    mt={1}
                  >
                    Vaqt: {timer}
                  </Typography>
                </Grid>
              ) : null}
              {exams.access && exams.duration_status && !startExam ? (
                <Button
                  fullWidth
                  sx={{
                    backgroundColor: 'green',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setModalStartEnd(true);
                  }}
                >
                  BOSHLASH
                </Button>
              ) : null}
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  gutterBottom
                  mb={1}
                  mt={1}
                >
                  {t('Bloklar')}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
          <Grid container my={1}>
            {exams.bloks.map((items, i) => (
              <Grid key={items.scienceId} item xs={12} md={12} sm={12} my={1}>
                <Accordion
                  expanded={expanded === `panel${i + 1}`}
                  onChange={handleChangeExpanded(`panel${i + 1}`)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Grid container>
                      <Grid item xs={12} md={11} sm={12}>
                        <Typography sx={{ color: '#ffffff' }}>
                          Blok #{i + 1} ({items.scienceName}) &nbsp; &nbsp;
                          Ball:
                          {items.blokBall}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container my={1}>
                      {items.test_data.map((item, i) => (
                        <Grid key={item.test_id} item xs={12} md={6} sm={12}>
                          <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                            <CardContent>
                              <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: item.question.replace(
                                    'numberNN',
                                    (page - 1) * pageSize + i + 1 + '. '
                                  ),
                                }}
                              />
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                    paddingRight: 1,
                                  }}
                                  variant={
                                    selectKeyList.some(
                                      (value) =>
                                        value.testId === item.test_id &&
                                        value.key === item.option_a.order
                                    )
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    selectKeyList.some(
                                      (value) =>
                                        value.testId === item.test_id &&
                                        value.key === item.option_a.order
                                    )
                                      ? 'primary'
                                      : 'warning'
                                  }
                                  startIcon={<Avatar>A</Avatar>}
                                  onClick={() => {
                                    selectOption(
                                      item,
                                      selectKeyList.some(
                                        (value) => value.testId === item.test_id
                                      ),
                                      items.scienceId,
                                      item.option_a.order
                                    );
                                  }}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_a.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>

                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    selectKeyList.some(
                                      (value) =>
                                        value.testId === item.test_id &&
                                        value.key === item.option_b.order
                                    )
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    selectKeyList.some(
                                      (value) =>
                                        value.testId === item.test_id &&
                                        value.key === item.option_b.order
                                    )
                                      ? 'primary'
                                      : 'warning'
                                  }
                                  startIcon={<Avatar>B</Avatar>}
                                  onClick={() => {
                                    selectOption(
                                      item,
                                      selectKeyList.some(
                                        (value) => value.testId === item.test_id
                                      ),
                                      items.scienceId,
                                      item.option_b.order
                                    );
                                  }}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_b.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    selectKeyList.some(
                                      (value) =>
                                        value.testId === item.test_id &&
                                        value.key === item.option_c.order
                                    )
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    selectKeyList.some(
                                      (value) =>
                                        value.testId === item.test_id &&
                                        value.key === item.option_c.order
                                    )
                                      ? 'primary'
                                      : 'warning'
                                  }
                                  startIcon={<Avatar>C</Avatar>}
                                  onClick={() => {
                                    selectOption(
                                      item,
                                      selectKeyList.some(
                                        (value) => value.testId === item.test_id
                                      ),
                                      items.scienceId,
                                      item.option_c.order
                                    );
                                  }}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_c.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    selectKeyList.some(
                                      (value) =>
                                        value.testId === item.test_id &&
                                        value.key === item.option_d.order
                                    )
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  color={
                                    selectKeyList.some(
                                      (value) =>
                                        value.testId === item.test_id &&
                                        value.key === item.option_d.order
                                    )
                                      ? 'primary'
                                      : 'warning'
                                  }
                                  startIcon={<Avatar>D</Avatar>}
                                  onClick={() => {
                                    selectOption(
                                      item,
                                      selectKeyList.some(
                                        (value) => value.testId === item.test_id
                                      ),
                                      items.scienceId,
                                      item.option_d.order
                                    );
                                  }}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_d.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                    {items.test_data.length === 0 ? (
                      <Typography
                        my={1}
                        variant="h4"
                        align="left"
                        color={colors.grey[700]}
                      >
                        {t('no_test_data')}
                      </Typography>
                    ) : null}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
            <br />
            {exams.bloks.length > 0 ? (
              <Button
                fullWidth
                sx={{
                  backgroundColor: 'red',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                color="primary"
                onClick={() => {
                  setModalStartEnd(true);
                  setStartEnd(1);
                  setIsStart(true);
                }}
              >
                JAVOBLARNI TEKSHIRISH
              </Button>
            ) : null}
          </Grid>
          <Grid container my={1}>
            {examResults.length > 0 ? (
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  Natija
                </Typography>
              </Grid>
            ) : null}
            {examResults.map((item, i) => (
              <Grid key={item.scienceId} item xs={6} md={6} sm={6}>
                <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                  <CardContent>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Blok - {item.scienceName}
                    </Typography>
                    <Divider size="medium" color="primary" />
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      To`g`ri javoblar - {item.score}
                    </Typography>
                    <Divider size="medium" color="primary" />
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Ball - {item.blokBall}
                    </Typography>
                    <Divider size="medium" color="primary" />
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      Umumiy - {item.score * item.blokBall}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {formik.values.exam !== '' && examResults.length > 0 ? (
              <Button
                fullWidth
                sx={{
                  backgroundColor: 'green',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                color="primary"
                onClick={() => {
                  getTestsByExamId2(formik.values.exam.test_exam_id);
                }}
              >
                Ko`rish
              </Button>
            ) : null}
          </Grid>
          <Grid container my={1}>
            {examAnswers.bloks.map((items, i) => (
              <Grid key={items.scienceId} item xs={12} md={12} sm={12} my={1}>
                <Accordion
                  expanded={expanded === `panel${i + 1}`}
                  onChange={handleChangeExpanded(`panel${i + 1}`)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Grid container>
                      <Grid item xs={12} md={11} sm={12}>
                        <Typography>Blok #1 ({items.scienceName})</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container my={1}>
                      {items.test_data.map((item, i) => (
                        <Grid key={item.test_id} item xs={12} md={6} sm={12}>
                          <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                            <CardContent>
                              <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: item.question.replace(
                                    'numberNN',
                                    (page - 1) * pageSize + i + 1 + '. '
                                  ),
                                }}
                              />
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                    paddingRight: 1,
                                  }}
                                  variant={
                                    getKeyColorVariant(
                                      item,
                                      item.option_a.order
                                    ).variant
                                  }
                                  color={
                                    getKeyColorVariant(
                                      item,
                                      item.option_a.order
                                    ).color
                                  }
                                  startIcon={<Avatar>A</Avatar>}
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_a.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>

                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    getKeyColorVariant(
                                      item,
                                      item.option_b.order
                                    ).variant
                                  }
                                  color={
                                    getKeyColorVariant(
                                      item,
                                      item.option_b.order
                                    ).color
                                  }
                                  startIcon={<Avatar>B</Avatar>}
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_b.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    getKeyColorVariant(
                                      item,
                                      item.option_c.order
                                    ).variant
                                  }
                                  color={
                                    getKeyColorVariant(
                                      item,
                                      item.option_c.order
                                    ).color
                                  }
                                  startIcon={<Avatar>C</Avatar>}
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_c.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    getKeyColorVariant(
                                      item,
                                      item.option_d.order
                                    ).variant
                                  }
                                  color={
                                    getKeyColorVariant(
                                      item,
                                      item.option_d.order
                                    ).color
                                  }
                                  startIcon={<Avatar>D</Avatar>}
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_d.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                    {items.test_data.length === 0 ? (
                      <Typography
                        my={1}
                        variant="h4"
                        align="left"
                        color={colors.grey[700]}
                      >
                        {t('no_test_data')}
                      </Typography>
                    ) : null}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
