/* eslint-disable no-prototype-builtins */
import { useRoutes, Navigate, useLocation } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import GlobalStyles from './components/GlobalStyles';
import theme from './assets/theme';

import DashboardLayout from './components/Layout/DashboardLayout';
import MainLayout from './components/Layout/MainLayout';
import Modal from './components/Modals';

import Home from './views/main/App';
import Services from './views/main/Services';
import About from './views/main/About/App';
import Articles from './views/main/Articles';
import ArticlesItem from './views/main/Articles/Item';
import ImpelPrivacy from './views/main/Privacy/Impel';
import AcademicPrivacy from './views/main/Privacy/Academic';
import TestsMain from './views/main/TestMain';
import TestCompetition from './views/main/TestCompetition';

import Impel from './views/main/Impel';
import AcademicApp from './views/main/AcademicApp';

import Account from './views/education_system/Account/Personal';
import AccountMain from './views/main/Account/Personal';
import AddBooking from './views/education_system/Booking/Add';
import AddClass from './views/education_system/Class/Add';
import AddCenter from './views/education_system/Center';
import AddCustomers from './views/education_system/Customers/Add';
import AddExam from './views/education_system/Exam/Add';
import AddRooms from './views/education_system/Rooms/Add';
import AddEmployee from './views/education_system/Employee/Add';
import AddEmployeeTitle from './views/education_system/EmployeeTitle/Add';
import AddScience from './views/education_system/Sciences/Add';

import AddTest from './views/education_system/Tests/Add';
import AddScienceTest from './views/education_system/Tests/AddScience';
import AddTopicTest from './views/education_system/Tests/AddTopic';
import TestsForum from './views/education_system/Tests/Forum';
import ShowTests from './views/education_system/Tests/Show';
import FavoriteTests from './views/education_system/Tests/Favorite';
import TestGroups from './views/education_system/Tests/Groups';
import TestExams from './views/education_system/Tests/Exams';
import TestExamEdit from './views/education_system/Tests/Exams/Edit';
import TestExamOne from './views/education_system/Tests/Exams/One';

import Booking from './views/education_system/Booking/List';
import Class from './views/education_system/Class/List';
import Customers from './views/education_system/Customers/List';
import Dashboard from './views/education_system/Dashboard';
import LessonPlan from './views/education_system/LessonPlan';
import Guide from './views/education_system/Guide';

import Login from './views/education_system/Auth/Login';
import NotFound from './views/404';
import Exam from './views/education_system/Exam/List';
import Controls from './views/education_system/Controls/List';
import Tests from './views/education_system/Tests/List';
import PaymentDetails from './views/education_system/Payments/PaymentDetails';
import PaymentHistory from './views/education_system/Payments/PaymentHistory';
import Register from './views/education_system/Auth/Register';
import ResetPassword from './views/education_system/Auth/ResetPassword';
import Verification from './views/education_system/Auth/Verification';
import NewPassword from './views/education_system/Auth/NewPassword';
import Rooms from './views/education_system/Rooms/List';
import Settings from './views/education_system/Account/Settings';
import Employee from './views/education_system/Employee/List';
import EmployeeTitle from './views/education_system/EmployeeTitle/List';
import Sciences from './views/education_system/Sciences/List';

const App = () => {
  const token = useSelector((state) => state.authReducer.token);
  const location = useLocation();

  window.gtag('event', 'page_view', {
    page_title: 'title',
    page_path: location.pathname + location.search,
    page_location: window.location.href,
  });

  const protectedRoutes = [
    {
      element: <DashboardLayout />,
      children: [
        { path: '/app/dashboard', element: <Dashboard /> },
        { path: '/app/lesson_plan', element: <LessonPlan /> },
        { path: '/tests', element: <TestsMain /> },
        { path: '/tests/competition', element: <TestCompetition /> },
        { path: '/app/tests', element: <Tests /> },
        { path: '/app/tests/newmake', element: <AddTest /> },
        { path: '/app/science_tests/newmake', element: <AddScienceTest /> },
        { path: '/app/topic_tests/newmake', element: <AddTopicTest /> },
        { path: '/app/tests/forum/:id', element: <TestsForum /> },
        { path: '/app/tests/favorites', element: <FavoriteTests /> },
        { path: '/app/tests/show', element: <ShowTests /> },
        { path: '/app/tests/groups', element: <TestGroups /> },
        { path: '/app/tests/exams', element: <TestExams /> },
        { path: '/app/tests/exams/:id', element: <TestExamEdit /> },
        { path: '/exam/:id', element: <TestExamOne /> },
        { path: '/app/guide', element: <Guide /> },
        { path: '/app/account', element: <Account /> },
        { path: '/app/exam', element: <Exam /> },
        { path: '/app/exam/create', element: <AddExam /> },
        { path: '/app/rooms', element: <Rooms /> },
        { path: '/app/rooms/create', element: <AddRooms /> },
        { path: '/app/customers', element: <Customers /> },
        { path: '/app/controls', element: <Controls /> },
        { path: '/app/customers/create', element: <AddCustomers /> },
        { path: '/app/employee_title', element: <EmployeeTitle /> },
        { path: '/app/employee_title/create', element: <AddEmployeeTitle /> },
        { path: '/app/science', element: <Sciences /> },
        { path: '/app/science/create', element: <AddScience /> },
        { path: '/app/employees', element: <Employee /> },
        { path: '/app/employees/create', element: <AddEmployee /> },
        { path: '/app/class', element: <Class /> },
        { path: '/app/class/create', element: <AddClass /> },
        { path: '/app/club/create', element: <AddCenter /> },
        { path: '/app/booking', element: <Booking /> },
        { path: '/app/booking/create', element: <AddBooking /> },
        { path: '/app/payment_details', element: <PaymentDetails /> },
        { path: '/app/payment_history', element: <PaymentHistory /> },
        { path: '/app/settings', element: <Settings /> },
        { path: '', element: <Navigate to="/home" /> },
        { path: '/home', element: <Home /> },
        { path: '/about', element: <About /> },
        { path: '/services', element: <Services /> },
        { path: '/impel', element: <Impel /> },
        { path: '/articles', element: <Articles /> },
        { path: '/articles/:id', element: <ArticlesItem /> },
        { path: '/impel/privacy', element: <ImpelPrivacy /> },
        { path: '/academic/privacy', element: <AcademicPrivacy /> },
        { path: '*', element: <Navigate to="/home" /> },
        { path: '/login', element: <Login /> },
      ],
    },
  ];

  const publicRoutes = [
    {
      element: <MainLayout />,
      children: [
        { path: '', element: <Navigate to="/home" /> },
        { path: '/home', element: <Home /> },
        { path: '/about', element: <About /> },
        { path: '/services', element: <Services /> },
        { path: '/tests', element: <TestsMain /> },
        { path: '/tests/competition', element: <TestCompetition /> },
        { path: '/app/tests/forum/:id', element: <TestsForum /> },
        { path: '/app/tests/favorites', element: <FavoriteTests /> },
        { path: '/app/tests/show', element: <ShowTests /> },
        { path: '/app/tests/groups', element: <TestGroups /> },
        { path: '/app/tests/exams', element: <TestExams /> },
        { path: '/app/tests/exams/:id', element: <TestExamEdit /> },
        { path: '/exam/:id', element: <TestExamOne /> },
        { path: '/impel', element: <Impel /> },
        { path: '/academic_app', element: <AcademicApp /> },
        { path: '/articles', element: <Articles /> },
        { path: '/articles/:id', element: <ArticlesItem /> },
        { path: '/impel/privacy', element: <ImpelPrivacy /> },
        { path: '/academic/privacy', element: <AcademicPrivacy /> },
        { path: '/account', element: <AccountMain /> },
        { path: '/login', element: <Login /> },
        { path: '/about', element: <About /> },
        { path: 'register', element: <Register /> },
        { path: 'resetPassword', element: <ResetPassword /> },
        { path: 'verify', element: <Verification /> },
        { path: 'newPassword', element: <NewPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/login" /> },
      ],
    },
  ];

  const checkToken = () => {
    localStorage.removeItem('select_test_unit_id');
    if (localStorage.hasOwnProperty('token')) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role === null) {
          return false;
        }
      } catch (err) {
        localStorage.removeItem('token');
        return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const content = useRoutes(checkToken() ? protectedRoutes : publicRoutes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* <Modal /> */}
        {content}
        <GlobalStyles />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
