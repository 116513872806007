import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

let langCode = localStorage.getItem('i18nextLng');

if (langCode === null || langCode === undefined || langCode === 'en') {
  langCode = 'uz';
}
i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['uz', 'ru'],
    fallbackLng: langCode,
    lng: langCode,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie', 'localStorage'],
    },
    backend: {
      loadPath: '/assets/locals/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false,
    },
  });
