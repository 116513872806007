import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Body } from '../GlobalStyles/modals';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, backgroundColor, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2, backgroundColor: '#4d4d4d', color: 'white' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default ({
  title,
  modal = false,
  maxWidth,
  handleModal,
  body,
  backgroundColor,
  fullScreen,
}) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  return (
    <BootstrapDialog
      fullScreen={fullScreen}
      hideBackdrop={false}
      fullWidth={fullWidth}
      open={modal}
      onClose={handleModal}
      maxWidth={maxWidth}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => handleModal(null)}
        backgroundColor={backgroundColor}
      >
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>{body}</DialogContent>
      {/* <DialogActions>
        <Button color="primary" autoFocus onClick={handleModal}>
          Accept
        </Button>
      </DialogActions> */}
    </BootstrapDialog>
  );
  // return (
  //   <Dialog
  //     hideBackdrop={true}
  //     fullWidth={fullWidth}
  //     open={modal}
  //     onClose={handleModal}
  //     maxWidth={maxWidth}
  //   >
  //     <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
  //     <Body overflow="auto">{body}</Body>
  //   </Dialog>
  // );
};
