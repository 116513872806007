import { service } from '.';

export default {
  getAll: (query) => service.get(`/orders/?${query}`),
  checkName: (query) => service.get(`/orders/validation?${query}`),
  getOne: (id) => service.get(`/orders/${id}/`),
  getByLocationId: (id) => service.get(`/orders/?location=${id}`),
  create: (data) => service.post('/orders/', data),
  update: (id, data) => service.patch(`/orders/${id}/`, data),
  disable: (id) => service.patch(`/orders/${id}/${0}`),
};
