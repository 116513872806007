/* eslint-disable no-prototype-builtins */
import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  Divider,
  IconButton,
  TextField,
  Avatar,
  Chip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import MuiAlert from '@mui/material/Alert';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TelegramLoginButton from 'react-telegram-login';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ForumIcon from '@mui/icons-material/Forum';
import ScoreIcon from '@mui/icons-material/Score';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GroupsIcon from '@mui/icons-material/Groups';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Loading from '../../../components/Loading';
import { setSelectTests, setCoin } from '../../../redux/modules/store/actions';
import { setToken } from '../../../redux/modules/auth/actions';
import { useStyles } from './style';
import ModalWindow from '../../../components/Modals';
import SignUpModal from '../../../components/Modals/SignUpModal';
import AddSelectUnit from '../../../components/Cart/Modals/AddSelectUnit';
import topicTest from '../../../services/topic_tests';
import scienceTest from '../../../services/science_tests';
import testsApi from '../../../services/tests';
import authApi from '../../../services/auth';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({ ...rest }) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [scienceTestList, setScienceTestList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [testList, setTestList] = useState([]);

  const [selectTestList, setSelectTestList] = useState([]);

  const [modalAddSelectUnit, setModalAddSelectUnit] = useState(false);

  const [testSelectList, setTestSelectList] = useState([]);
  const [selectId, setSelectId] = useState('');
  const [selectOption, setSelectOption] = useState('');

  const language = useSelector((state) => state.langsReducer.language);
  const selectTests = useSelector((state) => state.storeReducer.select_tests);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [score, setScore] = useState(0);

  const [modalTelegram, setModalTelegram] = useState(false);

  const [timer, setTimer] = useState('00:00:00');
  const [isStart, setIsStart] = useState(false);
  const [startEnd, setStartEnd] = useState(0);

  const Ref = useRef(null);

  // const token = localStorage.getItem('token');
  // const decodedToken = jwtDecode(token);

  const selectTest = (item, status) => {
    if (selectId) {
      const data = new FormData();
      const arr = selectTestList;
      if (status === 1) {
        arr.push(item.test_id);
        data.append('tests', JSON.stringify(arr));
      } else {
        data.append(
          'tests',
          JSON.stringify(arr.filter((value) => value !== item.test_id))
        );
      }

      // data.append('name', values.name);

      data.append('status', 1);
      testsApi
        .updateTestSelectUnit(selectId, data)
        .then((res) => {
          dispatch(setSelectTests(uuidv4()));
          setSelectTestList(res.tests);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSnackbarMessage(t("Kerakli testlar to'plamini tanlang"));
      setOpen(true);
    }

    // if (localStorage.getItem('selectTests')) {
    //   const arr = JSON.parse(localStorage.getItem('selectTests'));
    //   if (arr.length > 30) {
    //     setSnackbarMessage(
    //       t('Tanlash limit 30 ta. Tanlov limitdan oshib ketdi')
    //     );
    //     setOpen(true);
    //   } else if (status === 1) {
    //     arr.push(item.test_id);
    //     localStorage.setItem('selectTests', JSON.stringify(arr));
    //   } else {
    //     localStorage.setItem(
    //       'selectTests',
    //       JSON.stringify(arr.filter((value) => value !== item.test_id))
    //     );
    //   }
    // } else {
    //   localStorage.setItem('selectTests', JSON.stringify([item.test_id]));
    // }
    // dispatch(setSelectTests(uuidv4()));

    // setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
  };

  const validationSchema = Yup.object().shape({
    science_tests: Yup.string().required(t('required')),
    topic_tests: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      science_tests: '',
      topic_tests: '',
    },
    validationSchema,
  });

  const getTimeRemaining = (e, timer) => {
    const total = Date.parse(e) - Date.parse(new Date());
    if (total >= 0) {
      const hours = Math.floor((total / 1000 / 3600) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const seconds = Math.floor((total / 1000) % 60);

      // const hours = Math.floor(total / 3600);
      // const minutes = Math.floor((total % 3600) / 60);
      // const seconds = total % 60;

      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const formattedSeconds = seconds.toString().padStart(2, '0');
      setTimer(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
    } else {
      clearInterval(timer);
      setIsStart(false);
    }
  };

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const timer = setInterval(() => {
      if (window.location.pathname === '/tests/competition') {
        getTimeRemaining(e, timer);
      } else {
        clearInterval(timer);
      }
    }, 1000);
    Ref.current = timer;
  };

  const getDeadTime = (time) => {
    const deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + time);
    return deadline;
  };

  const getTestsByTopicId = (id) => {
    // setTestList([]);
    setLoading(false);
    const query = `status=1&topic_id=${id}`;
    testsApi
      .getCompetitionTest(query)
      .then((res) => {
        setScore(res.score);
        setTestList(res.tests);
        setLoading(false);
        setStartEnd(1);
        clearTimer(getDeadTime(10));
        setIsStart(true);
        setSelectOption('');

        // if (isStart) {
        //   setStartEnd(0);

        //   clearTimer(getDeadTime(0));
        //   setIsStart(false);
        // } else {
        //   setStartEnd(1);
        //   clearTimer(getDeadTime(10));
        //   setIsStart(true);
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getTopicByScienceId = (id) => {
    setTestList([]);
    setTopicList([]);
    formik.setFieldValue('topic_tests', '');

    setLoading(false);
    const query = 'science_id=' + id;
    topicTest
      .getAllOpen(query)
      .then((res) => {
        setTopicList(res);
        if (res.length > 0) {
          const topicId = localStorage.getItem('topic_id');
          if (topicId !== null) {
            const arr = res.filter((item) => item.topic_test_id === topicId);
            if (arr.length > 0) {
              formik.setFieldValue('topic_tests', arr[0].name.uz);
              getTestsByTopicId(topicId);
            } else {
              formik.setFieldValue('topic_tests', res[0].name.uz);
              getTestsByTopicId(res[0].topic_test_id);
            }
          } else {
            formik.setFieldValue('topic_tests', res[0].name.uz);
            localStorage.setItem('topic_id', res[0].topic_test_id);
            getTestsByTopicId(res[0].topic_test_id);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setLoading(false);
  };

  const loadingData = () => {
    setLoading(true);
    scienceTest
      .getAll()
      .then((res) => {
        setScienceTestList(res);
        if (res.length > 0) {
          const scienceId = localStorage.getItem('science_id');
          if (scienceId !== null) {
            const arr = res.filter((item) => item.science_id === scienceId);
            if (arr.length > 0) {
              formik.setFieldValue('science_tests', arr[0].name.uz);
            }
            getTopicByScienceId(scienceId);
          } else {
            formik.setFieldValue('science_tests', res[0].name.uz);
            localStorage.setItem('science_id', res[0].science_id);
            getTopicByScienceId(res[0].science_id);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getTestAnswer = (id, option, topicId) => {
    setLoading(false);
    const query = `test_id=${id}&option=${option}&topic_id=${topicId}`;
    testsApi
      .getCompetitionScore(query)
      .then((res) => {
        if (res.status) {
          setScore(res.score);
          setSnackbarMessage(res.message);
          setOpen(true);
        } else {
          setSnackbarMessage(res.message);
          setOpen(true);
        }
        setStartEnd(0);
        clearTimer(getDeadTime(0));
        setIsStart(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    loadingData();

    // if (localStorage.getItem('selectTests')) {
    //   setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
    // }
    testsApi
      .getTestSelectUnit('page_size=1000')
      .then((res) => {
        setTestSelectList(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // usersApi
    //   .getOne(decodedToken.user_id)
    //   .then((res) => {
    //     setTestMakeAccess(res.test_make_access);
    //   })
    //   .catch((err) => {});
  }, [language]);

  useEffect(() => {
    if (localStorage.getItem('select_test_unit_id')) {
      const id = localStorage.getItem('select_test_unit_id');
      testsApi
        .getOneTestSelectUnit(id)
        .then((res) => {
          setSelectTestList(res.tests);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSelectTestList([]);
    }
    // if (localStorage.getItem('selectTests')) {
    //   setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
    // }
  }, [selectTests]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleTelegramResponse = (response) => {
    authApi
      .authTelegram(`id=${response.id}&name=${response.first_name}`)
      .then((res) => {
        console.log(res.data.error);
        if (!Object.is(res.data.error, null)) {
          dispatch(setToken(res.data));
          dispatch(setCoin(res.data.coin));
          setModalTelegram(false);
        }
      });
  };

  const addFavorite = (testId) => {
    setLoading(false);
    const data = { test: testId };
    testsApi
      .addFavorite(data)
      .then((res) => {
        setLoading(false);
        // const topicId = localStorage.getItem('topic_id');
        // getTestsByTopicId(page, topicId);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleModalTelegram = () => {
    setModalTelegram(!modalTelegram);
  };

  const checkTestOwner = (option) => {
    if (localStorage.hasOwnProperty('token')) {
      const token = localStorage.getItem('token');
      if (jwtDecode(token).user_id === option.owner) {
        return true;
      }
    }
    return false;
  };

  const getId = () => {
    if (localStorage.hasOwnProperty('token')) {
      const token = localStorage.getItem('token');
      const text = 'ID: ' + jwtDecode(token).telegram_id;
      return text;
    }
    return '';
  };

  return (
    <>
      <Helmet>
        <title>{t('tests')}</title>
      </Helmet>

      <ModalWindow
        title={t('Eslatma')}
        modal={modalTelegram}
        handleModal={handleModalTelegram}
        maxWidth="sm"
        body={<SignUpModal handleTelegramResponse={handleTelegramResponse} />}
      />
      <ModalWindow
        title={t("Yangi tanlovlar to'plami")}
        modal={modalAddSelectUnit}
        handleModal={() => {
          setModalAddSelectUnit(false);
        }}
        maxWidth="md"
        body={
          <AddSelectUnit
            handleModal={() => {
              testsApi
                .getTestSelectUnit('page_size=1000')
                .then((res) => {
                  setTestSelectList(res);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                });
              setModalAddSelectUnit(false);
            }}
            yes={t('save')}
            cancel={t('cancel')}
            text={t('ask_clear')}
          />
        }
      />
      <Box
        sx={{
          backgroundColor: 'background.default',
          // height: '100vh',
          py: 3,
          px: 1,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>

          <Loading loading={loading} />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            mb={1}
            mt={1}
          >
            {t('Musobaqa')}
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={4} md={4} sm={4}>
              <TelegramLoginButton
                dataOnauth={handleTelegramResponse}
                botName={process.env.REACT_APP_TELEGRAM_BOT}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography variant="h5" color="textPrimary">
                {getId()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<GroupsIcon />}
                onClick={() => {
                  if (localStorage.hasOwnProperty('token')) {
                    navigate('/tests');
                  } else {
                    handleModalTelegram();
                  }
                }}
                // color="info"
              >
                {t('Testlar')}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} sm={12} my={2}>
            <Autocomplete
              fullWidth
              size="small"
              id="combo-box1"
              freeSolo
              clearOnBlur
              className={classes.search}
              value={formik.values.science_tests}
              options={scienceTestList}
              onChange={(event, newValue) => {
                if (newValue) {
                  formik.setFieldValue('science_tests', newValue.name.uz);
                  localStorage.setItem('science_id', newValue.science_id);
                  getTopicByScienceId(newValue.science_id);
                }
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }

                // Regular option
                return option.name.uz;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name.uz}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} label={t('science')} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12} my={2}>
            <Autocomplete
              fullWidth
              size="small"
              id="combo-box1"
              freeSolo
              clearOnBlur
              className={classes.search}
              value={formik.values.topic_tests}
              options={topicList}
              onChange={(event, newValue) => {
                if (newValue) {
                  formik.setFieldValue('topic_tests', newValue.name.uz);
                  localStorage.setItem('topic_id', newValue.topic_test_id);
                  getTestsByTopicId(newValue.topic_test_id);
                }
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }

                // Regular option
                return option.name.uz;
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  <Grid container spacing={1}>
                    <Grid item xs={11} md={11} sm={11}>
                      <Typography align="left" color={colors.grey[700]}>
                        {option.name.uz}
                      </Typography>
                    </Grid>
                    {checkTestOwner(option) ? (
                      // eslint-disable-next-line react/jsx-indent
                      <Grid item xs={1} md={1} sm={1}>
                        <IconButton
                          size="small"
                          sx={{
                            color: '#2A455F',
                          }}
                          variant="contained"
                          onClick={() => {
                            if (localStorage.hasOwnProperty('token')) {
                              navigate(
                                '/app/topic_tests/edit/' + option.topic_test_id
                              );
                            } else {
                              handleModalTelegram();
                            }
                          }}
                          // color="info"
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label={t('topic')} />
              )}
            />
          </Grid>

          <Chip
            size="medium"
            sx={{ height: 25 }}
            label={score}
            color="success"
            icon={<ScoreIcon />}
            variant="outlined"
            onClick={() => {}}
          />
          <Grid container my={1}>
            {testList.length === 0 ? (
              <Typography
                my={1}
                variant="h4"
                align="left"
                color={colors.grey[700]}
              >
                {t('no_test_data')}
              </Typography>
            ) : null}
            {testList.map((item, i) => (
              <Grid key={item.test_id} item xs={12} md={6} sm={12}>
                <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                  {/* <Tooltip
                    title={
                      selectTestList.indexOf(item.test_id) < 0
                        ? t('selection')
                        : t('selected')
                    }
                    placement="top"
                    arrow
                  >
                    <IconButton
                      variant="filled"
                      onClick={() => {
                        selectTest(
                          item,
                          selectTestList.indexOf(item.test_id) < 0 ? 1 : 0
                        );
                      }}
                    >
                      {selectTestList.indexOf(item.test_id) < 0 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <HighlightOffIcon color="error" />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Muhokama" placement="top" arrow>
                    <IconButton
                      variant="filled"
                      onClick={() => {
                        navigate('/app/tests/forum/' + item.test_id);
                      }}
                    >
                      <ForumIcon color="primary" />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="Sevimlilarga qo'shish" placement="top" arrow>
                    <IconButton
                      variant="filled"
                      onClick={() => {
                        if (localStorage.hasOwnProperty('token')) {
                          addFavorite(item.test_id);
                        } else {
                          handleModalTelegram();
                        }
                      }}
                    >
                      {item.favorite ? (
                        <FavoriteIcon color="error" />
                      ) : (
                        <FavoriteIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography variant="h5" color="red" mx={2}>
                      Vaqt: {timer}
                    </Typography>
                  </Grid>

                  <CardContent>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.question.replace('numberNN', ''),
                      }}
                    />
                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                          paddingRight: 1,
                        }}
                        variant={
                          selectOption === item.option_a.order
                            ? 'contained'
                            : 'outlined'
                        }
                        color={
                          selectOption === item.option_a.order
                            ? 'primary'
                            : 'warning'
                        }
                        startIcon={<Avatar>A</Avatar>}
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            // selectOption(item.test_id, 'a');
                            setSelectOption(item.option_a.order);
                          } else {
                            handleModalTelegram();
                          }
                        }}
                      >
                        <div
                          style={{ paddingTop: 10 }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.option_a.text
                              .replace('optionA', '')
                              .replace('optionB', '')
                              .replace('optionC', '')
                              .replace('optionD', ''),
                          }}
                        />
                      </Button>
                    </div>

                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                        }}
                        variant={
                          selectOption === item.option_b.order
                            ? 'contained'
                            : 'outlined'
                        }
                        color={
                          selectOption === item.option_b.order
                            ? 'primary'
                            : 'warning'
                        }
                        startIcon={<Avatar>B</Avatar>}
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            // selectOption(item.test_id, 'b');
                            setSelectOption(item.option_b.order);
                          } else {
                            handleModalTelegram();
                          }
                        }}
                      >
                        <div
                          style={{ paddingTop: 10 }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.option_b.text
                              .replace('optionA', '')
                              .replace('optionB', '')
                              .replace('optionC', '')
                              .replace('optionD', ''),
                          }}
                        />
                      </Button>
                    </div>
                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                        }}
                        variant={
                          selectOption === item.option_c.order
                            ? 'contained'
                            : 'outlined'
                        }
                        color={
                          selectOption === item.option_c.order
                            ? 'primary'
                            : 'warning'
                        }
                        startIcon={<Avatar>C</Avatar>}
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            // selectOption(item.test_id, 'c');
                            setSelectOption(item.option_c.order);
                          } else {
                            handleModalTelegram();
                          }
                        }}
                      >
                        <div
                          style={{ paddingTop: 10 }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.option_c.text
                              .replace('optionA', '')
                              .replace('optionB', '')
                              .replace('optionC', '')
                              .replace('optionD', ''),
                          }}
                        />
                      </Button>
                    </div>
                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                        }}
                        variant={
                          selectOption === item.option_d.order
                            ? 'contained'
                            : 'outlined'
                        }
                        color={
                          selectOption === item.option_d.order
                            ? 'primary'
                            : 'warning'
                        }
                        startIcon={<Avatar>D</Avatar>}
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            // selectOption(item.test_id, 'd');
                            setSelectOption(item.option_d.order);
                          } else {
                            handleModalTelegram();
                          }
                        }}
                      >
                        <div
                          style={{ paddingTop: 10 }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.option_d.text
                              .replace('optionA', '')
                              .replace('optionB', '')
                              .replace('optionC', '')
                              .replace('optionD', ''),
                          }}
                        />
                      </Button>
                    </div>
                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        disabled={!isStart}
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          // justifyContent: 'flex-start',
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (selectOption === '') {
                            setSnackbarMessage('Kerakli javobni tanlang');
                            setOpen(true);
                          } else {
                            const topicId = localStorage.getItem('topic_id');
                            getTestAnswer(item.test_id, selectOption, topicId);
                          }
                        }}
                      >
                        Tekshirish
                      </Button>
                    </div>
                    {!isStart ? (
                      <>
                        <Divider size="medium" color="primary" />
                        <div>
                          <Button
                            fullWidth
                            disableRipple
                            sx={{
                              marginTop: 1,
                              marginBottom: 1,
                              textTransform: 'none',
                              // justifyContent: 'flex-start',
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              const tId = localStorage.getItem('topic_id');
                              getTestsByTopicId(tId);
                            }}
                          >
                            Keyingisi
                          </Button>
                        </div>
                      </>
                    ) : null}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
