import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  colors,
  FormHelperText,
  Typography,
  CircularProgress,
  TextField,
} from '@mui/material';
import Container from '@mui/material/Container';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import jwtDecode from 'jwt-decode';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../../components/Loading';
import { useStyles } from './style';
import { IOSSwitch } from '../../../../components/GlobalStyles';
import scienceTest from '../../../../services/science_tests';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scienceId, setScienceId] = useState();
  const [scienceTestList, setScienceTestList] = useState([]);

  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
  });

  const getSciences = () => {
    setLoading(true);
    const query = '';
    scienceTest
      .getAll(query)
      .then((res) => {
        setScienceTestList(res.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              getSciences();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const item = {
        uz: values.name,
        ru: values.name,
      };
      const data = new FormData();
      data.append('name', JSON.stringify(item));
      data.append('status', status ? 1 : 0);

      if (state) {
        scienceTest
          .update(scienceId, data)
          .then((res) => {
            setLoading(false);
            // navigate('/app/tests');
            getSciences();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      } else {
        scienceTest
          .create(data)
          .then((res) => {
            setLoading(false);
            navigate('/app/tests');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const loadingData = (id) => {
    setLoading(true);
    if (state) {
      scienceTest
        .getOne(id)
        .then((resp) => {
          formik.setValues({
            ...formik.values,
            name: resp.name[language],
          });
          if (resp.status === 1) {
            setStatus(true);
          } else {
            setStatus(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    console.log(state);
    // loadingData();
    getSciences();
  }, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        height: '100vh',
        p: 3,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {state ? t('edit') : t('add')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} my={1}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} mb={6}>
              {state ? (
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    my={1}
                    variant="h4"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('science')}
                  </Typography>
                  <FormControl
                    size="small"
                    sx={{ width: '100%', backgroundColor: 'white' }}
                  >
                    <Select
                      className={classes.input}
                      fullWidth
                      name="science_tests"
                      value={formik.values.science_tests}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.science_tests &&
                        Boolean(formik.errors.science_tests)
                      }
                      onChange={(e) => {
                        setScienceId(e.target.value);
                        loadingData(e.target.value);
                      }}
                    >
                      {scienceTestList.map((item, index) => (
                        <MenuItem
                          key={`${index + 1}`}
                          value={item.science_test_id}
                        >
                          {t(`${item.name[language]}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('name')}
                </Typography>
                <TextField
                  size="small"
                  name="name"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={t('enter_name')}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => navigate('/app/tests')}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => console.log()}
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size="0.9rem" />
                  ) : undefined
                }
              >
                {renderButton(state, formik.isSubmitting)}
              </Button>
            </Box>
          </form>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
