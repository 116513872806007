import { service } from '.';

export default {
  getAll: (query) => service.get(`/rooms/?${query}`),
  getOne: (id) => service.get(`/rooms/${id.room_id}/`),
  getByLocationId: (id) => service.get(`/rooms/?locations=${id.room_id}`),
  create: (data) => service.post('/rooms/', data),
  update: (id, data) => service.patch(`/rooms/${id.room_id}/`, data),
  changeStatus: (id, data) => service.patch(`/rooms/status/${id}/`, data),
};
