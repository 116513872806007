/* eslint-disable no-prototype-builtins */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  colors,
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useTheme } from '@mui/material/styles';

// import InputIcon from '@mui/icons-material/Input';

// import ProfileBtn from '../../Profile2';
import Loading from '../Loading';
import authApi from '../../services/auth';
import testsApi from '../../services/tests';
import { useStyles } from './style';

export default function SelectList({ handleModalScore }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);

  const [testPaymentList, setTestPaymentList] = useState([]);

  const [accountCoin, setAccountCoin] = useState(0);

  const handleChange = (event, value) => {
    setPage(value);
    // loadingData(value, scienceId);
  };

  useEffect(() => {
    if (localStorage.hasOwnProperty('token')) {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);

      authApi
        .authTelegram(
          `id=${decodedToken.telegram_id}&name=${decodedToken.name}`
        )
        .then((res) => {
          setAccountCoin(res.data.coin);
        });
    }
    setLoading(true);
    testsApi
      .getTestPayments('page_size=10000')
      .then((res) => {
        setTestPaymentList(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <Container maxWidth={false}>
      <Loading loading={loading} />

      <Grid container my={1}>
        <Grid container my={1}>
          <Typography my={1} variant="h2" color={colors.grey[700]}>
            {t('Umumiy') + ': ' + accountCoin}
          </Typography>
          {testPaymentList.map((item, i) => (
            <Grid key={item.test_payment_log_id} item xs={12} md={12} sm={12}>
              <Card
                sx={{
                  marginRight: 1,
                  marginBottom: 1,
                  backgroundColor: item.amount > 0 ? 'green' : 'red',
                }}
              >
                <CardContent>
                  <Typography my={1} variant="h4">
                    &nbsp; &nbsp; {t('Qiymati') + ': ' + item.amount}
                  </Typography>
                  <Typography my={1} variant="h4">
                    &nbsp; &nbsp; {t('Narx') + ': ' + item.price}
                  </Typography>
                  <Typography my={1} variant="h4">
                    &nbsp; &nbsp; {t('Testlar soni') + ': ' + item.tests.length}
                  </Typography>
                  <Typography variant="h4">
                    &nbsp; &nbsp; {t('Tafsilotlar') + ': ' + item.desc}
                  </Typography>
                  <Typography variant="h4">
                    &nbsp; &nbsp;{' '}
                    {t('Vaqt') +
                      ': ' +
                      item.created_at.replace('T', ' ').split('.')[0]}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <br />
      </Grid>

      {/* <Stack spacing={2}>
        <Pagination
          ullwidth="true"
          count={countPages}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </Stack> */}
    </Container>
  );
}
