import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { useStyles } from '../../FilteredBody/style';

export default ({
  items,
  handleChangeChips,
  selectedItems,
  name,
  error,
  optionText,
  labelText,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      size="small"
      noOptionsText={optionText}
      className={classes.input}
      name={name}
      multiple
      fullWidth
      limitTags={2}
      options={items}
      onChange={handleChangeChips}
      getOptionLabel={(option) => option.name}
      value={selectedItems}
      renderInput={(params) => <TextField label={labelText} {...params} />}
    />
  );
};
