/* eslint-disable no-prototype-builtins */
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  Divider,
  IconButton,
  TextField,
  Avatar,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ListIcon from '@mui/icons-material/ListAltOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GroupIcon from '@mui/icons-material/Group';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { styled, lighten, darken } from '@mui/system';
import { setCoin } from '../../../../../redux/modules/store/actions';
import { setToken } from '../../../../../redux/modules/auth/actions';
import Loading from '../../../../../components/Loading';
import { useStyles } from './style';
import ModalWindow from '../../../../../components/Modals';
import ModalWindowFullScreen from '../../../../../components/Modals/FullScreen';
import SignUpModal from '../../../../../components/Modals/SignUpModal';
import topicTest from '../../../../../services/topic_tests';
import testsApi from '../../../../../services/tests';
import authApi from '../../../../../services/auth';
import NewGroup from '../../Groups/Modals/NewGroup';
import NewBlok from '../Modals/NewBlok';
import ChangeDeleteModal from '../../../../../components/Modals/ConfirmDelete';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [groupTestList, setGroupTestList] = useState([]);

  const [blokList, setBlokList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [deleteBlokList, setDeleteBlokList] = useState([]);

  const [selectTestList, setSelectTestList] = useState([]);

  const language = useSelector((state) => state.langsReducer.language);
  const selectTests = useSelector((state) => state.storeReducer.select_tests);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [countPages, setCountPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [modalTelegram, setModalTelegram] = useState(false);
  const [modalNewGroup, setModalNewGroup] = useState(false);
  const [modalNewBlok, setModalNewBlok] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [blokIndex, setBlokIndex] = useState();
  const [blokScienceId, setBlokScienceId] = useState();
  const [blokScienceName, setBlokScienceName] = useState();
  const [blokBall, setBlokBall] = useState();

  const [expanded, setExpanded] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { id } = useParams();

  const selectTest = (item, status, scienceId) => {
    if (localStorage.getItem('selectTests-' + scienceId)) {
      const arr = JSON.parse(localStorage.getItem('selectTests-' + scienceId));
      if (arr.length > 30) {
        setSnackbarMessage(
          t('Tanlash limit 30 ta. Tanlov limitdan oshib ketdi')
        );
        setOpen(true);
      } else if (status === 1) {
        arr.push({ testId: item.test_id, key: 0 });
        localStorage.setItem('selectTests-' + scienceId, JSON.stringify(arr));
      } else {
        localStorage.setItem(
          'selectTests-' + scienceId,
          JSON.stringify(arr.filter((value) => value.testId !== item.test_id))
        );
      }
    } else {
      localStorage.setItem(
        'selectTests-' + scienceId,
        JSON.stringify([{ testId: item.test_id, key: 0 }])
      );
    }

    setSelectTestList(
      JSON.parse(localStorage.getItem('selectTests-' + scienceId))
    );
  };

  const selectOption = (item, status, scienceId, key) => {
    if (localStorage.getItem('selectTests-' + scienceId)) {
      const arr = JSON.parse(localStorage.getItem('selectTests-' + scienceId));
      if (arr.length > 30) {
        setSnackbarMessage(
          t('Tanlash limit 30 ta. Tanlov limitdan oshib ketdi')
        );
        setOpen(true);
        return;
      }
      if (status === 1) {
        setSnackbarMessage(t('Iltimos, avval testni tanlang'));
        setOpen(true);
        return;
      }
      if (status === 0) {
        const newArr = arr.map((obj) =>
          obj.testId === item.test_id ? { ...obj, key } : obj
        );

        localStorage.setItem(
          'selectTests-' + scienceId,
          JSON.stringify(newArr)
        );
      }
    } else {
      setSnackbarMessage(t('Iltimos, avval testni tanlang'));
      setOpen(true);
    }

    setSelectTestList(
      JSON.parse(localStorage.getItem('selectTests-' + scienceId))
    );
  };

  const validationSchema = Yup.object().shape({
    group: Yup.string().required(t('required')),
    examName: Yup.string().required(t('required')),
    duration_status: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      test_exam_id: '',
      group: '',
      groupId: '',
      examName: '',
      topic_tests: '',
      duration_status: '',
      duration_time: 0,
      info: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const data = new FormData();
      data.append('test_exam_id', values.test_exam_id);
      data.append('name', values.examName);
      data.append('group', values.groupId);
      data.append(
        'duration_status',
        values.duration_status === 'time' ? 'True' : 'False'
      );
      data.append(
        'duration_time',
        values.duration_status === 'infinity' ? 0 : values.duration_time
      );
      data.append('info', values.info);

      const blokData = [];
      blokList.forEach((item) => {
        JSON.parse(
          localStorage.getItem('selectTests-' + item.scienceId)
        ).forEach((obj) => {
          if (obj.key === 0) {
            setSnackbarMessage(
              'Barcha test kalitlari kiritilmagan. Tekshirib qaytadan saqlang.'
            );
            setOpen(true);
            setLoading(false);
            throw new Error('Break the loop.');
          }
        });
        const val = {
          scienceId: item.scienceId,
          blokBall: item.blokBall,
          tests: localStorage.getItem('selectTests-' + item.scienceId)
            ? JSON.parse(localStorage.getItem('selectTests-' + item.scienceId))
            : [],
        };
        blokData.push(val);
      });
      data.append('bloks', JSON.stringify(blokData));

      testsApi
        .updateExam(id, data)
        .then((res) => {
          setLoading(false);
          formik.setSubmitting(false);
          setSnackbarMessage('Imtihon saqlandi');
          setOpen(true);
          navigate('/app/tests/groups');
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          formik.setSubmitting(false);
          // setSnackbarMessage(t(err.response.data.error));
          // setOpen(true);
        });
    },
  });

  const getByExamId = (id) => {
    setLoading(true);

    testsApi
      .getOneExam(id)
      .then((res) => {
        formik.setFieldValue('test_exam_id', res.test_exam_id);
        formik.setFieldValue('examName', res.name);
        formik.setFieldValue('group', res.group.name);
        formik.setFieldValue('groupId', res.group.test_group_id);

        formik.setFieldValue(
          'duration_status',
          res.duration_status ? 'time' : 'infinity'
        );
        formik.setFieldValue('duration_time', res.duration_time);
        formik.setFieldValue('info', res.info);
        setBlokList(res.bloks);
        res.bloks.forEach((item) => {
          if (item.tests) {
            localStorage.setItem(
              'selectTests-' + item.scienceId,
              JSON.stringify(item.tests)
            );
          } else {
            localStorage.setItem(
              'selectTests-' + item.scienceId,
              JSON.stringify([])
            );
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getTestsByTopicId = (currentPage, id) => {
    localStorage.setItem('topic_id', id);
    setLoading(true);
    const query =
      `page=${currentPage}&page_size=${pageSize}&status=1&topic=` + id;
    testsApi
      .getAll(query)
      .then((res) => {
        setCountPages(res.count_pages);
        setTestList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getTopicByScienceId = (id) => {
    setTestList([]);
    setTopicList([]);
    formik.setFieldValue('topic_tests', '');

    setLoading(true);
    const query = 'science_id=' + id;
    topicTest
      .getAll(query)
      .then((res) => {
        setTopicList(res);
        if (res.length > 0) {
          formik.setFieldValue('topic_tests', res[0].name.uz);

          getTestsByTopicId(1, res[0].topic_test_id);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadingData = () => {
    setLoading(true);
    testsApi
      .getAllGroups('page_size=10&request_type=owner')
      .then((res) => {
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        const options = res.map((option) => {
          return {
            group:
              option.group_owner.user_id === decodedToken.user_id
                ? 'Mening gruppalarim'
                : 'Gruppalar',
            ...option,
          };
        });
        setGroupTestList(options);
        getByExamId(id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    const tId = localStorage.getItem('topic_id');
    getTestsByTopicId(value, tId);
  };

  useEffect(() => {
    loadingData();
  }, [language]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleTelegramResponse = (response) => {
    authApi
      .authTelegram(`id=${response.id}&name=${response.first_name}`)
      .then((res) => {
        dispatch(setToken(res.data));
        dispatch(setCoin(res.data.coin));
        setModalTelegram(false);
      });
  };

  const handleChangeExpanded = (panel, scienceId) => (event, isExpanded) => {
    getTopicByScienceId(scienceId);

    if (localStorage.hasOwnProperty('selectTests-' + scienceId)) {
      setSelectTestList(
        JSON.parse(localStorage.getItem('selectTests-' + scienceId))
      );
    } else {
      setSelectTestList([]);
    }

    setExpanded(isExpanded ? panel : false);
  };

  const handleModalTelegram = () => {
    setModalTelegram(!modalTelegram);
  };

  const handleModalNewGroup = () => {
    setModalNewGroup(!modalNewGroup);
  };

  const handleModalNewBlok = () => {
    setModalNewBlok(!modalNewBlok);
  };

  const handlerBlok = (item) => {
    localStorage.setItem('selectTests-' + item.scienceId, JSON.stringify([]));
    const arr = blokList.filter((value) => value.scienceId === item.scienceId);
    if (arr.length > 0) {
      setSnackbarMessage('Kechirasiz, bu fan kiritilgan.');
      setOpen(true);
    } else {
      setBlokList([...blokList, item]);
      handleModalNewBlok();
    }
  };

  const handlerEditBlok = (item) => {
    const arr1 = blokList;

    const arr = blokList.filter((value) => value.scienceId === item.scienceId);
    if (arr.length > 0) {
      if (item.blokBall === arr1[blokIndex].blokBall) {
        setSnackbarMessage('Kechirasiz, bu fan kiritilgan.');
        setOpen(true);
      } else {
        arr1[blokIndex] = item;
        setBlokList(arr1);
        setBlokIndex();
        setBlokScienceId();
        setBlokScienceName();
        handleModalNewBlok();
      }
    } else {
      localStorage.setItem('selectTests-' + item.scienceId, JSON.stringify([]));
      arr1[blokIndex] = item;
      setBlokList(arr1);
      setBlokIndex();
      setBlokScienceId();
      setBlokScienceName();
      handleModalNewBlok();
    }
  };

  const timeConvert = () => {
    const seconds = formik.values.duration_time;
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const getSelectTestCount = (scienceId) => {
    if (localStorage.hasOwnProperty('selectTests-' + scienceId)) {
      const count = JSON.parse(
        localStorage.getItem('selectTests-' + scienceId)
      ).length;
      return count;
    }
    return 0;
  };

  const getSelectTest = (scienceId) => {
    const arr = JSON.parse(localStorage.getItem('selectTests-' + scienceId));
    if (arr.length > 0) {
      const arr2 = arr.map((item) => item.testId);

      setLoading(true);
      const queryTest = `${arr2.join('&testIds=')}`;
      const query = 'testIds=' + queryTest;
      testsApi
        .getSelectTest(query)
        .then((res) => {
          setCountPages(0);
          setTestList(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('Gruppa')}</title>
      </Helmet>
      <ModalWindow
        title={t('Login')}
        modal={modalTelegram}
        handleModal={handleModalTelegram}
        maxWidth="sm"
        body={<SignUpModal handleTelegramResponse={handleTelegramResponse} />}
      />
      <ModalWindowFullScreen
        title={t('Yangi gruppa')}
        modal={modalNewGroup}
        handleModal={handleModalNewGroup}
        maxWidth="lg"
        body={<NewGroup />}
      />
      <ModalWindow
        title={t("Blok qo'shish")}
        modal={modalNewBlok}
        handleModal={handleModalNewBlok}
        maxWidth="sm"
        body={
          <NewBlok
            index={blokIndex}
            ball={blokBall}
            handlerBlok={handlerBlok}
            scienceId={blokScienceId}
            scienceName={blokScienceName}
            handlerEditBlok={handlerEditBlok}
          />
        }
      />
      <ModalWindow
        title={t('delete')}
        modal={deleteModal}
        handleModal={handleDeleteModal}
        maxWidth="sm"
        body={
          <ChangeDeleteModal
            handleModal={handleDeleteModal}
            getData={() => {
              const thirdArray = blokList.filter((elem) => {
                return deleteBlokList.some((ele) => {
                  return ele.scienceId !== elem.scienceId;
                });
              });

              setBlokList(thirdArray);
              setDeleteBlokList([]);
              handleDeleteModal();
            }}
            yes={t('yes')}
            cancel={t('cancel')}
            text={t('is_delete')}
          />
        }
      />
      <Loading loading={loading} />
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3,
          px: 1,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>
          {/* {telegramAuth === null ? (
            <TelegramLoginButton
              id="telegram_login"
              dataOnauth={handleTelegramResponse}
              botName="academya_uz_bot"
            />
          ) : null} */}

          <Grid item xs={12} md={12} sm={12} mb={3}>
            <Button
              sx={{
                backgroundColor: '#2A455F',
                color: '#FFFFFF',
                textTransform: 'none',
              }}
              variant="contained"
              startIcon={<GroupIcon />}
              onClick={() => {
                navigate('/app/tests/groups');
              }}
              // color="info"
            >
              {t('Gruppalar')}
            </Button>
          </Grid>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Typography
              variant="h2"
              color="textPrimary"
              gutterBottom
              mb={3}
              mt={2}
            >
              {t('Tahrirlash')}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} sm={12} my={1}>
                <TextField
                  autoComplete="off"
                  size="small"
                  name="examName"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={t('Nom kiriting')}
                  value={formik.values.examName}
                  error={
                    formik.touched.examName && Boolean(formik.errors.examName)
                  }
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  label="Nomni kiriting"
                  helperText={formik.touched.examName && formik.errors.examName}
                />
              </Grid>
              <Grid item xs={11} md={11} sm={11}>
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box1"
                  freeSolo
                  clearOnBlur
                  className={classes.search}
                  value={formik.values.group}
                  options={groupTestList}
                  onChange={(event, newValue) => {
                    // console.log(newValue);
                    formik.setFieldValue('group', newValue.name);
                    formik.setFieldValue('groupId', newValue.test_group_id);
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }

                    // Regular option
                    return option.name;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Gruppani tanlang')}
                      InputLabelProps={{
                        style: { color: 'black' },
                      }}
                      InputProps={{
                        style: {
                          backgroundColor: theme.palette.background.paper,
                        },
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.group &&
                    formik.touched.group &&
                    formik.errors.group}
                </FormHelperText>
              </Grid>
              <Grid item xs={1} md={1} sm={1}>
                <IconButton
                  sx={{
                    color: '#2A455F',
                  }}
                  variant="contained"
                  onClick={() => {
                    if (localStorage.hasOwnProperty('token')) {
                      handleModalNewGroup();
                    } else {
                      handleModalTelegram();
                    }
                  }}
                  // color="info"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
            {blokList.length !== 0 ? (
              <Grid container>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    gutterBottom
                    mt={1}
                  >
                    {t('Bloklar')}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid container my={1}>
              {blokList.map((items, i) => (
                <Grid container key={`${i + 1}`}>
                  <Grid
                    key={items.scienceId}
                    item
                    xs={11}
                    md={11}
                    sm={11}
                    my={1}
                  >
                    <Accordion
                      expanded={expanded === `panel${i + 1}`}
                      onChange={handleChangeExpanded(
                        `panel${i + 1}`,
                        items.scienceId
                      )}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid>
                            <Typography>
                              Blok #{i + 1} ({items.scienceName}) &nbsp; &nbsp;
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography>
                              Ball:
                              {items.blokBall}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography>
                              Tanlangan testlar soni:
                              {getSelectTestCount(items.scienceId)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container my={1}>
                          <Grid item xs={12} md={12} sm={12} my={1}>
                            <Autocomplete
                              fullWidth
                              size="small"
                              id="combo-box1"
                              freeSolo
                              clearOnBlur
                              className={classes.search}
                              value={formik.values.topic_tests}
                              options={topicList}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  formik.setFieldValue(
                                    'topic_tests',
                                    newValue.name.uz
                                  );

                                  getTestsByTopicId(1, newValue.topic_test_id);
                                }
                              }}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }

                                // Regular option
                                return option.name.uz;
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option.name.uz}</li>
                              )}
                              renderInput={(params) => (
                                <TextField {...params} label={t('topic')} />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} md={12} sm={12} my={1}>
                            <Button
                              sx={{
                                backgroundColor: '#2A455F',
                                color: colors.white,
                                textTransform: 'none',
                              }}
                              name="list"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                if (
                                  localStorage.hasOwnProperty(
                                    'selectTests-' + items.scienceId
                                  )
                                ) {
                                  getSelectTest(items.scienceId);
                                }
                              }}
                              startIcon={<ListIcon sx={{ mr: 1 }} />}
                            >
                              Tanlangan testlar
                            </Button>
                          </Grid>

                          {testList.map((item, i) => (
                            <Grid
                              key={item.test_id}
                              item
                              xs={12}
                              md={6}
                              sm={12}
                            >
                              <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                                <Tooltip
                                  title={
                                    selectTestList.indexOf(item.test_id) < 0
                                      ? t('selection')
                                      : t('selected')
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <IconButton
                                    variant="filled"
                                    onClick={() => {
                                      selectTest(
                                        item,
                                        selectTestList.filter(
                                          (value) =>
                                            value.testId === item.test_id
                                        ).length <= 0
                                          ? 1
                                          : 0,
                                        items.scienceId
                                      );
                                    }}
                                  >
                                    {!selectTestList.some(
                                      (value) => value.testId === item.test_id
                                    ) ? (
                                      <CheckCircleIcon color="success" />
                                    ) : (
                                      <HighlightOffIcon color="error" />
                                    )}
                                  </IconButton>
                                </Tooltip>
                                <CardContent>
                                  <div
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.question.replace(
                                        'numberNN',
                                        (page - 1) * pageSize + i + 1 + '. '
                                      ),
                                    }}
                                  />
                                  <Divider size="medium" color="primary" />
                                  <div>
                                    <Button
                                      fullWidth
                                      disableRipple
                                      sx={{
                                        marginTop: 1,
                                        marginBottom: 1,
                                        textTransform: 'none',
                                        justifyContent: 'flex-start',
                                        paddingRight: 1,
                                      }}
                                      variant={
                                        selectTestList.some(
                                          (value) =>
                                            value.testId === item.test_id &&
                                            value.key === 1
                                        )
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      color={
                                        selectTestList.some(
                                          (value) =>
                                            value.testId === item.test_id &&
                                            value.key === 1
                                        )
                                          ? 'primary'
                                          : 'warning'
                                      }
                                      startIcon={<Avatar>A</Avatar>}
                                      onClick={() => {
                                        selectOption(
                                          item,
                                          selectTestList.filter(
                                            (value) =>
                                              value.testId === item.test_id
                                          ).length <= 0
                                            ? 1
                                            : 0,
                                          items.scienceId,
                                          1
                                        );
                                      }}
                                    >
                                      <div
                                        style={{ paddingTop: 10 }}
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                          __html: item.option_a.replace(
                                            'optionA',
                                            ''
                                          ),
                                        }}
                                      />
                                    </Button>
                                  </div>

                                  <Divider size="medium" color="primary" />
                                  <div>
                                    <Button
                                      fullWidth
                                      disableRipple
                                      sx={{
                                        marginTop: 1,
                                        marginBottom: 1,
                                        textTransform: 'none',
                                        justifyContent: 'flex-start',
                                      }}
                                      variant={
                                        selectTestList.some(
                                          (value) =>
                                            value.testId === item.test_id &&
                                            value.key === 2
                                        )
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      color={
                                        selectTestList.some(
                                          (value) =>
                                            value.testId === item.test_id &&
                                            value.key === 2
                                        )
                                          ? 'primary'
                                          : 'warning'
                                      }
                                      startIcon={<Avatar>B</Avatar>}
                                      onClick={() => {
                                        selectOption(
                                          item,
                                          selectTestList.filter(
                                            (value) =>
                                              value.testId === item.test_id
                                          ).length <= 0
                                            ? 1
                                            : 0,
                                          items.scienceId,
                                          2
                                        );
                                      }}
                                    >
                                      <div
                                        style={{ paddingTop: 10 }}
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                          __html: item.option_b.replace(
                                            'optionB',
                                            ''
                                          ),
                                        }}
                                      />
                                    </Button>
                                  </div>
                                  <Divider size="medium" color="primary" />
                                  <div>
                                    <Button
                                      fullWidth
                                      disableRipple
                                      sx={{
                                        marginTop: 1,
                                        marginBottom: 1,
                                        textTransform: 'none',
                                        justifyContent: 'flex-start',
                                      }}
                                      variant={
                                        selectTestList.some(
                                          (value) =>
                                            value.testId === item.test_id &&
                                            value.key === 3
                                        )
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      color={
                                        selectTestList.some(
                                          (value) =>
                                            value.testId === item.test_id &&
                                            value.key === 3
                                        )
                                          ? 'primary'
                                          : 'warning'
                                      }
                                      startIcon={<Avatar>C</Avatar>}
                                      onClick={() => {
                                        selectOption(
                                          item,
                                          selectTestList.filter(
                                            (value) =>
                                              value.testId === item.test_id
                                          ).length <= 0
                                            ? 1
                                            : 0,
                                          items.scienceId,
                                          3
                                        );
                                      }}
                                    >
                                      <div
                                        style={{ paddingTop: 10 }}
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                          __html: item.option_c.replace(
                                            'optionC',
                                            ''
                                          ),
                                        }}
                                      />
                                    </Button>
                                  </div>
                                  <Divider size="medium" color="primary" />
                                  <div>
                                    <Button
                                      fullWidth
                                      disableRipple
                                      sx={{
                                        marginTop: 1,
                                        marginBottom: 1,
                                        textTransform: 'none',
                                        justifyContent: 'flex-start',
                                      }}
                                      variant={
                                        selectTestList.some(
                                          (value) =>
                                            value.testId === item.test_id &&
                                            value.key === 4
                                        )
                                          ? 'contained'
                                          : 'outlined'
                                      }
                                      color={
                                        selectTestList.some(
                                          (value) =>
                                            value.testId === item.test_id &&
                                            value.key === 4
                                        )
                                          ? 'primary'
                                          : 'warning'
                                      }
                                      startIcon={<Avatar>D</Avatar>}
                                      onClick={() => {
                                        selectOption(
                                          item,
                                          selectTestList.filter(
                                            (value) =>
                                              value.testId === item.test_id
                                          ).length < 0
                                            ? 1
                                            : 0,
                                          items.scienceId,
                                          4
                                        );
                                      }}
                                    >
                                      <div
                                        style={{ paddingTop: 10 }}
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                          __html: item.option_d.replace(
                                            'optionD',
                                            ''
                                          ),
                                        }}
                                      />
                                    </Button>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                        {testList.length === 0 ? (
                          <Typography
                            my={1}
                            variant="h4"
                            align="left"
                            color={colors.grey[700]}
                          >
                            {t('no_test_data')}
                          </Typography>
                        ) : null}
                        {testList.length > 0 ? (
                          <Stack spacing={2}>
                            <Pagination
                              ullwidth="true"
                              count={countPages}
                              variant="outlined"
                              shape="rounded"
                              page={page}
                              onChange={handleChange}
                            />
                          </Stack>
                        ) : null}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={1} md={1} sm={1} key={`${i + 1}`} my={2}>
                    <IconButton
                      size="small"
                      sx={{
                        color: '#2A455F',
                      }}
                      variant="contained"
                      onClick={handleClickMenu}

                      // color="info"
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={openMenu}
                      onClose={handleCloseMenu}
                      onClick={handleCloseMenu}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            setBlokIndex(i);
                            setBlokScienceId(items.scienceId);
                            setBlokScienceName(items.scienceName);
                            setBlokBall(items.blokBall);
                            handleModalNewBlok();
                          } else {
                            handleModalTelegram();
                          }
                          handleCloseMenu();
                        }}
                      >
                        <ListItemIcon>
                          <EditIcon fontSize="small" />
                        </ListItemIcon>
                        {t('edit')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setDeleteBlokList([items]);
                          handleDeleteModal();
                          handleCloseMenu();
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        {t('delete')}
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              ))}
              <br />
              <Box sx={{ '& > :not(style)': { my: 1 } }}>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (localStorage.hasOwnProperty('token')) {
                      handleModalNewBlok();
                    } else {
                      handleModalTelegram();
                    }
                  }}
                  startIcon={<AddCircleOutlineIcon sx={{ mr: 1 }} />}
                >
                  Blok qo`shish
                </Button>
              </Box>
              {blokList.length > 0 ? (
                <Grid container my={1}>
                  <Grid item xs={12} md={12} sm={12}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        Imtihon davom etish vaqti
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="duration_status"
                        value={formik.values.duration_status}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="infinity"
                          control={<Radio />}
                          label="Cheklanmagan"
                        />
                        <FormControlLabel
                          value="time"
                          control={<Radio />}
                          label="Cheklangan"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.duration_status &&
                        formik.touched.duration_status &&
                        formik.errors.duration_status}
                    </FormHelperText>
                  </Grid>
                  {formik.values.duration_status === 'time' ? (
                    <Grid item xs={12} md={12} sm={12}>
                      <Grid container my={1}>
                        <Grid item xs={12} md={12} sm={12}>
                          <TextField
                            autoComplete="off"
                            size="small"
                            name="duration_time"
                            fullWidth
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            variant="outlined"
                            placeholder={t('Vaqtni kiriting (sekund hisobida)')}
                            value={formik.values.duration_time}
                            error={
                              formik.touched.duration_time &&
                              Boolean(formik.errors.duration_time)
                            }
                            InputProps={{
                              style: {
                                backgroundColor: theme.palette.background.paper,
                              },
                            }}
                            InputLabelProps={{
                              style: { color: 'black' },
                            }}
                            label="Vaqti kiriting (sekund hisobida)"
                            helperText={
                              formik.touched.duration_time &&
                              formik.errors.duration_time
                            }
                          />
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                          <Typography
                            variant="h2"
                            color="textPrimary"
                            gutterBottom
                            mt={2}
                          >
                            {timeConvert()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid item md={12} xs={12} my={2}>
                    <TextField
                      InputProps={{
                        style: {
                          backgroundColor: theme.palette.background.paper,
                        },
                      }}
                      InputLabelProps={{
                        style: { color: 'black' },
                      }}
                      label="Tafsilotlar"
                      name="info"
                      fullWidth
                      multiline
                      rows={4}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      placeholder={t('enter_notes')}
                      value={formik.values.info}
                    />
                  </Grid>
                  <Button
                    fullWidth
                    sx={{
                      backgroundColor: 'green',
                      color: colors.white,
                      textTransform: 'none',
                    }}
                    name="add"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Saqlash
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </form>
        </Container>
      </Box>
    </>
  );
};
