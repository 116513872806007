export function dataSelector(dataResponse) {
  const filtered = dataResponse.results.map((item) => ({
    id: item,
    locations: item.locations,
    name: item.name,
    employee: item.employee,
    class: item.classes.class_name,
    status: item.status,
    created_time:
      (new Date(item.createdAt).getDate() < 10
        ? '0' + new Date(item.createdAt).getDate()
        : new Date(item.createdAt).getDate()) +
      '/' +
      (new Date(item.createdAt).getMonth() < 10
        ? '0' + (new Date(item.createdAt).getMonth() + 1)
        : new Date(item.createdAt).getMonth() + 1) +
      '/' +
      new Date(item.createdAt).getFullYear(),
  }));
  return { total: dataResponse.count, data: filtered };
}
