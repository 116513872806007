import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  colors,
  Box,
  Button,
  Container,
  TextField,
  FormHelperText,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Loading from '../../../../../../components/Loading';
import { useStyles } from './style';
import scienceTestApi from '../../../../../../services/science_tests';

export default ({
  index,
  handlerBlok,
  handlerEditBlok,
  ball,
  scienceId,
  scienceName,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const language = useSelector((state) => state.langsReducer.language);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [sciences, setSciences] = useState([]);

  const validationSchema = Yup.object().shape({
    scienceId: Yup.string().required(t('required')),
    scienceName: Yup.string().required(t('required')),
    blokBall: Yup.number().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      scienceId: index !== undefined ? scienceId : '',
      scienceName: index !== undefined ? scienceName : '',
      blokBall: index !== undefined ? ball : 1,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        scienceId: values.scienceId,
        scienceName: values.scienceName,
        blokBall: values.blokBall,
        status: 1,
      };
      if (index !== undefined) {
        handlerEditBlok(data);
      } else {
        handlerBlok(data);
      }
    },
  });

  const loadingData = () => {
    setLoading(true);
    scienceTestApi
      .getAll('page_size=10')
      .then((res) => {
        setSciences(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handlerTest = () => {
    if (localStorage.getItem('selectTests-' + scienceId)) {
      if (
        JSON.parse(localStorage.getItem('selectTests-' + scienceId).length > 0)
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    loadingData();
    console.log(index);
    if (index !== undefined) {
      formik.setFieldValue('blokBall', ball);
      formik.setFieldValue('scienceId', scienceId);
      formik.setFieldValue('scienceName', scienceName);
    }
  }, [language]);

  return (
    <>
      <Container maxWidth={false}>
        <Loading loading={loading} />
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} my={1}>
            <Grid item xs={12} md={12} sm={12}>
              <Autocomplete
                disabled={handlerTest()}
                fullWidth
                size="small"
                id="combo-box1"
                className={classes.search}
                name="scienceId"
                value={formik.values.scienceName}
                options={sciences}
                onChange={(event, newValue) => {
                  formik.setFieldValue('scienceId', newValue.science_id);
                  formik.setFieldValue('scienceName', newValue.name.uz);
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }

                  // Regular option
                  return option.name.uz;
                }}
                isOptionEqualToValue={(option, newValue) =>
                  option.science_id === newValue.science_id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Fan')}
                    name="scienceId"
                    error={
                      formik.touched.scienceId &&
                      Boolean(formik.errors.scienceId)
                    }
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(props, option, { inputValue }) => {
                  const matches = match(option.name.uz, inputValue, {
                    insideWords: true,
                  });
                  const parts = parse(option.name.uz, matches);

                  return (
                    <li {...props}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={`${index + 1}`}
                            style={{
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </li>
                  );
                }}
              />
              <FormHelperText
                sx={{
                  color: colors.red[700],
                }}
              >
                {formik.errors.scienceId &&
                  formik.touched.scienceId &&
                  formik.errors.scienceId}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md={12} sm={12} my={1}>
              <TextField
                autoComplete="off"
                size="small"
                name="blokBall"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  const sp = e.target.value.split('.');

                  const re = /^[0-9\b.]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    if (sp.length <= 2) {
                      formik.setFieldValue('blokBall', e.target.value);
                    }
                  }
                }}
                variant="outlined"
                placeholder={t('Ballni kiriting')}
                value={formik.values.blokBall}
                error={
                  formik.touched.blokBall && Boolean(formik.errors.blokBall)
                }
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                label="Ballni kiriting"
                helperText={formik.touched.blokBall && formik.errors.blokBall}
              />
            </Grid>
          </Grid>
          <Box>
            <Button
              sx={{
                backgroundColor: '#2A455F',
                color: colors.white,
                textTransform: 'none',
              }}
              name="add"
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => console.log()}
              startIcon={<SaveIcon sx={{ mr: 1 }} />}
            >
              Saqlash
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
};
