import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';
import TimePicker from '@mui/lab/TimePicker';
import IconButton from '@mui/material/IconButton';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  colors,
  FormHelperText,
  Typography,
  CardMedia,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Modal,
  InputLabel,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import jwtDecode from 'jwt-decode';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import Loading from '../../../../components/Loading';
import MultiChipSelect from '../../../../components/FormElements/MultiSelect';
import locations from '../../../../services/locations';
import staffTitle from '../../../../services/employee_title';
import science from '../../../../services/science';
import staffs from '../../../../services/employees';
import { useStyles } from './style';
import UploadIcon from '../../../../assets/icons/upload.svg';
import { IOSSwitch, Alert } from '../../../../components/GlobalStyles';
import { marks, weekDays, permissions } from '../../../../constants';

function valuetext(value) {
  return `${value}`;
}

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedStaffTitle, setSelectedStaffTitle] = useState([]);
  const [selectedScience, setSelectedScience] = useState([]);

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const { t } = useTranslation();

  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState(false);
  const [isCoach, setIsCoach] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [confirmationShowPassword, setConfirmationShowPassword] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [locationSchema, setLocationSchema] = useState([]);
  const [staffTitleSchema, setStaffTitleSchema] = useState([]);
  const [scienceSchema, setScienceSchema] = useState([]);

  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [permissionList, setPermissionList] = useState(permissions);

  const [permission2List, setPermission2List] = useState([
    {
      id: 1,
      name: 'Manager',
    },
    {
      id: 2,
      name: 'Supervisor',
    },
    {
      id: 3,
      name: 'Staff',
    },
  ]);

  const [weekDayList, setWeekDayList] = useState(weekDays);

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const saveData = (values, data, formik) => {
    if (values.password !== values.confirmation_password) {
      formik.setSubmitting(false);
      setSnackbarMessage(t('error_input_password'));
      setOpen(true);
    } else if (
      values.password.length < 6 ||
      values.confirmation_password.length < 6
    ) {
      formik.setSubmitting(false);
      setSnackbarMessage(t('invalid_password_count'));
      setOpen(true);
    } else {
      setLoading(true);
      staffs
        .create(data)
        .then((res) => {
          setLoading(false);
          navigate('/app/employees');
        })
        .catch((err) => {
          setLoading(false);
          setSnackbarMessage(err.response.data.error);
          setOpen(true);
        });
    }
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.array().min(1, t('required')),
    availability_weekday: Yup.array().min(1, t('required')),
    employee_titles: Yup.array().min(1, t('required')),
    employee_name: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
    username: Yup.string().required(t('required')),
    gender: Yup.string().required(t('required')),
    date_birth: Yup.date().required(t('required')),
    joined_date: Yup.date().required(t('required')),
    role: Yup.string().required(t('required')),
    // password: Yup.string().required(t('required')),
    // confirmation_password: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: [],
      employee_titles: [],
      sciences: [],
      employee_name: '',
      phone: '',
      username: '',
      gender: '',
      availability_weekday: weekDayList,
      date_birth: new Date(),
      joined_date: new Date(),
      role: '',
      password: '',
      confirmation_password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const data = new FormData();

      data.append('locations', JSON.stringify(selectedLocation));
      data.append('employee_titles', JSON.stringify(selectedStaffTitle));
      data.append('sciences', JSON.stringify(selectedScience));
      data.append(
        'availability_weekday',
        JSON.stringify(values.availability_weekday)
      );
      data.append('employee_name', values.employee_name);
      data.append(
        'phone',
        values.phone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '')
      );

      data.append('gender', values.gender);
      data.append('date_birth', values.date_birth);
      data.append('joined_date', values.joined_date);
      data.append('role', values.role);
      data.append('username', values.username);
      data.append('password', values.password);
      data.append('email', '');
      data.append('is_coach', isCoach ? 1 : 0);
      data.append('photo', photo);
      data.append('status', status ? 1 : 0);

      if (state) {
        if (
          values.password !== '' &&
          values.password !== values.confirmation_password
        ) {
          formik.setSubmitting(false);
          setSnackbarMessage(t('error_input_password'));
          setOpen(true);
        } else if (
          values.password !== '' &&
          (values.password.length < 6 ||
            values.confirmation_password.length < 6)
        ) {
          formik.setSubmitting(false);
          setSnackbarMessage(t('invalid_password_count'));
          setOpen(true);
        } else {
          staffs
            .update(state, data)
            .then((res) => {
              setLoading(false);
              navigate('/app/employees');
            })
            .catch((err) => {
              setLoading(false);
              setSnackbarMessage(err.response.data.error);
              setOpen(true);
            });
        }
      } else {
        saveData(values, data, formik);
      }
    },
  });

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setPreviewImg(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
      });
    }
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  const getByLocationId = (id) => {
    setLoading(true);
    staffTitle
      .getAll(id)
      .then((ress) => {
        setLoading(false);
        if (ress.results.length === 0) {
          setSnackbarMessage('Empty staff title');
          setOpen(true);
        } else {
          setStaffTitleSchema(
            ress.results.map((item) => ({
              name: item.name,
              id: item.employee_title_id,
            }))
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    science
      .getAll(id)
      .then((ress) => {
        setLoading(false);
        if (ress.results.length === 0) {
          setSnackbarMessage('Empty science');
          setOpen(true);
        } else {
          setScienceSchema(
            ress.results.map((item) => ({
              name: item.name,
              id: item.science_id,
            }))
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadingData = () => {
    const query = `user_id=${decodedToken.user_id}`;
    if (decodedToken.role !== 'Admin') {
      setPermissionList(permission2List);
    }
    setLoading(true);
    locations
      .getAll(query)
      .then((res) => {
        setLocationSchema(
          res.results.map((item) => ({
            name: item.location_name,
            id: item.location_id,
          }))
        );
        if (state) {
          staffs
            .getOne(state)
            .then((resp) => {
              const locationIds = resp.locations.map(
                (item) => item.location_id
              );
              const query = `status=1&locations=${locationIds.join(
                '&locations='
              )}`;
              staffTitle
                .getAll(query)
                .then((ress) => {
                  if (ress.results.length === 0) {
                    setSnackbarMessage('Empty staff title');
                    setOpen(true);
                  } else {
                    setStaffTitleSchema(
                      ress.results.map((item) => ({
                        name: item.name,
                        id: item.employee_title_id,
                      }))
                    );
                    const arr = [];
                    ress.results.forEach((value) => {
                      resp.employee_titles.forEach((item) => {
                        if (
                          value.employee_title_id === item.employee_title_id
                        ) {
                          arr.push({
                            id: value.employee_title_id,
                            name: value.name,
                          });
                        }
                      });
                    });
                    setSelectedStaffTitle(arr);
                  }
                })
                .catch((err) => console.log(err));

              science
                .getAll(query)
                .then((ress) => {
                  if (ress.results.length === 0) {
                    setSnackbarMessage('Empty science');
                    setOpen(true);
                  } else {
                    setScienceSchema(
                      ress.results.map((item) => ({
                        name: item.name,
                        id: item.science_id,
                      }))
                    );
                    const arr = [];
                    ress.results.forEach((value) => {
                      resp.sciences.forEach((item) => {
                        if (value.science_id === item.science_id) {
                          arr.push({
                            id: value.science_id,
                            name: value.name,
                          });
                        }
                      });
                    });
                    setSelectedScience(arr);
                  }
                })
                .catch((err) => console.log(err));

              formik.setValues({
                ...formik.values,
                locations: resp.locations.map((item) => item.location_id),
                employee_titles: resp.employee_titles.map(
                  (item) => item.employee_title_id
                ),
                sciences: resp.sciences.map((item) => item.science_id),
                employee_name: resp.user_id.name,
                phone: resp.user_id.phone,
                gender: resp.user_id.gender,
                availability_weekday: resp.availability_weekday,
                date_birth: resp.user_id.date_birth,
                joined_date: resp.user_id.joined_date,
                role: resp.user_id.role,
                username: resp.user_id.username,
                password: '',
                confirmation_password: '',
              });
              const arr1 = [];

              res.results.forEach((value) => {
                resp.locations.forEach((item) => {
                  if (value.location_id === item.location_id) {
                    arr1.push({
                      id: value.location_id,
                      name: value.location_name,
                    });
                  }
                });
              });

              setSelectedLocation(arr1);
              setWeekDayList(resp.availability_weekday);

              if (resp.user_id.photo) {
                setPreviewImg(resp.user_id.photo);
              }
              if (resp.status === 1) {
                setStatus(true);
              } else {
                setStatus(false);
              }
              if (resp.is_coach === 1) {
                setIsCoach(true);
              } else {
                setIsCoach(false);
              }
            })
            .catch((err) => console.log(err));
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, []);

  const handleStatus = () => {
    setStatus(!status);
  };

  const handleIsCoach = () => {
    setIsCoach(!isCoach);
  };

  const handleChangeLocationChips = (event, values) => {
    // const arr = selectedLocation.map((item) => item.id);
    // event.target.value.forEach((item) => {
    //   const index = arr.indexOf(item.id);
    //   if (index < 0) {
    //     selectedLocation.push({ id: item.id, name: item.name });
    //   }
    // });
    setSelectedLocation(values);
    formik.setFieldValue('locations', values);
    const query = `status=1&locations=${values
      .map((item) => item.id)
      .join('&locations=')}`;

    getByLocationId(query);
  };

  const handleChangeEmployeeTitleChips = (event, values) => {
    // const arr = selectedStaffTitle.map((item) => item.id);
    // event.target.value.forEach((item) => {
    //   const index = arr.indexOf(item.id);
    //   if (index < 0) {
    //     selectedStaffTitle.push({ id: item.id, name: item.name });
    //   }
    // });
    setSelectedStaffTitle(values);
    formik.setFieldValue('employee_titles', values);
  };

  const handleChangeScienceChips = (event, values) => {
    // const arr = selectedStaffTitle.map((item) => item.id);
    // event.target.value.forEach((item) => {
    //   const index = arr.indexOf(item.id);
    //   if (index < 0) {
    //     selectedStaffTitle.push({ id: item.id, name: item.name });
    //   }
    // });
    setSelectedScience(values);
    formik.setFieldValue('sciences', values);
  };

  const handleWeekDay = (event) => {
    weekDayList[event.target.name * 1 - 1].active =
      !weekDayList[event.target.name * 1 - 1].active;

    setWeekDayList(weekDayList);
    formik.setFieldValue('availability_weekday', weekDayList);
  };

  const handleGender = (event) => {
    formik.setFieldValue('gender', event.target.name);
  };

  const handleChange = (event, newValue) => {
    weekDayList[event.target.name * 1 - 1].hours = newValue;
    setWeekDayList(weekDayList);
    formik.setFieldValue('availability_weekday', weekDayList);
  };

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmationPassword = () => {
    setConfirmationShowPassword(!confirmationShowPassword);
  };

  const handleMouseDownConfirmationPassword = (event) => {
    event.preventDefault();
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        pt: 3,
        minHeight: '100%',
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Modal hideBackdrop open={openCrop} onClose={handleCropModal}>
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">{t('edit_image')}</h2>
            <div style={{ width: '100%' }}>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleCropModal()}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                }
                onClick={() => {
                  getCropData();
                  handleCropModal();
                }}
              >
                {t('ready')}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h3" color="textPrimary" gutterBottom mt={1}>
              {state ? t('edit') : t('add')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={3} xs={12}>
            <Box>
              <CardMedia
                component="img"
                style={{
                  width: 210,
                  height: 210,
                  borderRadius: 5,
                  objectFit: 'fill',
                }}
                image={previewImg || UploadIcon}
                alt=""
              />
              <input
                id="selectFile"
                type="file"
                accept=".png,.jpg"
                style={{ display: 'none' }}
                onChange={imgSelectHandler.bind(this)}
              />
              <Button
                type="file"
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  width: 210,
                  marginTop: '1rem',
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
                color="error"
                onClick={() => {
                  document.getElementById('selectFile').click();
                }}
              >
                {previewImg ? t('change') : t('select_image')}
              </Button>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid item xs={12} md={12} sm={12}>
                <FormControl size="small" sx={{ width: '100%' }}>
                  <MultiChipSelect
                    labelText={t('location')}
                    name="locations"
                    items={locationSchema}
                    handleChangeChips={handleChangeLocationChips}
                    selectedItems={selectedLocation}
                    optionText={t('no_option')}
                    id="location"
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.locations &&
                      formik.touched.locations &&
                      formik.errors.locations}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid container spacing={2} my={0.5}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    name="employee_name"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    label={t('employee_name')}
                    value={formik.values.employee_name}
                    error={
                      formik.touched.employee_name &&
                      Boolean(formik.errors.employee_name)
                    }
                    size="small"
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    helperText={
                      formik.touched.employee_name &&
                      formik.errors.employee_name
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} my={0.5}>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <MultiChipSelect
                      labelText={t('employee_title')}
                      name="employee_titles"
                      items={staffTitleSchema}
                      handleChangeChips={handleChangeEmployeeTitleChips}
                      selectedItems={selectedStaffTitle}
                      optionText={t('no_option')}
                      id="employee_titles"
                      error={
                        formik.touched.employee_titles &&
                        Boolean(formik.errors.employee_titles)
                      }
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.employee_titles &&
                        formik.touched.employee_titles &&
                        formik.errors.employee_titles}
                    </FormHelperText>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_coach"
                        checked={isCoach}
                        value={isCoach}
                        onChange={handleIsCoach}
                        color="success"
                      />
                    }
                    variant="standard"
                    label={t('is_coach')}
                  />
                </Grid>
                {isCoach ? (
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl size="small" sx={{ width: '100%' }}>
                      <MultiChipSelect
                        labelText={t('science')}
                        name="science"
                        items={scienceSchema}
                        handleChangeChips={handleChangeScienceChips}
                        selectedItems={selectedScience}
                        optionText={t('no_option')}
                        id="science"
                        error={
                          formik.touched.sciences &&
                          Boolean(formik.errors.sciences)
                        }
                      />
                      <FormHelperText
                        sx={{
                          color: colors.red[700],
                        }}
                      >
                        {formik.errors.sciences &&
                          formik.touched.sciences &&
                          formik.errors.sciences}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                ) : null}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xl={12} md={6} xs={12} mt={4.5}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        label={t('date_of_birth')}
                        name="date_birth"
                        mask="__/__/____"
                        // openTo="year"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.date_birth}
                        onChange={(e) => formik.setFieldValue('date_birth', e)}
                        error={
                          formik.touched.date_birth &&
                          Boolean(formik.errors.date_birth)
                        }
                        InputProps={{
                          style: {
                            backgroundColor: theme.palette.background.paper,
                          },
                        }}
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        helperText={
                          formik.touched.date_birth && formik.errors.date_birth
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} sm={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('gender')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 4,
                      }}
                    >
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          name="male"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            backgroundColor:
                              formik.values.gender === 'male'
                                ? colors.white
                                : '#fff',
                            color: '#000',
                          }}
                          variant="contained"
                          onClick={handleGender}
                        >
                          {t('male')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          name="female"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            backgroundColor:
                              formik.values.gender === 'female'
                                ? colors.white
                                : '#fff',
                            color: '#000',
                          }}
                          variant="contained"
                          onClick={handleGender}
                        >
                          {t('female')}
                        </Button>
                      </Grid>
                    </Box>
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.gender &&
                        formik.touched.gender &&
                        formik.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} my={1}>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('phone')}
                  </Typography>
                  <InputMask
                    size="small"
                    className={`${classes.inputmask} input_mask_custom`}
                    onChange={formik.handleChange}
                    name="phone"
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="+998 (_ _) _ _ _ - _ _ - _ _"
                    mask="+\9\98 (99) 999-99-99"
                    maskChar="_"
                    style={{
                      border: '1px solid #c4c4c4',
                      outline: '1px solid #F7F8FA',
                      paddingLeft: '14px',
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6} sm={12} my={4.5}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.search}>
                      <DatePicker
                        label={t('joined_date')}
                        name="joined_date"
                        openTo="year"
                        mask="__/__/____"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.joined_date}
                        onChange={(e) => formik.setFieldValue('joined_date', e)}
                        error={
                          formik.touched.joined_date &&
                          Boolean(formik.errors.joined_date)
                        }
                        InputProps={{
                          style: {
                            backgroundColor: theme.palette.background.paper,
                          },
                        }}
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        helperText={
                          formik.touched.joined_date &&
                          formik.errors.joined_date
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} sm={12} my={1}>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <InputLabel id="employee_permission">
                    {t('employee_permission')}
                  </InputLabel>
                  <Select
                    labelId="employee_permission"
                    label={t('employee_permission')}
                    className={classes.input}
                    fullWidth
                    name="role"
                    value={formik.values.role}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    {permissionList.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={12} my={2}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('username')} <span style={{ color: 'red' }}> *</span>
                </Typography>
                <FormControl variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    {t('username')}
                  </InputLabel>
                  <OutlinedInput
                    id="standard-adornment-username"
                    label={t('username')}
                    disabled={!!state}
                    className={classes.search}
                    name="username"
                    fullWidth
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    // variant="outlined"
                    value={formik.values.username}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    size="small"
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 2,
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.username &&
                      formik.touched.username &&
                      formik.errors.username}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('password')}
                  </Typography>
                  <OutlinedInput
                    className={classes.search}
                    name="password"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_password')}
                    value={formik.values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    size="small"
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 2,
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.password &&
                      formik.touched.password &&
                      formik.errors.password}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('confirmation_password')}
                  </Typography>
                  <OutlinedInput
                    className={classes.search}
                    name="confirmation_password"
                    fullWidth
                    type={confirmationShowPassword ? 'text' : 'password'}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_confirmation_password')}
                    value={formik.values.confirmation_password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmationPassword}
                          onMouseDown={handleMouseDownConfirmationPassword}
                          edge="end"
                        >
                          {confirmationShowPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.confirmation_password &&
                      Boolean(formik.errors.confirmation_password)
                    }
                    size="small"
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 2,
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.confirmation_password &&
                      formik.touched.confirmation_password &&
                      formik.errors.confirmation_password}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} sm={12} my={4}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('availibilty')}
                </Typography>
                <Card>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      p: 0.2,
                    }}
                  >
                    {formik.values.availability_weekday.map((item, index) => (
                      <Button
                        // fullWidth
                        sx={{
                          textTransform: 'none',
                          backgroundColor: formik.values.availability_weekday[
                            item.id - 1
                          ].active
                            ? '#2A455F'
                            : colors.common.white,
                          borderColor: 'white',
                          color: formik.values.availability_weekday[item.id - 1]
                            .active
                            ? 'white'
                            : colors.grey[700],
                          '&:hover': {
                            backgroundColor: '#2A455F',
                            color: 'white',
                          },
                        }}
                        key={item.id}
                        name={item.id}
                        variant="outlined"
                        onClick={handleWeekDay}
                      >
                        {t(`${item.name}`)}
                      </Button>
                    ))}
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} sm={12} my={1}>
                {formik.values.availability_weekday.map((item, index) => (
                  <div key={item.id}>
                    <Box
                      sx={{
                        py: 1,
                      }}
                    >
                      {item.active ? (
                        <Grid container>
                          <Grid item md={1} sm={1} xs={1}>
                            <Typography
                              my={1}
                              variant="h5"
                              align="left"
                              color={colors.grey[700]}
                            >
                              {t(`${item.name}`)}
                            </Typography>
                          </Grid>
                          <Grid item md={11} sm={11} xs={11}>
                            <Slider
                              name={item.id}
                              getAriaLabel={() => ''}
                              value={item.hours}
                              onChange={handleChange}
                              valueLabelDisplay="auto"
                              getAriaValueText={valuetext}
                              step={1}
                              marks={marks}
                              min={0}
                              max={23}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </div>
                ))}
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={() => navigate('/app/employees')}
                >
                  {t('cancel')}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => console.log()}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(state, formik.isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
