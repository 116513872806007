import types from '../../../constants/action-types';

const defaultState = {
  total: 0,
};

const map = {
  [types.SET_DATA]: (state, { payload }) => ({
    ...state,
    item: payload,
  }),
  [types.TABLE_SET_TOTAL]: (state, { payload }) => ({
    ...state,
    total: payload,
  }),
  [types.SET_PROFILE_IMAGE]: (state, { payload }) => ({
    ...state,
    image: payload,
  }),
  [types.SET_COIN]: (state, { payload }) => ({
    ...state,
    coin: payload,
  }),
  [types.SET_SELECT_TESTS]: (state, { payload }) => ({
    ...state,
    select_tests: payload,
  }),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) =>
  (map[action.type] && map[action.type](state, action)) ||
  state ||
  defaultState;
