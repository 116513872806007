import React from 'react';
import { colors, Typography, CardMedia, Avatar } from '@mui/material';
import { Container, Content } from './style';
import UploadIcon from '../../../assets/icons/upload.svg';

export default ({ value }) => {
  const photoUrl = process.env.REACT_APP_BASE_URL.replace('/api/v1', '');

  const handleNamer = (value) => {
    if (value) {
      if (value.package_name) {
        return value.package_name;
      }
      if (value.user_id) {
        if (value.user_id.name) {
          return value.user_id.name;
        }
        if (value.user_id.fields) {
          if (value.user_id.fields.name) {
            return value.user_id.fields.name;
          }
        }
      }
    }
    return '';
  };
  return (
    <Container>
      <Content>
        {value.photo ? (
          <CardMedia
            component="img"
            style={{
              borderRadius: 5,
              width: 60,
              height: 60,
            }}
            image={
              value.photo.replace('http:', 'https:').includes(photoUrl)
                ? value.photo
                : value.photo
            }
            alt=""
          />
        ) : (
          <CardMedia
            component="img"
            style={{
              borderRadius: 5,
              width: 60,
              height: 60,
            }}
            image={UploadIcon}
            alt=""
          />
        )}
      </Content>

      <Content>&nbsp; {handleNamer(value)}</Content>
    </Container>
  );
};
