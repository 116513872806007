import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  Snackbar,
  Stack,
  Modal,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  InputLabel,
  IconButton,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MuiAlert from '@mui/material/Alert';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { Filter as FilterIcon, Search as SearchIcon } from 'react-feather';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { readXlsxFile, readSheetNames } from 'read-excel-file';
import Loading from '../../../../components/Loading';
import { toolTips } from './helper';
import { useStyles } from './style';
import Table from '../../../../components/Table';
import { customersHeader } from '../../../../redux/modules/table/common';
import { headerMaker } from '../../../../components/Table/helper';
import { fetchData } from '../../../../redux/modules/customers/actions';
import { setTotals } from '../../../../redux/modules/store/actions';
import FilterTools from '../../../../components/FilteredBody/Customer';
import AnimationDiv from '../../../../components/Animations/Transition';
import { useQuery } from '../../../useQuery';
import locations from '../../../../services/locations';
import importCustomer from '../../../../services/import_customers';
import exportData from '../../../../services/exports';
import ModalWindow from '../../../../components/Modals';
import ChangeStatusModal from '../../../../components/Modals/ConfirmStatus';
import customerApi from '../../../../services/customers';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({ ...rest }) => {
  const classes = useStyles();
  const {
    query,
    search,
    status,
    setSearch,
    setStatus,
    handleOnTableChange,
    setLocationId,
  } = useQuery({ fetchData });

  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const [locationList, setLocationList] = useState([]);
  const { data, total } = useSelector((state) => state.customersReducer);

  localStorage.setItem('total_data', total);
  const language = useSelector((state) => state.langsReducer.language);
  const headers = useMemo(
    () => headerMaker(customersHeader),
    [customersHeader]
  );
  const [loading, setLoading] = useState(false);
  const customerItem = useSelector((state) => state.ordersReducer.item);
  const [statusModal, setStatusModal] = useState(false);

  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState('');
  const [sheetNames, setSheetNames] = useState([]);
  const [selectSheetName, setSelectSheetName] = useState('');
  const [exportLocationId, setExportLocationId] = useState('');
  const [selectImportLocation, setSelectImportLocation] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [exportIsAllDate, setExportIsAllDate] = useState(false);
  const [downloadFile, setDownloadFile] = useState('empty');

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const curr = addHoursToDate(new Date(), 5);
  const first = curr.getDate();
  const last = first - 6;

  const [firstday, setFirstDay] = useState(
    new Date(curr.setDate(first)).toISOString()
  );
  const [lastday, setLastDay] = useState(
    new Date(curr.setDate(last)).toISOString()
  );

  const [exportBeginDate, setExportBeginDate] = useState(lastday);
  const [exportEndDate, setExportEndDate] = useState(firstday);

  const addCustomer = () => {
    navigate('/app/customers/create');
  };

  const doubleClick = (id) => {
    navigate('/app/customers/create', { state: id });
  };

  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 500,
    bgcolor: 'background.default',
    border: '2px solid #fff',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: 'all',
    },
    validationSchema,
  });

  const getCustomerByLocationId = (id) => {
    setLoading(true);
    formik.setFieldValue('locations', id);
    if (id === 'all') {
      const locationIds = [];
      locationList.forEach((item) => {
        locationIds.push(item.location_id);
      });
      const query = `${locationIds.join('&locations=')}`;
      setLocationId(query);
    } else {
      setLocationId(id);
    }
    setLoading(false);
  };

  const loadingData = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationList(res.results);
        const locationIds = [];
        res.results.forEach((item) => {
          locationIds.push(item.location_id);
        });
        const query = `${locationIds.join('&locations=')}`;
        setLocationId(query);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    dispatch(setTotals(total));
    loadingData();
  }, [language]);

  const renderButton = (isSubmitting) => {
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const fileSelectHandler = (e) => {
    console.log(e.target.files);
    if (e.target.files.length !== 0) {
      setFile(e.target.files[0]);
      readSheetNames(e.target.files[0]).then((names) => {
        setSheetNames(names);
      });
    }
  };

  const uploadFile = () => {
    document.getElementById('selectFile').click();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExport = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setExportModal(false);
  };

  const sendImportCustomer = () => {
    setIsSubmitting(true);
    const data = new FormData();
    data.append('locations', selectImportLocation);
    data.append('sheet_name', selectSheetName);
    data.append('file', file);
    importCustomer
      .upload(data)
      .then((res) => {
        console.log(res);
        handleModal();
        loadingData();
      })
      .catch((err) => {
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              sendImportCustomer();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  const sendExport = (query, data) => {
    exportData
      .get(query, data)
      .then((res) => {
        setDownloadFile(res.filename);
        setIsSubmitting(false);
      })
      .catch((err) => {
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              sendExport(query, data);
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  const getExportData = () => {
    setIsSubmitting(true);
    const data = new FormData();
    const first = new Date(exportEndDate);
    const last = new Date(exportBeginDate);
    data.append('is_all_date', exportIsAllDate ? 1 : 0);
    data.append('table', 'Customer');
    data.append('begin_date', first.toISOString());
    data.append('end_date', last.toISOString());
    if (exportLocationId === 'all') {
      const locationIds = locationList.map((item) => item.location_id);

      const query = `locations=${locationIds.join('&locations=')}`;
      data.append('locations', exportLocationId);
      sendExport(query, data);
    } else {
      data.append('locations', exportLocationId);
      const query = 'locations=' + exportLocationId;
      sendExport(query, data);
    }
  };

  const handleStatusModal = () => {
    setStatusModal(!statusModal);
  };

  const changeStatus = () => {
    setLoading(true);
    const data = new FormData();
    data.append('status', customerItem.status === 1 ? 0 : 1);

    customerApi
      .changeStatus(customerItem.customer_id, data)
      .then((resp) => {
        setStatusModal(!statusModal);
        setLoading(false);
        setSearch(customerItem.user_id.name);
        setSearch('');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  return (
    <>
      <Helmet>
        <title> {t('customers')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 1,
        }}
      >
        <Container maxWidth={false}>
          <ModalWindow
            title={t('change_status')}
            modal={statusModal}
            handleModal={handleStatusModal}
            maxWidth="sm"
            body={
              <ChangeStatusModal
                handleModal={handleStatusModal}
                changeStatus={changeStatus}
                yes={t('yes')}
                cancel={t('cancel')}
                text={t('ask_change_status')}
              />
            }
          />
          <Modal hideBackdrop open={modal} onClose={handleModal}>
            <Box sx={{ ...style }}>
              <h2 id="child-modal-title">{t('upload')}</h2>
              <Box
                sx={{
                  py: 2,
                }}
              >
                <Grid item xs={12} md={12} sm={12} my={1}>
                  <FormControl
                    size="small"
                    sx={{ width: '100%', backgroundColor: 'white' }}
                  >
                    <InputLabel id="locations">{t('location')}</InputLabel>
                    <Select
                      labelId="locations"
                      label={t('location')}
                      className={classes.input}
                      fullWidth
                      name="location"
                      value={selectImportLocation}
                      onChange={(e) => setSelectImportLocation(e.target.value)}
                    >
                      {locationList.map((item, index) => (
                        <MenuItem key={`${index + 1}`} value={item.location_id}>
                          {item.location_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <input
                  id="selectFile"
                  type="file"
                  accept=".xlsx"
                  style={{ display: 'none' }}
                  onChange={fileSelectHandler.bind(this)}
                />
                <Button
                  type="file"
                  fullWidth
                  size="small"
                  variant="contained"
                  sx={{
                    textTransform: 'none',
                    fontSize: 20,
                    marginTop: '0.5rem',
                    backgroundColor: '#2A455F',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                      backgroundColor: '#2A455F',
                      opacity: 0.8,
                    },
                  }}
                  color="error"
                  onClick={uploadFile}
                >
                  {t('select_file')}
                </Button>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  gutterBottom
                  mb={2}
                  mt={2}
                >
                  {file.name}
                </Typography>
                {sheetNames.length !== 0 ? (
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                    mb={2}
                    mt={2}
                  >
                    {t('select')}
                  </Typography>
                ) : null}
                <div className={classes.startBtns}>
                  {sheetNames.map((item) => {
                    return (
                      <Button
                        sx={{
                          backgroundColor:
                            selectSheetName === item ? '#2A455F' : '#fff',
                          color: '#161616',
                          textTransform: 'none',
                        }}
                        variant="contained"
                        onClick={() => {
                          setSelectSheetName(item);
                        }}
                      >
                        {item}
                      </Button>
                    );
                  })}
                </div>

                <Grid container spacing={3} my={2}>
                  <Grid item xs={6} md={12} sm={6}>
                    <div className={classes.topBtns}>
                      <Button
                        sx={{
                          backgroundColor: '#CFE0F0',
                          color: '#161616',
                          textTransform: 'none',
                        }}
                        variant="contained"
                        onClick={() => handleModal()}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: '#2A455F',
                          color: colors.white,
                          textTransform: 'none',
                        }}
                        name="add"
                        variant="contained"
                        type="submit"
                        color="primary"
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress size="0.9rem" />
                          ) : undefined
                        }
                        onClick={() => {
                          if (
                            selectSheetName === '' ||
                            selectImportLocation === ''
                          ) {
                            setSnackbarMessage(t('select'));
                            setOpen(true);
                          } else {
                            sendImportCustomer();
                          }
                        }}
                      >
                        {renderButton(isSubmitting)}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
          <Modal hideBackdrop open={exportModal} onClose={handleExport}>
            <Box sx={{ ...style }}>
              <h2 id="child-modal-title">{t('export')}</h2>
              <Box
                sx={{
                  py: 1,
                }}
              >
                <Grid container spacing={1} alignItems="center" my={1}>
                  <Grid item xs={12} md={12} sm={12}>
                    <FormControl
                      size="small"
                      sx={{ width: '100%', backgroundColor: 'white' }}
                    >
                      <InputLabel id="locations">{t('location')}</InputLabel>
                      <Select
                        labelId="locations"
                        label={t('location')}
                        className={classes.input}
                        fullWidth
                        name="export_locations"
                        value={exportLocationId}
                        onChange={(e) => setExportLocationId(e.target.value)}
                      >
                        <MenuItem key="all" value="all">
                          {t('all')}
                        </MenuItem>
                        {locationList.map((item, index) => (
                          <MenuItem
                            key={`${index + 1}`}
                            value={item.location_id}
                          >
                            {item.location_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_all_date"
                          checked={exportIsAllDate}
                          value={exportIsAllDate}
                          onChange={() => {
                            setExportIsAllDate(!exportIsAllDate);
                          }}
                          color="success"
                        />
                      }
                      variant="standard"
                      label={t('is_all_date')}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={12} md={12} sm={12}>
                        <Typography
                          my={1}
                          variant="h6"
                          align="left"
                          color={colors.grey[700]}
                        >
                          {t('begin_date')}
                        </Typography>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={localeMap[language]}
                        >
                          <Stack spacing={3} className={classes.input}>
                            <DatePicker
                              disabled={exportIsAllDate}
                              name="export_begin_date"
                              openTo="day"
                              mask="__/__/____"
                              views={['year', 'month', 'day']}
                              // minDate={new Date()}
                              value={exportBeginDate}
                              onChange={(e) => {
                                setExportBeginDate(e);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12} md={12} sm={12}>
                        <Typography
                          my={1}
                          variant="h6"
                          align="left"
                          color={colors.grey[700]}
                        >
                          {t('end_date')}
                        </Typography>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={localeMap[language]}
                        >
                          <Stack spacing={3} className={classes.input}>
                            <DatePicker
                              disabled={exportIsAllDate}
                              name="export_end_date"
                              openTo="day"
                              mask="__/__/____"
                              views={['year', 'month', 'day']}
                              // maxDate={new Date()}
                              value={exportEndDate}
                              onChange={(e) => {
                                setExportEndDate(e);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {downloadFile !== 'empty' ? (
                  <a href={downloadFile} download="">
                    {t('download')}
                  </a>
                ) : null}
                <Grid container spacing={3} my={1}>
                  <Grid item xs={6} md={12} sm={6}>
                    <div className={classes.topBtns}>
                      <Button
                        sx={{
                          backgroundColor: '#CFE0F0',
                          color: '#161616',
                          textTransform: 'none',
                        }}
                        variant="contained"
                        onClick={() => handleExport()}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: '#2A455F',
                          color: colors.white,
                          textTransform: 'none',
                        }}
                        name="add"
                        variant="contained"
                        type="submit"
                        color="primary"
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress size="0.9rem" />
                          ) : undefined
                        }
                        onClick={() => {
                          if (exportLocationId === '') {
                            setSnackbarMessage(t('select_location'));
                            setOpen(true);
                          } else {
                            getExportData();
                          }
                        }}
                      >
                        {renderButton(isSubmitting)}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Modal>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <Loading loading={loading} />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            mb={4}
            mt={2}
          >
            {t('customers')}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={11} md={11} sm={11}>
              <div className={classes.navFilterSection}>
                <TextField
                  className={classes.search}
                  size="small"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  label={t('search')}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={addCustomer}
                // color="info"
              >
                <AddIcon />
              </IconButton>
              {/* <div className={classes.topBtns}> */}
              {/* <Button
                  sx={{
                    padding: 1,
                    backgroundColor: 'white',
                    color: '#161616',
                    textTransform: 'none',
                    borderColor: '#F3F3F3',
                    border: 1,
                  }}
                  className={classes.search}
                  variant="outlined"
                  startIcon={<OpenInNewIcon />}
                  onClick={() => setModal(!modal)}
                  // color="info"
                >
                  {t('import')}
                </Button> */}
              {/* <Button
                  sx={{
                    backgroundColor: 'white',
                    color: '#161616',
                    textTransform: 'none',
                    borderColor: '#F3F3F3',
                    border: 1,
                  }}
                  className={classes.search}
                  variant="outlined"
                  startIcon={<OpenInNewIcon />}
                  onClick={() => {
                    setDownloadFile('empty');
                    setExportModal(!exportModal);
                  }}
                  // color="info"
                >
                  {t('export')}
                </Button>
                
              </div> */}
            </Grid>
          </Grid>
          <Grid container spacing={1} my={0.5}>
            <Grid item xs={11} md={11} sm={11}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="locations">{t('location')}</InputLabel>
                <Select
                  labelId="locations"
                  label={t('location')}
                  className={classes.input}
                  fullWidth
                  name="locations"
                  value={formik.values.locations}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.locations && Boolean(formik.errors.locations)
                  }
                  onChange={(e) => getCustomerByLocationId(e.target.value)}
                >
                  <MenuItem key="all" value="all">
                    {t('all')}
                  </MenuItem>
                  {locationList.map((item, index) => (
                    <MenuItem key={`${index + 1}`} value={item.location_id}>
                      {item.location_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                color="primary"
                aria-label="delete"
                size="medium"
                onClick={() => {
                  setIsShow(!isShow);
                }}
              >
                <FilterListIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
          <AnimatePresence className={classes.filters}>
            {isShow ? (
              <AnimationDiv>
                <FilterTools status={status} setStatus={setStatus} />
              </AnimationDiv>
            ) : null}
          </AnimatePresence>
          <Box sx={{ pt: 2 }}>
            <div className={classes.root}>
              <Card {...rest}>
                <Table
                  data={data}
                  headers={headers}
                  toolTips={toolTips}
                  onChange={handleOnTableChange}
                  modal={handleModal}
                  statusModal={handleStatusModal}
                  doubleClick={doubleClick}
                />
              </Card>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};
