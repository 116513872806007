import forms from './actions/form';
import comments from './actions/comments';
import customers from './actions/customers';
import locations from './actions/locations';
import staffs from './actions/staffs';
import exam from './actions/exam';
import auth from './actions/auth';
import rooms from './actions/rooms';
import classes from './actions/class';
import course from './actions/course';
import courseSchedule from './actions/course_schedule';
import staffTitle from './actions/staff_title';
import science from './actions/science';
import booking from './actions/booking';
import articles from './actions/articles';
import orders from './actions/orders';
import ordersHistory from './actions/orders_history';
import modals from './actions/modals';
import store from './actions/store';
import application from './actions/application';
import lang from './actions/lang';

export default {
  ...forms,
  ...staffTitle,
  ...science,
  ...rooms,
  ...comments,
  ...auth,
  ...store,
  ...customers,
  ...locations,
  ...staffs,
  ...booking,
  ...articles,
  ...orders,
  ...ordersHistory,
  ...classes,
  ...course,
  ...courseSchedule,
  ...exam,
  ...modals,
  ...application,
  ...lang,
};
