import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';

export default function CustomizedInputBase({ handlerSearch }) {
  return (
    <Grid item xs={12} md={12} sm={12}>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
      >
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          fullWidth
          sx={{ ml: 1, flex: 1 }}
          placeholder="Qidiriv"
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => {
            handlerSearch(e.target.value);
          }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        {/* <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
      </Paper>
    </Grid>
  );
}
