/* eslint-disable no-prototype-builtins */
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  FormControlLabel,
  Divider,
  IconButton,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Avatar,
  CardMedia,
} from '@mui/material';
import Moment from 'react-moment';
import Pagination from '@mui/material/Pagination';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled, lighten, darken } from '@mui/system';
import Loading from '../../../../../../components/Loading';
import ModalWindowFullScreen from '../../../../../../components/Modals/FullScreen';
import {
  setSelectTests,
  setCoin,
} from '../../../../../../redux/modules/store/actions';
import { setToken } from '../../../../../../redux/modules/auth/actions';
import { useStyles } from './style';
import userApi from '../../../../../../services/users';
import testsApi from '../../../../../../services/tests';
import authApi from '../../../../../../services/auth';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({ id }) => {
  const [expanded, setExpanded] = useState(false);

  const language = useSelector((state) => state.langsReducer.language);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState([]);
  const [examName, setExamName] = useState('');

  const [examAnswers, setExamAnswers] = useState({ bloks: [] });
  const [selectKeyList, setSelectKeyList] = useState([]);

  const [modalResult, setModalResult] = useState(false);
  const [examOwner, setExamOwner] = useState('');

  const [countPages, setCountPages] = useState(0);

  const [page, setPage] = useState(1);

  const getTestsByExamId2 = (id) => {
    setLoading(false);

    testsApi
      .getOneExamResult(id)
      .then((res2) => {
        const arr = [];
        setExamAnswers(res2);

        res2.bloks.forEach((val) => {
          val.test_data.forEach((val2) => {
            res2.keys.forEach((val3) => {
              if (
                val3.scienceId === val.scienceId &&
                val3.testId === val2.test_id
              ) {
                const item = {
                  scienceId: val.scienceId,
                  key: val3.key,
                  testId: val2.test_id,
                };
                arr.push(item);
              }
            });
            const item = {
              scienceId: val.scienceId,
              key: 0,
              testId: val2.test_id,
            };
            arr.push(item);
          });
        });
        setSelectKeyList(arr);
        setModalResult(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadingData = (page) => {
    setLoading(true);
    testsApi
      .getAllExamResults('page=' + page + '&page_size=10&exam_id=' + id)
      .then((res) => {
        setCountPages(res.count_pages);
        setExamName(res.data.examName);
        setExamOwner(res.data.exam_owner);
        setResults(res.data.users);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    loadingData(page);
  }, [language]);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getKeyColorVariant = (item, optionKey) => {
    if (
      selectKeyList.some(
        (value) =>
          value.testId === item.test_id &&
          value.key === item.key &&
          item.key === optionKey
      )
    ) {
      return { color: 'success', variant: 'contained' };
    }
    if (
      selectKeyList.some(
        (value) =>
          value.testId === item.test_id &&
          value.key !== item.key &&
          value.key === optionKey
      )
    ) {
      return { color: 'error', variant: 'contained' };
    }
    return { color: 'warning', variant: 'outlined' };
  };

  const handleModalResult = () => {
    setModalResult(!modalResult);
  };

  const handleChange = (event, value) => {
    setPage(value);

    loadingData(value);
  };

  const checkUser = (id) => {
    if (localStorage.hasOwnProperty('token')) {
      try {
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        if (decodedToken.role === 'Super_Admin') {
          return true;
        }
        if (decodedToken.user_auth_id === examOwner) {
          return true;
        }
        if (decodedToken.user_auth_id === id) {
          return true;
        }
      } catch (err) {
        localStorage.removeItem('token');
        return false;
      }
    }
    return false;
  };

  const timeConvert = (time) => {
    if (time !== null) {
      const sp1 = time.split('T');
      const sp2 = sp1[1].split(':');
      const strTime = sp1[0] + ' ' + sp2[0] + ':' + sp2[1];
      return strTime;
    }
    return time;
  };

  return (
    <>
      <ModalWindowFullScreen
        title={t('Natija')}
        modal={modalResult}
        handleModal={handleModalResult}
        body={
          <Grid container my={1}>
            {examAnswers.bloks.map((items, i) => (
              <Grid key={items.scienceId} item xs={12} md={12} sm={12} my={1}>
                <Accordion
                  expanded={expanded === `panels${i + 1}`}
                  onChange={handleChangeExpanded(`panels${i + 1}`)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Grid container>
                      <Grid item xs={12} md={11} sm={12}>
                        <Typography>Blok #1 ({items.scienceName})</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container my={1}>
                      {items.test_data.map((item, i) => (
                        <Grid key={item.test_id} item xs={12} md={6} sm={12}>
                          <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                            <CardContent>
                              <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: item.question.replace(
                                    'numberNN',
                                    i + 1 + '. '
                                  ),
                                }}
                              />
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                    paddingRight: 1,
                                  }}
                                  variant={
                                    getKeyColorVariant(
                                      item,
                                      item.option_a.order
                                    ).variant
                                  }
                                  color={
                                    getKeyColorVariant(
                                      item,
                                      item.option_a.order
                                    ).color
                                  }
                                  startIcon={<Avatar>A</Avatar>}
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_a.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>

                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    getKeyColorVariant(
                                      item,
                                      item.option_b.order
                                    ).variant
                                  }
                                  color={
                                    getKeyColorVariant(
                                      item,
                                      item.option_b.order
                                    ).color
                                  }
                                  startIcon={<Avatar>B</Avatar>}
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_b.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    getKeyColorVariant(
                                      item,
                                      item.option_c.order
                                    ).variant
                                  }
                                  color={
                                    getKeyColorVariant(
                                      item,
                                      item.option_c.order
                                    ).color
                                  }
                                  startIcon={<Avatar>C</Avatar>}
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_c.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                              <Divider size="medium" color="primary" />
                              <div>
                                <Button
                                  fullWidth
                                  disableRipple
                                  sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                    textTransform: 'none',
                                    justifyContent: 'flex-start',
                                  }}
                                  variant={
                                    getKeyColorVariant(
                                      item,
                                      item.option_d.order
                                    ).variant
                                  }
                                  color={
                                    getKeyColorVariant(
                                      item,
                                      item.option_d.order
                                    ).color
                                  }
                                  startIcon={<Avatar>D</Avatar>}
                                  onClick={() => {}}
                                >
                                  <div
                                    style={{ paddingTop: 10 }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                      __html: item.option_d.text
                                        .replace('optionA', '')
                                        .replace('optionB', '')
                                        .replace('optionC', '')
                                        .replace('optionD', ''),
                                    }}
                                  />
                                </Button>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                    {items.test_data.length === 0 ? (
                      <Typography
                        my={1}
                        variant="h4"
                        align="left"
                        color={colors.grey[700]}
                      >
                        {t('no_test_data')}
                      </Typography>
                    ) : null}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
        }
      />
      <Container maxWidth={false}>
        <Loading loading={loading} />
        <Grid container>
          <Grid item xs={12} md={11} sm={12}>
            <Typography>Imtihon - {examName}</Typography>
          </Grid>
        </Grid>
        <Grid container my={1}>
          {results.map((items, i) => (
            <Grid key={`${i + 1}`} item xs={12} md={12} sm={12} my={1}>
              <Accordion
                expanded={expanded === `panel${i + 1}`}
                onChange={handleChangeExpanded(`panel${i + 1}`)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Grid container>
                    {items.photo ? (
                      <CardMedia
                        component="img"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: 5,
                          objectFit: 'fill',
                        }}
                        image={items.photo}
                        alt=""
                      />
                    ) : (
                      <Avatar sx={{ borderRadius: 5, bgcolor: '#E5E8EC' }}>
                        <AccountCircleIcon
                          sx={{ borderRadius: 5, bgcolor: '#000' }}
                        />
                        {/* <PersonOutlineIcon onClick={handleClick} /> */}
                      </Avatar>
                    )}
                    <Grid item xs={12} md={12} sm={12}>
                      <Typography>
                        {i + 1}. {items.userName} (
                        <Moment format="YYYY-MM-DD HH:mm">
                          {timeConvert(items.created_at)}
                        </Moment>
                        ) &nbsp; ({items.commonScore})
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container my={1}>
                    {items.bloks.map((item, i) => (
                      <Grid key={item.scienceId} item xs={12} md={6} sm={12}>
                        <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                          <CardContent>
                            <Typography
                              my={1}
                              variant="h5"
                              align="left"
                              color={colors.grey[700]}
                            >
                              Blok - {item.scienceName}
                            </Typography>
                            <Divider size="medium" color="primary" />
                            <Typography
                              my={1}
                              variant="h5"
                              align="left"
                              color={colors.grey[700]}
                            >
                              To`g`ri javoblar - {item.score}
                            </Typography>
                            <Divider size="medium" color="primary" />
                            <Typography
                              my={1}
                              variant="h5"
                              align="left"
                              color={colors.grey[700]}
                            >
                              Ball - {item.blokBall}
                            </Typography>
                            <Divider size="medium" color="primary" />
                            <Typography
                              my={1}
                              variant="h5"
                              align="left"
                              color={colors.grey[700]}
                            >
                              Umumiy - {item.score * item.blokBall}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                    {checkUser(items.userId) ? (
                      <Button
                        fullWidth
                        sx={{
                          backgroundColor: 'green',
                          color: colors.white,
                          textTransform: 'none',
                        }}
                        name="add"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          console.log(items);
                          getTestsByExamId2(items.test_exam_result_id);
                        }}
                      >
                        Ko`rish
                      </Button>
                    ) : null}
                  </Grid>
                  {/* {items.test_data.length === 0 ? (
                    <Typography
                      my={1}
                      variant="h4"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('no_test_data')}
                    </Typography>
                  ) : null} */}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
          <br />
        </Grid>

        <Stack spacing={2}>
          <Pagination
            ullwidth="true"
            count={countPages}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handleChange}
          />
        </Stack>
      </Container>
    </>
  );
};
