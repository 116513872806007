import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  InputAdornment,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import Pagination from './Pagination';
import Tooltip from './Tooltip';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <Typography ref={resolvedRef} {...rest} />;
  }
);

export default ({
  data,
  headers,
  toolTips,
  onChange,
  fetchData,
  load,
  modal,
  statusModal,
  hidePagination,
  doubleClick,
}) => {
  const [selectedRows, setSelectedRows] = useState({});
  const [indexT, setIndexT] = useState(-1);

  const totals = localStorage.getItem('total_data');
  const [pgCount, setPgCount] = useState(0);
  const { t } = useTranslation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns: headers,
      data,
      manualSortBy: true,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount: pgCount,
      autoResetPage: false,
    },
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: 'selection',
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );
  useEffect(() => {
    setSelectedRows(selectedFlatRows.map((item) => item.original.id));
    if (fetchData) fetchData({ pageIndex, pageSize });
    onChange({ pageIndex, pageSize });
    const count = Math.ceil(totals / pageSize);
    setPgCount(count);
  }, [
    setSelectedRows,
    selectedRowIds,
    pageIndex,
    pageSize,
    onChange,
    fetchData,
    totals,
  ]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((header, idx) => (
                  <TableCell
                    key={`${idx + 1}`}
                    sx={{
                      // paddingLeft: 0,
                      textAlign: 'left',
                      color: '#FFFFFF',
                      backgroundColor: '#000000',
                    }}
                  >
                    {typeof header.render('Header') === 'string'
                      ? t(`${header.render('Header')}`)
                      : ''}
                  </TableCell>
                ))}
                {hidePagination !== 1 ? (
                  <TableCell
                    align="right"
                    sx={{
                      textAlign: 'right',
                      color: '#FFFFFF',
                      backgroundColor: '#000000',
                    }}
                  >
                    {t('action')}
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <TableRow key={`${index + 1}`}>
                  {row.cells.map((cell, ind) => (
                    <TableCell
                      key={`${ind + 1}`}
                      sx={{
                        // paddingLeft: 0,
                        textAlign: 'left',
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                      onDoubleClick={() => {
                        doubleClick(row.original.id);
                      }}
                    >
                      {cell.column.prefix}
                      <span>{cell.render('Cell')}</span>
                      {` ${cell.column.suffix || ''}`}
                    </TableCell>
                  ))}

                  {hidePagination !== 1 ? (
                    <TableCell
                      align="right"
                      sx={{
                        textAlign: 'right',
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                      onDoubleClick={() => {
                        doubleClick(row.original.id);
                      }}
                    >
                      <Tooltip
                        indexT={indexT}
                        index={index}
                        itemId={row.original.id}
                        status={row.original.status}
                        data={toolTips}
                        load={load}
                        modal={modal}
                        statusModal={statusModal}
                        setIndexT={setIndexT}
                      />
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length !== 0 ? (
        <Box
          sx={{
            backgroundColor: 'background.default',
            // py: 1,
          }}
        >
          <Pagination
            canPreviousPage={canPreviousPage}
            pageOptions={pageOptions}
            canNextPage={canNextPage}
            total={totals}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
            hidePagination={hidePagination}
          />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            py: 3,
          }}
        >
          <Container maxWidth={false}>
            <h2 align="center">{t('no_data')}</h2>
          </Container>
        </Box>
      )}
    </Paper>
  );
};
