import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import InputMask from 'react-input-mask';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  TextField,
  Typography,
  Grid,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';
import auth from '../../../../services/auth';
import { setVerify } from '../../../../redux/modules/auth/actions';
import AcademicIcon from '../../../../assets/icons/logo.png';

const ResetPassword = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isInvalid, setIsInvalid] = useState(false);
  const schema = Yup.object().shape({
    phone: Yup.string().min(19).max(255).required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const data = {};
      data.phone = values.phone
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll(' ', '')
        .replaceAll('-', '');

      auth
        .checkPhone(data)
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            const payload = resp.data;
            payload.phone = data.phone;
            payload.code = 'code';
            console.log(payload);
            dispatch(setVerify(payload));
            navigate('/verify');
          }
        })
        .catch((err) => {
          console.log(err);
          setIsInvalid(true);
        });
    },
  });
  return (
    <>
      <Helmet>
        <title>{t('reset_password')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 6 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h3" color="text.primary">
                  {t('reset_password')}
                </Typography>
              </div>
            </Box>
            <Grid container>
              {isInvalid && (
                <Box sx={{ mb: 4 }} display="flex" justifyContent="center">
                  <Typography variant="body1" color="error">
                    {t('invalid_phone')}
                  </Typography>
                </Box>
              )}
              <Grid item xs={2} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.black}
                >
                  {t('phone')}
                </Typography>
                <InputMask
                  size="small"
                  onChange={formik.handleChange}
                  name="phone"
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  placeholder="+998 (_ _) _ _ _ - _ _ - _ _"
                  mask="+\9\98 (99) 999-99-99"
                  maskChar="_"
                  style={{
                    border: '1px solid #c4c4c4',
                    outline: '1px solid #F7F8FA',
                    paddingLeft: '14px',
                    height: '55px',
                    width: '100%',
                    fontSize: '16px',
                    borderRadius: 4,
                  }}
                />
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.phone &&
                    formik.touched.phone &&
                    formik.errors.phone}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid display="flex" justifyContent="right">
              <Typography
                component={Link}
                to="/login"
                mt={1}
                sx={{
                  cursor: 'pointer',
                  color: 'rgba(22, 22, 22, 0.6) ',
                  transition: 'all 0.3s ease-in-out',
                  paddingRight: 2,
                  '&:hover': {
                    color: 'rgba(22, 22, 22, 1)',
                  },
                }}
              >
                {t('login')}
              </Typography>
            </Grid>

            <Box sx={{ py: 2 }}>
              <Button
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
              >
                {t('confirm')}
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
