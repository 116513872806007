import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import { setOrderData } from '../../../../redux/modules/orders/actions';

export const toolTips = [
  // {
  //   name: 'edit',
  //   icon: <EditIcon color="action" fontSize="small" />,
  //   onClick: (id, { history }) => {
  //     history('/app/customers/create', { state: id });
  //   },
  // },
  // {
  //   name: 'disable',
  //   icon: <BlockIcon color="action" fontSize="small" />,
  //   onClick: (id, { dispatch, statusModal }) => {
  //     dispatch(setOrderData(id));
  //     statusModal();
  //   },
  // },
];
