import React from 'react';
import { Card, Box, Typography, CardActionArea } from '@mui/material';
import Moment from 'react-moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Logo from '../../../assets/icons/logo.png';

export default function MainCard({ handlerShowBlog, item }) {
  console.log(item);
  return (
    <Card
      variant="outlined"
      style={{ align: 'left', paddingTop: '15px', cursor: 'pointer' }}
    >
      <CardActionArea
        onClick={() => {
          handlerShowBlog(item);
        }}
      >
        {item.photo === null || item.photo === '' ? (
          <img
            style={{ borderRadius: '30%', width: 200, height: 100 }}
            alt="Logo"
            src={Logo}
          />
        ) : (
          <img
            style={{ width: 200, height: 100 }}
            alt={item.photo}
            src={item.photo}
          />
        )}

        <Box sx={{ pr: 2 }}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: item.blog_topic,
            }}
          />
          <VisibilityIcon color="primary" /> {item.views} <br />
          <Typography variant="caption" color="text.secondary">
            <Moment format="YYYY-MM-DD HH:mm">{item.createdAt}</Moment>
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
}
