import { service } from '.';

export default {
  getAll: (query) => service.get(`/booking/?${query}`),
  getOne: (id) => service.get(`/booking/${id.booking_id}/`),
  getByLocationId: (query) => service.get(`/booking/?locations=${query}`),
  create: (data) => service.post('/booking/', data),
  update: (id, data) => service.patch(`/booking/${id.booking_id}/`, data),
  changeStatus: (id, data) => service.patch(`/booking/status/${id}/`, data),
};
