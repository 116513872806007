import { Helmet } from 'react-helmet';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  colors,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  TextField,
  Paper,
  Divider,
  InputLabel,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import lessonApi from '../../../services/lessons';
import classApi from '../../../services/class';
import employees from '../../../services/employees';
import { useStyles } from './style';
import locations from '../../../services/locations';
import scienceApi from '../../../services/science';
import { useHorizontalScroll } from '../../../components/Scroll/useSideScroll';
import { weekDayNames } from '../../../constants';
import ModalWindow from '../../../components/Modals';
import AddLessonModal from './addLesson';
import EditLessonModal from './editLesson';
import ChangeDeleteModal from '../../../components/Modals/ConfirmDelete';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default () => {
  const classes = useStyles();
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const language = useSelector((state) => state.langsReducer.language);
  const [locationList, setLocationList] = useState([]);

  const [classesData, setClassesData] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [scienceList, setScienceList] = useState([]);
  const [classId, setClassId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [day, setDay] = useState('');
  const [teacherId, setTeacherId] = useState('');
  const [scienceId, setScienceId] = useState('');

  const [lessonId, setLessonId] = useState();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { height, width } = useWindowDimensions();
  const [addLessonModal, setAddLessonModal] = useState(false);
  const [editLessonModal, setEditLessonModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const scrollRef = useHorizontalScroll();

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const curr = addHoursToDate(new Date(), 5);
  const first = curr.getDate();
  const last = first - 6;

  useEffect(() => {
    if (decodedToken.role === 'Manager') {
      navigate('/app/employee_title');
    }
    if (decodedToken.role === 'Supervisor') {
      navigate('/app/class');
    }
  }, []);

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: '',
      classes: '',
    },
    validationSchema,
  });

  const getDataLocations = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        if (res.results.length !== 0) {
          setLocationList(res.results);
          formik.setFieldValue('locations', res.results[0].location_id);
          setLocationId(res.results[0].location_id);

          const query = `locations=${res.results[0].location_id}&status=1`;
          classApi
            .getAll(query)
            .then((ress) => {
              setClassesData(ress.results);
              if (ress.results.length !== 0) {
                formik.setFieldValue('classes', ress.results[0].class_id);
                setClassId(ress.results[0].class_id);
                lessonApi
                  .getAll(query + '&classes=' + ress.results[0].class_id)
                  .then((les) => {
                    console.log(les);
                    setLessonData(les.results);
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => console.log(err));

          scienceApi
            .getAll(query)
            .then((resp) => {
              setScienceList(resp.results);
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getDataLocations();
  }, [language]);

  const getData = (location) => {
    const query = `${'locations=' + location}&status=1`;
    setLocationId(location);

    classApi
      .getAll(query)
      .then((ress) => {
        setClassesData(ress.results);
        if (ress.results.length !== 0) {
          formik.setFieldValue('classes', ress.results[0].class_id);
          setClassId(ress.results[0].class_id);
          lessonApi
            .getAll(query + '&classes=' + ress.results[0].class_id)
            .then((les) => {
              console.log(les);
              setLessonData(les.results);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));

    // lessonApi
    //   .getAll(query + '&classes=' + classId)
    //   .then((les) => {
    //     console.log(les);
    //     setLessonData(les.results);
    //   })
    //   .catch((err) => console.log(err));
  };

  const getDataByClass = (classes) => {
    setClassId(classes);
    formik.setFieldValue('classes', classes);
    const query = `${'locations=' + locationId}`;

    lessonApi
      .getAll(query + '&classes=' + classes)
      .then((les) => {
        console.log(les);
        setLessonData(les.results);
      })
      .catch((err) => console.log(err));
  };

  const getDataByLocationId = (id) => {
    setLoading(true);
    formik.setFieldValue('locations', id);
    getData(id);
  };

  const handleAddLesonModal = () => {
    setAddLessonModal(!addLessonModal);
  };

  const handleEditLesonModal = () => {
    setEditLessonModal(!editLessonModal);
  };

  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const loadingLesson = () => {
    // setLoading(true);
    const query = `${'locations=' + locationId}`;

    lessonApi
      .getAll(query + '&classes=' + classId)
      .then((les) => {
        console.log(les);
        setLessonData(les.results);
      })
      .catch((err) => console.log(err));
  };

  const updateLesson = () => {
    if (day === 'monday') {
      lessonData[0].monday.splice(lessonId, 1);
    } else if (day === 'tuesday') {
      lessonData[0].tuesday.splice(lessonId, 1);
    } else if (day === 'wednesday') {
      lessonData[0].wednesday.splice(lessonId, 1);
    } else if (day === 'thursday') {
      lessonData[0].thursday.splice(lessonId, 1);
    } else if (day === 'friday') {
      lessonData[0].friday.splice(lessonId, 1);
    } else if (day === 'saturday') {
      lessonData[0].saturday.splice(lessonId, 1);
    } else if (day === 'sunday') {
      lessonData[0].sunday.splice(lessonId, 1);
    }
    lessonApi
      .update(lessonData[0].lesson_id, lessonData[0])
      .then((resp) => {
        handleDeleteModal();
        loadingLesson();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title> {t('dashboard')}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          backgroundColor: '#E5E8EC',
          p: 1,
        }}
      >
        <Container>
          {/* <Loading loading={loading} /> */}
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <ModalWindow
            title={t('delete')}
            modal={deleteModal}
            handleModal={handleDeleteModal}
            maxWidth="sm"
            body={
              <ChangeDeleteModal
                handleModal={handleDeleteModal}
                getData={updateLesson}
                yes={t('yes')}
                cancel={t('cancel')}
                text={t('is_delete')}
              />
            }
          />
          <ModalWindow
            title={t('add_lesson')}
            modal={addLessonModal}
            handleModal={handleAddLesonModal}
            maxWidth="md"
            body={
              <AddLessonModal
                load={loadingLesson}
                onClose={handleAddLesonModal}
                scienceList={scienceList}
                locationId={locationId}
                classId={classId}
                lessonData={lessonData}
                day={day}
              />
            }
          />
          <ModalWindow
            title={t('edit_lesson')}
            modal={editLessonModal}
            handleModal={handleEditLesonModal}
            maxWidth="md"
            body={
              <EditLessonModal
                load={loadingLesson}
                onClose={handleEditLesonModal}
                scienceList={scienceList}
                locationId={locationId}
                classId={classId}
                lessonData={lessonData}
                day={day}
                teacherId={teacherId}
                scienceId={scienceId}
                lessonId={lessonId}
                startDate={startDate}
                endDate={endDate}
              />
            }
          />
          <Grid item xs={12} md={12} sm={12}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {t('lesson_plan')}
            </Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            // justifyContent="space-between"
            // alignItems="center"
            my={2}
          >
            <Grid item xs={12} md={4} sm={12}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="locations">{t('locations')}</InputLabel>
                <Select
                  fullWidth
                  name="locations"
                  labelId="locations"
                  label={t('locations')}
                  value={formik.values.locations}
                  onBlur={formik.handleBlur}
                  onChange={(e) => getDataByLocationId(e.target.value)}
                >
                  {locationList.map((item, index) => (
                    <MenuItem key={`${index + 1}`} value={item.location_id}>
                      {item.location_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sm={12}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="classes">{t('classes')}</InputLabel>
                <Select
                  labelId="classes"
                  label={t('classes')}
                  fullWidth
                  name="classes"
                  value={formik.values.classes}
                  onBlur={formik.handleBlur}
                  onChange={(e) => getDataByClass(e.target.value)}
                >
                  {classesData.map((item, index) => (
                    <MenuItem key={`${index + 1}`} value={item.class_id}>
                      {item.class_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4} sm={12}>
              <div className={classes.topBtns}>
                <Button
                  sx={{
                    backgroundColor: 'white',
                    color: '#161616',
                    textTransform: 'none',
                    borderColor: '#F3F3F3',
                    border: 1,
                  }}
                  className={classes.search}
                  variant="outlined"
                  startIcon={<FileDownloadIcon />}
                  onClick={() => {
                    if (lessonData.length === 0) {
                      setSnackbarMessage(t('no_data'));
                      setOpen(true);
                    } else {
                      const query = `${'locations=' + locationId}`;

                      lessonApi
                        .getAll(
                          query +
                            '&classes=' +
                            classId +
                            '&download_lesson=ok&language=' +
                            language
                        )
                        .then((les) => {
                          console.log(les);
                          window.open(les.filename);
                        })
                        .catch((err) => console.log(err));
                    }
                  }}
                  // color="info"
                >
                  {t('export')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div
          ref={scrollRef}
          style={{ overflow: 'auto', display: 'flex', flexWrap: 'noWrap' }}
        >
          {weekDayNames.map((x, index) => {
            return (
              <Container key={`${index + 1}`} sx={{ paddingBottom: 2 }}>
                <Card sx={{ minWidth: 300 }}>
                  <CardContent>
                    <Typography
                      // sx={{ fontSize: 14 }}
                      variant="h4"
                      color="textPrimary"
                      gutterBottom
                    >
                      {t(x)}
                    </Typography>
                  </CardContent>

                  {lessonData.map((items, index1) => {
                    return x === '_monday' ? (
                      <div key={`${index1 + 1}`}>
                        {items.monday.map((item, index2) => {
                          return (
                            <div
                              key={`${index2 + 1}`}
                              style={{ paddingLeft: 5, paddingBottom: 5 }}
                            >
                              {index2 === 0 ? (
                                <Typography
                                  // sx={{ fontSize: 14 }}
                                  variant="h5"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {t('count_lesson')}: {items.monday.length}
                                </Typography>
                              ) : null}
                              <Divider />
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                {index2 + 1}. {item.science.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                👤 {item.teacher.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                ⏰
                                {(new Date(item.start).getHours() < 10
                                  ? '0' + new Date(item.start).getHours()
                                  : new Date(item.start).getHours()) +
                                  ':' +
                                  (new Date(item.start).getMinutes() < 10
                                    ? '0' + new Date(item.start).getMinutes()
                                    : new Date(item.start).getMinutes()) +
                                  ' - ' +
                                  (new Date(item.end).getHours() < 10
                                    ? '0' + new Date(item.end).getHours()
                                    : new Date(item.end).getHours()) +
                                  ':' +
                                  (new Date(item.end).getMinutes() < 10
                                    ? '0' + new Date(item.end).getMinutes()
                                    : new Date(item.end).getMinutes())}
                              </Typography>
                              <Grid
                                container
                                // spacing={1}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={8} md={8} sm={8}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'green',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setTeacherId(item.teacher.employee_id);
                                      setScienceId(item.science.science_id);
                                      setStartDate(item.start);
                                      setEndDate(item.end);
                                      setLessonId(index2);
                                      setEditLessonModal(true);
                                    }}
                                  >
                                    {t('edit')}
                                  </Button>
                                </Grid>
                                <Grid item xs={4} md={4} sm={4}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'red',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="delete"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setLessonId(index2);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    {t('delete')}
                                  </Button>
                                </Grid>
                              </Grid>
                              <div style={{ paddingTop: 5 }}>
                                <Divider />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}
                  {lessonData.map((items, index1) => {
                    return x === '_tuesday' ? (
                      <div key={`${index1 + 1}`}>
                        {items.tuesday.map((item, index2) => {
                          return (
                            <div
                              key={`${index2 + 1}`}
                              style={{ paddingLeft: 5 }}
                            >
                              {index2 === 0 ? (
                                <Typography
                                  // sx={{ fontSize: 14 }}
                                  variant="h5"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {t('count_lesson')}: {items.tuesday.length}
                                </Typography>
                              ) : null}
                              <Divider />
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                {index2 + 1}. {item.science.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                👤 {item.teacher.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                ⏰
                                {(new Date(item.start).getHours() < 10
                                  ? '0' + new Date(item.start).getHours()
                                  : new Date(item.start).getHours()) +
                                  ':' +
                                  (new Date(item.start).getMinutes() < 10
                                    ? '0' + new Date(item.start).getMinutes()
                                    : new Date(item.start).getMinutes()) +
                                  ' - ' +
                                  (new Date(item.end).getHours() < 10
                                    ? '0' + new Date(item.end).getHours()
                                    : new Date(item.end).getHours()) +
                                  ':' +
                                  (new Date(item.end).getMinutes() < 10
                                    ? '0' + new Date(item.end).getMinutes()
                                    : new Date(item.end).getMinutes())}
                              </Typography>
                              <Grid
                                container
                                // spacing={1}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={8} md={8} sm={8}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'green',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setTeacherId(item.teacher.employee_id);
                                      setScienceId(item.science.science_id);
                                      setLessonId(index2);
                                      setStartDate(item.start);
                                      setEndDate(item.end);
                                      setEditLessonModal(true);
                                    }}
                                  >
                                    {t('edit')}
                                  </Button>
                                </Grid>
                                <Grid item xs={4} md={4} sm={4}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'red',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setLessonId(index2);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    {t('delete')}
                                  </Button>
                                </Grid>
                              </Grid>
                              <div style={{ paddingTop: 5 }}>
                                <Divider />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}
                  {lessonData.map((items, index1) => {
                    return x === '_wednesday' ? (
                      <div key={`${index1 + 1}`}>
                        {items.wednesday.map((item, index2) => {
                          return (
                            <div
                              key={`${index2 + 1}`}
                              style={{ paddingLeft: 5 }}
                            >
                              {index2 === 0 ? (
                                <Typography
                                  // sx={{ fontSize: 14 }}
                                  variant="h5"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {t('count_lesson')}: {items.wednesday.length}
                                </Typography>
                              ) : null}
                              <Divider />
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                {index2 + 1}. {item.science.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                👤 {item.teacher.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                ⏰
                                {(new Date(item.start).getHours() < 10
                                  ? '0' + new Date(item.start).getHours()
                                  : new Date(item.start).getHours()) +
                                  ':' +
                                  (new Date(item.start).getMinutes() < 10
                                    ? '0' + new Date(item.start).getMinutes()
                                    : new Date(item.start).getMinutes()) +
                                  ' - ' +
                                  (new Date(item.end).getHours() < 10
                                    ? '0' + new Date(item.end).getHours()
                                    : new Date(item.end).getHours()) +
                                  ':' +
                                  (new Date(item.end).getMinutes() < 10
                                    ? '0' + new Date(item.end).getMinutes()
                                    : new Date(item.end).getMinutes())}
                              </Typography>
                              <Grid
                                container
                                // spacing={1}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={8} md={8} sm={8}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'green',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setTeacherId(item.teacher.employee_id);
                                      setScienceId(item.science.science_id);
                                      setLessonId(index2);
                                      setStartDate(item.start);
                                      setEndDate(item.end);
                                      setEditLessonModal(true);
                                    }}
                                  >
                                    {t('edit')}
                                  </Button>
                                </Grid>
                                <Grid item xs={4} md={4} sm={4}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'red',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setLessonId(index2);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    {t('delete')}
                                  </Button>
                                </Grid>
                              </Grid>
                              <div style={{ paddingTop: 5 }}>
                                <Divider />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}
                  {lessonData.map((items, index1) => {
                    return x === '_thursday' ? (
                      <div key={`${index1 + 1}`}>
                        {items.thursday.map((item, index2) => {
                          return (
                            <div
                              key={`${index2 + 1}`}
                              style={{ paddingLeft: 5 }}
                            >
                              {index2 === 0 ? (
                                <Typography
                                  // sx={{ fontSize: 14 }}
                                  variant="h5"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {t('count_lesson')}: {items.thursday.length}
                                </Typography>
                              ) : null}
                              <Divider />
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                {index2 + 1}. {item.science.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                👤 {item.teacher.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                ⏰
                                {(new Date(item.start).getHours() < 10
                                  ? '0' + new Date(item.start).getHours()
                                  : new Date(item.start).getHours()) +
                                  ':' +
                                  (new Date(item.start).getMinutes() < 10
                                    ? '0' + new Date(item.start).getMinutes()
                                    : new Date(item.start).getMinutes()) +
                                  ' - ' +
                                  (new Date(item.end).getHours() < 10
                                    ? '0' + new Date(item.end).getHours()
                                    : new Date(item.end).getHours()) +
                                  ':' +
                                  (new Date(item.end).getMinutes() < 10
                                    ? '0' + new Date(item.end).getMinutes()
                                    : new Date(item.end).getMinutes())}
                              </Typography>
                              <Grid
                                container
                                // spacing={1}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={8} md={8} sm={8}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'green',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setTeacherId(item.teacher.employee_id);
                                      setScienceId(item.science.science_id);
                                      setLessonId(index2);
                                      setStartDate(item.start);
                                      setEndDate(item.end);
                                      setEditLessonModal(true);
                                    }}
                                  >
                                    {t('edit')}
                                  </Button>
                                </Grid>
                                <Grid item xs={4} md={4} sm={4}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'red',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setLessonId(index2);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    {t('delete')}
                                  </Button>
                                </Grid>
                              </Grid>
                              <div style={{ paddingTop: 5 }}>
                                <Divider />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}
                  {lessonData.map((items, index1) => {
                    return x === '_friday' ? (
                      <div key={`${index1 + 1}`}>
                        {items.friday.map((item, index2) => {
                          return (
                            <div
                              key={`${index2 + 1}`}
                              style={{ paddingLeft: 5 }}
                            >
                              {index2 === 0 ? (
                                <Typography
                                  // sx={{ fontSize: 14 }}
                                  variant="h5"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {t('count_lesson')}: {items.friday.length}
                                </Typography>
                              ) : null}
                              <Divider />
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                {index2 + 1}. {item.science.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                👤 {item.teacher.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                ⏰
                                {(new Date(item.start).getHours() < 10
                                  ? '0' + new Date(item.start).getHours()
                                  : new Date(item.start).getHours()) +
                                  ':' +
                                  (new Date(item.start).getMinutes() < 10
                                    ? '0' + new Date(item.start).getMinutes()
                                    : new Date(item.start).getMinutes()) +
                                  ' - ' +
                                  (new Date(item.end).getHours() < 10
                                    ? '0' + new Date(item.end).getHours()
                                    : new Date(item.end).getHours()) +
                                  ':' +
                                  (new Date(item.end).getMinutes() < 10
                                    ? '0' + new Date(item.end).getMinutes()
                                    : new Date(item.end).getMinutes())}
                              </Typography>
                              <Grid
                                container
                                // spacing={1}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={8} md={8} sm={8}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'green',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setTeacherId(item.teacher.employee_id);
                                      setScienceId(item.science.science_id);
                                      setLessonId(index2);
                                      setStartDate(item.start);
                                      setEndDate(item.end);
                                      setEditLessonModal(true);
                                    }}
                                  >
                                    {t('edit')}
                                  </Button>
                                </Grid>
                                <Grid item xs={4} md={4} sm={4}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'red',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setLessonId(index2);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    {t('delete')}
                                  </Button>
                                </Grid>
                              </Grid>
                              <div style={{ paddingTop: 5 }}>
                                <Divider />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}
                  {lessonData.map((items, index1) => {
                    return x === '_saturday' ? (
                      <div key={`${index1 + 1}`}>
                        {items.saturday.map((item, index2) => {
                          return (
                            <div
                              key={`${index2 + 1}`}
                              style={{ paddingLeft: 5 }}
                            >
                              {index2 === 0 ? (
                                <Typography
                                  // sx={{ fontSize: 14 }}
                                  variant="h5"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {t('count_lesson')}: {items.saturday.length}
                                </Typography>
                              ) : null}
                              <Divider />
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                {index2 + 1}. {item.science.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                👤 {item.teacher.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                ⏰
                                {(new Date(item.start).getHours() < 10
                                  ? '0' + new Date(item.start).getHours()
                                  : new Date(item.start).getHours()) +
                                  ':' +
                                  (new Date(item.start).getMinutes() < 10
                                    ? '0' + new Date(item.start).getMinutes()
                                    : new Date(item.start).getMinutes()) +
                                  ' - ' +
                                  (new Date(item.end).getHours() < 10
                                    ? '0' + new Date(item.end).getHours()
                                    : new Date(item.end).getHours()) +
                                  ':' +
                                  (new Date(item.end).getMinutes() < 10
                                    ? '0' + new Date(item.end).getMinutes()
                                    : new Date(item.end).getMinutes())}
                              </Typography>
                              <Grid
                                container
                                // spacing={1}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={8} md={8} sm={8}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'green',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setTeacherId(item.teacher.employee_id);
                                      setScienceId(item.science.science_id);
                                      setLessonId(index2);
                                      setStartDate(item.start);
                                      setEndDate(item.end);
                                      setEditLessonModal(true);
                                    }}
                                  >
                                    {t('edit')}
                                  </Button>
                                </Grid>
                                <Grid item xs={4} md={4} sm={4}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'red',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setLessonId(index2);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    {t('delete')}
                                  </Button>
                                </Grid>
                              </Grid>
                              <div style={{ paddingTop: 5 }}>
                                <Divider />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}
                  {lessonData.map((items, index1) => {
                    return x === '_sunday' ? (
                      <div key={`${index1 + 1}`}>
                        {items.sunday.map((item, index2) => {
                          return (
                            <div
                              key={`${index2 + 1}`}
                              style={{ paddingLeft: 5 }}
                            >
                              {index2 === 0 ? (
                                <Typography
                                  // sx={{ fontSize: 14 }}
                                  variant="h5"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {t('count_lesson')}: {items.sunday.length}
                                </Typography>
                              ) : null}
                              <Divider />
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                {index2 + 1}. {item.science.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                👤 {item.teacher.name}
                              </Typography>
                              <Typography
                                // sx={{ fontSize: 14 }}
                                variant="h5"
                                color="textPrimary"
                                gutterBottom
                              >
                                ⏰
                                {(new Date(item.start).getHours() < 10
                                  ? '0' + new Date(item.start).getHours()
                                  : new Date(item.start).getHours()) +
                                  ':' +
                                  (new Date(item.start).getMinutes() < 10
                                    ? '0' + new Date(item.start).getMinutes()
                                    : new Date(item.start).getMinutes()) +
                                  ' - ' +
                                  (new Date(item.end).getHours() < 10
                                    ? '0' + new Date(item.end).getHours()
                                    : new Date(item.end).getHours()) +
                                  ':' +
                                  (new Date(item.end).getMinutes() < 10
                                    ? '0' + new Date(item.end).getMinutes()
                                    : new Date(item.end).getMinutes())}
                              </Typography>
                              <Grid
                                container
                                // spacing={1}
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={8} md={8} sm={8}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'green',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setTeacherId(item.teacher.employee_id);
                                      setScienceId(item.science.science_id);
                                      setLessonId(index2);
                                      setStartDate(item.start);
                                      setEndDate(item.end);
                                      setEditLessonModal(true);
                                    }}
                                  >
                                    {t('edit')}
                                  </Button>
                                </Grid>
                                <Grid item xs={4} md={4} sm={4}>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: 'red',
                                      color: colors.white,
                                      textTransform: 'none',
                                    }}
                                    name="edit"
                                    variant="contained"
                                    type="submit"
                                    color="secondary"
                                    onClick={() => {
                                      setDay(x.replace('_', ''));
                                      setLessonId(index2);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    {t('delete')}
                                  </Button>
                                </Grid>
                              </Grid>
                              <div style={{ paddingTop: 5 }}>
                                <Divider />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}
                  <CardContent>
                    <Button
                      sx={{
                        backgroundColor: '#2A455F',
                        color: colors.white,
                        textTransform: 'none',
                      }}
                      name="add"
                      variant="contained"
                      type="submit"
                      color="primary"
                      onClick={() => {
                        setDay(x.replace('_', ''));
                        setAddLessonModal(true);
                      }}
                    >
                      + {t('add')}
                    </Button>
                  </CardContent>
                </Card>
              </Container>
            );
          })}
        </div>
      </Box>
    </>
  );
};
