import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';

const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#2A455F' : '#308fe8',
  },
}));

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#72A1EF' : '#308fe8',
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#9CBDF4' : '#308fe8',
  },
}));

const BorderLinearProgress4 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#B8D0F7' : '#308fe8',
  },
}));

export default ({ rest }) => {
  const { t } = useTranslation();

  const count =
    rest.length === 0
      ? 0
      : rest
          .map((value) => value.customers)
          .reduce((acc, amount) => acc + amount);

  const prog = [
    <BorderLinearProgress1
      variant="determinate"
      value={rest[0] ? (rest[0].customers / count) * 100 : 0}
    />,
    <BorderLinearProgress2
      variant="determinate"
      value={rest[1] ? (rest[1].customers / count) * 100 : 0}
    />,
    <BorderLinearProgress3
      variant="determinate"
      value={rest[2] ? (rest[2].customers / count) * 100 : 0}
    />,
    <BorderLinearProgress4
      variant="determinate"
      value={rest[3] ? (rest[3].customers / count) * 100 : 0}
    />,
  ];

  return (
    <Card>
      <CardHeader title={t('classes')} />
      {rest.length !== 0 ? (
        rest.map((value, index) => {
          return (
            <Box
              key={`${index + 1}`}
              sx={{
                paddingLeft: 2,
                paddingBottom: 2,
                // display: 'flex',
              }}
            >
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                justifyContent="space-between"
              >
                <Grid item md={2} xs={12} sm={12}>
                  <Typography variant="h6">{`${value.class_name}`}</Typography>
                </Grid>
                <Grid item md={8} xs={11} sm={11}>
                  {prog[index]}
                </Grid>
                <Grid item md={2} xs={12} sm={12}>
                  <Typography variant="h6">{`${value.customers}`}</Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })
      ) : (
        <CardContent>
          <Typography gutterBottom variant="h6">
            {t('no_data')}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};
