import { service } from '.';

export default {
  getRegisterClub: (query) => service.get(`/register_club/?${query}`),
  updateRegisterClub: (regId, data) => service.patch(`/register_club/${regId}/`, data),
  getTerms: (query) => service.get(`/terms_and_conditions/?${query}`),
  updateTerms: (termId, data) => service.patch(`/terms_and_conditions/${termId}/`, data),
  getSocialNetworks: (query) => service.get(`/social_networks/?${query}`),
  updateSocialNetworks: (socialId, data) => service.patch(`/social_networks/${socialId}/`, data),
};
