import { React, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  SvgIcon,
  TextField,
  InputLabel,
  IconButton,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import jwtDecode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControl from '@mui/material/FormControl';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Filter as FilterIcon, Search as SearchIcon } from 'react-feather';
import { AnimatePresence } from 'framer-motion';
import Loading from '../../../../components/Loading';
import { toolTips } from './helper';
import { useStyles } from './style';
import Table from '../../../../components/Table';
import { staffsHeader } from '../../../../redux/modules/table/common';
import { headerMaker } from '../../../../components/Table/helper';
import { fetchData } from '../../../../redux/modules/employees/actions';
import { setTotals } from '../../../../redux/modules/store/actions';
import FilterTools from '../../../../components/FilteredBody/Employee';
import AnimationDiv from '../../../../components/Animations/Transition';
import { useQuery } from '../../../useQuery';
import locations from '../../../../services/locations';
import ModalWindow from '../../../../components/Modals';
import ChangeStatusModal from '../../../../components/Modals/ConfirmStatus';
import employeeApi from '../../../../services/employees';

export default ({ ...rest }) => {
  const classes = useStyles();
  const {
    query,
    search,
    status,
    setSearch,
    setStatus,
    handleOnTableChange,
    setLocationId,
  } = useQuery({ fetchData });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const [locationList, setLocationList] = useState([]);
  const { data, total } = useSelector((state) => state.staffsReducer);

  localStorage.setItem('total_data', total);
  const language = useSelector((state) => state.langsReducer.language);
  const employeeItem = useSelector((state) => state.ordersReducer.item);
  const headers = useMemo(() => headerMaker(staffsHeader), [staffsHeader]);
  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState(false);

  const addStaff = () => {
    navigate('/app/employees/create');
  };

  const doubleClick = (id) => {
    navigate('/app/employees/create', { state: id });
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: 'all',
    },
    validationSchema,
  });

  const getStaffsByLocationId = (id) => {
    setLoading(true);
    formik.setFieldValue('locations', id);
    if (id === 'all') {
      const locationIds = locationList.map((item) => item.location_id);
      const query = `${locationIds.join('&locations=')}`;
      setLocationId(query);
    } else {
      setLocationId(id);
    }
    setLoading(false);
  };

  const loadingData = () => {
    dispatch(setTotals(total));
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationList(res.results);

        const locationIds = res.results.map((item) => item.location_id);
        const query = `${locationIds.join('&locations=')}`;
        setLocationId(query);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, [language]);

  const changeStatus = () => {
    setLoading(true);
    const data = new FormData();
    data.append('status', employeeItem.status === 1 ? 0 : 1);

    employeeApi
      .changeStatus(employeeItem.employee_id, data)
      .then((resp) => {
        setModal(!modal);
        setLoading(false);
        setSearch(employeeItem.employee_name);
        setSearch('');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response);
      });
  };

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <Helmet>
        <title> {t('employees')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 1,
        }}
      >
        <Container maxWidth={false}>
          <ModalWindow
            title={t('change_status')}
            modal={modal}
            handleModal={handleModal}
            maxWidth="sm"
            body={
              <ChangeStatusModal
                handleModal={handleModal}
                changeStatus={changeStatus}
                yes={t('yes')}
                cancel={t('cancel')}
                text={t('ask_change_status')}
              />
            }
          />
          <Loading loading={loading} />
          <Typography
            variant="h3"
            color="textPrimary"
            gutterBottom
            mb={1}
            mt={2}
          >
            {t('employees')}
          </Typography>
          <Grid container spacing={1} my={1}>
            <Grid item xs={11} md={11} sm={11}>
              <div className={classes.navFilterSection}>
                <TextField
                  className={classes.search}
                  size="small"
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  label={t('search')}
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={addStaff}
              >
                <AddIcon />
              </IconButton>
              {/* <div className={classes.topBtns}> */}
              {/* <Button
                  sx={{
                    backgroundColor: 'white',
                    color: '#161616',
                    textTransform: 'none',
                    borderColor: '#F3F3F3',
                    border: 1,
                  }}
                  variant="contained"
                  startIcon={<OpenInNewIcon />}
                  // onClick={() => setIsShow(!isShow)}
                >
                  {t('export')}
                </Button> */}

              {/* </div> */}
            </Grid>
          </Grid>

          <Grid container spacing={1} my={0.5}>
            <Grid item xs={11} md={11} sm={11}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="locations">{t('location')}</InputLabel>
                <Select
                  labelId="locations"
                  label={t('location')}
                  className={classes.input}
                  fullWidth
                  name="locations"
                  value={formik.values.locations}
                  onBlur={formik.handleBlur}
                  onChange={(e) => getStaffsByLocationId(e.target.value)}
                >
                  <MenuItem key="all" value="all">
                    {t('all')}
                  </MenuItem>
                  {locationList.map((item, index) => (
                    <MenuItem key={`${index + 1}`} value={item.location_id}>
                      {item.location_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                color="primary"
                aria-label="delete"
                size="medium"
                onClick={() => {
                  setIsShow(!isShow);
                }}
              >
                <FilterListIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
          <AnimatePresence className={classes.filters}>
            {isShow ? (
              <AnimationDiv>
                <FilterTools status={status} setStatus={setStatus} />
              </AnimationDiv>
            ) : null}
          </AnimatePresence>
          <Box sx={{ pt: 2 }}>
            <div className={classes.root}>
              <Card {...rest}>
                <Table
                  data={data}
                  headers={headers}
                  toolTips={toolTips}
                  onChange={handleOnTableChange}
                  modal={handleModal}
                  doubleClick={doubleClick}
                />
              </Card>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};
