import axios from 'axios';
import types from '../../../constants/action-types';

export const setData = (payload) => {
  return {
    type: types.SET_DATA,
    payload,
  };
};

export const setTotals = (payload) => {
  return {
    type: types.TABLE_SET_TOTAL,
    payload,
  };
};

export const setProfileImage = (payload) => {
  return {
    type: types.SET_PROFILE_IMAGE,
    payload,
  };
};

export const setSelectTests = (payload) => {
  return {
    type: types.SET_SELECT_TESTS,
    payload,
  };
};

export const setCoin = (payload) => {
  return {
    type: types.SET_COIN,
    payload,
  };
};
