import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  TextField,
  FormHelperText,
  Typography,
  Grid,
  IconButton,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import jwtDecode from 'jwt-decode';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useStyles } from './style';
import auth from '../../../../services/auth';
import { setToken } from '../../../../redux/modules/auth/actions';
import AcademicIcon from '../../../../assets/icons/logo.png';
// import AcademicIcon from '../../../assets/icons/rise.jpg';
import ScrollToTop from '../../../main/ScrollToTop';

const Login = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isInvalid, setIsInvalid] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const schema = Yup.object().shape({
    username: Yup.string().max(255).required(t('required')),
    password: Yup.string().min(4).max(255).required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const data = { username: values.username, password: values.password };

      auth
        .getToken(data)
        .then((resp) => {
          if (resp.status === 200 || resp.status === 201) {
            console.log(resp);
            dispatch(setToken(resp.data));
            navigate('/app/dashboard');
          }
        })
        .catch((err) => {
          console.log(err);
          setIsInvalid(true);
        });
    },
  });

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      {/* <Navbar />
      <ScrollToTop /> */}
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 4 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  style={{ borderRadius: '30%' }}
                  src={AcademicIcon}
                  alt=""
                  width={50}
                />
              </div>
            </Box>
            {isInvalid && (
              <Box sx={{ mb: 4 }} display="flex" justifyContent="center">
                <Typography variant="body1" color="error">
                  {t('invalid_phone_or_password')}
                </Typography>
              </Box>
            )}
            <Grid container my={2}>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  error={Boolean(
                    formik.touched.username && formik.errors.username
                  )}
                  fullWidth
                  helperText={formik.touched.username && formik.errors.username}
                  label={t('username')}
                  name="username"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  variant="outlined"
                  // autoComplete="off"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  error={Boolean(
                    formik.touched.password && formik.errors.password
                  )}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label={t('password')}
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
            </Grid>
            {/* <Grid display="flex" justifyContent="right">
              <Typography
                component={Link}
                to="/resetPassword"
                mt={1}
                sx={{
                  cursor: 'pointer',
                  color: 'rgba(22, 22, 22, 0.6) ',
                  transition: 'all 0.3s ease-in-out',

                  '&:hover': {
                    color: 'rgba(22, 22, 22, 1)',
                  },
                }}
              >
                {t('forgot_password')}
              </Typography>
            </Grid> */}

            <Box sx={{ pt: 5 }}>
              <Button
                // disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
              >
                Login
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
