import { Box, Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import GuidePdf from '../../../assets/guide/file.pdf';

export default () => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = (numPages) => {
    setNumPages(numPages._pdfInfo.numPages);
  };

  useEffect(() => {
    // loadingLocation();
  }, []);

  return (
    <>
      <Helmet>
        <title> {t('guide')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: '#E5E8EC',
          p: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%',
        }}
      >
        <Container>
          <Document file={GuidePdf} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </Container>
      </Box>
    </>
  );
};
