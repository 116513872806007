import { service } from '.';

export default {
  getAll: (query) => service.get(`/science/?${query}`),
  getOne: (id) => service.get(`/science/${id.science_id}/`),
  getByLocationId: (query) => service.get(`/science/?locations=${query}`),
  create: (data) => service.post('/science/', data),
  update: (id, data) => service.patch(`/science/${id.science_id}/`, data),
  changeStatus: (id, data) => service.patch(`/science/status/${id}/`, data),
};
