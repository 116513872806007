/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { IconButton, Badge, CardMedia, Grid, Typography } from '@mui/material';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import jwtDecode from 'jwt-decode';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MessageIcon from '@mui/icons-material/Message';
import BellAnimationDiv from '../Animations/Bell';
import testService from '../../services/tests';
import Loading from '../Loading';
import ModalWindow from '../Modals';
import ModalWindowFullScreen from '../Modals/FullScreen';
import NotificationSound from './notification-sound.mp3';

export default () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
  const [modalGroup, setModalGroup] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [messageItem, setMessageItem] = useState({});
  const Ref = useRef(null);

  // const token = localStorage.getItem('token');
  // const decodedToken = jwtDecode(token);

  const open = Boolean(anchorEl);

  const playAudioNotification = () => {
    const clickSound = new Audio(NotificationSound);
    clickSound.play().catch((error) => {
      console.log('Chrome cannot play sound without user interaction first');
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const countNotifications = () => {
    let arr = [];
    if (localStorage.getItem('notifications') !== null) {
      arr = JSON.parse(localStorage.getItem('notifications'));
    }
    return arr.length;
  };

  const getNotifications = () => {
    testService
      .getNotifications('message_type=notifications&page=1&page_size=1000')
      .then((res) => {
        let arr = [];
        if (localStorage.getItem('notifications') !== null) {
          arr = JSON.parse(localStorage.getItem('notifications'));
        }

        if (res.length > arr.length) {
          playAudioNotification();
        }
        localStorage.setItem('notifications', JSON.stringify(res));
        setNotifications(res);
      })
      .catch((err) => {});
  };

  // const updateNotifications = (id) => {
  //   settingService
  //     .updateNotification(id)
  //     .then((res) => {
  //       getNotifications();
  //     })
  //     .catch((err) => {});
  // };

  const loadingNotifications = () => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      if (localStorage.hasOwnProperty('token')) {
        getNotifications();
      }
    }, 120000);
    Ref.current = id;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    localStorage.removeItem('notifications');
    if (localStorage.hasOwnProperty('token')) {
      getNotifications();
    }
    loadingNotifications();
  }, []);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    color: 'blue',
    '& .MuiBadge-badge': {
      right: -5,
      top: 5,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const getText = (item) => {
    if (item.message_type === 'group_join') {
      return 'Gruppa';
    }
    return 'Xabar';
  };
  const handleModalGroup = () => {
    setModalGroup(!modalGroup);
  };

  return (
    <div>
      <ModalWindow
        title={t('Gruppa')}
        modal={modalGroup}
        handleModal={handleModalGroup}
        maxWidth="md"
        body={
          <>
            <Grid container spacing={1} my={1}>
              <Grid item xs={4} md={4} sm={4}>
                {messageItem.sender && messageItem.sender.photo ? (
                  <CardMedia
                    component="img"
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: 5,
                      objectFit: 'fill',
                    }}
                    image={messageItem.sender.photo}
                    alt=""
                  />
                ) : (
                  <Avatar sx={{ borderRadius: 5, bgcolor: '#E5E8EC' }}>
                    <AccountCircleIcon
                      sx={{ borderRadius: 5, bgcolor: '#000' }}
                    />
                  </Avatar>
                )}
              </Grid>
              <Grid item xs={8} md={8} sm={8}>
                <Typography variant="h4" color="textPrimary" gutterBottom>
                  A`zo: {messageItem.sender ? messageItem.sender.name : ''}
                </Typography>
                <Typography variant="h4" color="textPrimary" gutterBottom>
                  Gruppa: {messageItem.groupId ? messageItem.groupId.name : ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} my={1}>
              <Grid item xs={4} md={4} sm={4}>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: '#FFFFFF',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setLoading(true);
                    const data = new FormData();
                    data.append('group', messageItem.groupId.test_group_id);
                    data.append('sender', messageItem.sender.user_id);
                    data.append('message_id', messageItem.test_message_id);
                    data.append('request_type', 'addGroup');

                    testService
                      .createNotification(data)
                      .then((res) => {
                        getNotifications();
                        handleModalGroup();
                        setLoading(false);
                      })
                      .catch((err) => {
                        setLoading(false);
                        console.log(err);
                      });
                  }}
                  // color="info"
                >
                  {t("Qo'shish")}
                </Button>
              </Grid>
              <Grid item xs={4} md={4} sm={4}>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: '#FFFFFF',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  startIcon={<DeleteOutlineIcon />}
                  onClick={() => {
                    testService
                      .deleteNotification(messageItem.test_message_id)
                      .then((res) => {
                        getNotifications();
                        handleModalGroup();
                      })
                      .catch((err) => {});
                  }}
                  // color="info"
                >
                  {t("Xabarni o'chirish")}
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      <ModalWindow
        title={t('Xabar')}
        modal={modalMessage}
        handleModal={() => {
          setModalMessage(false);
        }}
        maxWidth="md"
        body={
          <>
            <Grid container spacing={1} my={1}>
              <Grid item xs={8} md={8} sm={8}>
                <Typography variant="h4" color="textPrimary" gutterBottom>
                  Gruppaga qo`shilish tasdiqlandi
                </Typography>
                <Typography variant="h4" color="textPrimary" gutterBottom>
                  Gruppa: {messageItem.groupId ? messageItem.groupId.name : ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1} my={1}>
              <Grid item xs={4} md={4} sm={4}>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: '#FFFFFF',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  startIcon={<DeleteOutlineIcon />}
                  onClick={() => {
                    setModalMessage(false);
                  }}
                  // color="info"
                >
                  {t('Yopish')}
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
      {/* <ModalWindowFullScreen
        title={t('Natijalar')}
        modal={modalResults}
        handleModal={handleModalResults}
        maxWidth="lg"
        body={<Results id={examId} />}
      /> */}
      {countNotifications() > 0 ? (
        <AnimatePresence>
          <BellAnimationDiv>
            <IconButton size="medium" onClick={handleClick}>
              <StyledBadge
                badgeContent={countNotifications()}
                color="secondary"
              >
                <NotificationsIcon />
              </StyledBadge>
            </IconButton>
          </BellAnimationDiv>
        </AnimatePresence>
      ) : (
        <IconButton size="medium" onClick={handleClick}>
          <StyledBadge badgeContent={countNotifications()} color="secondary">
            <NotificationsIcon />
          </StyledBadge>
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        id="long-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {countNotifications() === 0 ? (
          <MenuItem
            key="empty"
            onClick={() => {
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteOutlineIcon />
            </ListItemIcon>
            {t("Xabarnomalar yo'q")}
          </MenuItem>
        ) : (
          notifications.map((item, i) => {
            return (
              <MenuItem
                key={`${i + 1}`}
                name={item.test_message_id}
                value={item.test_message_id}
                onClick={() => {
                  if (item.message_type === 'group_join') {
                    setMessageItem(item);
                    setModalGroup(true);
                  } else {
                    setModalMessage(true);
                  }
                }}
              >
                <ListItemIcon>
                  <MessageIcon />
                </ListItemIcon>
                {getText(item)}
              </MenuItem>
            );
          })
        )}
      </Menu>
    </div>
  );
};
