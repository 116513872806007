export default {
  TABLE_CLASS_FETCH_STATUSES: 'TABLE_CLASS_FETCH_STATUSES',
  TABLE_CLASS_SET_DATA: 'TABLE_CLASS_SET_DATA',
  TABLE_CLASS_SET_TOTAL: 'TABLE_CLASS_SET_TOTAL',
  TABLE_CLASS_SET_LOADING: 'TABLE_CLASS_SET_LOADING',
  TABLE_CLASS_ERROR: 'TABLE_CLASS_ERROR',
  TABLE_CLASS_FETCH_DATA: 'TABLE_CLASS_FETCH_DATA',
  TABLE_CLASS_SAGA_ONSEARCH_ACTION:
    'TABLE_CLASS_SAGA_ONSEARCH_ACTION',
};
