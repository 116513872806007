import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Typography, CardContent, Grid } from '@mui/material';
import { Container } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import Moment from 'react-moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import articleApi from '../../../../services/articles';
import Loading from '../../../../components/Loading';

export default function MainCard() {
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  const getOne = () => {
    setLoading(true);
    articleApi
      .getOne(id)
      .then((res) => {
        setLoading(false);
        setContent(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getOne();
  }, []);

  return (
    <>
      <Container fluid className="about-section">
        <>
          <Container>
            <div
              style={{
                padding: 10,
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <Button
                variant="text"
                onClick={() => {
                  navigate('/articles');
                }}
              >
                Boshqa yangiliklar
              </Button>
            </div>
            {content.length === 0 ? (
              <Loading loading={loading} />
            ) : (
              <Grid key="news" variant="outlined" item xs={12} md={12} sm={12}>
                <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                  <div
                    style={{
                      padding: 10,
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <VisibilityIcon color="primary" /> &nbsp;{' '}
                    <h5>{content[0].fields.views}</h5>
                    &nbsp; &nbsp;
                    <Typography>
                      <Moment format="YYYY-MM-DD HH:mm">
                        {content[0].fields.createdAt}
                      </Moment>
                    </Typography>
                  </div>
                  <div
                    style={{
                      paddingLeft: 10,
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: content[0].fields.blog_topic,
                      }}
                    />
                  </div>
                  <CardContent>
                    <ReactQuill
                      value={content[0].fields.content[0].item}
                      readOnly={true}
                      // className={classes.search}
                      modules={{ toolbar: false }}
                      // modules={moduless1}
                      // formats={formats}
                      theme="snow"
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Container>
        </>
      </Container>
    </>
  );
}
