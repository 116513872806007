import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { Box, colors, CircularProgress } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import jwtDecode from 'jwt-decode';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import location from '../../../services/locations';
import { useStyles } from './style';

export default ({ load, onClose, countryList }) => {
  const classes = useStyles();
  const theme = useTheme();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const [countryIndex, setCountryIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    location_name: Yup.string().required(t('required')),
    region: Yup.number().required(t('required')),
    district: Yup.number().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      location_name: '',
      country: 'Uzbekistan',
      region: 0,
      district: 0,
      address: '',
      latitude: '',
      longitude: '',
      zipcode: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append('user_id', decodedToken.user_id);
      data.append('location_name', values.location_name);
      data.append('region', values.region);
      data.append('district', values.district);
      data.append('country', 'Uzbekistan');
      data.append('zipcode', '');
      data.append('address', values.address);
      data.append('latitude', values.latitude);
      data.append('longitude', values.longitude);
      data.append('status', 1);

      location
        .create(data)
        .then((resp) => {
          load();
          onClose();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  useEffect(() => {}, []);

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        // minHeight: '100vh',
        pt: 2,
      }}
    >
      <Container maxWidth={false}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    size="small"
                    name="location_name"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    label={t('location_name')}
                    value={formik.values.location_name}
                    error={
                      formik.touched.location_name &&
                      Boolean(formik.errors.location_name)
                    }
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    helperText={
                      formik.touched.location_name &&
                      formik.errors.location_name
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} my={1}>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl
                    size="small"
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <InputLabel id="country">{t('country')}</InputLabel>
                    <Select
                      labelId="country"
                      label={t('country')}
                      disabled
                      className={classes.input}
                      fullWidth
                      value="uzbekistan"
                      name="country"
                    >
                      {countryList.map((item, i) => (
                        <MenuItem key={`${i + 1}`} value={item.country_name}>
                          {t(`places.${item.country_name}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl
                    size="small"
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <InputLabel id="region">{t('region')}</InputLabel>
                    <Select
                      labelId="region"
                      label={t('region')}
                      className={classes.input}
                      fullWidth
                      onChange={(event) => {
                        formik.setFieldValue('region', event.target.value);
                      }}
                      value={formik.values.region}
                      name="region"
                    >
                      {countryList[countryIndex].regions.map((items, i) => (
                        <MenuItem key={`${i + 1}`} value={i}>
                          {t(`places.uzbekistan_regions.${items.region_name}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl
                    size="small"
                    sx={{
                      width: '100%',
                      backgroundColor: 'white',
                    }}
                  >
                    <InputLabel id="district">{t('district')}</InputLabel>
                    <Select
                      labelId="district"
                      label={t('district')}
                      className={classes.input}
                      fullWidth
                      onChange={(event) => {
                        formik.setFieldValue('district', event.target.value);
                      }}
                      value={formik.values.district}
                      name="district"
                    >
                      {countryList[countryIndex].regions[
                        formik.values.region
                      ].districts.map((items, i) => (
                        <MenuItem key={`${i + 1}`} value={i}>
                          {t(
                            `places.uzbekistan_regions.${
                              countryList[countryIndex].regions[
                                formik.values.region
                              ].region_name
                            }_districts.${items}`
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    label={t('Latitude')}
                    size="small"
                    name="latitude"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('0.000')}
                    value={formik.values.latitude}
                    error={
                      formik.touched.latitude && Boolean(formik.errors.latitude)
                    }
                    type="number"
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    helperText={
                      formik.touched.latitude && formik.errors.latitude
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    label={t('Longitude')}
                    size="small"
                    name="longitude"
                    fullWidth
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('0.000')}
                    value={formik.values.longitude}
                    error={
                      formik.touched.longitude &&
                      Boolean(formik.errors.longitude)
                    }
                    type="number"
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    helperText={
                      formik.touched.longitude && formik.errors.longitude
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    label={t('address')}
                    name="address"
                    fullWidth
                    multiline
                    rows={4}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_address')}
                    value={formik.values.address}
                    // error={formik.touched.notes && Boolean(formik.errors.notes)}
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    // helperText={formik.touched.notes && formik.errors.notes}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => setIsSubmitting(true)}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(state, isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
