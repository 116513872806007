import React from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  colors,
  Box,
  InputAdornment,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TablePagination from '@mui/material/TablePagination';
import {
  Container,
  PageButtons,
  ChangeButtons,
  Button,
  ChangeButton,
} from './style';

import { useButtons } from './useButtons';

// const optionData = [
//   { value: 10, label: '10 item view' },
//   { value: 25, label: '25 item view' },
//   { value: 50, label: '50 item view' },
//   { value: 100, label: '100 item view' },
// ];

const Pagination = ({
  canPreviousPage,
  canNextPage,
  total,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  pageSize,
  setPageSize,
  pageIndex,
  hidePagination,
}) => {
  const buttons = useButtons({ pageCount, pageIndex });
  const { t } = useTranslation();

  const handlePageChange = (title) => {
    if (title !== '...') gotoPage(Number(title));
  };

  return (
    <Container>
      {hidePagination !== 1 ? (
        <IconButton
          size="small"
          sx={{ padding: 0 }}
          disabled={!canPreviousPage}
          onClick={previousPage}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
      ) : null}
      {hidePagination !== 1 ? (
        <Typography
          sx={{ fontSize: 14 }}
          my={1}
          align="left"
          color={colors.grey[700]}
        >
          {t('previous')}
        </Typography>
      ) : null}
      <Typography
        sx={{ fontSize: 14 }}
        my={1}
        align="center"
        color={colors.grey[700]}
      >
        ({pageIndex * pageSize + 1}
        {canNextPage || pageIndex * (pageSize + 1) <= total ? ' - ' : ''}
        {!canNextPage && pageIndex * (pageSize + 1) <= total ? total : ''}
        {!canNextPage ? ' ' : pageSize * (pageIndex + 1) + ' '}) (#{total})
      </Typography>
      {hidePagination !== 1 ? (
        <Typography
          sx={{ fontSize: 14 }}
          my={1}
          align="right"
          color={colors.grey[700]}
        >
          {t('next')}
        </Typography>
      ) : null}
      {hidePagination !== 1 ? (
        <IconButton size="small" disabled={!canNextPage} onClick={nextPage}>
          <ArrowForwardIosIcon />
        </IconButton>
      ) : null}
    </Container>
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number,
};
Pagination.defaultProps = {
  pageCount: 40,
};

export default Pagination;
