import React, { useState, useEffect } from 'react';
import Preloader from '../Pre';
import Navbar from '../Navbar';
import Services from './Services';
import ScrollToTop from '../ScrollToTop';
import '../style.css';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader load={load} />
      <div className="App" id={load ? 'no-scroll' : 'scroll'}>
        <Navbar />
        <ScrollToTop />
        <Services />
      </div>
    </>
  );
}

export default App;
