export function dataSelector(dataResponse) {
  const filtered = dataResponse.results.map((item) => ({
    id: item,
    locations: item.locations,
    service_name: item.service_name,
    service_duration: item.service_duration,
    hours:
      (new Date(item.start).getHours() < 10
        ? '0' + new Date(item.start).getHours()
        : new Date(item.start).getHours()) +
      ':' +
      (new Date(item.start).getMinutes() < 10
        ? '0' + new Date(item.start).getMinutes()
        : new Date(item.start).getMinutes()) +
      ' - ' +
      (new Date(item.end).getHours() < 10
        ? '0' + new Date(item.end).getHours()
        : new Date(item.end).getHours()) +
      ':' +
      (new Date(item.end).getMinutes() < 10
        ? '0' + new Date(item.end).getMinutes()
        : new Date(item.end).getMinutes()),
    price: item.price,
    repeat: item.repeat,
    notes: item.notes,
    begin_date:
      (new Date(item.begin_date).getDate() < 10
        ? '0' + new Date(item.begin_date).getDate()
        : new Date(item.begin_date).getDate()) +
      '/' +
      (new Date(item.begin_date).getMonth() < 10
        ? '0' + (new Date(item.begin_date).getMonth() + 1)
        : new Date(item.begin_date).getMonth() + 1) +
      '/' +
      new Date(item.begin_date).getFullYear(),
    personnel: item.personnel,
    customers: item.customers,
    status: item.status,
    created_time:
      (new Date(item.createdAt).getDate() < 10
        ? '0' + new Date(item.createdAt).getDate()
        : new Date(item.createdAt).getDate()) +
      '/' +
      (new Date(item.createdAt).getMonth() < 10
        ? '0' + (new Date(item.createdAt).getMonth() + 1)
        : new Date(item.createdAt).getMonth() + 1) +
      '/' +
      new Date(item.createdAt).getFullYear(),
  }));
  return { total: dataResponse.count, data: filtered };
}
