/* eslint-disable no-prototype-builtins */
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  colors,
  Grid,
  Pagination,
  Stack,
  Container,
  Typography,
  CardMedia,
  Avatar,
  Autocomplete,
  CircularProgress,
  TextField,
  Divider,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  Button,
  Badge,
  Snackbar,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import SnackbarContent from '@mui/material/SnackbarContent';
import jwtDecode from 'jwt-decode';
import Moment from 'react-moment';
import { useTheme } from '@mui/material/styles';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ForumIcon from '@mui/icons-material/Forum';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  setSelectTests,
  setCoin,
} from '../../../../redux/modules/store/actions';
// import InputIcon from '@mui/icons-material/Input';

// import ProfileBtn from '../../Profile2';
import ModalWindow from '../../../Modals';
import ChangeStatusModal from '../../../Modals/ConfirmStatus';
import SignUpModal from '../../../Modals/SignUpModal';
import Loading from '../../../Loading';
import { clearToken, setToken } from '../../../../redux/modules/auth/actions';
import auth from '../../../../services/auth';
import testsApi from '../../../../services/tests';
import { useStyles } from './style';

export default function SelectList({ handleModalScore }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalTelegram, setModalTelegram] = useState(false);
  const [testDelete, setTestDelete] = useState(false);

  const [countPages, setCountPages] = useState(0);

  const selectTests = useSelector((state) => state.storeReducer.select_tests);

  const [page, setPage] = useState(1);
  const [testSelectList, setTestSelectList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [selectTestList, setSelectTestList] = useState([]);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [selectId, setSelectId] = useState('');
  const [selectName, setSelectName] = useState('');

  const [telegramAuth, setTelegramAuth] = useState(
    localStorage.getItem('token')
  );

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleTelegramResponse = (response) => {
    auth
      .authTelegram(`id=${response.id}&name=${response.first_name}`)
      .then((res) => {
        setTelegramAuth('auth');
        setCoin(res.data.coin);
        setModalTelegram(false);
        dispatch(setToken(res.data));
      });
  };

  const loadingData = (selectTestLists) => {
    console.log(selectTestLists);
    if (selectTestLists.length > 0) {
      setLoading(true);
      const queryTest = `${selectTestLists.join('&testIds=')}`;
      const query = 'testIds=' + queryTest;
      testsApi
        .getShow(query)
        .then((res) => {
          console.log(res);
          setTestList(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    // loadingData(value, scienceId);
  };

  useEffect(() => {
    setLoading(true);
    testsApi
      .getTestSelectUnit('page_size=1000')
      .then((res) => {
        setTestSelectList(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const selectTest = (item, status) => {
    console.log(item, status);
    console.log(selectTestList, selectId);
    if (selectId) {
      const data = new FormData();
      const arr = selectTestList;
      if (status === 1) {
        arr.push(item.pk);
        data.append('tests', JSON.stringify(arr));
      } else {
        data.append(
          'tests',
          JSON.stringify(arr.filter((value) => value !== item.pk))
        );
      }
      console.log(arr);

      // data.append('name', values.name);

      data.append('status', 1);
      testsApi
        .updateTestSelectUnit(selectId, data)
        .then((res) => {
          dispatch(setSelectTests(uuidv4()));
          setSelectTestList(res.tests);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (selectId) {
    //   const data = new FormData();
    //   // data.append('name', values.name);
    //   data.append('tests', JSON.stringify(item));
    //   data.append('status', 1);
    //   testsApi
    //     .updateTestSelectUnit(selectId, data)
    //     .then((res) => {})
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else {
    //   console.log('Xatolik');
    // }
    // if (localStorage.getItem('selectTests')) {
    //   const arr = JSON.parse(localStorage.getItem('selectTests'));

    //   if (status === 1) {
    //     arr.push(item.pk);
    //     localStorage.setItem('selectTests', JSON.stringify(arr));
    //   } else {
    //     localStorage.setItem(
    //       'selectTests',
    //       JSON.stringify(arr.filter((value) => value !== item.pk))
    //     );
    //   }
    // } else {
    //   localStorage.setItem('selectTests', JSON.stringify([item.pk]));
    // }
    // // dispatch(setSelectTests(uuidv4()));
    // setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
  };

  const downloadTests = (shuffle) => {
    if (selectTestList.length > 30) {
      setSnackbarMessage(t('Tanlash limit 30 ta. Tanlov limitdan oshib ketdi'));
      setOpenSnackbar(true);
    } else if (selectTestList.length > 0 && selectTestList.length <= 30) {
      setLoading(true);

      if (shuffle === 'yes') {
        const queryTest = `${selectTestList.join('&testIds=')}`;
        const query = 'testIds=' + queryTest + '&shuffle=yes';
        testsApi
          .getAllDownload(query)
          .then((res) => {
            if (res.status) {
              window.open(res.filename);
            } else {
              setSnackbarMessage(
                `Sizda mablag' yetarli emas. Balans: ${res.coin} Ma'lumot uchun: +998 90 309 39 78`
              );
              setOpenSnackbar(true);
            }

            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        const queryTest = `${selectTestList.join('&testIds=')}`;
        const query = 'testIds=' + queryTest;
        testsApi
          .getAllDownload(query)
          .then((res) => {
            if (res.status) {
              window.open(res.filename);
            } else {
              setSnackbarMessage(
                `Sizda mablag' yetarli emas. Balans: ${res.coin} Ma'lumot uchun: +998 90 309 39 78`
              );
              setOpenSnackbar(true);
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    } else if (selectTestList.length === 0) {
      setSnackbarMessage(t('no_select'));
      setOpenSnackbar(true);
    }
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handleModalTelegram = () => {
    setModalTelegram(!modalTelegram);
  };

  const changeStatus = () => {
    if (testDelete) {
      setTestDelete(false);
      setModal(!modal);
    } else {
      if (selectId) {
        const data = new FormData();
        data.append('tests', JSON.stringify([]));

        data.append('status', 1);
        testsApi
          .updateTestSelectUnit(selectId, data)
          .then((res) => {
            setSelectTestList(res.tests);
            setModal(!modal);
            setSnackbarMessage(t('cleared'));
            setOpenSnackbar(true);
            dispatch(setSelectTests('clear'));
          })
          .catch((err) => {
            console.log(err);
          });
      }
      console.log();
      // localStorage.setItem('selectTests', JSON.stringify([]));
      // setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
    }
  };

  return (
    <Container maxWidth={false}>
      <Loading loading={loading} />
      <ModalWindow
        title={t('Eslatma')}
        modal={modalTelegram}
        handleModal={handleModalTelegram}
        maxWidth="sm"
        body={<SignUpModal handleTelegramResponse={handleTelegramResponse} />}
      />
      <ModalWindow
        title={t('clear')}
        modal={modal}
        handleModal={handleModal}
        maxWidth="sm"
        body={
          <ChangeStatusModal
            handleModal={handleModal}
            changeStatus={changeStatus}
            yes={t('yes')}
            cancel={t('cancel')}
            text={t('ask_clear')}
          />
        }
      />

      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="warning"
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Stack>
      <Grid container my={1}>
        <Grid item xs={12} md={12} sm={12}>
          <Autocomplete
            fullWidth
            size="small"
            id="combo-box1"
            className={classes.search}
            name="selectId"
            value={selectName}
            options={testSelectList}
            onChange={(event, newValue) => {
              if (newValue) {
                setSelectId(newValue.select_saved_id);
                setSelectName(newValue.name);
                setSelectTestList(newValue.tests);
                loadingData(newValue.tests);
              } else {
                setSelectId();
                setSelectName();
                setTestList([]);
                setSelectTestList([]);
              }
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }

              // Regular option
              return option.name;
            }}
            // isOptionEqualToValue={(option, newValue) =>
            //   option.select_saved_id === newValue.select_saved_id
            // }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('name')}
                // name="selectId"
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.name, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.name, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={`${index + 1}`}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />
        </Grid>
        <Typography variant="h2" color="textPrimary" gutterBottom mb={3} mt={2}>
          {t('Tanlangan testlar')}
        </Typography>
        {testList.length > 0 ? (
          <Grid container spacing={1}>
            <Grid item xs={4} md={4} sm={4}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<FileDownloadIcon />}
                onClick={() => {
                  if (telegramAuth !== null) {
                    downloadTests('no');
                  } else {
                    handleModalTelegram();
                  }
                }}
                // color="info"
              >
                {t('download')}
              </Button>
            </Grid>
            <Grid item xs={4} md={4} sm={4}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<AutorenewIcon />}
                onClick={() => {
                  if (telegramAuth !== null) {
                    downloadTests('yes');
                  } else {
                    handleModalTelegram();
                  }
                }}
                // color="info"
              >
                {t('download_shuffle')}
              </Button>
            </Grid>
            <Grid item xs={4} md={4} sm={4}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  handleModal();
                }}
                // color="info"
              >
                {t('clear')}
              </Button>
            </Grid>
          </Grid>
        ) : null}

        <Grid container my={1}>
          {testList.length === 0 ? (
            <Typography
              my={1}
              variant="h4"
              align="left"
              color={colors.grey[700]}
            >
              {t('no_test_data')}
            </Typography>
          ) : null}
          {testList.map((item, i) => (
            <Grid key={item.tests[0].pk} item xs={12} md={6} sm={12}>
              <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                <Tooltip
                  title={
                    selectTestList.indexOf(item.tests[0].pk) < 0
                      ? t('selection')
                      : t('selected')
                  }
                  placement="top"
                  arrow
                >
                  <IconButton
                    variant="filled"
                    onClick={() => {
                      selectTest(
                        item.tests[0],
                        selectTestList.indexOf(item.tests[0].pk) < 0 ? 1 : 0
                      );
                    }}
                  >
                    {selectTestList.indexOf(item.tests[0].pk) < 0 ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <HighlightOffIcon color="error" />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Muhokama" placement="top" arrow>
                  <IconButton
                    variant="filled"
                    onClick={() => {
                      navigate('/app/tests/forum/' + item.tests[0].pk);
                    }}
                  >
                    <ForumIcon color="primary" />
                  </IconButton>
                </Tooltip>

                <Typography my={1} variant="h5" color={colors.grey[700]}>
                  &nbsp; &nbsp; {t('Fan') + ': ' + item.science.uz}
                </Typography>
                <Typography variant="h5" color={colors.grey[700]}>
                  &nbsp; &nbsp; {t('Mavzu') + ': ' + item.topic.uz}
                </Typography>
                <CardContent>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.tests[0].fields.question.replace(
                        'numberNN',
                        i + 1 + '. '
                      ),
                    }}
                  />
                  <Divider />
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.tests[0].fields.option_a.replace(
                        'optionA',
                        'A). '
                      ),
                    }}
                  />
                  <Divider />
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.tests[0].fields.option_b.replace(
                        'optionB',
                        'B). '
                      ),
                    }}
                  />
                  <Divider />
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.tests[0].fields.option_c.replace(
                        'optionC',
                        'C). '
                      ),
                    }}
                  />
                  <Divider />
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.tests[0].fields.option_d.replace(
                        'optionD',
                        'D). '
                      ),
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <br />
      </Grid>

      {/* <Stack spacing={2}>
        <Pagination
          ullwidth="true"
          count={countPages}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </Stack> */}
    </Container>
  );
}
