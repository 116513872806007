import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';

export const localeMap = {
  en: enLocale,
  uz: uzLocale,
  ru: ruLocale,
};

export const weekDayNames = [
  '_monday',
  '_tuesday',
  '_wednesday',
  '_thursday',
  '_friday',
  '_saturday',
  '_sunday',
];

export const weekDays = [
  {
    id: 1,
    name: 'monday',
    hours: [0, 0],
    active: false,
  },
  {
    id: 2,
    name: 'tuesday',
    hours: [0, 0],
    active: false,
  },
  {
    id: 3,
    name: 'wednesday',
    hours: [0, 0],
    active: false,
  },
  {
    id: 4,
    name: 'thursday',
    hours: [0, 0],
    active: false,
  },
  {
    id: 5,
    name: 'friday',
    hours: [0, 0],
    active: false,
  },
  {
    id: 6,
    name: 'saturday',
    hours: [0, 0],
    active: false,
  },
  {
    id: 7,
    name: 'sunday',
    hours: [0, 0],
    active: false,
  },
];

export const permissions = [
  {
    id: 1,
    name: 'Admin',
  },
  {
    id: 2,
    name: 'Manager',
  },
  {
    id: 3,
    name: 'Supervisor',
  },
  {
    id: 4,
    name: 'Staff',
  },
];

export const marks = [
  {
    value: 0,
    label: '00',
  },
  {
    value: 1,
    label: '01',
  },
  {
    value: 2,
    label: '02',
  },
  {
    value: 3,
    label: '03',
  },
  {
    value: 4,
    label: '04',
  },
  {
    value: 5,
    label: '05',
  },
  {
    value: 6,
    label: '06',
  },
  {
    value: 7,
    label: '07',
  },
  {
    value: 8,
    label: '08',
  },
  {
    value: 9,
    label: '09',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 11,
    label: '11',
  },
  {
    value: 12,
    label: '12',
  },
  {
    value: 13,
    label: '13',
  },
  {
    value: 14,
    label: '14',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 16,
    label: '16',
  },
  {
    value: 17,
    label: '17',
  },
  {
    value: 18,
    label: '18',
  },
  {
    value: 19,
    label: '19',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 21,
    label: '21',
  },
  {
    value: 22,
    label: '22',
  },
  {
    value: 23,
    label: '23',
  },
];

export const omrType = ['30-1', '30-3', '90', '105'];

export const scienceList = ['chemistry', 'math'];
