import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton } from '@mui/material';
import { Container, Circle, CircleContent, Content } from './style';
import controls from '../../../services/controls';

export default ({ value, handler }) => {
  return (
    <>
      {value.absent === 1 ? (
        <Container>
          <IconButton
            onClick={(e) => {
              const data = new FormData();
              data.append('locations', value.location_data);
              data.append('class_id', value.class_data);
              data.append('customer_id', value.customer_id);
              data.append('attendance', 0);
              data.append('date_time', localStorage.getItem('dateTime'));
              data.append('lesson', localStorage.getItem('lesson'));

              controls
                .create(data)
                .then((res) => {
                  handler();
                })
                .catch((err) => {
                  console.log(err.response);
                });
            }}
            size="small"
          >
            <CheckCircleIcon color="success" />
          </IconButton>
        </Container>
      ) : (
        <Container>
          <IconButton
            onClick={(e) => {
              const data = new FormData();
              data.append('locations', value.location_data);
              data.append('class_id', value.class_data);
              data.append('customer_id', value.customer_id);
              data.append('attendance', 1);
              data.append('date_time', localStorage.getItem('dateTime'));
              data.append('lesson', localStorage.getItem('lesson'));

              controls
                .create(data)
                .then((res) => {
                  handler();
                })
                .catch((err) => {
                  console.log(err.response);
                });
            }}
            size="small"
          >
            <CheckCircleIcon color="error" />
          </IconButton>
        </Container>
      )}
    </>
  );
};
