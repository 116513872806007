import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  navFilterSection: {
    width: '100%',
  },
  input: {
    backgroundColor: theme.palette.background.paper,
  },
  topBtns: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'flex-end',
  },
}));
