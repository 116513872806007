import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import { Box, Button, Container, Typography, Grid } from '@mui/material';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import auth from '../../../../services/auth';
import { setVerify } from '../../../../redux/modules/auth/actions';

const StyledReactInputVerificationCode = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  --ReactInputVerificationCode-itemWidth: 46px;
  --ReactInputVerificationCode-itemHeight: 46px;
  --ReactInputVerificationCode-itemSpacing: 24px;

  .ReactInputVerificationCode__item {
    font-size: 16px;
    font-weight: 500;
    color: #000;

    background: rgba(229, 238, 247, 0.4);
    border: 1px solid
      ${({ isInvalid }) => (isInvalid ? '#EF6C65' : 'rgba(42, 69, 95, 0.45);')};
    border-radius: 4px;
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #36c6d9;
  }
`;

const Verification = () => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [now, setNow] = useState(Date.now());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const phone = useSelector((state) => state.authReducer.phone);
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {minutes < 10 ? '0' + minutes : minutes}:
        {seconds < 10 ? '0' + seconds : seconds}
      </span>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {};
    data.code = code;
    data.phone = phone;
    auth
      .checkConfirmationCode(data, accessToken)
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {
          const payload = resp.data;
          payload.phone = data.phone;
          payload.code = code;
          dispatch(setVerify(payload));
          navigate('/newPassword');
        }
      })
      .catch((err) => {
        console.log(err);
        // setIsInvalid(true);
      });
  };

  const resend = (e) => {
    e.preventDefault();

    const data = {};
    data.phone = phone;
    auth
      .resend(data)
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {
          const payload = resp.data;
          payload.phone = data.phone;
          payload.code = 'code';
          setNow(Date.now());
          dispatch(setVerify(payload));
        }
      })
      .catch((err) => {
        console.log(err);
        // setIsInvalid(true);
      });
  };

  const renderCountdown = () => {
    return <Countdown date={now + 120000} renderer={renderer} />;
  };

  return (
    <>
      <Helmet>
        <title>Verification</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <Container sx={{ width: 'fit-content' }}>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Box sx={{ mb: 4 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h4" color="textPrimary">
                  {t('verification')}
                </Typography>
              </div>
            </Box>
            <Grid container my={2}>
              <Grid item xs={2} md={12} sm={12}>
                <StyledReactInputVerificationCode>
                  <ReactInputVerificationCode
                    placeholder={null}
                    length={6}
                    onChange={setCode}
                    value={code}
                    autoFocus={true}
                  />
                </StyledReactInputVerificationCode>
              </Grid>
            </Grid>
            <Grid container display="flex">
              <Grid item xs={12} md={8} sm={12}>
                <Typography
                  sx={{
                    color: 'rgba(22, 22, 22, 0.6) ',
                  }}
                >
                  Enter the code we have sent your number
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sm={12}
                display="flex"
                justifyContent="right"
              >
                <Typography
                  sx={{
                    color: 'rgba(22, 22, 22, 0.6) ',
                  }}
                >
                  {renderCountdown()}
                </Typography>
              </Grid>
            </Grid>

            <Box sx={{ pt: 5 }}>
              <Button
                disabled={code.length < 6}
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
              >
                Confirm
              </Button>
            </Box>
            <Box sx={{ pt: 5 }} display="flex" justifyContent="center">
              <Typography
                onClick={resend}
                sx={{
                  color: 'rgba(22, 22, 22, 0.6) ',
                  fontSize: '14px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    color: '#2A455F',
                  },
                }}
              >
                Resend
              </Typography>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Verification;
