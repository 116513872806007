import React from 'react';
import Card from 'react-bootstrap/Card';
import { ImPointRight } from 'react-icons/im';

export default function AcademicCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: 'justify' }}>
            <span className="purple"> Privacy policy </span>
            <br />
            <span className="purple">
              Your privacy is important to team Marketify Group and we are
              committed to protecting your privacy. So we’ve developed a Privacy
              Policy. This Privacy Policy explains how the we treats your
              information and protects your privacy when you Use the Application
              and the rights and options available to you regarding your
              information. Personal Information we don`t collect any personal
              information of the user.
              <br /> Permissions
              <br /> 1. Contacts
              <br /> 2. SMS <br />
              Note: The Intent (Marketify) android app not provide the
              functionality of sending free messaging. These messages charges is
              depend on user`s network provider.
            </span>
            <br />
            <br />
          </p>
          <ul>
            {/* <li className="about-activity">
              <ImPointRight /> Make beautiful model
            </li> */}
            {/* <li className="about-activity">
              <ImPointRight /> Writting Coding
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li> */}
          </ul>

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}
