/* eslint-disable no-prototype-builtins */
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Pagination,
  Stack,
  Container,
  Typography,
  CardMedia,
  Avatar,
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import Moment from 'react-moment';
import { useTheme } from '@mui/material/styles';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useMediaQuery from '@mui/material/useMediaQuery';
// import InputIcon from '@mui/icons-material/Input';
import authApi from '../../../services/auth';
// import ProfileBtn from '../../Profile2';
import Loading from '../../Loading';
import testsApi from '../../../services/tests';
import scienceTestApi from '../../../services/science_tests';
import { useStyles } from './style';

export default function Scores({ handleModalScore }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [scores, setScores] = useState([]);
  const [countPages, setCountPages] = useState(0);

  const [page, setPage] = useState(1);
  const [sciences, setSciences] = useState([]);

  const [scienceId, setScienceId] = useState('');
  const [scienceName, setScienceName] = useState('');

  const loadingData = (page, id) => {
    setLoading(true);
    testsApi
      .getAllScores('page=' + page + '&page_size=10&science=' + id)
      .then((res) => {
        setCountPages(res.count);
        setScores(res.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    loadingData(value, scienceId);
  };

  useEffect(() => {
    setLoading(true);
    scienceTestApi
      .getAll('page_size=1000')
      .then((res) => {
        setSciences(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const timeConvert = (time) => {
    if (time !== null) {
      const sp1 = time.split('T');
      const sp2 = sp1[1].split(':');
      const strTime = sp1[0] + ' ' + sp2[0] + ':' + sp2[1];
      return strTime;
    }
    return time;
  };

  return (
    <Container maxWidth={false}>
      <Loading loading={loading} />

      <Grid container my={1}>
        <Grid item xs={12} md={12} sm={12}>
          <Autocomplete
            fullWidth
            size="small"
            id="combo-box1"
            className={classes.search}
            name="scienceId"
            value={scienceName}
            options={sciences}
            onChange={(event, newValue) => {
              setScienceId(newValue.science_id);
              setScienceName(newValue.name.uz);
              loadingData(page, newValue.science_id);
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }

              // Regular option
              return option.name.uz;
            }}
            isOptionEqualToValue={(option, newValue) =>
              option.science_id === newValue.science_id
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Fan')}
                name="scienceId"
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.name.uz, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.name.uz, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={`${index + 1}`}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />
        </Grid>
        {scores.map((items, i) => (
          <Grid key={`${i + 1}`} item xs={12} md={12} sm={12} my={1}>
            <Grid container>
              {items.user.photo ? (
                <CardMedia
                  component="img"
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 5,
                    objectFit: 'fill',
                  }}
                  image={items.user.photo}
                  alt=""
                />
              ) : (
                <Avatar sx={{ borderRadius: 5, bgcolor: '#E5E8EC' }}>
                  <AccountCircleIcon
                    sx={{ borderRadius: 5, bgcolor: '#000' }}
                  />
                  {/* <PersonOutlineIcon onClick={handleClick} /> */}
                </Avatar>
              )}
              <Grid item xs={12} md={12} sm={12}>
                <Typography>
                  {i + 1}. {items.user.name} &nbsp; ({items.score}) &nbsp; (
                  <Moment format="YYYY-MM-DD HH:mm">
                    {timeConvert(items.created_at)}
                  </Moment>
                  )
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <br />
      </Grid>

      <Stack spacing={2}>
        <Pagination
          ullwidth="true"
          count={countPages}
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </Stack>
    </Container>
  );
}
