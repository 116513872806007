import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  InputLabel,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Pagination from '@mui/material/Pagination';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import RemoveIcon from '@mui/icons-material/Remove';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ForumIcon from '@mui/icons-material/Forum';
import AddIcon from '@mui/icons-material/Add';
// import Navbar from '../../../NavbarBlack';
import Loading from '../../../../components/Loading';
import { setSelectTests } from '../../../../redux/modules/store/actions';
import { useStyles } from './style';
// import ModalWindow from '../../../../components/Modals';
// import ChangeStatusModal from '../../../../components/Modals/ConfirmStatus';
import topicTest from '../../../../services/topic_tests';
import scienceTest from '../../../../services/science_tests';
import testsApi from '../../../../services/tests';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({ ...rest }) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const [testList, setTestList] = useState([]);
  const [selectTestList, setSelectTestList] = useState([]);

  const language = useSelector((state) => state.langsReducer.language);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const loadingData = () => {
    setLoading(true);

    const query = '?page_size=10';
    testsApi
      .getFavorites(query)
      .then((res) => {
        console.log(res);
        setTestList(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const selectTest = (item, status) => {
    if (localStorage.getItem('selectTests')) {
      const arr = JSON.parse(localStorage.getItem('selectTests'));

      if (status === 1) {
        arr.push(item.test_id);
        localStorage.setItem('selectTests', JSON.stringify(arr));
      } else {
        localStorage.setItem(
          'selectTests',
          JSON.stringify(arr.filter((value) => value !== item.test_id))
        );
      }
    } else {
      localStorage.setItem('selectTests', JSON.stringify([item.test_id]));
    }
    dispatch(setSelectTests(uuidv4()));
    setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
  };

  useEffect(() => {
    loadingData();
    if (localStorage.getItem('selectTests')) {
      setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
    }
  }, [language]);

  return (
    <>
      <Helmet>
        <title>{t('tests')}</title>
      </Helmet>
      {/* <Navbar /> */}
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3,
          px: 1,
        }}
      >
        <Container maxWidth={false}>
          <Loading loading={loading} />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            mb={3}
            mt={2}
          >
            {t('Sevimli testlar')}
          </Typography>

          <Grid container my={1}>
            {testList.length === 0 ? (
              <Typography
                my={1}
                variant="h4"
                align="left"
                color={colors.grey[700]}
              >
                {t('no_test_data')}
              </Typography>
            ) : null}
            {testList.map((item, i) => (
              <Grid key={item.test.test_id} item xs={12} md={6} sm={12}>
                <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                  <Tooltip
                    title={
                      selectTestList.indexOf(item.test.test_id) < 0
                        ? t('selection')
                        : t('selected')
                    }
                    placement="top"
                    arrow
                  >
                    <IconButton
                      variant="filled"
                      onClick={() => {
                        selectTest(
                          item.test,
                          selectTestList.indexOf(item.test.test_id) < 0 ? 1 : 0
                        );
                      }}
                    >
                      {selectTestList.indexOf(item.test.test_id) < 0 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <HighlightOffIcon color="error" />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Muhokama" placement="top" arrow>
                    <IconButton
                      variant="filled"
                      onClick={() => {
                        navigate('/app/tests/forum/' + item.test.test_id);
                      }}
                    >
                      <ForumIcon color="primary" />
                    </IconButton>
                  </Tooltip>

                  {/* <Typography my={1} variant="h5" color={colors.grey[700]}>
                    &nbsp; &nbsp; {t('Fan') + ': ' + item.science.uz}
                  </Typography>
                  <Typography variant="h5" color={colors.grey[700]}>
                    &nbsp; &nbsp; {t('Mavzu') + ': ' + item.topic.uz}
                  </Typography> */}
                  <CardContent>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.test.question.replace(
                          'numberNN',
                          i + 1 + '. '
                        ),
                      }}
                    />
                    <Divider />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.test.option_a.replace('optionA', 'A). '),
                      }}
                    />
                    <Divider />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.test.option_b.replace('optionB', 'B). '),
                      }}
                    />
                    <Divider />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.test.option_c.replace('optionC', 'C). '),
                      }}
                    />
                    <Divider />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.test.option_d.replace('optionD', 'D). '),
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};
