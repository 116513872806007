import React from 'react';
import { Container, Typography } from '@mui/material';
import { useStyles } from './style';

export default ({ handleTelegramResponse }) => {
  const classes = useStyles();
  return (
    <div>
      <Container>
        <Typography variant="h5" color="textPrimary" gutterBottom mb={1} mt={1}>
          Bu bo`limdan foydalanish uchun ro`yxatdan o`tish kerak. Iltimos, Login
          tugmasi bosing
        </Typography>
      </Container>
    </div>
  );
};
