import React from 'react';
import { colors, Typography, CardMedia, Avatar } from '@mui/material';
import { Container, Content } from './style';
import UploadIcon from '../../../assets/icons/upload.svg';

export default ({ value }) => {
  const handleNamer = (value) => {
    if (value) {
      if (value.user_id) {
        if (value.user_id.name) {
          return value.user_id.name;
        }
      }
    }
    return '';
  };
  return (
    <Container>
      <Content>
        {value.user_id.photo ? (
          <CardMedia
            component="img"
            style={{
              borderRadius: 5,
              width: 60,
              height: 60,
            }}
            image={value.user_id.photo}
            alt=""
          />
        ) : (
          <CardMedia
            component="img"
            style={{
              borderRadius: 5,
              width: 60,
              height: 60,
            }}
            image={UploadIcon}
            alt=""
          />
        )}
      </Content>

      <Content>&nbsp; {handleNamer(value)}</Content>
    </Container>
  );
};
