import { takeLatest, put, delay } from 'redux-saga/effects';
import types from '../../../constants/action-types';
import service from '../../../services/class';
import { setData, setError, setTotal, setLoading } from './actions';
import { dataSelector } from './selectors';

function* fetchData({ payload }) {
  try {
    if (payload.isSearch) yield delay(500);
    // yield put(setLoading(true));
    if (payload.query !== 'page=1&page_size=10&start=-1') {
      const res = yield service.getAll(payload.query);
      const { data, total } = dataSelector(
        res,
        payload.pageIndex,
        payload.pageSize
      );

      yield put(setError(''));
      yield put(setData(data));
      yield put(setTotal(total));
      yield put(setLoading(false));
    }
  } catch (error) {
    yield put(setError(error.message));
  }
}

function* onSearch({ payload }) {
  yield delay(500);
  console.log(payload);
}

export default function* classSaga() {
  yield takeLatest(types.TABLE_CLASS_FETCH_DATA, fetchData);
  yield takeLatest(types.TABLE_CLASS_SAGA_ONSEARCH_ACTION, onSearch);
}
