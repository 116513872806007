import AlignAction from 'quill-blot-formatter/dist/actions/align/AlignAction';
import DeleteAction from 'quill-blot-formatter/dist/actions/DeleteAction';
import ImageSpec from 'quill-blot-formatter/dist/specs/ImageSpec';

class CustomImageSpec extends ImageSpec {
  static getActions() {
    return [AlignAction, DeleteAction];
  }
}

export default CustomImageSpec;
