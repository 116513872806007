import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { Container } from 'react-bootstrap';
import Particle from '../Particle';
import SearchCard from './SearchCard';
import MainCard from './MainCard';
import articleApi from '../../../services/articles';

export default function ListArticles() {
  const [articleList, setArticleList] = useState([]);
  const [loading, setLoading] = useState(false);
  let timer = null;

  const navigate = useNavigate();

  const getArticleList = () => {
    setLoading(true);
    articleApi
      .getAllMain()
      .then((res) => {
        setLoading(false);
        setArticleList(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getArticleListSearch = (text) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (text === '') {
        getArticleList();
      } else {
        setLoading(true);

        articleApi
          .getSearch(text)
          .then((res) => {
            setLoading(false);
            setArticleList(res.data);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }, 2000);
    return () => clearTimeout(timer);
  };

  const handlerShowBlog = (item) => {
    navigate('/articles/' + item.blog_id);
  };

  useEffect(() => {
    getArticleList();
  }, []);

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Grid item xs={12} md={12} sm={12}>
          <SearchCard handlerSearch={getArticleListSearch} />
        </Grid>
        <br />
        <Grid container>
          {loading ? (
            <Box sx={{ width: 210, marginRight: 0.5, my: 5 }}>
              <Skeleton variant="rectangular" width={210} height={118} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
            </Box>
          ) : null}
        </Grid>
        <Grid container>
          {articleList.map((items, index) => (
            <Grid key={`${index + 1}`} item xs={12} md={6} sm={12}>
              <MainCard
                key={`${index + 1}`}
                handlerShowBlog={handlerShowBlog}
                item={items}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
}
