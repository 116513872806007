import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  CardMedia,
  colors,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  OutlinedInput,
  Divider,
  Chip,
  Modal,
  Snackbar,
  Stack,
  InputLabel,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import jwtDecode from 'jwt-decode';
import { styled, useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { v1 as uuidv1 } from 'uuid';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-cropper';
import Loading from '../../../../components/Loading';
import ManySelect from '../../../../components/FormElements/ManySelect';
import locations from '../../../../services/locations';
import classApi from '../../../../services/class';
import customers from '../../../../services/customers';
import { useStyles } from './style';
import UploadIcon from '../../../../assets/icons/upload.svg';
import { IOSSwitch, Alert } from '../../../../components/GlobalStyles';

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const [locationList, setLocationList] = useState([]);
  // const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);

  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const { t } = useTranslation();
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [classModal, setClassModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [classPaymentIndex, setClassPaymentIndex] = useState(0);
  const [classPaymentIndexId, setClassPaymentIndexId] = useState(0);
  const [classPaymentDates, setClassPaymentDates] = useState([]);

  const [visitType, setVisitType] = useState('booking');
  const [showPassword, setshowPassword] = useState(false);
  const [isNavigate, setIsNavigate] = useState(true);
  const [confirmationShowPassword, setConfirmationShowPassword] =
    useState(false);

  const [classJoinedDates, setClassJoinedDates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [classSchema, setClassSchema] = useState([]);

  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const saveData = (values, data, formik) => {
    if (values.password !== values.confirmation_password) {
      formik.setSubmitting(false);
      setSnackbarMessage(t('error_input_password'));
      setOpen(true);
    } else if (
      values.password.length < 6 ||
      values.confirmation_password.length < 6
    ) {
      formik.setSubmitting(false);
      setSnackbarMessage(t('invalid_password_count'));
      setOpen(true);
    } else {
      setLoading(true);
      customers
        .create(data)
        .then((res) => {
          setLoading(false);
          navigate('/app/customers');
        })
        .catch((err) => {
          setLoading(false);
          formik.setSubmitting(false);
          setSnackbarMessage(t(err.response.data.error));
          setOpen(true);
        });
    }
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
    unique_number: Yup.string().required(t('required')),
    username: Yup.string().required(t('required')),
    gender: Yup.string().required(t('required')),
    joined_date: Yup.date().required(t('required')),
    locations: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      username: '',
      unique_number: '',
      email: '',
      gender: '',
      date_birth: new Date(),
      joined_date: new Date(),
      locations: '',
      classes: [],
      password: '',
      confirmation_password: '',
      notes: '',
      class_payment_dates: classPaymentDates,
    },
    validationSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append('locations', values.locations);
      data.append('classes', JSON.stringify(values.classes));
      data.append('name', values.name);
      data.append('email', values.email);
      data.append(
        'phone',
        values.phone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '')
      );
      data.append('photo', photo);
      data.append('username', values.username);
      data.append('password', values.password);
      data.append('gender', values.gender);
      data.append('unique_number', values.unique_number);
      data.append('date_birth', values.date_birth);
      data.append('joined_date', values.joined_date);
      data.append('class_joined_dates', JSON.stringify(classJoinedDates));
      data.append(
        'class_payment_dates',
        JSON.stringify(values.class_payment_dates)
      );
      data.append('notes', values.notes);
      data.append('status', status ? 1 : 0);

      if (state) {
        if (
          values.password !== '' &&
          values.password !== values.confirmation_password
        ) {
          formik.setSubmitting(false);
          setSnackbarMessage(t('error_input_password'));
          setOpen(true);
        } else if (
          values.password !== '' &&
          (values.password.length < 6 ||
            values.confirmation_password.length < 6)
        ) {
          formik.setSubmitting(false);
          setSnackbarMessage(t('invalid_password_count'));
          setOpen(true);
        } else {
          setLoading(true);
          customers
            .update(state, data)
            .then((res) => {
              setLoading(false);
              if (isNavigate) {
                navigate('/app/customers');
              }
            })
            .catch((err) => {
              setLoading(false);
              formik.setSubmitting(false);
              setSnackbarMessage(t(err.response.data.error));
              setOpen(true);
            });
        }
      } else {
        saveData(values, data, formik);
      }
    },
  });

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      setPreviewImg(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
      });
    }
  };

  const getByLocationId = (id) => {
    setLoading(true);
    const query = `page=1&page_size=1000&status=1&locations=${id}`;
    classApi
      .getAll(query)
      .then((ress) => {
        if (ress.results.length === 0) {
          setSnackbarMessage('Empty class list');
          setOpen(true);
        } else {
          setClassSchema(
            ress.results
              .filter((element) => element.status === 1)
              .map((item) => ({
                name: item.class_name,
                id: item.class_id,
              }))
          );
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const loadingData = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationList(res.results);
        if (state) {
          customers
            .getOne(state)
            .then((resp) => {
              const query = `page=1&page_size=1000&status=1&locations=${resp.locations.location_id}`;
              classApi
                .getAll(query)
                .then((ress) => {
                  if (ress.results.length === 0) {
                    setSnackbarMessage('Empty class list');
                    setOpen(true);
                  } else {
                    setClassSchema(
                      ress.results
                        .filter((element) => element.status === 1)
                        .map((item) => ({
                          name: item.class_name,
                          id: item.class_id,
                        }))
                    );
                    const arr = [];
                    ress.results.forEach((value) => {
                      resp.classes.forEach((item) => {
                        if (value.class_id === item.class_id) {
                          arr.push({
                            id: value.class_id,
                            name: value.class_name,
                          });
                        }
                      });
                    });
                    setSelectedClass(arr);
                  }
                })
                .catch((err) => console.log(err));

              formik.setValues({
                ...formik.values,
                locations: resp.locations.location_id,
                classes: resp.classes.map((item) => item.class_id),
                name: resp.user_id.name,
                phone: resp.user_id.phone,
                unique_number: resp.unique_number,
                username: resp.user_id.username,
                email: resp.user_id.email,
                gender: resp.user_id.gender,
                date_birth: resp.user_id.date_birth,
                joined_date: resp.user_id.joined_date,
                class_payment_dates: resp.class_payment_dates,
                password: resp.user_id.password,
                confirmation_password: resp.user_id.password,
                notes: resp.user_id.notes,
              });

              setClassJoinedDates(resp.class_joined_dates);
              setClassPaymentDates(resp.class_payment_dates);

              if (resp.user_id.photo) {
                setPreviewImg(resp.user_id.photo);
              }
              if (resp.status === 1) {
                setStatus(true);
              } else {
                setStatus(false);
              }
            })
            .catch((err) => {
              setLoading(false);
            });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const handleChangeClassPaymentDates = (id) => {
    for (let i = 0; i < classPaymentDates.length; i += 1) {
      if (id === classPaymentDates[i].class_id) {
        const data = {
          id: uuidv1(),
          date: new Date(),
          payment_status: 0,
          discount: 0,
        };
        classPaymentDates[i].dates.push(data);
        setClassPaymentDates(classPaymentDates);
        formik.setFieldValue('class_payment_dates', classPaymentDates);
      }
    }
  };

  const handleChangeChips = (event) => {
    if (event.target.name === 'locations') {
      formik.setFieldValue('locations', event.target.value);
      const query = `status=1&locations=${event.target.value}`;

      getByLocationId(event.target.value);
    } else if (event.target.name === 'classes') {
      const arr = selectedClass.map((item) => item.id);
      const cl = classJoinedDates;
      const dates = classPaymentDates;
      try {
        event.target.value.forEach((item) => {
          const index = arr.indexOf(item.id);
          if (index < 0) {
            selectedClass.push({ id: item.id, name: item.name });
            cl.push({ id: item.id, joined_date: new Date() });
            const data = {
              class_id: item.id,
              dates: [
                {
                  id: uuidv1(),
                  date: new Date(),
                  payment_status: 0,
                  discount: 0,
                },
              ],
            };
            dates.push(data);
          }
        });
        console.log(dates);
        setClassJoinedDates(cl);
        setSelectedClass(selectedClass);
        formik.setFieldValue(
          'classes',
          selectedClass.map((item) => item.id)
        );
        setClassPaymentDates(dates);
        formik.setFieldValue('class_payment_dates', dates);
      } catch (error) {
        const a = 1;
      }
    }
    event.stopPropagation();
  };

  const handleDeleteChipsClass = (name, id) => {
    const checker = formik.values.class_payment_dates
      .filter((classes) => classes.class_id === id)
      .map((item) => item.dates)[0]
      ? formik.values.class_payment_dates
          .filter((classes) => classes.class_id === id)
          .map((item) => item.dates)[0]
          .filter(
            (date) => date.payment_status === 1 || date.payment_status === '1'
          )
      : [];
    if (checker.length === 0) {
      setSelectedClass((Classes) =>
        Classes.filter((chip) => chip.name !== name)
      );

      formik.setFieldValue(
        'classes',
        formik.values.classes.filter((item) => item !== id)
      );
      formik.setFieldValue(
        'class_payment_dates',
        formik.values.class_payment_dates.filter(
          (classes) => classes.class_id !== id
        )
      );
      setClassPaymentDates(
        formik.values.class_payment_dates.filter(
          (classes) => classes.class_id !== id
        )
      );
    }
  };

  const handleGender = (event) => {
    formik.setFieldValue('gender', event.target.name);
  };

  const locationSchema = locationList.map((item) => ({
    name: item.location_name,
    id: item.location_id,
  }));

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmationPassword = () => {
    setConfirmationShowPassword(!confirmationShowPassword);
  };

  const handleMouseDownConfirmationPassword = (event) => {
    event.preventDefault();
  };

  const handleClassModal = () => {
    setClassModal(!classModal);
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Modal hideBackdrop open={classModal} onClose={handleClassModal}>
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">{t('is_delete')}</h2>
            {/* <p id="child-modal-description"></p> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleClassModal()}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                }
                onClick={() => {
                  if (state) {
                    setIsSubmitting(true);

                    const arr = [];
                    classPaymentDates.forEach((element) => {
                      const data = { class_id: element.class_id, dates: [] };
                      element.dates.forEach((value) => {
                        if (value.id !== classPaymentIndexId) {
                          data.dates.push(value);
                        }
                      });
                      arr.push(data);
                    });

                    if (classPaymentIndex && state) {
                      const data = new FormData();
                      data.append('locations', formik.values.locations);
                      data.append('customer', state.customer_id);
                      data.append('id', classPaymentIndexId);

                      const item = { class_id: classPaymentIndex };
                      data.append('delete_payment_date', 'true');
                      classApi
                        .update(item, data)
                        .then((res) => {
                          setClassPaymentDates(arr);
                          formik.setFieldValue('class_payment_dates', arr);
                          handleClassModal();
                          setIsSubmitting(false);
                          setLoading(true);
                          const data = new FormData();
                          data.append('locations', formik.values.locations);
                          data.append(
                            'classes',
                            JSON.stringify(formik.values.classes)
                          );
                          data.append(
                            'packages',
                            JSON.stringify(formik.values.packages)
                          );
                          data.append('name', formik.values.name);
                          data.append('email', formik.values.email);
                          data.append(
                            'phone',
                            formik.values.phone
                              .replaceAll('(', '')
                              .replaceAll(')', '')
                              .replaceAll(' ', '')
                              .replaceAll('-', '')
                          );
                          data.append('photo', photo);
                          data.append('username', formik.values.username);
                          data.append('password', formik.values.password);
                          data.append('gender', formik.values.gender);
                          data.append('date_birth', formik.values.date_birth);
                          data.append('joined_date', formik.values.joined_date);
                          data.append(
                            'class_joined_dates',
                            JSON.stringify(classJoinedDates)
                          );
                          data.append(
                            'class_payment_dates',
                            JSON.stringify(arr)
                          );
                          data.append('notes', formik.values.notes);
                          data.append('status', status ? 1 : 0);

                          if (state) {
                            customers
                              .update(state, data)
                              .then((res) => {
                                setLoading(false);
                              })
                              .catch((err) => {
                                setLoading(false);
                                formik.setSubmitting(false);
                                setSnackbarMessage(t(err.response.data.error));
                                setOpen(true);
                              });
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          setIsNavigate(true);
                          setIsSubmitting(false);
                          console.log(err);
                        });
                    } else {
                      handleClassModal();
                      formik.setFieldValue('class_payment_dates', arr);
                    }
                  }
                }}
              >
                {t('yes')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal hideBackdrop open={openCrop} onClose={handleCropModal}>
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">{t('edit_image')}</h2>
            <div style={{ width: '100%' }}>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleCropModal()}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                }
                onClick={() => {
                  getCropData();
                  handleCropModal();
                }}
              >
                {t('ready')}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {state ? t('edit_member') : t('add_member')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={3} xs={12}>
            <Box
              sx={{
                py: 2,
              }}
            >
              <CardMedia
                component="img"
                style={{
                  width: 210,
                  height: 210,
                  borderRadius: 5,
                  objectFit: 'fill',
                }}
                image={previewImg || UploadIcon}
                alt=""
              />
              <input
                id="selectFile"
                type="file"
                accept=".png,.jpg"
                style={{ display: 'none' }}
                onChange={imgSelectHandler.bind(this)}
              />
              <Box
                sx={{
                  py: 0.5,
                }}
              />
              <Button
                type="file"
                fullWidth
                size="small"
                variant="contained"
                sx={{
                  width: 210,
                  textTransform: 'none',
                  fontSize: 20,
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
                color="error"
                onClick={() => {
                  document.getElementById('selectFile').click();
                }}
              >
                {previewImg ? t('change') : t('select_image')}
              </Button>
            </Box>
          </Grid>

          <Grid item md={9} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12} my={1}>
                  <FormControl
                    size="small"
                    sx={{ width: '100%', backgroundColor: 'white' }}
                  >
                    <InputLabel id="locations">{t('location')}</InputLabel>
                    <Select
                      labelId="locations"
                      label={t('location')}
                      className={classes.input}
                      fullWidth
                      name="locations"
                      value={formik.values.locations}
                      onBlur={formik.handleBlur}
                      onChange={handleChangeChips}
                    >
                      {locationSchema.map((item, index) => (
                        <MenuItem key={`${index + 1}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    label={t('full_name')}
                    className={classes.search}
                    name="name"
                    autoComplete="namer"
                    type="text"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_full_name')}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.name &&
                      formik.touched.name &&
                      formik.errors.name}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <TextField
                    label={t('ID')}
                    className={classes.search}
                    name="unique_number"
                    autoComplete="id"
                    type="text"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_id')}
                    value={formik.values.unique_number}
                    error={
                      formik.touched.unique_number &&
                      Boolean(formik.errors.unique_number)
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.unique_number &&
                      formik.touched.unique_number &&
                      formik.errors.unique_number}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} my={1}>
                <Grid item xl={6} md={6} xs={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('date_of_birth')}
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        name="date_birth"
                        mask="__/__/____"
                        // openTo="year"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.date_birth}
                        onChange={(e) => formik.setFieldValue('date_birth', e)}
                        error={
                          formik.touched.date_birth &&
                          Boolean(formik.errors.date_birth)
                        }
                        InputProps={{
                          style: {
                            backgroundColor: theme.palette.background.paper,
                          },
                        }}
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        helperText={
                          formik.touched.date_birth && formik.errors.date_birth
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('gender')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 4,
                      }}
                    >
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          name="male"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            height: '100%',
                            backgroundColor:
                              formik.values.gender === 'male'
                                ? colors.white
                                : '#fff',
                            color: '#000',
                          }}
                          variant="contained"
                          onClick={handleGender}
                        >
                          {t('male')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          name="female"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            backgroundColor:
                              formik.values.gender === 'female'
                                ? colors.white
                                : '#fff',
                            color: '#000',
                          }}
                          variant="contained"
                          onClick={handleGender}
                        >
                          {t('female')}
                        </Button>
                      </Grid>
                    </Box>
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.gender &&
                        formik.touched.gender &&
                        formik.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} my={1}>
                <Grid item xs={12} md={6} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('phone')}
                  </Typography>
                  <InputMask
                    className={classes.inputmask}
                    autoComplete="off"
                    size="small"
                    onChange={formik.handleChange}
                    name="phone"
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="+998 (_ _) _ _ _ - _ _ - _ _"
                    mask="+\9\98 (99) 999-99-99"
                    maskChar="_"
                    style={{
                      border: '1px solid #c4c4c4',
                      outline: '1px solid #F7F8FA',
                      paddingLeft: '14px',
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </FormHelperText>
                </Grid>
                <Grid item xl={12} md={6} xs={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('joined_date')}
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.search}>
                      <DatePicker
                        name="join_date"
                        mask="__/__/____"
                        openTo="year"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.join_date}
                        onChange={(e) => formik.setFieldValue('join_date', e)}
                        error={
                          formik.touched.join_date &&
                          Boolean(formik.errors.join_date)
                        }
                        helperText={
                          formik.touched.join_date && formik.errors.join_date
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('username')} <span style={{ color: 'red' }}> *</span>
                </Typography>
                <OutlinedInput
                  disabled={!!state}
                  className={classes.search}
                  name="username"
                  fullWidth
                  type="text"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  // label={t('username')}
                  value={formik.values.username}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  size="small"
                />
                <FormHelperText
                  sx={{
                    paddingLeft: 2,
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.username &&
                    formik.touched.username &&
                    formik.errors.username}
                </FormHelperText>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('password')}
                  </Typography>
                  <OutlinedInput
                    className={classes.search}
                    name="password"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_password')}
                    value={formik.values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    size="small"
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 2,
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.password &&
                      formik.touched.password &&
                      formik.errors.password}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('confirmation_password')}
                  </Typography>
                  <OutlinedInput
                    className={classes.search}
                    name="confirmation_password"
                    fullWidth
                    type={confirmationShowPassword ? 'text' : 'password'}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_confirmation_password')}
                    value={formik.values.confirmation_password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmationPassword}
                          onMouseDown={handleMouseDownConfirmationPassword}
                          edge="end"
                        >
                          {confirmationShowPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={
                      formik.touched.confirmation_password &&
                      Boolean(formik.errors.confirmation_password)
                    }
                    size="small"
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 2,
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.confirmation_password &&
                      formik.touched.confirmation_password &&
                      formik.errors.confirmation_password}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container my={1}>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl sx={{ width: '100%' }}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('visit_type')}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid container spacing={1} my={1}>
                  <Grid item xs={12} md={6} sm={12}>
                    <Button
                      fullWidth
                      sx={{
                        textTransform: 'none',
                        height: '100%',
                        backgroundColor:
                          visitType === 'booking' ? colors.white : '#fff',
                        color: '#000',
                      }}
                      variant="contained"
                      onClick={() => {
                        setVisitType('booking');
                      }}
                    >
                      {t('booking')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    <Button
                      fullWidth
                      sx={{
                        textTransform: 'none',
                        height: '100%',
                        backgroundColor:
                          visitType === 'class' ? colors.white : '#fff',
                        color: '#000',
                      }}
                      variant="contained"
                      onClick={() => {
                        setVisitType('class');
                      }}
                    >
                      {t('class')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {visitType === 'class' ? (
                <div key="class">
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('classes')}
                    </Typography>
                    <FormControl size="small" sx={{ width: '100%' }}>
                      <ManySelect
                        name="classes"
                        items={classSchema}
                        deleteChips={handleDeleteChipsClass}
                        handleChangeChips={handleChangeChips}
                        selectedItems={selectedClass}
                      />
                      <FormHelperText
                        sx={{
                          color: colors.red[700],
                        }}
                      >
                        {formik.errors.classes &&
                          formik.touched.classes &&
                          formik.errors.classes}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {formik.values.class_payment_dates.map((items, index) => (
                    <Grid key={items.id} container spacing={2} my={1}>
                      <Grid item xl={6} md={6} xs={6}>
                        <Typography
                          my={1}
                          variant="h5"
                          align="left"
                          color={colors.grey[700]}
                        >
                          {`${index + 1}. `}
                          {selectedClass.map((value) =>
                            value.id === items.class_id ? value.name : ''
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xl={6} md={6} xs={6}>
                        <Typography
                          my={1}
                          variant="h5"
                          align="left"
                          color={colors.grey[700]}
                        >
                          {t('joined_date')}
                        </Typography>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={localeMap[language]}
                        >
                          <Stack spacing={3} className={classes.search}>
                            <DatePicker
                              openTo="year"
                              mask="__/__/____"
                              views={['year', 'month', 'day']}
                              // minDate={new Date()}
                              value={
                                classJoinedDates[index]
                                  ? classJoinedDates[index].join_date
                                  : ''
                              }
                              onChange={(e) => {
                                classJoinedDates[index].join_date = e;
                                setClassJoinedDates(classJoinedDates);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <Card sx={{ padding: 2, marginBottom: 5 }}>
                          <Grid key={items.id} container>
                            <Grid item md={6} sm={6}>
                              <Typography
                                my={1}
                                variant="h5"
                                align="left"
                                color={colors.grey[700]}
                              >
                                {selectedClass.map((value) =>
                                  value.id === items.cass_id ? value.name : ''
                                )}
                              </Typography>
                            </Grid>
                            {formik.values.class_payment_dates[index].dates.map(
                              (item, i) => (
                                <Grid key={item.id} container my={1}>
                                  <Grid item md={4} sm={6}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                      locale={localeMap[language]}
                                    >
                                      <Stack
                                        spacing={3}
                                        className={classes.input}
                                      >
                                        <DatePicker
                                          label={t('payment_date')}
                                          disabled={
                                            item.payment_status === '1' ||
                                            item.payment_status === '2' ||
                                            item.payment_status === 1 ||
                                            item.payment_status === 2
                                          }
                                          openTo="year"
                                          mask="__/__/____"
                                          views={['year', 'month', 'day']}
                                          // minDate={new Date()}
                                          value={item.date}
                                          onChange={(e) => {
                                            classPaymentDates[index].dates[
                                              i
                                            ].date = e;
                                            setClassPaymentDates(
                                              classPaymentDates
                                            );
                                            formik.setFieldValue(
                                              'class_payment_dates',
                                              classPaymentDates
                                            );
                                          }}
                                          error={
                                            formik.touched
                                              .class_payment_dates &&
                                            Boolean(
                                              formik.errors.class_payment_dates
                                            )
                                          }
                                          helperText={
                                            formik.touched
                                              .class_payment_dates &&
                                            formik.errors.class_payment_dates
                                          }
                                          renderInput={(params) => (
                                            <TextField
                                              size="small"
                                              {...params}
                                            />
                                          )}
                                        />
                                      </Stack>
                                    </LocalizationProvider>
                                  </Grid>
                                  &nbsp;
                                  <Grid item md={4} sm={12}>
                                    <TextField
                                      label={t('discount')}
                                      className={classes.search}
                                      name="discount"
                                      autoComplete="namer"
                                      type="number"
                                      fullWidth
                                      size="small"
                                      // onBlur={formik.handleBlur}
                                      onChange={(e) => {
                                        classPaymentDates[index].dates[
                                          i
                                        ].discount = e.target.value;
                                        setClassPaymentDates(classPaymentDates);
                                        formik.setFieldValue(
                                          'class_payment_dates',
                                          classPaymentDates
                                        );
                                      }}
                                      variant="outlined"
                                      placeholder={t('discount')}
                                      value={item.discount}
                                    />
                                  </Grid>
                                  <Grid item md={3} sm={12}>
                                    <Divider>
                                      <Chip
                                        onDelete={() => {
                                          if (
                                            item.payment_status === 0 ||
                                            item.payment_status === '0'
                                          ) {
                                            setClassPaymentIndex(
                                              items.class_id
                                            );
                                            setClassPaymentIndexId(item.id);
                                            handleClassModal();
                                          }
                                        }}
                                        deleteIcon={
                                          item.payment_status === 0 ||
                                          item.payment_status === '0' ? null : (
                                            <DoneIcon />
                                          )
                                        }
                                        color={
                                          item.payment_status === 0 ||
                                          item.payment_status === '0'
                                            ? 'error'
                                            : 'success'
                                        }
                                        icon={
                                          item.payment_status === 0 ||
                                          item.payment_status === '0' ? (
                                            <CloseIcon />
                                          ) : (
                                            <CheckCircleIcon />
                                          )
                                        }
                                        label={`${t('payment_status')}`}
                                      />
                                    </Divider>
                                  </Grid>
                                </Grid>
                              )
                            )}
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: 2,
                                py: 2,
                              }}
                            >
                              <Grid item>
                                <Button
                                  sx={{
                                    backgroundColor: 'transparent',
                                    color: colors.grey[700],
                                  }}
                                  name="add"
                                  variant="dashed"
                                  onClick={() => {
                                    handleChangeClassPaymentDates(
                                      items.class_id
                                    );
                                  }}
                                >
                                  + {t('add_payment_date')}
                                </Button>
                              </Grid>
                            </Box>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              ) : null}

              <Grid item xl={12} md={12} xs={12} my={2}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('description')}
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="notes"
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={t('enter_notes')}
                  value={formik.values.notes}
                />
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={() => navigate('/app/customers')}
                >
                  {t('cancel')}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => console.log()}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(state, formik.isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
