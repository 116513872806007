import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Navbar from '../../NavBars/DashboardNavbar';
import { Container, Content, Root } from '../style';
import { Wrapper, Main } from './style';
import Sidebar from '../../Sidebar/Dashboard';

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const matches = useMediaQuery('(min-width:1200px)');
  const location = useLocation();

  const checkPath = (path) => {
    if (path === '/') {
      return true;
    }
    if (path === '/about') {
      return true;
    }
    return false;
  };

  const pdLeft = () => {
    if (checkPath(location.pathname)) {
      return 0;
    }
    if (!matches) {
      return 0;
    }
    return 226;
  };

  return (
    <Root>
      <Navbar onMobileNavOpen={() => setMobileNavOpen(true)} />

      <Sidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />

      <div
        style={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden',
          paddingTop: 50,
          paddingLeft: pdLeft(),
          position: 'relative',
          top: 0,
          left: 0,
        }}
      >
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </div>
    </Root>
  );
};

export default DashboardLayout;
