import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  TextField,
  Typography,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@mui/styles';
import ReactInputVerificationCode from 'react-input-verification-code';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { add, intervalToDuration, isBefore } from 'date-fns';
import auth from '../../../../services/auth';
import { setToken } from '../../../../redux/modules/auth/actions';
import ResetSeccessful from './ResetSuccessful';

const NewPassword = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const code = useSelector((state) => state.authReducer.code);
  const phone = useSelector((state) => state.authReducer.phone);
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const schema = Yup.object().shape({
    password: Yup.string().min(8).max(255).required(t('required')),
    confirmation_password: Yup.string().min(8).max(255).required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmation_password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (values.password === values.confirmation_password) {
        const data = values;
        data.confirmation_code = code;
        data.phone = phone;
        auth
          .resetPassword(data, accessToken)
          .then((resp) => {
            if (resp.status === 200 || resp.status === 201) {
              const payload = resp.data;
              payload.phone = data.phone;
              payload.code = code;
              setIsOpen(true);
            }
          })
          .catch((err) => {
            console.log(err);
            // setIsInvalid(true);
          });
      }
    },
  });

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {isOpen && (
        <ResetSeccessful
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
          }}
        />
      )}
      <Helmet>
        <title>{t('new_password')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          width: '100%',
          display: isOpen ? 'none' : 'block',
          // display: 'flex',
          // flexDirection: 'column',
          // height: '100%',
          // justifyContent: 'center',
        }}
      >
        <Container maxWidth="sm">
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box sx={{ mb: 4 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h4" color="textPrimary">
                  {t('new_password')}
                </Typography>
              </div>
            </Box>
            <Grid container my={2}>
              <Grid item xs={2} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.black}
                >
                  {t('new_password')}
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:active': {
                        boxShadow: 'none',
                        backgroundColor: 'red',
                        borderColor: '#005cbf',
                      },
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  error={Boolean(
                    formik.touched.password && formik.errors.password
                  )}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  placeholder={t('enter_new_password')}
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={2} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.black}
                >
                  {t('confirmation_password')}
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                      '&:active': {
                        boxShadow: 'none',
                        backgroundColor: 'red',
                        borderColor: '#005cbf',
                      },
                      '&:focus': {
                        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
                      },
                    },
                  }}
                  error={Boolean(
                    formik.touched.confirmation_password &&
                      formik.errors.confirmation_password
                  )}
                  fullWidth
                  helperText={
                    formik.touched.confirmation_password &&
                    formik.errors.confirmation_password
                  }
                  placeholder={t('enter_confirmation_password')}
                  name="confirmation_password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.confirmation_password}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
            </Grid>

            <Box sx={{ pt: 4 }}>
              <Button
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  transition: 'all 0.3s ease-in-out',
                  backgroundColor: '#2A455F',
                  '&:hover': {
                    backgroundColor: '#2A455F',
                    opacity: 0.8,
                  },
                }}
              >
                {t('confirm')}
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Grid>
  );
};

export default NewPassword;
