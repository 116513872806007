import { Bar } from 'react-chartjs-2';
import {
  Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export default ({ rest }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }
  const myDate = new Date();
  const curr = addHoursToDate(myDate, 5);
  const first = curr.getDate();
  const last = first - 6;
  const firstDay = new Date(curr.setDate(first)).toISOString();
  const secondDay = new Date(curr.setDate(first - 1)).toISOString();
  const thirdDay = new Date(curr.setDate(first - 2)).toISOString();
  const fourDay = new Date(curr.setDate(first - 3)).toISOString();
  const fiveDay = new Date(curr.setDate(first - 4)).toISOString();
  const sixDay = new Date(curr.setDate(first - 5)).toISOString();
  const lastDay = new Date(curr.setDate(last)).toISOString();

  const labels = [
    lastDay,
    sixDay,
    fiveDay,
    fourDay,
    thirdDay,
    secondDay,
    firstDay,
  ];
  const datas = [0, 0, 0, 0, 0, 0, 0];

  const month = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const data = {
    datasets: [
      {
        fill: true,
        backgroundColor: '#72A1EF',
        barPercentage: 1,
        barThickness: 30,
        maxBarThickness: 50,
        borderRadius: 5,
        categoryPercentage: 0.5,
        data: rest.length === 0 ? datas : rest.map((item) => item.quantity),
        label: t('members'),
      },
      // {
      //   fill: true,
      //   backgroundColor: '#2A455F',
      //   barPercentage: 0.5,
      //   barThickness: 30,
      //   maxBarThickness: 50,
      //   borderRadius: 5,
      //   categoryPercentage: 0.5,
      //   data: [150, 1260, 1110, 890, 2210, 1650, 1490, 3040, 2650, 1630],
      //   label: t('new'),
      // },
    ],
    labels:
      rest.length === 0
        ? labels.map((item) =>
            new Date(item).getDate()
              ? new Date(item).getDate() +
                ' ' +
                t(`short_month_name.${month[new Date(item).getMonth()]}`)
              : ''
          )
        : rest.map((item) =>
            new Date(item.time).getDate()
              ? new Date(item.time).getDate() +
                ' ' +
                t(`short_month_name.${month[new Date(item.time).getMonth()]}`)
              : ''
          ),
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: 'top',
        align: 'end',
        labels: {
          boxHeight: 60,
          usePointStyle: true,
          pointStyle: 'rectRounded',
        },
      },
      tooltip: {
        backgroundColor: theme.palette.text.paper,
        bodyFontColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        enabled: true,
        footerFontColor: theme.palette.text.secondary,
        intersect: false,
        // mode: 'index',
        titleFontColor: theme.palette.text.primary,
      },
    },
    animation: true,
    cornerRadius: 50,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
      // xAxes: [
      //   {
      //     ticks: {
      //       fontColor: theme.palette.text.secondary,
      //     },
      //     gridLines: {
      //       display: false,
      //       drawBorder: false,
      //     },
      //   },
      // ],
      // yAxes: [
      //   {
      //     ticks: {
      //       fontColor: theme.palette.text.secondary,
      //       beginAtZero: true,
      //       callback: (value = data) => `$ ${value}`,
      //       min: 0,
      //     },
      //     gridLines: {
      //       borderDash: [2],
      //       borderDashOffset: [2],
      //       color: theme.palette.divider,
      //       drawBorder: false,
      //       zeroLineBorderDash: [2],
      //       zeroLineBorderDashOffset: [2],
      //       zeroLineColor: theme.palette.divider,
      //     },
      //   },
      // ],
    },
  };

  return (
    <Card>
      <CardHeader title={t('members')} />
      <CardContent>
        <Box
          sx={{
            height: 200,
            position: 'relative',
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};
