import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  colors,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import Loading from '../../../../../components/Loading';
import orders from '../../../../../services/orders';
import { useStyles } from './style';
import Table from '../Table';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({ load, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [isSendMessage, setIsSendMessage] = useState(false);
  const [amount, setAmount] = useState(0);
  const [allAmount, setAllAmount] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceType, setServiceType] = useState('');
  const { t } = useTranslation();
  const paymentItem = useSelector((state) => state.ordersReducer.item);

  const [paymentMethod, setPaymentMethod] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    payment_date: Yup.date().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      order_number: '',
      customer_name: '',
      phone: '',
      service_type: '',
      service_name: '',
      payed_date: new Date(),
      promo: '123456789',
      amount: 0,
      success_payment_dates: [],
      notes: '',
      reason_free: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const data = new FormData();

      if (!isFree && paymentMethod === '') {
        setSnackbarMessage('Select payment method');
        setOpen(true);
        setIsSubmitting(false);
      } else if (amount > formik.values.amount) {
        setSnackbarMessage('Large amount');
        setOpen(true);
        setIsSubmitting(false);
      } else {
        setLoading(true);
        data.append('order_id', paymentItem.order_id);
        data.append('locations', paymentItem.locations.location_id);
        data.append('order_number', paymentItem.order_number);
        data.append('discount', paymentItem.discount);
        data.append('amount', amount);
        data.append('state', amount === 0 ? 2 : 0);
        data.append('customer', paymentItem.customer.customer_id);
        data.append(
          'success_payment_dates',
          JSON.stringify(paymentItem.success_payment_dates)
        );
        data.append('booking', JSON.stringify(paymentItem.booking));
        data.append('classes', JSON.stringify(paymentItem.classes));

        data.append('service_type', paymentItem.service_type);
        data.append('payment_date', values.payment_date);
        data.append('payment_uuid_date', paymentItem.payment_uuid_date);
        data.append('payed_date', new Date(values.payed_date).toISOString());
        data.append(
          'input_payed_date',
          amount === 0 ? new Date().toISOString() : '0'
        );
        data.append('payment_type', paymentMethod);
        data.append('promo', values.promo);
        data.append('notes', values.notes);
        data.append('is_free', isFree ? 1 : 0);
        data.append('is_send_message', isSendMessage ? 1 : 0);
        data.append('reason_free', values.reason_free);

        orders
          .update(paymentItem.order_id, data)
          .then((res) => {
            setLoading(false);
            onClose();
            load();
          })
          .catch((err) => {
            setLoading(false);
            setIsSubmitting(false);
            console.log(err);
          });
        // if (state) {
        //   ordersHistory
        //     .update(state, data)
        //     .then((res) => {
        //       // navigate('/app/customers');
        //     })
        //     .catch((err) => console.log(err));
        // } else {

        // }
      }
    },
  });

  const handleNamer = (item) => {
    if (item.service_type === 'Booking') {
      return item.booking.service_name;
    }
    if (item.service_type === 'Package') {
      return item.package.package_name;
    }
    if (item.service_type === 'Class') {
      return item.classes.class_name;
    }
    return '';
  };

  const handleAmount = (item) => {
    if (item.service_type === 'Booking') {
      return item.booking.price;
    }
    if (item.service_type === 'Package') {
      return item.package.price;
    }
    if (item.service_type === 'Class') {
      return item.classes.price;
    }
    return '';
  };

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      order_number: paymentItem.order_number,
      customer_name: paymentItem.customer.user_id.fields.name,
      phone: paymentItem.customer.user_id.fields.phone,
      service_type: paymentItem.service_type,
      service_name: handleNamer(paymentItem),
      payment_date: paymentItem.payment_date,
      payed_date: paymentItem.payed_date,
      amount: paymentItem.amount,
      discount: paymentItem.discount,
      success_payment_dates:
        paymentItem.success_payment_dates !== 0
          ? paymentItem.success_payment_dates
          : [],
      notes: '',
      reason_free: '',
    });
    const free = paymentItem.is_free === 1;
    setIsFree(free);
    setPaymentMethod(paymentItem.payment_type);
    setAmount(paymentItem.amount);
    setAllAmount(handleAmount(paymentItem));
    setServiceType(t(`${paymentItem.service_type.toLowerCase()}`));
  }, [paymentItem]);

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  const getDateString = (date) => {
    return (
      (new Date(date).getMonth() < 10
        ? '0' + parseInt(new Date(date).getMonth() + 1, 10)
        : parseInt(new Date(date).getMonth() + 1, 10)) +
      '/' +
      (new Date(date).getDate() < 10
        ? '0' + new Date(date).getDate()
        : new Date(date).getDate()) +
      '/' +
      new Date(date).getFullYear() +
      ' ' +
      (new Date(date).getHours() < 10
        ? '0' + new Date(date).getHours()
        : new Date(date).getHours()) +
      ':' +
      (new Date(date).getMinutes() < 10
        ? '0' + new Date(date).getMinutes()
        : new Date(date).getMinutes())
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        minHeight: '100vh',
        p: 2,
      }}
    >
      <Container maxWidth={false}>
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>

        <Grid container>
          <Grid item md={9} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid item xl={6} md={6} xs={6}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('order_number')}: {formik.values.order_number}
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('customer')}
                  </Typography>
                  <TextField
                    label=""
                    disabled
                    className={classes.search}
                    name="customer_name"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    value={formik.values.customer_name}
                  />
                </Grid>
                <Grid item xs={6} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('phone')}
                  </Typography>
                  <InputMask
                    disabled
                    size="small"
                    className={classes.inputmask}
                    onChange={formik.handleChange}
                    name="phone"
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="+998 (_ _) _ _ _ - _ _ - _ _"
                    mask="+\9\98 (99) 999-99-99"
                    maskChar="_"
                    style={{
                      border: '1px solid #c4c4c4',
                      outline: '1px solid #F7F8FA',
                      paddingLeft: '14px',
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </FormHelperText>
                </Grid>
              </Grid>
              {amount !== 0 ? (
                <Grid container spacing={2} my={1}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('payment_date')}
                    </Typography>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={localeMap[language]}
                    >
                      <Stack spacing={3}>
                        <DatePicker
                          disabled={amount === 0}
                          name="payed_date"
                          // openTo="year"
                          mask="__/__/____"
                          views={['year', 'month', 'day']}
                          // minDate={new Date()}
                          value={formik.values.payed_date}
                          onChange={(e) =>
                            formik.setFieldValue('payed_date', e)
                          }
                          error={
                            formik.touched.payed_date &&
                            Boolean(formik.errors.payed_date)
                          }
                          InputProps={{
                            style: {
                              backgroundColor: theme.palette.background.paper,
                            },
                          }}
                          InputLabelProps={{
                            style: { color: 'black' },
                          }}
                          helperText={
                            formik.touched.payed_date &&
                            formik.errors.payed_date
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={2} my={1}>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('service_type')}: {serviceType}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        gap: 4,
                      }}
                    >
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            height: '100%',
                            backgroundColor: '#E5EEF7',
                            color: colors.grey[700],
                          }}
                          variant="contained"
                          onClick={() => {}}
                        >
                          {formik.values.service_name}
                        </Button>
                      </Grid>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
              {amount !== 0 ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} sm={12}>
                    <FormControl sx={{ width: '100%' }}>
                      <Typography
                        my={1}
                        variant="h5"
                        align="left"
                        color={colors.grey[700]}
                      >
                        {t('payment_method')}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 1,
                        }}
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <Button
                            fullWidth
                            sx={{
                              textTransform: 'none',
                              backgroundColor:
                                paymentMethod === 'clickuz'
                                  ? colors.white
                                  : '#fff',
                              color: '#000',
                            }}
                            variant="contained"
                            onClick={() => {
                              setPaymentMethod('clickuz');
                            }}
                          >
                            {t('Click')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <Button
                            fullWidth
                            sx={{
                              textTransform: 'none',
                              backgroundColor:
                                paymentMethod === 'paymeuz'
                                  ? colors.white
                                  : '#fff',
                              color: '#000',
                            }}
                            variant="contained"
                            onClick={() => {
                              setPaymentMethod('paymeuz');
                            }}
                          >
                            {t('Payme')}
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <Button
                            fullWidth
                            sx={{
                              textTransform: 'none',
                              backgroundColor:
                                paymentMethod === 'cash'
                                  ? colors.white
                                  : '#fff',
                              color: '#000',
                            }}
                            variant="contained"
                            onClick={() => {
                              setPaymentMethod('cash');
                            }}
                          >
                            {t('cash')}
                          </Button>
                        </Grid>
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}

              <Grid item xl={12} md={12} xs={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('description')}
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="notes"
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  label={t('enter_notes')}
                  value={formik.values.notes}
                />
              </Grid>
              <Grid item xl={6} md={6} xs={6}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('sub_total')}
                </Typography>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {amount === 0
                    ? allAmount +
                      ' ' +
                      t('sum') +
                      '; ' +
                      t('discount') +
                      ' - ' +
                      formik.values.discount
                    : formik.values.amount +
                      ' ' +
                      t('sum') +
                      '; ' +
                      t('discount') +
                      ' - ' +
                      formik.values.discount}
                </Typography>
              </Grid>

              {/* {formik.values.success_payment_dates.map((item, index) => {
                return (
                  <Grid key={`${index + 1}`} item md={12} xs={12}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {`${index + 1}`}. {t('payment')}:{' '}
                      {item.amount + ' ' + t('sum')}; &nbsp; {t('date')}:{' '}
                      {getDateString(item.date)}; &nbsp;
                      {t('payment_type')}: {t(item.payment_type)}
                    </Typography>
                  </Grid>
                );
              })} */}
              <Grid container spacing={2}>
                <Grid hidden item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('promo')}
                  </Typography>
                  <TextField
                    disabled={amount === 0}
                    className={classes.search}
                    name="promo"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    value={formik.values.promo}
                  />
                </Grid>
                {amount !== 0 ? (
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('amount')}
                    </Typography>
                    <TextField
                      className={classes.search}
                      name="amount"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        setAmount(event.target.value);
                      }}
                      variant="outlined"
                      value={amount}
                      type="number"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {amount > formik.values.amount
                        ? t('enter_large_amount')
                        : ''}
                    </FormHelperText>
                  </Grid>
                ) : null}
              </Grid>
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={amount === 0}
                      name="is_coach"
                      checked={isFree}
                      value={isFree}
                      onChange={() => {
                        setIsFree(!isFree);
                      }}
                      color="success"
                    />
                  }
                  variant="standard"
                  label={t('is_free')}
                />
              </Grid>
              {isFree ? (
                <Grid item xl={12} md={12} xs={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('reason_free')}
                  </Typography>
                  <TextField
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    name="reason_free"
                    fullWidth
                    multiline
                    rows={4}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder={t('enter_reason_free')}
                    value={formik.values.reason_free}
                  />
                </Grid>
              ) : null}
              {formik.values.success_payment_dates.length !== 0 ? (
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('old_pay')}
                  </Typography>
                </Grid>
              ) : null}
              <Table data={formik.values.success_payment_dates} />
              {/* {amount !== 0 ? (
                <Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_send_message"
                        checked={isSendMessage}
                        value={isSendMessage}
                        onChange={() => {
                          setIsSendMessage(!isSendMessage);
                        }}
                        color="success"
                      />
                    }
                    variant="standard"
                    label={t('is_send_message')}
                  />
                </Grid>
              ) : null} */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={onClose}
                >
                  {t('cancel')}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => setIsSubmitting(true)}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(state, isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
