import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  colors,
  FormHelperText,
  Typography,
  CircularProgress,
  TextField,
  Autocomplete,
} from '@mui/material';
import Container from '@mui/material/Container';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import jwtDecode from 'jwt-decode';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../../components/Loading';
import { useStyles } from './style';
import { IOSSwitch } from '../../../../components/GlobalStyles';
import topicTest from '../../../../services/topic_tests';
import scienceTest from '../../../../services/science_tests';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const language = useSelector((state) => state.langsReducer.language);

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scienceId, setScienceId] = useState();

  const [scienceTestList, setScienceTestList] = useState([]);
  const [topicList, setTopicList] = useState([]);

  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [topicId, setTopicId] = useState();

  const { id } = useParams();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      science: null,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const item = {
        uz: values.name,
        ru: values.name,
      };
      const data = new FormData();
      data.append('sciences', scienceId);
      data.append('name', JSON.stringify(item));
      data.append('status', 1);

      if (id) {
        topicTest
          .update(id, data)
          .then((res) => {
            setLoading(false);
            navigate('/tests');
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      } else {
        topicTest
          .create(data)
          .then((res) => {
            setLoading(false);
            navigate('/tests');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const getSciences = (ids) => {
    setLoading(true);
    const query = 'status=1';
    scienceTest
      .getAll(query)
      .then((res) => {
        setScienceTestList(res);

        if (ids) {
          setScienceId(ids);
          const arr = res.filter((item) => item.science_id === ids);
          if (arr.length > 0) {
            formik.setFieldValue('science', arr[0]);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadingData = (id) => {
    setLoading(true);
    topicTest
      .getOne(id)
      .then((resp) => {
        formik.setValues({
          ...formik.values,
          name: resp.name.uz,
        });

        setLoading(false);
        getSciences(resp.sciences.science_test_id);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (id) {
      loadingData(id);
    } else {
      getSciences();
    }
  }, [id]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        height: '100vh',
        p: 3,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {id ? t('edit') : t('add')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} my={1}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} mb={6}>
              <Grid item xs={12} md={12} sm={12}>
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box1"
                  freeSolo
                  clearOnBlur
                  name="science"
                  className={classes.search}
                  value={formik.values.science}
                  options={scienceTestList}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setScienceId(newValue.science_id);
                      formik.setFieldValue('science', newValue);
                    }
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option.name.uz;
                    }

                    // Regular option
                    return option.name.uz;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name.uz}</li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Fanni tanlang')}
                      InputLabelProps={{
                        style: { color: 'black' },
                      }}
                      InputProps={{
                        style: {
                          backgroundColor: theme.palette.background.paper,
                        },
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.science &&
                    formik.touched.science &&
                    formik.errors.science}
                </FormHelperText>
              </Grid>

              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  size="small"
                  name="name"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  label={t('Nomni kiriting')}
                  placeholder={t('Nomni kiriting')}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => navigate('/app/tests')}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => console.log()}
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size="0.9rem" />
                  ) : undefined
                }
              >
                {renderButton(id, formik.isSubmitting)}
              </Button>
            </Box>
          </form>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
