import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import InputMask from 'react-input-mask';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';
import TimePicker from '@mui/lab/TimePicker';
import IconButton from '@mui/material/IconButton';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  CardMedia,
  colors,
  FormHelperText,
  Switch,
  CircularProgress,
  FormControlLabel,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import MultiChipSelect from '../../../../components/FormElements/MultiSelect';
import { IOSSwitch } from '../../../../components/FormElements/IOSSwitch/style';
import locations from '../../../../services/locations';
import customers from '../../../../services/customers';
import styles from './style.module.css';
import { useStyles } from './style';
import UploadIcon from '../../../../assets/icons/upload.svg';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function valuetext(value) {
  return `${value}`;
}

export default () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [classList, setClassList] = useState([]);
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const { t } = useTranslation();
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 40,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#2A455F' : '#2A455F',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 17,
      height: 17,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? 'grey' : 'grey',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t('required')),
    last_name: Yup.string().required(t('required')),
    phone: Yup.string().required(t('required')),
    gender: Yup.string().required(t('required')),
    date_birth: Yup.date().required(t('required')),
    joined_date: Yup.date().required(t('required')),
    notes: Yup.string().required(t('required')),
    locations: Yup.array().min(1, t('required')),
    packages: Yup.array().min(1, t('required')),
  });

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone: '',
      gender: '',
      date_birth: new Date(),
      joined_date: new Date(),
      locations: [],
      packages: [],
      notes: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append('locations', JSON.stringify(values.locations));
      // data.append('classes', JSON.stringify(values.classes));
      data.append('packages', JSON.stringify(values.packages));
      data.append('first_name', values.first_name);
      data.append('last_name', values.last_name);
      data.append(
        'phone',
        values.phone
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll(' ', '')
          .replaceAll('-', '')
      );
      data.append('photo', photo);
      data.append('gender', values.gender);
      data.append('date_birth', values.date_birth);
      data.append('joined_date', values.joined_date);
      data.append('notes', values.notes);
      data.append('status', status ? 1 : 0);

      if (state) {
        customers
          .update(state, data)
          .then((res) => {
            navigate('/app/customers');
          })
          .catch((err) => console.log(err));
      } else {
        customers
          .create(data)
          .then((res) => {
            navigate('/app/customers');
          })
          .catch((err) => console.log(err));
      }
    },
  });

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
    }
  };

  const uploadImg = () => {
    document.getElementById('selectFile').click();
  };

  const changeImg = () => {
    setPreviewImg('');
  };

  const getByLocationId = (id) => {
    // packages
    //   .getAll(id)
    //   .then((ress) => {
    //     if (ress.results.length === 0) {
    //       setClassList([]);
    //       setSnackbarMessage('Empty class list');
    //       setOpen(true);
    //     } else {
    //       setClassList(ress.results);
    //     }
    //   })
    //   .catch((err) => {
    //     try {
    //       if (err.response.status === 401) {
    //         setTimeout(() => {
    //           getByLocationId(id);
    //         }, 5000);
    //       }
    //     } catch (error) {
    //       //
    //     }
    //   });
  };

  useEffect(() => {}, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const handleChangeChips = (event) => {
    if (event.target.name === 'locations') {
      const arr = selectedLocation.map((item) => item.id);
      event.target.value.forEach((item) => {
        const index = arr.indexOf(item.id);
        if (index < 0) {
          selectedLocation.push({ id: item.id, name: item.name });
        }
      });
      setSelectedLocation(selectedLocation);
      formik.setFieldValue(
        'locations',
        selectedLocation.map((item) => item.id)
      );
      const query = `status=1&locations=${selectedLocation
        .map((item) => item.id)
        .join('&locations=')}`;

      getByLocationId(query);
    } else if (event.target.name === 'packages') {
      const arr = selectedClass.map((item) => item.id);
      event.target.value.forEach((item) => {
        const index = arr.indexOf(item.id);
        if (index < 0) {
          selectedClass.push({ id: item.id, name: item.name });
        }
      });
      setSelectedClass(selectedClass);
      formik.setFieldValue(
        'packages',
        selectedClass.map((item) => item.id)
      );
    }
  };

  const handleDeleteChipsLocation = (name, id) => {
    setSelectedLocation((Locations) =>
      Locations.filter((chip) => chip.name !== name)
    );
    const locations = [];
    formik.values.locations.forEach((item) => {
      if (id !== item) {
        locations.push(item);
      }
    });

    formik.setFieldValue('locations', locations);
  };

  const handleDeleteChipsClass = (name, id) => {
    setSelectedClass((Classes) => Classes.filter((chip) => chip.name !== name));
    const classes = [];
    formik.values.packages.forEach((item) => {
      if (id !== item) {
        classes.push(item);
      }
    });

    formik.setFieldValue('packages', classes);
  };

  const handleGender = (event) => {
    formik.setFieldValue('gender', event.target.name);
  };

  const locationSchema = locationList.map((item) => ({
    name: item.location_name,
    id: item.location_id,
  }));

  const classSchema = classList.map((item) => ({
    name: item.package_name,
    id: item.package_id,
  }));

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        minHeight: '100vh',
        p: 3,
      }}
    >
      <Container>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {state ? t('members.edit_member') : t('members.add_member')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label="Status of availability"
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={9} xs={12}>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('location')}
                  </Typography>
                  <FormControl size="small" sx={{ width: '100%' }}>
                    <MultiChipSelect
                      name="locations"
                      items={locationSchema}
                      deleteChips={handleDeleteChipsLocation}
                      handleChangeChips={handleChangeChips}
                      selectedItems={selectedLocation}
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.locations &&
                        formik.touched.locations &&
                        formik.errors.locations}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('members.first_name')}
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="first_name"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder="Pleace enter first name..."
                    value={formik.values.first_name}
                    error={
                      formik.touched.first_name &&
                      Boolean(formik.errors.first_name)
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.first_name &&
                      formik.touched.first_name &&
                      formik.errors.first_name}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('members.last_name')}
                  </Typography>
                  <TextField
                    className={classes.search}
                    name="last_name"
                    fullWidth
                    size="small"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    variant="outlined"
                    placeholder="Pleace enter last name..."
                    value={formik.values.last_name}
                    error={
                      formik.touched.last_name &&
                      Boolean(formik.errors.last_name)
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.last_name &&
                      formik.touched.last_name &&
                      formik.errors.last_name}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} my={1}>
                <Grid item xl={6} md={6} xs={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('date_of_birth')}
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3}>
                      <DatePicker
                        name="date_birth"
                        // openTo="year"
                        mask="__/__/____"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.date_birth}
                        onChange={(e) => formik.setFieldValue('date_birth', e)}
                        error={
                          formik.touched.date_birth &&
                          Boolean(formik.errors.date_birth)
                        }
                        InputProps={{
                          style: {
                            backgroundColor: theme.palette.background.paper,
                          },
                        }}
                        InputLabelProps={{
                          style: { color: 'black' },
                        }}
                        helperText={
                          formik.touched.date_birth && formik.errors.date_birth
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                  <FormControl sx={{ width: '100%' }}>
                    <Typography
                      my={1}
                      variant="h5"
                      align="left"
                      color={colors.grey[700]}
                    >
                      {t('gender')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 4,
                      }}
                    >
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          name="male"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            height: '100%',
                            backgroundColor:
                              formik.values.gender === 'male'
                                ? '#E5EEF7'
                                : colors.white,
                            color: colors.grey[700],
                          }}
                          variant="contained"
                          onClick={handleGender}
                        >
                          {t('male')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={6} sm={6}>
                        <Button
                          name="female"
                          fullWidth
                          sx={{
                            textTransform: 'none',
                            backgroundColor:
                              formik.values.gender === 'female'
                                ? '#E5EEF7'
                                : colors.white,
                            color: colors.grey[700],
                          }}
                          variant="contained"
                          onClick={handleGender}
                        >
                          {t('female')}
                        </Button>
                      </Grid>
                    </Box>
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.gender &&
                        formik.touched.gender &&
                        formik.errors.gender}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} my={1}>
                <Grid item xs={6} md={6} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('phone')}
                  </Typography>
                  <InputMask
                    size="small"
                    className={classes.inputmask}
                    onChange={formik.handleChange}
                    name="phone"
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="+998 (_ _) _ _ _ - _ _ - _ _"
                    mask="+\9\98 (99) 999-99-99"
                    maskChar="_"
                    style={{
                      border: '1px solid #c4c4c4',
                      outline: '1px solid #F7F8FA',
                      paddingLeft: '14px',
                    }}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.phone &&
                      formik.touched.phone &&
                      formik.errors.phone}
                  </FormHelperText>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {t('joined_date')}
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.search}>
                      <DatePicker
                        name="join_date"
                        openTo="year"
                        mask="__/__/____"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.join_date}
                        onChange={(e) => formik.setFieldValue('join_date', e)}
                        error={
                          formik.touched.join_date &&
                          Boolean(formik.errors.join_date)
                        }
                        helperText={
                          formik.touched.join_date && formik.errors.join_date
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} sm={12} my={4}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('Packages')}
                </Typography>
                <FormControl size="small" sx={{ width: '100%' }}>
                  <MultiChipSelect
                    name="packages"
                    items={classSchema}
                    deleteChips={handleDeleteChipsClass}
                    handleChangeChips={handleChangeChips}
                    selectedItems={selectedClass}
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.packages &&
                      formik.touched.packages &&
                      formik.errors.packages}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xl={12} md={12} xs={12}>
                <Typography
                  my={1}
                  variant="h5"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('description')}
                </Typography>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name="notes"
                  fullWidth
                  multiline
                  rows={4}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder="Please write street..."
                  value={formik.values.notes}
                />
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#CFE0F0',
                    color: '#161616',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  onClick={() => navigate('/app/customers')}
                >
                  {t('cancel')}
                </Button>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => console.log()}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(state, formik.isSubmitting)}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
