import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
  Snackbar,
  Stack,
  InputLabel,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MuiAlert from '@mui/material/Alert';
import jwtDecode from 'jwt-decode';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import Grid from '@mui/material/Grid';
import { Filter as FilterIcon, Search as SearchIcon } from 'react-feather';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/Loading';
import { toolTips } from './helper';
import { useStyles } from './style';
import Table from '../../../../components/Table';
import { controlsHeader } from '../../../../redux/modules/table/common';
import { headerMaker } from '../../../../components/Table/helper';
import { fetchData } from '../../../../redux/modules/customers/actions';
import { setTotals } from '../../../../redux/modules/store/actions';
import { useQuery } from '../../../useQuery';
import locations from '../../../../services/locations';
import customerApi from '../../../../services/customers';
import classApi from '../../../../services/class';
import lessonApi from '../../../../services/lessons';
import { weekDayNames } from '../../../../constants';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({ ...rest }) => {
  const classes = useStyles();
  const {
    query,
    search,
    status,
    setSearch,
    setStatus,
    handleOnTableChange,
    setLocationId,
  } = useQuery({ fetchData });

  const dispatch = useDispatch();
  const theme = useTheme();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const [locationList, setLocationList] = useState([]);
  const [locationIds, setLocationIds] = useState('');
  const [classIds, setClassIds] = useState('');
  const [classList, setClassList] = useState([]);
  const [customers, setCustomers] = useState([]);
  const { data, total } = useSelector((state) => state.customersReducer);

  const language = useSelector((state) => state.langsReducer.language);

  const [loading, setLoading] = useState(false);
  const customerItem = useSelector((state) => state.ordersReducer.item);

  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [lessonData, setLessonData] = useState([]);
  const [lessonIndex, setLessonIndex] = useState(0);

  const [dateTime, setDateTime] = useState(new Date());

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const curr = addHoursToDate(new Date(), 5);
  const first = curr.getDate();
  const last = first - 6;

  const [firstday, setFirstDay] = useState(
    new Date(curr.setDate(first)).toISOString()
  );
  const [lastday, setLastDay] = useState(
    new Date(curr.setDate(last)).toISOString()
  );

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const getCustomerByLocationId = (id) => {
    setLoading(true);
    setLocationIds(id);
    localStorage.setItem('locationIds', id);
    classApi
      .getAll('status=1&locations=' + id)
      .then((ress) => {
        if (ress.results.length === 0) {
          // setSnackbarMessage('Empty class list');
          // setOpen(true);
        } else {
          setClassList(ress.results);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const getData = () => {
    const query =
      'locations=' +
      localStorage.getItem('locationIds') +
      '&classes=' +
      localStorage.getItem('classIds') +
      '&date_time=' +
      localStorage.getItem('dateTime') +
      '&lesson=' +
      JSON.stringify([JSON.parse(localStorage.getItem('lesson'))]);
    customerApi
      .getAll(query)
      .then((ress) => {
        if (ress.results.data.length === 0) {
          // setSnackbarMessage('Empty class list');
          // setOpen(true);
          setCustomers([]);
        } else {
          const filtered = ress.results.data.map((item, i) => ({
            index: i + 1,
            id: item,
            locations: item.locations,
            name: item.user_id.name,
            phone: item.user_id.phone,
            customer: item,
            status: item.status,
            absent: item,
          }));
          setCustomers(filtered.sort((a, b) => a.name.localeCompare(b.name)));
          localStorage.setItem('total_data', ress.count);
        }
      })
      .catch((err) => console.log(err));
  };
  const headers = useMemo(
    () => headerMaker(controlsHeader, getData),
    [controlsHeader]
  );

  const getCustomerByClassId = (id) => {
    setLoading(true);
    setClassIds(id);
    localStorage.setItem('classIds', id);
    const query1 = `${'locations=' + locationIds}`;

    lessonApi
      .getAll(query1 + '&classes=' + id)
      .then((les) => {
        console.log(les);
        if (
          les.results[0][weekDayNames[dateTime.getDay() - 1].replace('_', '')]
            .length !== 0
        ) {
          setLessonIndex(0);
          setLessonData(
            les.results[0][weekDayNames[dateTime.getDay() - 1].replace('_', '')]
          );
          localStorage.setItem(
            'lesson',
            JSON.stringify(
              les.results[0][
                weekDayNames[dateTime.getDay() - 1].replace('_', '')
              ][0]
            )
          );
          const query2 =
            'locations=' +
            locationIds +
            '&classes=' +
            id +
            '&date_time=' +
            dateTime.toISOString() +
            '&lesson=' +
            JSON.stringify([
              les.results[0][
                weekDayNames[dateTime.getDay() - 1].replace('_', '')
              ][0],
            ]);
          customerApi
            .getAll(query2)
            .then((ress) => {
              if (ress.results.data.length === 0) {
                // setSnackbarMessage('Empty customer list');
                // setOpen(true);
                setCustomers([]);
              } else {
                const filtered = ress.results.data.map((item, i) => ({
                  index: i + 1,
                  id: item,
                  locations: item.locations,
                  name: item.user_id.name,
                  phone: item.user_id.phone,
                  customer: item,
                  status: item.status,
                  absent: item,
                }));
                setCustomers(
                  filtered.sort((a, b) => a.name.localeCompare(b.name))
                );
                localStorage.setItem('total_data', ress.count);
              }
            })
            .catch((err) => console.log(err));
        } else {
          // setSnackbarMessage('Empty lesson list');
          // setOpen(true);
          setCustomers([]);
          setLessonData([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const getCustomerByLesson = (id) => {
    setLoading(true);
    setLessonIndex(id);

    localStorage.setItem('lesson', JSON.stringify(lessonData[id]));
    const query2 =
      'locations=' +
      locationIds +
      '&classes=' +
      classIds +
      '&date_time=' +
      dateTime.toISOString() +
      '&lesson=' +
      JSON.stringify([lessonData[id]]);
    customerApi
      .getAll(query2)
      .then((ress) => {
        if (ress.results.data.length === 0) {
          // setSnackbarMessage('Empty customer list');
          // setOpen(true);
          setCustomers([]);
        } else {
          const filtered = ress.results.data.map((item, i) => ({
            index: i + 1,
            id: item,
            locations: item.locations,
            name: item.user_id.name,
            phone: item.user_id.phone,
            customer: item,
            status: item.status,
            absent: item,
          }));
          setCustomers(filtered.sort((a, b) => a.name.localeCompare(b.name)));
          localStorage.setItem('total_data', ress.count);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const loadingData = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationList(res.results);
        const locationIds = [];
        res.results.forEach((item) => {
          locationIds.push(item.location_id);
        });
        const query = `${locationIds.join('&locations=')}`;
        // setLocationId(query);

        // classApi
        //   .getAll(query)
        //   .then((ress) => {
        //     if (ress.results.length === 0) {
        //       setSnackbarMessage('Empty class list');
        //       setOpen(true);
        //     } else {
        //       setClassList(ress.results);
        //     }
        //   })
        //   .catch((err) => console.log(err));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    dispatch(setTotals(total));
    loadingData();
    localStorage.setItem('dateTime', dateTime.toISOString());
  }, [language]);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Helmet>
        <title> {t('controls')} </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 1,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>
          <Loading loading={loading} />
          <Typography
            variant="h3"
            color="textPrimary"
            gutterBottom
            mb={2}
            mt={2}
          >
            {t('controls')}
          </Typography>

          <Grid item xs={12} md={12} sm={12}>
            <FormControl
              size="small"
              sx={{ width: '100%', backgroundColor: 'white' }}
            >
              <InputLabel id="locations">{t('location')}</InputLabel>
              <Select
                labelId="locations"
                label={t('location')}
                className={classes.input}
                fullWidth
                name="locations"
                value={locationIds}
                onChange={(e) => getCustomerByLocationId(e.target.value)}
              >
                {locationList.map((item, index) => (
                  <MenuItem key={`${index + 1}`} value={item.location_id}>
                    {item.location_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} sm={12} my={2}>
            <FormControl
              size="small"
              sx={{ width: '100%', backgroundColor: 'white' }}
            >
              <InputLabel id="classes">{t('classes')}</InputLabel>
              <Select
                labelId="classes"
                label={t('classes')}
                className={classes.input}
                fullWidth
                name="classes"
                value={classIds}
                onChange={(e) => getCustomerByClassId(e.target.value)}
              >
                {classList.map((item, index) => (
                  <MenuItem key={`${index + 1}`} value={item.class_id}>
                    {item.class_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={12}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap[language]}
              >
                <Stack spacing={3}>
                  <DatePicker
                    label={t('date')}
                    name="date_time"
                    mask="__/__/____"
                    // openTo="year"
                    views={['day', 'month', 'year']}
                    maxDate={new Date()}
                    value={dateTime}
                    onChange={(e) => {
                      setLoading(true);
                      setDateTime(e);
                      const query1 = `${'locations=' + locationIds}`;

                      lessonApi
                        .getAll(query1 + '&classes=' + classIds)
                        .then((les) => {
                          if (
                            les.results[0][
                              weekDayNames[e.getDay() - 1].replace('_', '')
                            ].length !== 0
                          ) {
                            console.log(
                              les.results[0][
                                weekDayNames[e.getDay() - 1].replace('_', '')
                              ]
                            );
                            setLessonData(
                              les.results[0][
                                weekDayNames[e.getDay() - 1].replace('_', '')
                              ]
                            );
                            localStorage.setItem(
                              'lesson',
                              JSON.stringify(
                                les.results[0][
                                  weekDayNames[e.getDay() - 1].replace('_', '')
                                ][lessonIndex]
                              )
                            );

                            const query =
                              'locations=' +
                              locationIds +
                              '&classes=' +
                              classIds +
                              '&date_time=' +
                              e.toISOString() +
                              '&lesson=' +
                              JSON.stringify([
                                les.results[0][
                                  weekDayNames[e.getDay() - 1].replace('_', '')
                                ][lessonIndex],
                              ]);
                            localStorage.setItem('dateTime', e.toISOString());
                            customerApi
                              .getAll(query)
                              .then((ress) => {
                                if (ress.results.length === 0) {
                                  setSnackbarMessage('Empty customer list');
                                  setOpen(true);
                                } else {
                                  console.log(ress.results);
                                  const filtered = ress.results.data.map(
                                    (item, i) => ({
                                      index: i + 1,
                                      id: item,
                                      locations: item.locations,
                                      name: item.user_id.name,
                                      customer: item,
                                      notes: item.user_id.notes,
                                      status: item.status,
                                      absent: item,
                                    })
                                  );
                                  setCustomers(
                                    filtered.sort((a, b) =>
                                      a.name.localeCompare(b.name)
                                    )
                                  );
                                  localStorage.setItem(
                                    'total_data',
                                    ress.count
                                  );
                                }
                                setLoading(false);
                              })
                              .catch((err) => {
                                setLoading(false);
                                console.log(err);
                              });
                          } else {
                            setSnackbarMessage('Empty lesson list');
                            setOpen(true);
                            setCustomers([]);
                            setLessonData([]);
                            setLoading(false);
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          console.log(err);
                        });
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="lessons">{t('lessons')}</InputLabel>
                <Select
                  labelId="lessons"
                  label={t('lessons')}
                  className={classes.input}
                  fullWidth
                  name="lessons"
                  value={lessonIndex}
                  onChange={(e) => getCustomerByLesson(e.target.value)}
                >
                  {lessonData.map((item, index) => (
                    <MenuItem key={`${index + 1}`} value={index}>
                      {item.science.name} - {item.teacher.name} -
                      {' (' +
                        (new Date(item.start).getHours() < 10
                          ? '0' + new Date(item.start).getHours()
                          : new Date(item.start).getHours()) +
                        ':' +
                        (new Date(item.start).getMinutes() < 10
                          ? '0' + new Date(item.start).getMinutes()
                          : new Date(item.start).getMinutes()) +
                        ' - ' +
                        (new Date(item.end).getHours() < 10
                          ? '0' + new Date(item.end).getHours()
                          : new Date(item.end).getHours()) +
                        ':' +
                        (new Date(item.end).getMinutes() < 10
                          ? '0' + new Date(item.end).getMinutes()
                          : new Date(item.end).getMinutes()) +
                        ')'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} sm={12} my={1}>
            <div className={classes.navFilterSection}>
              <TextField
                className={classes.search}
                size="small"
                fullWidth
                // value={search}
                onChange={(e) => {
                  const query =
                    'search=' +
                    e.target.value +
                    '&locations=' +
                    locationIds +
                    '&classes=' +
                    classIds +
                    '&date_time=' +
                    dateTime.toISOString() +
                    '&lesson=' +
                    JSON.stringify([lessonData[lessonIndex]]);
                  customerApi
                    .getAll(query)
                    .then((ress) => {
                      console.log(ress);
                      if (ress.results.data.length === 0) {
                        // setSnackbarMessage('Empty customer list');
                        // setOpen(true);
                        setCustomers([]);
                      } else {
                        const filtered = ress.results.data.map((item, i) => ({
                          index: i + 1,
                          id: item,
                          locations: item.locations,
                          name: item.user_id.name,
                          customer: item,
                          notes: item.user_id.notes,
                          status: item.status,
                          absent: item,
                        }));
                        setCustomers(
                          filtered.sort((a, b) => a.name.localeCompare(b.name))
                        );
                        localStorage.setItem('total_data', ress.count);
                      }
                    })
                    .catch((err) => console.log(err));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                label={t('search')}
                variant="outlined"
              />
            </div>
          </Grid>

          <Box sx={{ pt: 2 }}>
            <div className={classes.root}>
              <Card {...rest}>
                <Table
                  data={customers}
                  headers={headers}
                  toolTips={toolTips}
                  onChange={handleOnTableChange}
                  modal={handleModal}
                  hidePagination={1}
                />
              </Card>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};
