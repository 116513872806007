import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export function useQuery({ fetchData }) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.langsReducer.language);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('-1');
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [locationId, setLocationId] = useState('');

  // query
  // const statusQuery = useMemo(
  //   () => (status ? `&status=${status}` : ''),
  //   [status]
  // );

  // const locationIdQuery = useMemo(
  //   () =>
  //     locationId !== 'all' && locationId ? `&location_id=${locationId}` : '',
  //   [locationId]
  // );

  // const locationIdQuery = useMemo(
  //   () =>
  //     locationId !== 'all' && locationId ? `&locations=${locationId}` : '',
  //   [locationId]
  // );

  // const paginationQuery = useMemo(
  //   () => (pageSize ? `&page_size=${pageSize}` : ''),
  //   [pageSize]
  // );

  const paginationQuery = useMemo(
    () =>
      `page=${
        search ? 1 : pageIndex + 1
      }&page_size=${pageSize}&status=${status}`,
    [pageIndex, pageSize, status, search]
  );

  const query = useMemo(() => `${paginationQuery}`, [paginationQuery]);

  const handleOnTableChange = ({ pageIndex, pageSize }) => {
    setPageIndex(pageIndex);
    setPageSize(pageSize);
  };

  useEffect(() => {
    dispatch(
      fetchData({
        pageIndex,
        pageSize,
        lng: language,
        isSearch: true,
        query: `${query}${locationId ? `&locations=${locationId}` : ''}${
          search ? '&search=' + search : ''
        }`,
      })
    );
    // eslint-disable-next-line
  }, [dispatch, search, language, locationId]);

  useEffect(() => {
    if (pageSize > 0) {
      dispatch(
        fetchData({
          pageIndex,
          pageSize,
          lng: language,
          isSearch: false,
          query: `${query}${locationId ? `&locations=${locationId}` : ''}${
            search ? `&search=${search}` : ''
          }`,
        })
      );
    }
    // eslint-disable-next-line
  }, [query, dispatch, language, locationId]);

  return {
    query,
    search,
    status,
    pageIndex,
    pageSize,
    locationId,
    setLocationId,
    setSearch,
    setStatus,
    handleOnTableChange,
  };
}
