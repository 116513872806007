import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { setOrderHistoryData } from '../../../../redux/modules/orders_history/actions';

export const toolTips = [
  {
    name: 'view_payment',
    icon: <VisibilityOutlinedIcon color="action" fontSize="small" />,
    onClick: (id, { dispatch, showBlured, load, modal }) => {
      dispatch(setOrderHistoryData(id));
      modal();
    },
  },
];
