import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { byStatus } from './helper';
import { useStyles } from '../style';
import FilterButton from '../../FormElements/FilterButton';

export default ({ status, setStatus }) => {
  const classes = useStyles();
  const [selectedFilter, setSelectedFilter] = useState(0);
  const { t } = useTranslation();

  const handleStatus = (e) => {
    setStatus(e.target.value);
    if (!status) {
      setSelectedFilter((prev) => prev + 1);
    }
  };
  const handleClearFilters = () => {
    setStatus('-1');
    setSelectedFilter(0);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.root}>
        <FormControl size="small">
          <InputLabel id="status"> {t('filter_by_status')}</InputLabel>
          <Select
            className={classes.input}
            value={status}
            onChange={handleStatus}
            labelId="status"
            label={t('filter_by_status')}
          >
            {byStatus.map((item, index) => (
              <MenuItem key={`${index + 1}`} value={item.value}>
                {t(`${item.name}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <FilterButton
        clearText={t('clear')}
        selectedFilter={selectedFilter}
        handleClearFilters={handleClearFilters}
      />
    </Box>
  );
};
