import { Box, Drawer, Hidden, List } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BarChart as BarChartIcon,
  LogOut as LogOutIcon,
  GitBranch as BranchIcon,
} from 'react-feather';
import PersonIcon from '@mui/icons-material/Person';
import HouseIcon from '@mui/icons-material/House';
import QuizIcon from '@mui/icons-material/Quiz';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InfoIcon from '@mui/icons-material/Info';
import AppsIcon from '@mui/icons-material/Apps';
import ArticleIcon from '@mui/icons-material/Article';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { useStyles } from './style';
import Item from './Item';

export default ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const classes = useStyles();

  const [isOpenService, setIsOpenService] = useState(false);

  const activeService =
    location.pathname.startsWith('/tests') ||
    location.pathname.startsWith('/impel') ||
    location.pathname.startsWith('/academic_app');

  const serviceSideMenu = [
    {
      index: 21,
      href: '/tests',
      icon: QuizIcon,
      title: 'Testlar',
    },
    {
      index: 22,
      href: '/impel',
      icon: LocalLibraryIcon,
      title: 'Impel',
    },
    {
      index: 23,
      href: '/academic_app',
      icon: DocumentScannerIcon,
      title: 'Akademik',
    },
  ];

  const [sideMenuItems, setSideMenu] = useState([
    {
      index: 1,
      href: '/',
      icon: HouseIcon,
      title: 'Asosiy',
    },
    {
      index: 5,
      href: '/app/dashboard',
      icon: AppsIcon,
      title: 'Platforma',
    },
    {
      index: 2,
      href: '/services',
      icon: MiscellaneousServicesIcon,
      title: 'Xizmatlar',
      childrenservice: serviceSideMenu,
    },
    {
      index: 3,
      href: '/articles',
      icon: ArticleIcon,
      title: 'Maqolalar',
    },
    {
      index: 4,
      href: '/about',
      icon: InfoIcon,
      title: 'Biz haqimizda',
    },
  ]);

  const accounts = [
    {
      index: 12,
      href: '/app/account',
      icon: PersonIcon,
      title: 'account',
    },
    // {
    //   index: 13,
    //   href: '/app/settings',
    //   icon: SettingsIcon,
    //   title: 'settings',
    // },
    {
      index: 14,
      href: '/login',
      icon: LogOutIcon,
      title: 'exit',
    },
  ];

  const handleColapse = (index) => {
    if (index === 2) {
      setIsOpenService(!isOpenService);
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    } else if (
      location.pathname === '/app/account' ||
      location.pathname === '/app/settings'
    ) {
      setSideMenu(accounts);
    }
  }, [location]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#000000',
        position: 'relative',
        height: openMobile ? 'calc(100% - 0px)' : 'calc(100% - 130px)',
        '&::-webkit-scrollbar': {
          width: 5,
        },
        '&::-webkit-scrollbar-track': {
          // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgrey',
          // outline: '1px solid slategrey',
          borderRadius: 2,
        },
      }}
      style={{
        // border: '1px solid black',
        overflow: 'auto',
        overflowY: 'scroll', // added scroll
      }}
    >
      <Box sx={{ pl: 0.5, paddingTop: 1 }}>
        <div className={classes.root}>
          <List>
            {sideMenuItems.map((item) => (
              <Item
                index={item.index}
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
                activeService={activeService}
                isOpenService={isOpenService}
                handleColapse={handleColapse}
                subitemsservice={item.childrenservice}
              />
            ))}
          </List>
        </div>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 238,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: '238px',
              top: 64,
              height: 'calc(100% + 64px)',
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};
