/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Box, Paper } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import * as Icon from 'react-feather';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#4d4d4d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default ({ data }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [datas, setDatas] = useState([]);

  const columns = [
    { id: 'number', label: '№', minWidth: 70 },
    {
      id: 'amount',
      label: t('amount'),
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'payment_type',
      label: t('payment_type'),
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'date',
      label: t('date'),
      minWidth: 170,
      align: 'left',
    },
    {
      id: 'description',
      label: t('description'),
      minWidth: 170,
      align: 'left',
    },
  ];

  const getDateString = (date) => {
    return (
      (new Date(date).getMonth() + 1 < 10
        ? '0' + parseInt(new Date(date).getMonth() + 1, 10)
        : parseInt(new Date(date).getMonth() + 1, 10)) +
      '/' +
      (new Date(date).getDate() < 10
        ? '0' + new Date(date).getDate()
        : new Date(date).getDate()) +
      '/' +
      new Date(date).getFullYear() +
      ' ' +
      (new Date(date).getHours() < 10
        ? '0' + new Date(date).getHours()
        : new Date(date).getHours()) +
      ':' +
      (new Date(date).getMinutes() < 10
        ? '0' + new Date(date).getMinutes()
        : new Date(date).getMinutes())
    );
  };

  useEffect(() => {
    console.log(data);
    if (data) {
      setDatas(data);
    }
  }, [data]);

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        // minHeight: '100vh',
        // p: 2,
      }}
    >
      <Container>
        <Paper
          sx={{
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                      }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {datas
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={`${i + 1}`}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.id === 'number') {
                            value = i + 1;
                          }
                          if (column.id === 'date') {
                            value = getDateString(row[column.id]);
                          }
                          return (
                            <StyledTableCell
                              key={column.id}
                              // align={
                              //     column.align
                              // }
                              style={{
                                cursor: 'pointer',
                              }}
                              onDoubleClick={() => {}}
                            >
                              {value}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={datas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={(event) => {
              setPage(0);
              setRowsPerPage(+event.target.value);
            }}
          />
        </Paper>
        <ToastContainer />
      </Container>
    </Box>
  );
};
