import { service } from '.';

export default {
  getAll: (query) => service.get(`/employee_title/?${query}`),
  getOne: (id) => service.get(`/employee_title/${id.employee_title_id}/`),
  getByLocationId: (query) =>
    service.get(`/employee_title/?locations=${query}`),
  create: (data) => service.post('/employee_title/', data),
  update: (id, data) =>
    service.patch(`/employee_title/${id.employee_title_id}/`, data),
  changeStatus: (id, data) =>
    service.patch(`/employee_title/status/${id}/`, data),
};
