import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import HouseIcon from '@mui/icons-material/House';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import InfoIcon from '@mui/icons-material/Info';
import AppsIcon from '@mui/icons-material/Apps';
import ArticleIcon from '@mui/icons-material/Article';
import { clearToken } from '../../redux/modules/auth/actions';

function NavBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { pathname } = useLocation();

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener('scroll', scrollHandler);

  const isAuthenticated = (token) => {
    try {
      const { exp } = jwtDecode(token);
      if (exp < (new Date().getTime() + 1) / 1000) {
        dispatch(clearToken());
        return false;
      }
    } catch (err) {
      dispatch(clearToken());
      return false;
    }
    return true;
  };

  const checkLogin = () => {
    const token = localStorage.getItem('token');
    if (token === null) {
      return false;
    }
    if (token === undefined) {
      return false;
    }

    return isAuthenticated(token);
  };

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? 'sticky' : 'navbar'}
    >
      <Container>
        {/* <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand> */}
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : 'expanded');
          }}
        >
          <span>{}</span>
          <span>{}</span>
          <span>{}</span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link
                onClick={() => {
                  navigate('/');
                  updateExpanded(false);
                }}
              >
                <HouseIcon style={{ marginBottom: '2px' }} /> Asosiy
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                onClick={() => {
                  navigate('/services');
                  updateExpanded(false);
                }}
              >
                <MiscellaneousServicesIcon style={{ marginBottom: '2px' }} />{' '}
                Xizmatlar
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                onClick={() => {
                  navigate('/articles');
                  updateExpanded(false);
                }}
              >
                <ArticleIcon style={{ marginBottom: '2px' }} /> Maqolalar
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                onClick={() => {
                  navigate('/about');
                  updateExpanded(false);
                }}
              >
                <InfoIcon style={{ marginBottom: '2px' }} /> Biz haqimizda
              </Nav.Link>
            </Nav.Item>

            {pathname !== '/login' ? (
              <Nav.Item>
                <Nav.Link
                  // as={Link}
                  // to={checkLogin() ? '/app/dashboard' : '/login'}
                  onClick={() => {
                    updateExpanded(false);
                    if (checkLogin()) {
                      navigate('/app/dashboard');
                    } else {
                      dispatch(clearToken());
                      navigate('/login');
                    }
                  }}
                >
                  <AppsIcon style={{ marginBottom: '2px' }} /> Platforma
                </Nav.Link>
              </Nav.Item>
            ) : null}

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume"
                onClick={() => updateExpanded(false)}
              >
                <CgFileDocument style={{ marginBottom: "2px" }} /> Resume
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link href="/" target="_self" rel="noreferrer">
                <ImBlog style={{ marginBottom: '2px' }} /> Blogs
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="fork-btn">
              <Button
                href="https://gitlab.com/actioncoder"
                target="_blank"
                className="fork-btn-inner"
              >
                <CgGitFork style={{ fontSize: '1.2em' }} />{' '}
                <AiFillStar style={{ fontSize: '1.1em' }} />
              </Button>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
