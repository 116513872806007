import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  InputLabel,
  Divider,
  CircularProgress,
  Chip,
} from '@mui/material';
import TelegramLoginButton from 'react-telegram-login';
import Pagination from '@mui/material/Pagination';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import jwtDecode from 'jwt-decode';
import Moment from 'react-moment';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
import 'quill-mention/dist/quill.mention.css';
import 'react-quill/dist/quill.snow.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../../../../components/Loading';
import {
  setSelectTests,
  setCoin,
} from '../../../../redux/modules/store/actions';
import { useStyles } from './style';
import ModalWindow from '../../../../components/Modals';
// import ChangeStatusModal from '../../../../components/Modals/ConfirmStatus';
import topicTest from '../../../../services/topic_tests';
import scienceTest from '../../../../services/science_tests';
import testsApi from '../../../../services/tests';
import authApi from '../../../../services/auth';
import CustomToolbar from '../../../../components/QuillEditor/CustomToolbar';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

Quill.register('modules/blotFormatter', BlotFormatter);

export default ({ ...rest }) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [test, setTest] = useState([]);

  const language = useSelector((state) => state.langsReducer.language);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [countPages, setCountPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);

  const [messages, setMessages] = useState([]);

  const [telegramAuth, setTelegramAuth] = useState(
    localStorage.getItem('token')
  );

  const { id } = useParams();

  const moduless1 = {
    toolbar: {
      container: '#toolbar-11',
    },
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize'],
    // },
    blotFormatter: {},
  };

  const formats = [
    'height',
    'width',
    'class',
    'style',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'indent',
    'list',
    'direction',
    'align',
    'link',
    'image',
    'video',
    'formula',
  ];

  const fetchMoreData = () => {
    testsApi
      .getForums('testId=' + id)
      .then((res) => {
        setMessages(res.forums);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validationSchema = Yup.object().shape({
    answer: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      answer: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const ans = values.answer;
      const checkAns = values.answer.replace(' ', '');

      if (checkAns === '<p></p>' || checkAns === '<p><br></p>') {
        setSnackbarMessage('Iltimos, matn kiriting');
        setOpen(true);
        formik.setSubmitting(false);
      } else {
        setLoading(true);

        const data = new FormData();
        data.append('test', id);
        data.append('answer', ans);

        testsApi
          .addForum(data)
          .then((res) => {
            setLoading(false);
            fetchMoreData();
            formik.setSubmitting(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const loadingData = () => {
    setLoading(false);
    testsApi
      .getOne('testId=' + id)
      .then((res) => {
        setTest(res.tests);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
    setLoading(false);
  };

  const handleChange = (event, value) => {
    console.log(value);
    setPage(value);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleTelegramResponse = (response) => {
    authApi
      .authTelegram(`id=${response.id}&name=${response.first_name}`)
      .then((res) => {
        setTelegramAuth('auth');
        dispatch(setCoin(res.data.coin));
      });
  };

  const renderButton = (isSubmitting) => {
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  useEffect(() => {
    loadingData();
    fetchMoreData();
  }, [language]);

  const handleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      <Helmet>
        <title>{t('Forum')}</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 3,
          px: 1,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>

          <Loading loading={loading} />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            mb={3}
            mt={2}
          >
            {t('Forum')}
          </Typography>

          <Grid container my={1}>
            {test.map((item, i) => (
              <Grid key={item.pk} item xs={12} md={12} sm={12}>
                <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                  <CardContent>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.fields.question.replace(
                          'numberNN',
                          (page - 1) * pageSize + i + 1 + '. '
                        ),
                      }}
                    />
                    <Divider />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.fields.option_a.replace('optionA', 'A). '),
                      }}
                    />
                    <Divider />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.fields.option_b.replace('optionB', 'B). '),
                      }}
                    />
                    <Divider />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.fields.option_c.replace('optionC', 'C). '),
                      }}
                    />
                    <Divider />
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.fields.option_d.replace('optionD', 'D). '),
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <div
            id="scrollableDiv"
            style={{
              height: messages.length === 0 ? 0 : 400,
              overflow: 'auto',
            }}
          >
            <InfiniteScroll
              dataLength={messages.length}
              next={fetchMoreData}
              hasMore={true}
              loader={
                messages.length === 0 ? <h4>Xabarlar topilmadi</h4> : null
              }
              scrollableTarget="scrollableDiv"
            >
              {messages.map((item, index) => (
                <Card
                  key={item.test_forum_id}
                  sx={{ marginRight: 1, marginBottom: 1 }}
                >
                  <Chip
                    variant="outlined"
                    color="primary"
                    icon={<AccountCircleIcon />}
                    label={item.sender}
                    sx={{ margin: 1 }}
                  />
                  <Chip
                    variant="outlined"
                    color="warning"
                    icon={<AccessTimeFilledIcon />}
                    label={
                      <Moment format="YYYY-MM-DD HH:mm">{item.createAt}</Moment>
                    }
                    sx={{ margin: 1 }}
                  />

                  <div
                    style={{
                      // height: messages.length * 100,
                      border: '1px solid green',
                      margin: 6,
                      padding: 8,
                    }}
                    key={`${index + 1}`}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: item.answer,
                    }}
                  />
                </Card>
              ))}
            </InfiniteScroll>
          </div>
          {telegramAuth === null ? (
            <TelegramLoginButton
              id="telegram_login"
              dataOnauth={handleTelegramResponse}
              botName={process.env.REACT_APP_TELEGRAM_BOT}
            />
          ) : (
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.black}
                >
                  {t('Javob yozish')}
                </Typography>
                <div key="answer">
                  <CustomToolbar key="answer1" id="11" />
                  <ReactQuill
                    value={formik.values.answer}
                    onChange={(e) => {
                      formik.setFieldValue('answer', e);
                    }}
                    className={classes.search}
                    modules={moduless1}
                    formats={formats}
                    theme="snow"
                  />
                </div>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                  py: 2,
                }}
              >
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: colors.white,
                    textTransform: 'none',
                  }}
                  name="add"
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => console.log()}
                  startIcon={
                    formik.isSubmitting ? (
                      <CircularProgress size="0.9rem" />
                    ) : undefined
                  }
                >
                  {renderButton(formik.isSubmitting)}
                </Button>
              </Box>
            </form>
          )}
        </Container>
      </Box>
    </>
  );
};
