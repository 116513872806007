import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import { React, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { PieChart } from 'react-minimal-pie-chart';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  width: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#2A455F' : '#308fe8',
  },
}));

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  width: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#72A1EF' : '#308fe8',
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  width: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#9CBDF4' : '#308fe8',
  },
}));

const BorderLinearProgress4 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  width: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#B8D0F7' : '#308fe8',
  },
}));

export default ({ rest }) => {
  // console.log(rest);
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(0);

  function makeTooltipContent(entry) {
    return `${entry ? entry.value : ''}`;
  }

  const count =
    rest.length === 0
      ? ''
      : rest
          .map((value) => value.customers)
          .reduce((acc, amount) => acc + amount);
  const colors = ['#2A455F', '#72A1EF', '#9CBDF4', '#B8D0F7'];
  const data = [];
  for (let i = 0; i < rest.length; i += 1) {
    data.push({
      title: rest[i].package_name,
      value: rest[i].customers,
      color: colors[i],
    });
  }
  const prog = [
    <BorderLinearProgress1 variant="determinate" value={100} />,
    <BorderLinearProgress2 variant="determinate" value={100} />,
    <BorderLinearProgress3 variant="determinate" value={100} />,
    <BorderLinearProgress4 variant="determinate" value={100} />,
  ];

  return (
    <Card sx={{ height: rest.length === 0 ? 110 : 220 }}>
      <CardHeader title={t('packages')} />
      {rest.length !== 0 ? (
        <CardContent sx={{ paddingTop: 0 }}>
          <Grid
            container
            // columnSpacing={{ xs: 1, sm: 2, md: 9 }}
            justifyContent="space-between"
          >
            <Grid item md={4} xs={6} sm={6}>
              <table>
                <tbody>
                  {rest.map((value, index) => {
                    return (
                      <tr key={`${index + 1}`}>
                        <td style={{ width: '20%', height: 35 }}>
                          {prog[index]}
                        </td>
                        <td style={{ width: '60%' }}>
                          <Typography variant="h6">
                            &nbsp;{' '}
                            {`${
                              value.package_name === 'others'
                                ? t('others')
                                : value.package_name
                            }: `}
                          </Typography>
                        </td>
                        <td style={{ width: '20%' }}>
                          <Typography variant="h6">
                            &nbsp;{`${value.customers}`}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Grid>
            <Grid
              item
              md={8}
              sm={6}
              xs={6}
              container
              justifyContent="flenx-end"
            >
              <div data-tip="" data-for="chart">
                <PieChart
                  style={{
                    top: 0,
                    // paddingLeft: 60,
                    width: '180px',
                    height: '150px',
                  }}
                  background="#bfbfbf"
                  lengthAngle={360}
                  radius={50}
                  lineWidth={30}
                  data={data}
                  label={({ x, y, dx, dy, dataEntry }) => (
                    <g key={dy + dataEntry.title}>
                      <text
                        x={x}
                        y={y}
                        dx={dx}
                        dy={dy - 5}
                        dominantBaseline="central"
                        textAnchor="middle"
                        style={{
                          fontSize: '15px',
                          fontFamily: 'sans-serif',
                          fill: '#000',
                        }}
                      >
                        <tspan x={x} dy={dy - 5}>
                          {count}
                        </tspan>
                      </text>
                      <text
                        x={x}
                        y={y}
                        dx={dx}
                        dy={dy}
                        dominantBaseline="central"
                        textAnchor="middle"
                        style={{
                          fontSize: '10px',
                          fontFamily: 'sans-serif',
                          fill: '#000',
                        }}
                      >
                        <tspan x={x} dy={dy + 6}>
                          {t('overall')}
                        </tspan>
                      </text>
                    </g>
                  )}
                  labelStyle={{
                    fontSize: '7px',
                    fontFamily: 'sans-serif',
                    fill: '#000',
                  }}
                  labelPosition={0}
                  onMouseOver={(_, index) => {
                    setHovered(index);
                  }}
                  onMouseOut={() => {
                    setHovered(null);
                  }}
                  rounded
                  animate
                />
                <ReactTooltip
                  id="chart"
                  getContent={() =>
                    typeof hovered === 'number'
                      ? makeTooltipContent(data[hovered])
                      : null
                  }
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardContent>
          <Typography variant="h6">{t('no_data')}</Typography>
        </CardContent>
      )}
    </Card>
  );
};
