import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  colors,
  FormHelperText,
  Typography,
  CircularProgress,
  TextField,
} from '@mui/material';
import 'quill-mention';
import Container from '@mui/material/Container';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import jwtDecode from 'jwt-decode';
import 'quill-mention/dist/quill.mention.css';
import 'react-quill/dist/quill.snow.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Loading from '../../../../components/Loading';
import { useStyles } from './style';
import locations from '../../../../services/locations';
import rooms from '../../../../services/rooms';
import { IOSSwitch } from '../../../../components/GlobalStyles';
import topicTest from '../../../../services/topic_tests';
import scienceTest from '../../../../services/science_tests';
import testsApi from '../../../../services/tests';
import CustomToolbar from '../../../../components/QuillEditor/CustomToolbar';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/blotFormatter', BlotFormatter);

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState([]);
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const [locationList, setLocationList] = useState([]);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [scienceTestList, setScienceTestList] = useState([]);
  const [topicList, setTopicList] = useState([]);

  const { t } = useTranslation();
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const [locationSchema, setLocationSchema] = useState([]);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const moduless1 = {
    toolbar: {
      container: '#toolbar-1',
    },
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize'],
    // },
    blotFormatter: {},
  };

  const moduless2 = {
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize'],
    // },
    blotFormatter: {},
    toolbar: {
      container: '#toolbar-2',
    },
  };

  const moduless3 = {
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize'],
    // },
    blotFormatter: {},
    toolbar: {
      container: '#toolbar-3',
    },
  };

  const moduless4 = {
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize'],
    // },
    blotFormatter: {},
    toolbar: {
      container: '#toolbar-4',
    },
  };

  const moduless5 = {
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize'],
    // },
    blotFormatter: {},
    toolbar: {
      container: '#toolbar-5',
    },
  };

  const formats = [
    'height',
    'width',
    'class',
    'style',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'indent',
    'list',
    'direction',
    'align',
    'link',
    'image',
    'video',
    'formula',
  ];

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required(t('required')),
    question: Yup.string().required(t('required')),
    option_a: Yup.string().required(t('required')),
    option_b: Yup.string().required(t('required')),
    option_c: Yup.string().required(t('required')),
    option_d: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      science: '',
      topic: '',
      question: '',
      option_a: '',
      option_b: '',
      option_c: '',
      option_d: '',
      answer: '0',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const data = new FormData();
      data.append('topic', values.topic);
      data.append('question', values.question);
      data.append('option_a', values.option_a);
      data.append('option_b', values.option_b);
      data.append('option_c', values.option_c);
      data.append('option_d', values.option_d);
      data.append('answer', values.answer);
      data.append('select_option', JSON.stringify([]));
      data.append('status', status ? 1 : 0);

      if (state) {
        testsApi
          .update(state, data)
          .then((res) => {
            setLoading(false);
            navigate('/app/tests');
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      } else {
        testsApi
          .create(data)
          .then((res) => {
            setLoading(false);
            navigate('/app/tests');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const getSciences = () => {
    setLoading(true);
    const query = 'status=1';
    scienceTest
      .getAll(query)
      .then((res) => {
        setScienceTestList(res.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              getSciences();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  const getTopicByScienceId = (id) => {
    formik.setFieldValue('science', id);
    setLoading(false);
    const query = 'status=1&sciences=' + id;
    topicTest
      .getAll(query)
      .then((res) => {
        setTopicList(res.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              getTopicByScienceId(id);
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
    setLoading(false);
  };

  const getTestsByTopicId = (id) => {
    formik.setFieldValue('topic', id);
    setLoading(false);
    const query = 'status=1&topic=' + id;
    testsApi
      .getAll(query)
      .then((res) => {
        // setTopicList(res.results);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              getTopicByScienceId(id);
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
    setLoading(false);
  };

  const loadingData = () => {
    setLoading(true);
    if (state) {
      testsApi
        .getOne(state)
        .then((resp) => {
          formik.setValues({
            ...formik.values,
            topic: resp.topic.topic_test_id,
            science: resp.topic.sciences,
            question: resp.question.replace('<strong>numberNN</strong>', ''),
            option_a: resp.option_a.replace('<strong>optionA</strong>', ''),
            option_b: resp.option_b.replace('<strong>optionB</strong>', ''),
            option_c: resp.option_c.replace('<strong>optionC</strong>', ''),
            option_d: resp.option_d.replace('<strong>optionD</strong>', ''),
            answer: resp.answer,
          });

          getTopicByScienceId(resp.topic.sciences);

          if (resp.status === 1) {
            setStatus(true);
          } else {
            setStatus(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    loadingData();
    getSciences();
  }, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        // height: '100vh',
        p: 3,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {state ? t('edit') : t('add')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} my={1}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3} mb={6}>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('science')}
                </Typography>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <Select
                    className={classes.input}
                    fullWidth
                    name="science"
                    value={formik.values.science}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.science && Boolean(formik.errors.science)
                    }
                    onChange={(e) => getTopicByScienceId(e.target.value)}
                  >
                    {scienceTestList.map((item, index) => (
                      <MenuItem
                        key={`${index + 1}`}
                        value={item.science_test_id}
                      >
                        {t(`${item.name[language]}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('topic')}
                </Typography>
                <FormControl
                  size="small"
                  sx={{ width: '100%', backgroundColor: 'white' }}
                >
                  <Select
                    className={classes.input}
                    fullWidth
                    name="topic"
                    value={formik.values.topic}
                    onBlur={formik.handleBlur}
                    error={formik.touched.topic && Boolean(formik.errors.topic)}
                    onChange={(e) => getTestsByTopicId(e.target.value)}
                  >
                    {topicList.map((item, index) => (
                      <MenuItem key={`${index + 1}`} value={item.topic_test_id}>
                        {t(`${item.name[language]}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.black}
                >
                  {t('question')}
                </Typography>
                <div key="question">
                  <CustomToolbar key="question" id="1" />
                  <ReactQuill
                    value={formik.values.question}
                    onChange={(e) => {
                      formik.setFieldValue('question', e);
                    }}
                    className={classes.search}
                    modules={moduless1}
                    formats={formats}
                    theme="snow"
                  />
                </div>
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.question &&
                    formik.touched.question &&
                    formik.errors.question}
                </FormHelperText>
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('option_a')}
                </Typography>
                <div key="option_a">
                  <CustomToolbar key="option_a" id="2" />
                  <ReactQuill
                    value={formik.values.option_a}
                    onChange={(e) => {
                      formik.setFieldValue('option_a', e);
                    }}
                    className={classes.search}
                    modules={moduless2}
                    formats={formats}
                    theme="snow"
                  />
                </div>
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.option_a &&
                    formik.touched.option_a &&
                    formik.errors.option_a}
                </FormHelperText>
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('option_b')}
                </Typography>
                <div key="option_b">
                  <CustomToolbar key="option_b" id="3" />
                  <ReactQuill
                    value={formik.values.option_b}
                    onChange={(e) => {
                      formik.setFieldValue('option_b', e);
                    }}
                    className={classes.search}
                    modules={moduless3}
                    formats={formats}
                    theme="snow"
                  />
                </div>
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.option_b &&
                    formik.touched.option_b &&
                    formik.errors.option_b}
                </FormHelperText>
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('option_c')}
                </Typography>
                <div key="option_c">
                  <CustomToolbar key="option_c" id="4" />
                  <ReactQuill
                    value={formik.values.option_c}
                    onChange={(e) => {
                      formik.setFieldValue('option_c', e);
                    }}
                    className={classes.search}
                    modules={moduless4}
                    formats={formats}
                    theme="snow"
                  />
                </div>
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.option_c &&
                    formik.touched.option_c &&
                    formik.errors.option_c}
                </FormHelperText>
              </Grid>
              <Grid item xl={12} md={12} xs={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t('option_d')}
                </Typography>
                <div key="option_d">
                  <CustomToolbar key="option_d" id="5" />
                  <ReactQuill
                    value={formik.values.option_d}
                    onChange={(e) => {
                      formik.setFieldValue('option_d', e);
                    }}
                    className={classes.search}
                    modules={moduless5}
                    formats={formats}
                    theme="snow"
                  />
                </div>
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.option_d &&
                    formik.touched.option_d &&
                    formik.errors.option_d}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.black}
                >
                  {t('answer_index')}
                </Typography>
                <TextField
                  size="small"
                  name="answer"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  variant="outlined"
                  placeholder={t('enter_answer')}
                  value={formik.values.answer}
                  error={formik.touched.answer && Boolean(formik.errors.answer)}
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  helperText={formik.touched.answer && formik.errors.answer}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => navigate('/app/tests')}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => console.log()}
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size="0.9rem" />
                  ) : undefined
                }
              >
                {renderButton(state, formik.isSubmitting)}
              </Button>
            </Box>
          </form>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
