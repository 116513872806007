import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { setOrderData } from '../../../../redux/modules/orders/actions';

export const toolTips = [
  {
    name: 'make_payment',
    icon: <AccountBalanceWalletOutlinedIcon color="action" fontSize="small" />,
    onClick: (id, { dispatch, showBlured, load, modal }) => {
      dispatch(setOrderData(id));
      modal();
    },
  },
];
