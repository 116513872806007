import axios from 'axios';

export default {
  getToken: (data) =>
    axios.post(process.env.REACT_APP_BASE_URL + '/login/', data, {
      headers: { 'Content-Type': 'application/json' },
    }),

  authTelegram: (query) =>
    axios.get(
      process.env.REACT_APP_BASE_URL.replace('/api/v1', '') +
        '/auth/telegram?' +
        query
    ),
  refreshToken: (data) =>
    axios.post(process.env.REACT_APP_BASE_URL + '/refresh_token/', data, {
      headers: { 'Content-Type': 'application/json' },
    }),

  removeToken: (data) =>
    axios.post(process.env.REACT_APP_BASE_URL + '/logout/', data, {
      headers: { 'Content-Type': 'application/json' },
    }),

  checkPhone: (data) =>
    axios.post(process.env.REACT_APP_BASE_URL + '/check_phone/', data, {
      headers: { 'Content-Type': 'application/json' },
    }),

  checkConfirmationCode: (data, accessToken) =>
    axios.post(
      process.env.REACT_APP_BASE_URL + '/check_confirmation_code/',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    ),

  resetPassword: (data, accessToken) =>
    axios.post(process.env.REACT_APP_BASE_URL + '/reset_password/', data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }),

  resend: (data) =>
    axios.post(process.env.REACT_APP_BASE_URL + '/resend/', data, {
      headers: { 'Content-Type': 'application/json' },
    }),
};
