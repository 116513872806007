export function dataSelector(dataResponse, page, size) {
  const handleNamer = (item) => {
    if (item.service_type === 'Booking') {
      return item.booking.service_name;
    }
    if (item.service_type === 'Package') {
      return item.package.package_name;
    }
    if (item.service_type === 'Class') {
      return item.classes.class_name;
    }
    return '';
  };
  const handleAmount = (item) => {
    if (item.service_type === 'Booking') {
      return item.booking.price;
    }
    if (item.service_type === 'Package') {
      return item.package.price;
    }
    if (item.service_type === 'Class') {
      return item.classes.price;
    }
    return 0;
  };

  const timeConvert = (time) => {
    if (time !== null) {
      const sp1 = time.split('T');
      const sp2 = sp1[1].split(':');
      const strTime = sp1[0] + ' ' + sp2[0] + ':' + sp2[1];
      return strTime;
    }
    return time;
  };

  const filtered = dataResponse.results.map((item, i) => ({
    index: i + 1 + page * size,
    id: item,
    customer_name: item.customer.user_id.fields.name,
    location: item.location,
    customer: item.customer,
    order_number: item.order_number,
    all_amount: handleAmount(item),
    discount: item.discount,
    amount: item.amount,
    state: item.amount === 0 ? 1 : 0,
    service_type: item.service_type,
    service_name: handleNamer(item),
    payment_type: item.payment_type,
    payment_date:
      (new Date(item.payment_date).getDate() < 10
        ? '0' + new Date(item.payment_date).getDate()
        : new Date(item.payment_date).getDate()) +
      '/' +
      (new Date(item.payment_date).getMonth() < 10
        ? '0' + (new Date(item.payment_date).getMonth() + 1)
        : new Date(item.payment_date).getMonth() + 1) +
      '/' +
      new Date(item.payment_date).getFullYear(),
    created_time: timeConvert(item.created_at),
  }));

  return { total: dataResponse.count, data: filtered };
}
