import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {
  Box,
  colors,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Snackbar,
  Stack,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/Loading';
import MultiChipSelect from '../../../../components/FormElements/MultiSelect';
import { useStyles } from './style';
import science from '../../../../services/science';
import locations from '../../../../services/locations';
import { IOSSwitch, Alert } from '../../../../components/GlobalStyles';

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const [status, setStatus] = useState(false);
  const { t } = useTranslation();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [locationSchema, setLocationSchema] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const validationSchema = Yup.object().shape({
    locations: Yup.array().min(1, t('required')),
    name: Yup.string().required(t('required')),
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      locations: [],
      name: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const data = new FormData();
      data.append('locations', JSON.stringify(values.locations));
      data.append('name', values.name);
      data.append('status', status ? 1 : 0);

      if (state) {
        science
          .update(state, data)
          .then((resp) => {
            setLoading(false);
            navigate('/app/science');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      } else {
        science
          .create(data)
          .then((resp) => {
            setLoading(false);
            navigate('/app/science');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const handleChangeChips = (event, values) => {
    console.log(values);

    setSelectedLocation(values);
    formik.setFieldValue(
      'locations',
      values.map((item) => item.id)
    );
  };

  const handleDeleteChips = (name, id) => {
    setSelectedLocation((Locations) =>
      Locations.filter((chip) => chip.name !== name)
    );
    formik.setFieldValue(
      'locations',
      formik.values.locations.map((item) => id !== item)
    );
  };

  const loadingData = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationList(res.results);

        setLocationSchema(
          res.results.map((item) => ({
            name: item.location_name,
            id: item.location_id,
          }))
        );

        if (state) {
          science
            .getOne(state)
            .then((resp) => {
              formik.setValues({
                ...formik.values,
                locations: resp.locations.map((item) => item.location_id),
                name: resp.name,
              });

              if (resp.status === 1) {
                setStatus(true);
              } else {
                setStatus(false);
              }

              const arr1 = [];

              res.results.forEach((value) => {
                resp.locations.forEach((item) => {
                  if (value.location_id === item.location_id) {
                    arr1.push({
                      id: value.location_id,
                      name: value.location_name,
                    });
                  }
                });
              });
              setSelectedLocation(arr1);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, []);

  const handleStatus = (e) => {
    setStatus(!status);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        height: '100vh',
        py: 1,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {state ? t('edit_science') : t('add_science')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12} my={1}>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} sm={12}>
                <FormControl size="small" sx={{ width: '100%' }}>
                  <MultiChipSelect
                    labelText={t('location')}
                    name="locations"
                    items={locationSchema}
                    deleteChips={handleDeleteChips}
                    handleChangeChips={handleChangeChips}
                    selectedItems={selectedLocation}
                    optionText={t('no_option')}
                    id="location"
                    error={
                      formik.touched.locations &&
                      Boolean(formik.errors.locations)
                    }
                  />
                  <FormHelperText
                    sx={{
                      color: colors.red[700],
                    }}
                  >
                    {formik.errors.locations &&
                      formik.touched.locations &&
                      formik.errors.locations}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <TextField
                  InputProps={{
                    style: {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  size="small"
                  name="name"
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  label={t('science')}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => navigate('/app/employee_title')}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => console.log()}
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size="0.9rem" />
                  ) : undefined
                }
              >
                {renderButton(state, formik.isSubmitting)}
              </Button>
            </Box>
          </form>
        </Grid>
        <ToastContainer />
      </Container>
    </Box>
  );
};
