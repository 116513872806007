import React from 'react';
import Container from '@mui/material/Container';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useStyles } from './style';

export default ({ handleModal, getData, yes, cancel, text }) => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Box className={classes.modalBody}>
          <Typography variant="h4" color="error">
            {text}
          </Typography>
        </Box>
        <Box className={classes.actionBtns}>
          <Button variant="outlined" color="error" onClick={handleModal}>
            {cancel}
          </Button>
          <Button variant="contained" onClick={getData}>
            {yes}
          </Button>
        </Box>
      </Container>
    </div>
  );
};
