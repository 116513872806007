import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Particle from '../Particle';
import Aboutcard from './AboutCard';
import laptopImg from '../../../assets/main/about.png';
import Toolstack from './Toolstack';

export default function Services() {
  const navigate = useNavigate();

  const linked = (url) => {
    navigate(url);
  };

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Bizning <strong className="purple">xizmatlar </strong>
        </h1>
        <Toolstack navigate={linked} />
      </Container>
    </Container>
  );
}
