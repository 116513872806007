export default {
  TABLE_COURSE_FETCH_STATUSES: 'TABLE_COURSE_FETCH_STATUSES',
  TABLE_COURSE_SET_DATA: 'TABLE_COURSE_SET_DATA',
  TABLE_COURSE_SET_TOTAL: 'TABLE_COURSE_SET_TOTAL',
  TABLE_COURSE_SET_LOADING: 'TABLE_COURSE_SET_LOADING',
  TABLE_COURSE_ERROR: 'TABLE_COURSE_ERROR',
  TABLE_COURSE_FETCH_DATA: 'TABLE_COURSE_FETCH_DATA',
  TABLE_COURSE_SAGA_ONSEARCH_ACTION:
    'TABLE_COURSE_SAGA_ONSEARCH_ACTION',
};
