import React from 'react';
import {
  colors,
  Box,
  Button,
  Card,
  Container,
  TextField,
  FormHelperText,
  Typography,
  Grid,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import homeLogo from '../../../assets/main/home-main.svg';
import Particle from '../Particle';
import Home2 from './Home2';
import Type from './Type';

export default () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        <Particle />
        <Row>
          <Col md={7} className="home-header">
            <h4 style={{ paddingTop: 15 }} className="heading">
              Assalomu alaykum, xush kelibsiz
              <span className="wave" role="img" aria-labelledby="wave">
                👋🏻
              </span>
            </h4>

            <h4 className="heading-name">
              <strong className="main-name"> Bizning xizmatlar</strong>
            </h4>

            <div
              style={{
                paddingLeft: 50,
                paddingBottom: 30,
                textAlign: 'left',
                fontSize: 14,
              }}
            >
              <Type />
            </div>
          </Col>

          {/* <Col md={5} style={{ paddingTop: 70 }}>
            <img
              src={homeLogo}
              alt="home pic"
              className="img-fluid"
              style={{ maxHeight: '450px' }}
            />
          </Col> */}
        </Row>
        <br />
        <Row>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              navigate('/login');
            }}
          >
            Login
          </Button>
        </Row>
      </Container>
    </Box>
  );
};
