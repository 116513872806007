import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  colors,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Divider,
  Chip,
  Modal,
  Snackbar,
  Stack,
  InputLabel,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import TimePicker from '@mui/lab/TimePicker';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { styled, useTheme } from '@mui/material/styles';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import MuiAlert from '@mui/material/Alert';
import { v1 as uuidv1 } from 'uuid';
import Loading from '../../../../components/Loading';
import MultiChipSelect from '../../../../components/FormElements/MultiSelect';
import ManySelect from '../../../../components/FormElements/ManySelect';
import customers from '../../../../services/customers';
import locations from '../../../../services/locations';
import employees from '../../../../services/employees';
import booking from '../../../../services/booking';
import { useStyles } from './style';
import UploadIcon from '../../../../assets/icons/upload.svg';
import { IOSSwitch } from '../../../../components/GlobalStyles';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const language = useSelector((state) => state.langsReducer.language);
  const { state } = useLocation();
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedStaffs, setSelectedStaffs] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [locationList, setLocationList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [previewImg, setPreviewImg] = useState();
  const [photo, setPhoto] = useState('');
  const [status, setStatus] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [isNavigate, setIsNavigate] = useState(true);

  const [locationSchema, setLocationSchema] = useState([]);
  const [employeeSchema, setEmployeeSchema] = useState([]);
  const [customerSchema, setCustomerSchema] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentIndex, setPaymentIndex] = useState(0);
  const [paymentIndexId, setPaymentIndexId] = useState(0);
  const [paymentDates, setPaymentDates] = useState([]);
  const repeatList = [
    {
      id: 1,
      name: 'Do not repeat',
    },
    {
      id: 2,
      name: 'Daily',
    },
    {
      id: 3,
      name: 'Weekly on Wednesday',
    },
    {
      id: 4,
      name: 'Every weekday (Monday to Friday)',
    },
  ];

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    alignItems: 'center',
    justifyContent: 'right',
    borderRadius: 2,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
    customers: Yup.array().min(1, t('required')),
    personnel: Yup.array().min(1, t('required')),
    service_name: Yup.string().required(t('required')),
    service_duration: Yup.string().required(t('required')),
    start: Yup.date().required(t('required')),
    end: Yup.date().required(t('required')),
    begin_date: Yup.date().required(t('required')),
    price: Yup.string().required(t('required')),
    repeat: Yup.number().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: '',
      service_name: '',
      service_duration: '',
      start: new Date(),
      end: new Date(),
      begin_date: new Date(),
      payment_dates: paymentDates,
      customers: [],
      personnel: [],
      price: '',
      repeat: 1,
      notes: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const data = new FormData();
      data.append('locations', values.locations);
      data.append('personnel', JSON.stringify(values.personnel));
      data.append('customers', JSON.stringify(values.customers));
      data.append('service_name', values.service_name);
      data.append('service_duration', values.service_duration);
      data.append('start', values.start.toISOString());
      data.append('end', values.end.toISOString());
      data.append('begin_date', values.begin_date.toISOString());
      data.append('price', values.price);
      data.append('payment_dates', JSON.stringify(paymentDates));
      data.append('notes', values.notes);
      data.append('repeat', values.repeat);
      data.append('photo', photo);
      data.append('status', status ? 1 : 0);

      if (state) {
        data.append('delete_payment_date', 'false');
        booking
          .update(state, data)
          .then((res) => {
            setLoading(false);
            if (isNavigate) {
              navigate('/app/booking');
            }
            setIsSubmitting(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      } else {
        booking
          .create(data)
          .then((res) => {
            setLoading(false);
            navigate('/app/booking');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err.response);
            formik.setSubmitting(false);
            setSnackbarMessage(t(err.response.data.error));
            setOpen(true);
          });
      }
    },
  });

  const getByLocationId = (id) => {
    setLoading(true);
    employees
      .getAll(id + '&is_coach=1')
      .then((resp) => {
        if (resp.results.length === 0) {
          // setSnackbarMessage('Empty staffs');
          // setOpen(true);
        } else {
          setEmployeeSchema(
            resp.results.map((item) => ({
              name: item.user_id.name,
              id: item.employee_id,
            }))
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              getByLocationId(id);
            }, 5000);
          }
        } catch (error) {
          //
        }
      });

    customers
      .getAll(id)
      .then((ress) => {
        if (ress.results.data.length === 0) {
          setLoading(false);
          // setSnackbarMessage('Empty packages');
          // setOpen(true);
        } else {
          setCustomerSchema(
            ress.results.data.map((item) => ({
              name: item.user_id.name,
              id: item.customer_id,
            }))
          );
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handleChangeLocationChips = (event) => {
    formik.handleChange(event);
    formik.setFieldValue('locations', event.target.value);
    const query = `status=1&locations=${event.target.value}`;

    getByLocationId(query);
  };

  const handleChangeEmployeeChips = (event, values) => {
    formik.handleChange(event);
    // const arr = selectedStaffs.map((item) => item.id);
    // event.target.value.forEach((item) => {
    //   const index = arr.indexOf(item.id);
    //   if (index < 0) {
    //     selectedStaffs.push({ id: item.id, name: item.name });
    //   }
    // });
    setSelectedStaffs(values);
    formik.setFieldValue(
      'personnel',
      values.map((item) => item.id)
    );
  };

  const handleChangeCustomerChips = (event) => {
    formik.handleChange(event);
    if (event.target.name === 'customers') {
      const arr = selectedCustomers.map((item) => item.id);
      const dates = paymentDates;
      event.target.value.forEach((item) => {
        const index = arr.indexOf(item.id);
        if (index < 0) {
          selectedCustomers.push({ id: item.id, name: item.name });
          const data = {
            customer_id: item.id,
            dates: [
              {
                id: uuidv1(),
                date: new Date(),
                payment_status: 0,
              },
            ],
          };
          dates.push(data);
        }
      });
      setSelectedCustomers(selectedCustomers);
      formik.setFieldValue(
        'customers',
        selectedCustomers.map((item) => item.id)
      );

      setPaymentDates(dates);
      formik.setFieldValue('payment_dates', dates);
    }
  };

  const handleDeleteChipsCustomers = (name, id) => {
    const checker = paymentDates
      .filter((customer) => customer.customer_id === id)
      .map((item) => item.dates)[0]
      .filter(
        (date) => date.payment_status === 1 || date.payment_status === '1'
      );

    if (checker.length === 0) {
      setSelectedCustomers((Packages) =>
        Packages.filter((chip) => chip.name !== name)
      );

      formik.setFieldValue(
        'customers',
        formik.values.customers.filter((item) => item !== id)
      );
      const arr = paymentDates.filter((item) => item.customer_id !== id);
      setPaymentDates(arr);
      formik.setFieldValue('payment_dates', arr);
    }
  };

  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
    }
  };

  const uploadImg = () => {
    document.getElementById('selectFile').click();
  };

  const loadingData = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        setLocationSchema(
          res.results.map((item) => ({
            name: item.location_name,
            id: item.location_id,
          }))
        );
        if (state) {
          booking
            .getOne(state)
            .then((resp) => {
              const query = `status=1&locations=${resp.locations.location_id}`;
              employees
                .getAll(query)
                .then((respS) => {
                  if (respS.results.length === 0) {
                    setSnackbarMessage('Empty staffs');
                    setOpen(true);
                  } else {
                    setEmployeeSchema(
                      respS.results.map((item) => ({
                        name: item.user_id.name,
                        id: item.employee_id,
                      }))
                    );
                    const arr2 = [];

                    respS.results.forEach((value) => {
                      resp.personnel.forEach((item) => {
                        if (value.employee_id === item.employee_id) {
                          arr2.push({
                            id: value.employee_id,
                            name: value.user_id.name,
                          });
                        }
                      });
                    });
                    setSelectedStaffs(arr2);
                  }

                  customers
                    .getAll(query)
                    .then((ressR) => {
                      if (ressR.results.data.length === 0) {
                        // setSnackbarMessage('Empty customer');
                        // setOpen(true);
                      } else {
                        setCustomerSchema(
                          ressR.results.data.map((item) => ({
                            name: item.user_id.name,
                            id: item.customer_id,
                          }))
                        );
                        const arr3 = [];

                        ressR.results.data.forEach((value) => {
                          resp.customers.forEach((item) => {
                            if (value.customer_id === item.customer_id) {
                              arr3.push({
                                id: value.customer_id,
                                name: value.user_id.name,
                              });
                            }
                          });
                        });
                        setSelectedCustomers(arr3);
                        setPaymentDates(resp.payment_dates);
                      }
                    })
                    .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
              formik.setValues({
                ...formik.values,
                locations: resp.locations.location_id,
                service_name: resp.service_name,
                service_duration: resp.service_duration,
                start: new Date(resp.start),
                end: new Date(resp.end),
                price: resp.price,
                repeat: resp.repeat,
                notes: resp.notes,
                begin_date: new Date(resp.begin_date),
                payment_dates: resp.payment_dates,
                personnel: resp.personnel.map((item) => item.employee_id),
                customers: resp.customers.map((item) => item.customer_id),
              });

              // if (resp.photo) {
              //   setPreviewImg(resp.photo);
              // }
              if (resp.status === 1) {
                setStatus(true);
              } else {
                setStatus(false);
              }
            })

            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, [state]);

  const handleStatus = () => {
    setStatus(!status);
  };

  const handleChangePaymentDates = (id) => {
    for (let i = 0; i < paymentDates.length; i += 1) {
      if (id === paymentDates[i].customer_id) {
        const data = {
          id: uuidv1(),
          date: new Date(),
          payment_status: 0,
        };
        paymentDates[i].dates.push(data);
        setPaymentDates(paymentDates);
        formik.setFieldValue('payment_dates', paymentDates);
      }
    }
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return t('updating');
      }
      return t('update');
    }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <Box
      sx={{
        backgroundColor: '#E5E8EC',
        py: 1,
      }}
    >
      <Container>
        <Loading loading={loading} />
        <Modal hideBackdrop open={modal} onClose={handleModal}>
          <Box sx={{ ...style }}>
            <h2 id="child-modal-title">{t('is_delete')}</h2>
            {/* <p id="child-modal-description"></p> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleModal()}
              >
                {t('cancel')}
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: colors.white,
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                startIcon={
                  isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                }
                onClick={() => {
                  setIsSubmitting(true);
                  setIsNavigate(false);
                  const arr = [];
                  const arr1 = formik.values.payment_dates.map((item) =>
                    item.customer_id === paymentIndex ? item.dates : ''
                  )[0];
                  paymentDates.forEach((value) => {
                    const val = value;
                    if (paymentIndex === value.customer_id) {
                      val.dates = [];
                      arr1.forEach((item) => {
                        if (item.id !== paymentIndexId) {
                          val.dates.push(item);
                          arr.push(val);
                        }
                      });
                    }
                  });

                  const data = new FormData();
                  data.append('locations', formik.values.locations);
                  data.append('customer', paymentIndex);
                  data.append('id', paymentIndexId);

                  if (state) {
                    data.append('delete_payment_date', 'true');
                    booking
                      .update(state, data)
                      .then((res) => {
                        handleModal();

                        formik.handleSubmit();
                        setPaymentDates(arr);
                        formik.setFieldValue('payment_dates', arr);
                        setIsSubmitting(false);
                      })
                      .catch((err) => {
                        setIsNavigate(true);
                        setIsSubmitting(false);
                        console.log(err);
                      });
                  }
                }}
              >
                {t('yes')}
              </Button>
            </Box>
          </Box>
        </Modal>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Stack>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6} md={6} sm={6}>
            <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
              {state ? t('edit_booking') : t('add_booking')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <div className={classes.topBtns}>
              <FormControlLabel
                value="start"
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={status}
                    value={status}
                    onChange={handleStatus}
                  />
                }
                label={t('status')}
                labelPlacement="start"
              />
            </div>
          </Grid>
        </Grid>

        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12} my={1}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="locations">{t('location')}</InputLabel>
                <Select
                  labelId="locations"
                  label={t('location')}
                  className={classes.input}
                  fullWidth
                  name="locations"
                  value={formik.values.locations}
                  onBlur={formik.handleBlur}
                  onChange={handleChangeLocationChips}
                >
                  {locationSchema.map((item, index) => (
                    <MenuItem key={`${index + 1}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                label={t('service_name')}
                size="small"
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                name="service_name"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('enter_service_name')}
                value={formik.values.service_name}
                error={
                  formik.touched.service_name &&
                  Boolean(formik.errors.service_name)
                }
                helperText={
                  formik.touched.service_name && formik.errors.service_name
                }
              />
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <TextField
                label={t('service_duration')}
                size="small"
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                name="service_duration"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('enter_service_duration')}
                value={formik.values.service_duration}
                error={
                  formik.touched.service_duration &&
                  Boolean(formik.errors.service_duration)
                }
                helperText={
                  formik.touched.service_duration &&
                  formik.errors.service_duration
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} my={1}>
            <Grid item md={6} xs={6} sm={6}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={localeMap[language]}
              >
                <Stack spacing={3} className={classes.input}>
                  <DatePicker
                    label={t('begin_date')}
                    name="begin_date"
                    openTo="year"
                    mask="__/__/____"
                    views={['year', 'month', 'day']}
                    // minDate={new Date()}
                    value={formik.values.begin_date}
                    onChange={(e) => formik.setFieldValue('begin_date', e)}
                    error={
                      formik.touched.begin_date &&
                      Boolean(formik.errors.begin_date)
                    }
                    helperText={
                      formik.touched.begin_date && formik.errors.begin_date
                    }
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item md={6} xs={6}>
              <TextField
                label={t('price')}
                size="small"
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                name="price"
                type="number"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('enter_price')}
                value={formik.values.price}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} my={1}>
            <Grid item md={6} xs={12} sm={12}>
              <Typography
                my={1}
                variant="h5"
                align="left"
                color={colors.grey[700]}
              >
                {t('hours')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={5.5} md={5.5}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.input}>
                      <TimePicker
                        label={t('begin_hour')}
                        clearable
                        ampm={false}
                        value={formik.values.start}
                        onChange={(e) => formik.setFieldValue('start', e)}
                        error={
                          formik.touched.start && Boolean(formik.errors.start)
                        }
                        helperText={formik.touched.start && formik.errors.start}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  container
                  item
                  xs={1}
                  md={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <ArrowForwardIcon />
                </Grid>
                <Grid item xs={5.5} md={5.5}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.input}>
                      <TimePicker
                        label={t('end_hour')}
                        clearable
                        ampm={false}
                        value={formik.values.end}
                        onChange={(e) => formik.setFieldValue('end', e)}
                        error={formik.touched.end && Boolean(formik.errors.end)}
                        helperText={formik.touched.end && formik.errors.end}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sm={12} my={2}>
            <FormControl size="small" sx={{ width: '100%' }}>
              <MultiChipSelect
                labelText={t('personnel')}
                name="personnel"
                items={employeeSchema}
                handleChangeChips={handleChangeEmployeeChips}
                selectedItems={selectedStaffs}
                optionText={t('no_option')}
              />
              <FormHelperText
                sx={{
                  color: colors.red[700],
                }}
              >
                {formik.errors.personnel &&
                  formik.touched.personnel &&
                  formik.errors.personnel}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} sm={12} my={2}>
            <Typography
              my={1}
              variant="h5"
              align="left"
              color={colors.grey[700]}
            >
              {t('include_membership')}
            </Typography>
            <FormControl size="small" sx={{ width: '100%' }}>
              <ManySelect
                name="customers"
                items={customerSchema}
                deleteChips={handleDeleteChipsCustomers}
                handleChangeChips={handleChangeCustomerChips}
                selectedItems={selectedCustomers}
              />
              <FormHelperText
                sx={{
                  color: colors.red[700],
                }}
              >
                {formik.errors.customers &&
                  formik.touched.customers &&
                  formik.errors.customers}
              </FormHelperText>
            </FormControl>
          </Grid>
          {formik.values.payment_dates.length !== 0 ? (
            <Grid item md={6} xs={12} sm={6} my={2}>
              <Typography
                my={1}
                variant="h5"
                align="left"
                color={colors.grey[700]}
              >
                {t('payment_dates')}
              </Typography>
            </Grid>
          ) : null}
          {formik.values.payment_dates.map((items, index) => (
            <Card key={`${index + 1}`} sx={{ padding: 2, marginBottom: 5 }}>
              <Grid key={items.customer_id} container ml={0.5}>
                <Grid item md={6} xs={12} sm={6}>
                  <Typography
                    my={1}
                    variant="h5"
                    align="left"
                    color={colors.grey[700]}
                  >
                    {selectedCustomers.map((value) =>
                      value.id === items.customer_id ? value.name : ''
                    )}
                  </Typography>
                </Grid>
                {formik.values.payment_dates[index].dates.map((itemKey, i) => (
                  <Grid key={itemKey.id} container my={1} spacing={1}>
                    <Grid item xs={12} md={4} sm={12}>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={localeMap[language]}
                      >
                        <Stack spacing={3} className={classes.input}>
                          <DatePicker
                            label={t('date')}
                            disabled={
                              itemKey.payment_status === '1' ||
                              itemKey.payment_status === '2' ||
                              itemKey.payment_status === 1 ||
                              itemKey.payment_status === 2
                            }
                            openTo="year"
                            mask="__/__/____"
                            views={['year', 'month', 'day']}
                            // minDate={new Date()}
                            value={itemKey.date}
                            onChange={(e) => {
                              paymentDates[index].dates[i].date = new Date(
                                e
                              ).toISOString();
                              console.log(paymentDates);
                              setPaymentDates(paymentDates);
                              formik.setFieldValue(
                                'payment_dates',
                                paymentDates
                              );
                            }}
                            error={itemKey.date && Boolean(itemKey.date)}
                            helperText={itemKey.date && itemKey.date}
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={2} sm={12}>
                      <TextField
                        label={t('discount')}
                        className={classes.search}
                        name="discount"
                        autoComplete="namer"
                        type="number"
                        fullWidth
                        size="small"
                        // onBlur={formik.handleBlur}
                        onChange={(e) => {
                          paymentDates[index].dates[i].discount =
                            e.target.value;
                          console.log(paymentDates);
                          setPaymentDates(paymentDates);
                          formik.setFieldValue('payment_dates', paymentDates);
                        }}
                        variant="outlined"
                        placeholder={t('discount')}
                        value={itemKey.discount}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={12}>
                      <Divider>
                        <Chip
                          onDelete={() => {
                            if (
                              itemKey.payment_status === 0 ||
                              itemKey.payment_status === '0'
                            ) {
                              setPaymentIndex(items.customer_id);
                              setPaymentIndexId(itemKey.id);
                              handleModal();
                            }
                          }}
                          deleteIcon={
                            itemKey.payment_status === 0 ||
                            itemKey.payment_status === '0' ? null : (
                              <DoneIcon />
                            )
                          }
                          color={
                            itemKey.payment_status === 0 ||
                            itemKey.payment_status === '0'
                              ? 'error'
                              : 'success'
                          }
                          icon={
                            itemKey.payment_status === 0 ||
                            itemKey.payment_status === '0' ? (
                              <CloseIcon />
                            ) : (
                              <CheckCircleIcon />
                            )
                          }
                          label={`${t('payment_status')}`}
                        />
                      </Divider>
                    </Grid>
                  </Grid>
                ))}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 2,
                    py: 2,
                  }}
                >
                  <Grid item>
                    <Button
                      sx={{
                        backgroundColor: 'transparent',
                        color: colors.grey[700],
                      }}
                      name="add"
                      variant="dashed"
                      onClick={() => {
                        handleChangePaymentDates(items.customer_id);
                      }}
                    >
                      + {t('add_payment_date')}
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Card>
          ))}

          <Grid item md={12} xs={12}>
            <Typography
              my={1}
              variant="h5"
              align="left"
              color={colors.grey[700]}
            >
              {t('description')}
            </Typography>
            <TextField
              InputProps={{
                style: {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              name="notes"
              fullWidth
              multiline
              rows={4}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              variant="outlined"
              label={t('description')}
              value={formik.values.notes}
            />
          </Grid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
            }}
            my={3}
          >
            <Button
              type="submit"
              sx={{
                backgroundColor: '#CFE0F0',
                color: '#161616',
                textTransform: 'none',
              }}
              variant="contained"
              onClick={() => navigate('/app/booking')}
            >
              {t('cancel')}
            </Button>
            <Button
              sx={{
                backgroundColor: '#2A455F',
                color: colors.white,
                textTransform: 'none',
              }}
              name="add"
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => setIsNavigate(true)}
              startIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="0.9rem" />
                ) : undefined
              }
            >
              {renderButton(state, formik.isSubmitting)}
            </Button>
          </Box>
        </form>
        <ToastContainer />
      </Container>
    </Box>
  );
};
