import { Bar } from 'react-chartjs-2';
import {
  Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

export default ({ rest }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }
  const myDate = new Date();
  const curr = addHoursToDate(myDate, 5);
  const first = curr.getDate();
  const last = first - 6;
  const firstDay = new Date(curr.setDate(first)).toISOString();
  const secondDay = new Date(curr.setDate(first - 1)).toISOString();
  const thirdDay = new Date(curr.setDate(first - 2)).toISOString();
  const fourDay = new Date(curr.setDate(first - 3)).toISOString();
  const fiveDay = new Date(curr.setDate(first - 4)).toISOString();
  const sixDay = new Date(curr.setDate(first - 5)).toISOString();
  const lastDay = new Date(curr.setDate(last)).toISOString();

  const month = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={10} textAnchor="end" fontSize="0.8em" fill="#000">
          {new Date(payload.value).getDate()
            ? new Date(payload.value).getDate() +
              ' ' +
              t(`short_month_name.${month[new Date(payload.value).getMonth()]}`)
            : ''}
        </text>
      </g>
    );
  };

  const data = [
    {
      time: lastDay,
      quantity: 0,
    },
    {
      time: sixDay,
      quantity: 0,
    },
    {
      time: fiveDay,
      quantity: 0,
    },
    {
      time: fourDay,
      quantity: 0,
    },
    {
      time: thirdDay,
      quantity: 0,
    },
    {
      time: secondDay,
      quantity: 0,
    },
    {
      time: firstDay,
      quantity: 0,
    },
  ];

  const renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => result + entry.value, 0);

    return (
      <Card key={label}>
        <CardContent key={`${label}_content`} sx={{ padding: 1 }}>
          <p className="total">
            {new Date(label).getDate()
              ? new Date(label).getDate() +
                ' ' +
                t(`short_month_name.${month[new Date(label).getMonth()]}`) +
                ' ' +
                new Date(label).getFullYear()
              : ''}
          </p>
          {payload.map((entry, index) => (
            <p key={entry.value} className="total">
              {`${entry.value} ` + t('sum')}
            </p>
          ))}
        </CardContent>
      </Card>
    );
  };

  return (
    <Card key="sales">
      <CardHeader title={t('sales')} />

      <CardContent key="sales_content">
        <Box
          sx={{
            height: 200,
            position: 'relative',
          }}
        >
          <ResponsiveContainer height="100%">
            <AreaChart
              // width={700}
              // height={200}
              data={rest.length === 0 ? data : rest}
              // stackOffset="expand"
              margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor="rgba(116, 89, 217, 0.2)"
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor="rgba(255, 255, 255, 0)"
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <XAxis interval={0} dataKey="time" tick={CustomizedAxisTick} />
              <YAxis />
              <CartesianGrid strokeDasharray="3" />
              <Tooltip key="tool" content={renderTooltipContent} />
              <Area
                type="monotone"
                dataKey="quantity"
                stroke="#2A455F"
                strokeWidth={3}
                fillOpacity={9}
                fill="url(#gradient)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};
