/* eslint-disable no-prototype-builtins */
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Hidden, IconButton, Toolbar, Chip } from '@mui/material';
import jwtDecode from 'jwt-decode';
import ScoreIcon from '@mui/icons-material/Score';
import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/ListAltOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PaymentIcon from '@mui/icons-material/Payment';
import useMediaQuery from '@mui/material/useMediaQuery';
// import InputIcon from '@mui/icons-material/Input';
import ProfileBtn from '../../Profile';
import Cart from '../../Cart';
import Payments from '../../Payments';
import authApi from '../../../services/auth';
// import ProfileBtn from '../../Profile2';
import ModalWindowFullScreen from '../../Modals/FullScreen';
import NotificationBtn from '../../Notifications';
import Scores from '../Modals/scores';

const Navbar = ({ onMobileNavOpen, ...rest }) => {
  const [notifications] = useState([]);
  const matches = useMediaQuery('(min-width:1200px)');
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const coin = useSelector((state) => state.storeReducer.coin);

  const [accountCoin, setAccountCoin] = useState(0);
  const [userScore, setUserScore] = useState(0);
  const [modalScore, setModalScore] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);

  const getRole = () => {
    if (localStorage.hasOwnProperty('token')) {
      const token = localStorage.getItem('token');
      return jwtDecode(token).role;
    }
    return '';
  };

  useEffect(() => {
    if (localStorage.hasOwnProperty('token')) {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);

      authApi
        .authTelegram(
          `id=${decodedToken.telegram_id}&name=${decodedToken.name}`
        )
        .then((res) => {
          setAccountCoin(res.data.coin);
          setUserScore(res.data.score);
        });
    }
  }, [coin]);

  const handleModalScore = () => {
    setModalScore(!modalScore);
  };

  return (
    <AppBar
      elevation={1}
      {...rest}
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <ModalWindowFullScreen
        title={t("To'lovlar")}
        modal={modalPayment}
        handleModal={() => {
          setModalPayment(false);
        }}
        body={<Payments />}
      />
      <ModalWindowFullScreen
        title={t('Reyting')}
        modal={modalScore}
        handleModal={handleModalScore}
        body={<Scores handleModalScore={handleModalScore} />}
      />
      <Toolbar>
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        {!matches ? (
          <Hidden>
            <IconButton onClick={onMobileNavOpen} size="small">
              <MenuIcon
                sx={{
                  color: 'darker.default',
                }}
              />
            </IconButton>
          </Hidden>
        ) : (
          <></>
        )}
        {/* <Box sx={{ p: 1 }} />
        <Hidden>
          <RouterLink to="/">{!matches ? <LogoIcon /> : <Logo />}</RouterLink>
        </Hidden> */}

        <Box sx={{ flexGrow: 8 }} />
        {/* <Hidden>
          <IconButton size="small">
            <StyledBadge badgeContent={4} color="warning" variant="dot">
              <NotificationsIcon />
            </StyledBadge>
          </IconButton>
        </Hidden> */}
        {/* <Hidden>
          <SelectLanguageBtn />
        </Hidden> */}

        <>
          {location.pathname.includes('/tests') ? (
            <Hidden>
              <Chip
                size="medium"
                sx={{ height: 25 }}
                label={userScore}
                color="success"
                icon={<ScoreIcon />}
                variant="outlined"
                onClick={() => {
                  handleModalScore();
                }}
              />
            </Hidden>
          ) : null}
          <Hidden>
            <NotificationBtn />
          </Hidden>
          <Box sx={{ py: 1 }} />
          {location.pathname !== '/app/tests/favorites' &&
          location.pathname.includes('/tests') ? (
            <>
              <Hidden>
                <IconButton
                  size="medium"
                  sx={{ height: 25 }}
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    navigate('/app/tests/favorites');
                  }}
                >
                  <FavoriteIcon />
                </IconButton>
              </Hidden>
              <Box sx={{ p: 1 }} />
            </>
          ) : null}
          {location.pathname === '/app/tests/favorites' &&
          location.pathname.includes('/tests') ? (
            <>
              <Hidden>
                <IconButton
                  size="medium"
                  sx={{ height: 25 }}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    navigate('/tests');
                  }}
                >
                  <ListIcon />
                </IconButton>
              </Hidden>
              <Box sx={{ p: 1 }} />
            </>
          ) : null}
          <Hidden>
            <Chip
              size="medium"
              sx={{ height: 25 }}
              label={accountCoin}
              color="info"
              icon={<PaymentIcon />}
              variant="outlined"
              onClick={() => {
                setModalPayment(true);
              }}
            />
          </Hidden>
          {location.pathname.includes('/tests') ? <Box sx={{ p: 1 }} /> : null}
          {location.pathname.includes('/tests') ? (
            <Hidden>
              <Cart />
            </Hidden>
          ) : null}
          {location.pathname.includes('/tests') ? <Box sx={{ p: 1 }} /> : null}
        </>

        {/* <Hidden>
          <Chip
            size="small"
            sx={{ height: 25 }}
            label={getRole()}
            color="primary"
          />
        </Hidden> */}
        <Box sx={{ p: 1 }} />
        <Hidden>
          <ProfileBtn />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default Navbar;
