/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Chip,
  Grid,
  Pagination,
  Stack,
  Container,
  Typography,
  CardMedia,
  Avatar,
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/ListAltOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PaymentIcon from '@mui/icons-material/Payment';
import ScoreIcon from '@mui/icons-material/Score';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { setToken } from '../../../redux/modules/auth/actions';
import { setCoin } from '../../../redux/modules/store/actions';
import Cart from '../../Cart';
import authApi from '../../../services/auth';
import NotificationBtn from '../../Notifications';
import ModalWindowFullScreen from '../../Modals/FullScreen';
import Loading from '../../Loading';
import testsApi from '../../../services/tests';
import Scores from '../Modals/scores';

export default ({ onMobileNavOpen, ...rest }) => {
  const [notifications] = useState([]);
  const matches = useMediaQuery('(min-width:1200px)');

  const location = useLocation();
  const [accountCoin, setAccountCoin] = useState(0);
  const [userScore, setUserScore] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [modalScore, setModalScore] = useState(false);
  const [scores, setScores] = useState([]);
  const [countPages, setCountPages] = useState(0);

  const [page, setPage] = useState(1);

  const coin = useSelector((state) => state.storeReducer.coin);

  const checkPath = () => {
    const path = location.pathname;
    if (path === '/tests') {
      return true;
    }
    if (path === '/app/tests/show') {
      return true;
    }
    if (path === '/app/tests/favorites') {
      return true;
    }
    if (path === '/app/tests/groups') {
      return true;
    }
    if (path === '/account') {
      return true;
    }
    if (path.startsWith('/app/tests/exams')) {
      return true;
    }
    if (path.startsWith('/app/tests/forum')) {
      return true;
    }
    return false;
  };

  const handleTelegramResponse = (response) => {
    authApi
      .authTelegram(`id=${response.id}&name=${response.first_name}`)
      .then((res) => {
        dispatch(setToken(res.data));
        dispatch(setCoin(res.data.coin));
      });
  };

  useEffect(() => {
    if (localStorage.hasOwnProperty('token')) {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);

      authApi
        .authTelegram(
          `id=${decodedToken.telegram_id}&name=${decodedToken.name}`
        )
        .then((res) => {
          setAccountCoin(res.data.coin);
          setUserScore(res.data.score);
          dispatch(setToken(res.data));
        });
    }
  }, [coin]);

  const handleModalScore = () => {
    setModalScore(!modalScore);
  };

  const loadingData = (page, id) => {
    setLoading(true);
    testsApi
      .getAllScores('page=' + page + '&page_size=10&science=' + id)
      .then((res) => {
        setCountPages(res.count_pages);
        setScores(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);

    loadingData(value);
  };

  return (
    <AppBar
      elevation={1}
      {...rest}
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <ModalWindowFullScreen
        title={t('Natija')}
        modal={modalScore}
        handleModal={handleModalScore}
        body={<Scores handleModalScore={handleModalScore} />}
      />
      <Toolbar>
        {/* <Box sx={{ flexGrow: 1 }} /> */}
        {!matches ? (
          <Hidden>
            <IconButton onClick={onMobileNavOpen} size="small">
              <MenuIcon
                sx={{
                  color: 'darker.default',
                }}
              />
            </IconButton>
          </Hidden>
        ) : (
          <></>
        )}
        {/* <Box sx={{ p: 1 }} />
        <Hidden>
          <RouterLink to="/">{!matches ? <LogoIcon /> : <Logo />}</RouterLink>
        </Hidden> */}

        <Box sx={{ flexGrow: 3 }} />

        {/* <Hidden>
          <SelectLanguageBtn />
        </Hidden> */}

        <>
          {location.pathname.includes('/tests') ? (
            <Hidden>
              <Chip
                size="medium"
                sx={{ height: 25 }}
                label={userScore}
                color="success"
                icon={<ScoreIcon />}
                variant="outlined"
                onClick={() => {
                  handleModalScore();
                }}
              />
            </Hidden>
          ) : null}
          <Hidden>
            <NotificationBtn />
          </Hidden>
          {/* {location.pathname.includes('/tests') ? <Box sx={{ p: 1 }} /> : null} */}
          {location.pathname !== '/app/tests/favorites' &&
          location.pathname.includes('/tests') ? (
            <>
              <Hidden>
                <IconButton
                  size="medium"
                  sx={{ height: 25 }}
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    navigate('/app/tests/favorites');
                  }}
                >
                  <FavoriteIcon />
                </IconButton>
              </Hidden>
              <Box sx={{ p: 1 }} />
            </>
          ) : null}

          {location.pathname === '/app/tests/favorites' &&
          location.pathname.includes('/tests') ? (
            <>
              <Hidden>
                <IconButton
                  size="medium"
                  sx={{ height: 25 }}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    navigate('/tests');
                  }}
                >
                  <ListIcon />
                </IconButton>
              </Hidden>
              <Box sx={{ p: 1 }} />
            </>
          ) : null}

          <Hidden>
            <Chip
              size="medium"
              sx={{ height: 25 }}
              label={accountCoin}
              color="info"
              icon={<PaymentIcon />}
              variant="outlined"
            />
          </Hidden>
          {location.pathname.includes('/tests') ? <Box sx={{ p: 1 }} /> : null}
          {location.pathname.includes('/tests') ? (
            <Hidden>
              <Cart />
            </Hidden>
          ) : null}
        </>
      </Toolbar>
    </AppBar>
  );
};
