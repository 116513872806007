import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  FormControlLabel,
  Divider,
  IconButton,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import jwtDecode from 'jwt-decode';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
import 'quill-mention/dist/quill.mention.css';
import 'react-quill/dist/quill.snow.css';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { styled, lighten, darken } from '@mui/system';
import Loading from '../../../../../../components/Loading';
import {
  setSelectTests,
  setCoin,
} from '../../../../../../redux/modules/store/actions';
import { setToken } from '../../../../../../redux/modules/auth/actions';
import { useStyles } from './style';
import userApi from '../../../../../../services/users';
import testsApi from '../../../../../../services/tests';
import authApi from '../../../../../../services/auth';
import CustomToolbar from '../../../../../../components/QuillEditor/CustomToolbar';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

Quill.register('modules/blotFormatter', BlotFormatter);

export default ({ id, handleModal, getGroups }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const language = useSelector((state) => state.langsReducer.language);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);

  const moduless1 = {
    toolbar: {
      container: '#toolbar-111',
    },
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize'],
    // },
    blotFormatter: {},
  };

  const formats = [
    'height',
    'width',
    'class',
    'style',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'indent',
    'list',
    'direction',
    'align',
    'link',
    'image',
    'video',
    'formula',
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
    // members: Yup.array().min(1, t('required')),
    group_type: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      members: [],
      group_type: 'public',
      info: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      const data = new FormData();
      data.append('name', values.name);
      data.append('group_type', values.group_type);
      data.append('members', JSON.stringify(values.members));
      data.append('info', JSON.stringify({ data: values.info }));
      data.append('status', 1);

      if (id) {
        data.append('test_group_id', id);
        testsApi
          .updateGroup(id, data)
          .then((res) => {
            console.log(res);

            setLoading(false);
            getGroups();
            handleModal();
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        testsApi
          .addGroup(data)
          .then((res) => {
            console.log(res);

            setLoading(false);
            getGroups();
            handleModal();
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    },
  });

  const getData = () => {
    setLoading(true);
    testsApi
      .getOneGroup(id)
      .then((res) => {
        console.log(res);
        formik.setFieldValue('name', res.name);
        formik.setFieldValue('group_type', res.group_type);
        formik.setFieldValue('members', res.members);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const loadingData = () => {
    setLoading(true);
    userApi
      .getAllUserAuth('status=1&page_size=10')
      .then((res) => {
        console.log(res);
        setUsers(res.results);
        setLoading(false);
        if (id) {
          getData();
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        try {
          if (err.response.status === 401) {
            setTimeout(() => {
              loadingData();
            }, 5000);
          }
        } catch (error) {
          //
        }
      });
  };

  useEffect(() => {
    loadingData();
  }, [language]);

  const renderButton = (isSubmitting) => {
    // if (state) {
    //   if (isSubmitting) {
    //     return t('updating');
    //   }
    //   return t('update');
    // }
    if (isSubmitting) {
      return t('submitting');
    }
    return t('submit');
  };

  return (
    <>
      <Container maxWidth={false}>
        <Loading loading={loading} />
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Grid container spacing={1} my={1}>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                autoComplete="off"
                size="small"
                name="name"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                variant="outlined"
                placeholder={t('Nom kiriting')}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                InputProps={{
                  style: {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                label="Nom"
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Gruppa tipi
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="group_type"
                  value={formik.values.group_type}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Ommaviy (Istalgan foydalanuvchi gruppaga qo'shila oladi)"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Xususiy (Faqat admin a'zolarni qo'sha oladi)"
                  />
                </RadioGroup>
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.group_type &&
                    formik.touched.group_type &&
                    formik.errors.group_type}
                </FormHelperText>
              </FormControl>
            </Grid>

            {formik.values.group_type === 'private' ? (
              <Grid item xs={12} md={12} sm={12}>
                <Typography
                  my={1}
                  variant="h4"
                  align="left"
                  color={colors.grey[700]}
                >
                  {t("A'zolar")}
                </Typography>
                <Autocomplete
                  multiple
                  fullWidth
                  size="small"
                  id="combo-box1"
                  disableCloseOnSelect
                  name="members"
                  className={classes.search}
                  value={formik.values.members}
                  options={users}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('members', newValue);
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }

                    // Regular option
                    return option.name;
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        key={option.user_id}
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('Foydalanuvchilar')}
                      error={
                        formik.touched.members && Boolean(formik.errors.members)
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <FormHelperText
                  sx={{
                    color: colors.red[700],
                  }}
                >
                  {formik.errors.members &&
                    formik.touched.members &&
                    formik.errors.members}
                </FormHelperText>
              </Grid>
            ) : null}
            <Grid item xs={12} md={12} sm={12}>
              <Typography my={1} variant="h4" align="left" color={colors.black}>
                {t('Guruh haqida')}
              </Typography>
              <div key="info">
                <CustomToolbar key="info1" id="111" />
                <ReactQuill
                  value={formik.values.info}
                  onChange={(e) => {
                    formik.setFieldValue('info', e);
                  }}
                  className={classes.search}
                  modules={moduless1}
                  formats={formats}
                  theme="snow"
                />
              </div>
            </Grid>
          </Grid>
          <Box sx={{ '& > :not(style)': { my: 1 } }}>
            <Button
              sx={{
                backgroundColor: '#2A455F',
                color: colors.white,
                textTransform: 'none',
              }}
              name="add"
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => console.log()}
              startIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="0.9rem" />
                ) : (
                  <SaveIcon sx={{ mr: 1 }} />
                )
              }
            >
              {renderButton(formik.isSubmitting)}
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
};
