import { service } from '.';

export default {
  getAll: (query) => service.get(`/locations/?${query}`),
  checkName: (query) => service.get(`/locations/validation?${query}`),
  getOne: (id) => service.get(`/locations/${id}/`),
  create: (data) => service.post('/locations/', data),
  update: (id, data) => service.patch(`/locations/${id}/`, data),
  disable: (id, data) => service.patch(`/locations/${id}/`, data),
  delete: (id) => service.delete(`/locations/${id}/`),
};
