export default {
  TABLE_ORDERS_FETCH_STATUSES: 'TABLE_ORDERS_FETCH_STATUSES',
  ORDER_DATA: 'ORDER_DATA',
  TABLE_ORDERS_SET_DATA: 'TABLE_ORDERS_SET_DATA',
  TABLE_ORDERS_SET_TOTAL: 'TABLE_ORDERS_SET_TOTAL',
  TABLE_ORDERS_SET_LOADING: 'TABLE_ORDERS_SET_LOADING',
  TABLE_ORDERS_ERROR: 'TABLE_ORDERS_ERROR',
  TABLE_ORDERS_FETCH_DATA: 'TABLE_ORDERS_FETCH_DATA',
  TABLE_ORDERS_SAGA_ONSEARCH_ACTION: 'TABLE_ORDERS_SAGA_ONSEARCH_ACTION',
};
