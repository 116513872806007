import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

export default ({ rest }) => {
  // console.log(rest);
  const { t } = useTranslation();
  const photoUrl = process.env.REACT_APP_BASE_URL.replace('/api/v1', '');

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const curr = addHoursToDate(new Date(), 5);
  const timeRenderer = (time) => {
    return (
      <span>
        {time.getHours() < 10 ? '0' + time.getHours() : time.getHours()}:
        {time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()}
      </span>
    );
  };

  return (
    <Card sx={{ height: rest.length === 0 ? 105 : 350 }}>
      {rest.length === 0 ? <CardHeader title={t('recent_visitors')} /> : null}
      {rest.length !== 0 ? (
        <CardContent>
          <Grid
            container
            spacing={3}
            sx={{ paddingBottom: 2, justifyContent: 'space-between' }}
          >
            <Grid item>
              <Typography color="textPrimary" variant="h6">
                {t('recent_visitors')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textPrimary" variant="h6">
                {t('today') + ': ' + rest.length}
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <List
              dense
              sx={{
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                height: 270,
                '&::-webkit-scrollbar': {
                  width: 5,
                },
                '&::-webkit-scrollbar-track': {
                  // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'darkgrey',
                  // outline: '1px solid slategrey',
                  borderRadius: 2,
                },
              }}
            >
              {rest.map((value, index) => {
                return (
                  <div>
                    <ListItem
                      key={`${index + 1}`}
                      secondaryAction={
                        <Typography edge="end" color="textPrimary" variant="h6">
                          {timeRenderer(new Date(value.user_id.created_at))}
                        </Typography>
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar alt="" src={`${value.user_id.photo}`} />
                        </ListItemAvatar>
                        <ListItemText primary={`${value.user_id.name}`} />
                      </ListItemButton>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
            </List>
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Typography variant="h6">{t('no_data')}</Typography>
        </CardContent>
      )}
    </Card>
  );
};
