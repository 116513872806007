import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import { Badge, Snackbar, Stack, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import SnackbarContent from '@mui/material/SnackbarContent';
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import ListIcon from '@mui/icons-material/ListAltOutlined';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { setLanguage } from '../../redux/modules/lang/actions';
import { clearToken, setToken } from '../../redux/modules/auth/actions';
import { setCoin, setSelectTests } from '../../redux/modules/store/actions';
import auth from '../../services/auth';
import usersApi from '../../services/users';
import testsApi from '../../services/tests';
import ModalWindow from '../Modals';
import ChangeStatusModal from '../Modals/ConfirmStatus';
import AddSelectUnit from './Modals/AddSelectUnit';
import SelectUnitList from './Modals/SelectsList';
import SignUpModal from '../Modals/SignUpModal';
import ModalWindowFullScreen from '../Modals/FullScreen';
import Loading from '../Loading';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    cursor: 'pointer',
  },
}));

export default () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const selectTests = useSelector((state) => state.storeReducer.select_tests);

  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectTestList, setSelectTestList] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalAddSelectUnit, setModalAddSelectUnit] = useState(false);
  const [modalSelects, setModalSelects] = useState(false);
  const [modalTelegram, setModalTelegram] = useState(false);
  const [testDelete, setTestDelete] = useState(false);

  const [telegramAuth, setTelegramAuth] = useState(
    localStorage.getItem('token')
  );

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // if (selectTestList.length > 0) {

    // } else {
    //   setSnackbarMessage(t('Tanlovlar mavjud emas'));
    //   setOpenSnackbar(true);
    // }
  };

  const downloadTests = (shuffle) => {
    if (selectTestList.length > 30) {
      setSnackbarMessage(t('Tanlash limit 30 ta. Tanlov limitdan oshib ketdi'));
      setOpenSnackbar(true);
    } else if (selectTestList.length > 0 && selectTestList.length <= 30) {
      setLoading(true);

      if (shuffle === 'yes') {
        const queryTest = `${selectTestList.join('&testIds=')}`;
        const query = 'testIds=' + queryTest + '&shuffle=yes';
        testsApi
          .getAllDownload(query)
          .then((res) => {
            if (res.status) {
              window.open(res.filename);
            } else {
              setSnackbarMessage(
                `Sizda mablag' yetarli emas. Balans: ${res.coin} Ma'lumot uchun: +998 90 309 39 78`
              );
              setOpenSnackbar(true);
            }

            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        const queryTest = `${selectTestList.join('&testIds=')}`;
        const query = 'testIds=' + queryTest;
        testsApi
          .getAllDownload(query)
          .then((res) => {
            if (res.status) {
              window.open(res.filename);
            } else {
              setSnackbarMessage(
                `Sizda mablag' yetarli emas. Balans: ${res.coin} Ma'lumot uchun: +998 90 309 39 78`
              );
              setOpenSnackbar(true);
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    } else if (selectTestList.length === 0) {
      setSnackbarMessage(t('no_select'));
      setOpenSnackbar(true);
    }
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handleModalTelegram = () => {
    setModalTelegram(!modalTelegram);
  };

  const changeStatus = () => {
    if (testDelete) {
      setTestDelete(false);
      setModal(!modal);
    } else {
      localStorage.setItem('selectTests', JSON.stringify([]));
      setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
      setModal(!modal);
      setSnackbarMessage(t('cleared'));
      setOpenSnackbar(true);
      dispatch(setSelectTests('clear'));
    }
  };

  useEffect(() => {
    if (localStorage.getItem('select_test_unit_id')) {
      const id = localStorage.getItem('select_test_unit_id');
      testsApi
        .getOneTestSelectUnit(id)
        .then((res) => {
          setSelectTestList(res.tests);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSelectTestList([]);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('select_test_unit_id')) {
      const id = localStorage.getItem('select_test_unit_id');
      testsApi
        .getOneTestSelectUnit(id)
        .then((res) => {
          setSelectTestList(res.tests);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSelectTestList([]);
    }
  }, [selectTests]);

  const checkPath = () => {
    const path = location.pathname;

    if (path === '/app/tests/show') {
      return true;
    }
    return false;
  };

  const handleTelegramResponse = (response) => {
    auth
      .authTelegram(`id=${response.id}&name=${response.first_name}`)
      .then((res) => {
        setTelegramAuth('auth');
        setCoin(res.data.coin);
        setModalTelegram(false);
        dispatch(setToken(res.data));
      });
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <div>
      <Loading loading={loading} />
      <ModalWindowFullScreen
        title={t("Tanlovlar to'plami")}
        modal={modalSelects}
        handleModal={() => {
          setModalSelects(false);
        }}
        body={<SelectUnitList />}
      />
      <ModalWindow
        title={t('Eslatma')}
        modal={modalTelegram}
        handleModal={handleModalTelegram}
        maxWidth="sm"
        body={<SignUpModal handleTelegramResponse={handleTelegramResponse} />}
      />
      <ModalWindow
        title={t('clear')}
        modal={modal}
        handleModal={handleModal}
        maxWidth="sm"
        body={
          <ChangeStatusModal
            handleModal={handleModal}
            changeStatus={changeStatus}
            yes={t('yes')}
            cancel={t('cancel')}
            text={t('ask_clear')}
          />
        }
      />
      <ModalWindow
        title={t("Yangi tanlovlar to'plami")}
        modal={modalAddSelectUnit}
        handleModal={() => {
          setModalAddSelectUnit(false);
        }}
        maxWidth="md"
        body={
          <AddSelectUnit
            handleModal={() => {
              setModalAddSelectUnit(false);
            }}
            yes={t('save')}
            cancel={t('cancel')}
            text={t('ask_clear')}
          />
        }
      />
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="warning"
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Stack>
      <StyledBadge
        badgeContent={selectTestList.length === 0 ? '0' : selectTestList.length}
        color="secondary"
      >
        <ShoppingCartIcon
          onClick={handleClick}
          sx={{ cursor: 'pointer' }}
          color="primary"
        />
      </StyledBadge>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key="add"
          onClick={() => {
            handleClose();
            setModalAddSelectUnit(true);
          }}
        >
          <AddCircleIcon />
          {t("Tanlovlarni qo'shish")}
        </MenuItem>
        <MenuItem
          key="list"
          onClick={() => {
            handleClose();
            setModalSelects(true);
          }}
        >
          <ListIcon />
          {t("Tanlovlarni ro'yxati")}
        </MenuItem>

        {/* {!checkPath() && selectTestList.length > 0 ? (
          <MenuItem
            key="show"
            onClick={() => {
              handleClose();
              navigate('/app/tests/show');
            }}
          >
            <CheckIcon />
            {t("Tanlanganlarni ko'rish")}
          </MenuItem>
        ) : null} */}
        {/* {checkPath() ? (
          <MenuItem
            key="show"
            onClick={() => {
              handleClose();
              navigate('/tests');
            }}
          >
            <ListIcon />
            {t('Testlar')}
          </MenuItem>
        ) : null} */}
        {/* <MenuItem
          key="download"
          onClick={() => {
            handleClose();
            if (telegramAuth !== null) {
              downloadTests('no');
            } else {
              handleModalTelegram();
            }
          }}
        >
          <FileDownloadIcon />
          {t('download')}
        </MenuItem> */}

        {/* <MenuItem
          key="download_shuffle"
          onClick={() => {
            handleClose();
            if (telegramAuth !== null) {
              downloadTests('yes');
            } else {
              handleModalTelegram();
            }
          }}
        >
          <AutorenewIcon />
          {t('download_shuffle')}
        </MenuItem>
        <MenuItem
          key="clear"
          onClick={() => {
            handleClose();
            handleModal();
          }}
        >
          <DeleteIcon />
          {t('clear')}
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
};
