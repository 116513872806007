import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import { Box, Button, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import testSelectApi from '../../../../services/tests';
import { useStyles } from './style';

export default ({ handleModal, state, yes, cancel }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
    tests: Yup.array().min(0, t('required')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      tests: [],
    },
    validationSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append('name', values.name);
      data.append('tests', JSON.stringify([]));
      data.append('status', 1);

      if (state) {
        testSelectApi
          .updateTestSelectUnit(state, data)
          .then((res) => {
            handleModal();
          })
          .catch((err) => {
            console.log(err);
            formik.setSubmitting(false);
          });
      } else {
        testSelectApi
          .createTestSelectUnit(data)
          .then((res) => {
            handleModal();
          })
          .catch((err) => {
            console.log(err.response);
            formik.setSubmitting(false);
          });
      }
    },
  });

  return (
    <div>
      <Container>
        <form autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box className={classes.modalBody}>
            <TextField
              label={t('name')}
              size="small"
              InputProps={{
                style: {
                  backgroundColor: theme.palette.background.paper,
                },
              }}
              InputLabelProps={{
                style: { color: 'black' },
              }}
              name="name"
              fullWidth
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              variant="outlined"
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Box>
          <Box className={classes.actionBtns}>
            <Button variant="outlined" color="error" onClick={handleModal}>
              {cancel}
            </Button>
            <Button variant="contained" type="submit">
              {yes}
            </Button>
          </Box>
        </form>
      </Container>
    </div>
  );
};
