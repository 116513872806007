import { useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { getStyles, MenuProps } from './style';
import { useStyles } from '../../FilteredBody/style';

export default ({
  items,
  deleteChips,
  handleChangeChips,
  selectedItems,
  name,
  error,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Select
      className={classes.input}
      labelId="demo-multiple-chip-label"
      id="demo-multiple-chip"
      name={name}
      multiple
      variant="outlined"
      error={error}
      fullWidth
      value={selectedItems}
      onChange={handleChangeChips}
      input={<OutlinedInput id="select-multiple-chip" />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value, idx) => (
            <Chip
              key={`${idx + 1}`}
              onDelete={() => deleteChips(value.name, value.id)}
              value={value.name}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              label={value.name}
            />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
    >
      {items.map((item, index) => (
        <MenuItem
          key={`${index + 1}`}
          value={item}
          style={getStyles(item, selectedItems, theme)}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};
