import { Helmet } from 'react-helmet';
import { React, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  colors,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Stack,
  TextField,
  InputLabel,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';
import enLocale from 'date-fns/locale/en-US';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Packages,
  Visitors,
  Sales,
  Members,
  Classes,
  Coaches,
} from '../../../components/Statistics';
import customerPackagesApi from '../../../services/customer_packages';
import customerClassesApi from '../../../services/customer_classes';
import customerVisitorsApi from '../../../services/customer_visitors';
import customerCoachesApi from '../../../services/customer_coaches';
import salesApi from '../../../services/sales';
import membersApi from '../../../services/members';
import { useStyles } from './style';
import locations from '../../../services/locations';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default () => {
  const classes = useStyles();
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [color, setColor] = useState('#2A455F');
  const language = useSelector((state) => state.langsReducer.language);
  const [locationList, setLocationList] = useState([]);
  const [packagesData, setPackagesData] = useState([]);
  const [classesData, setClassesData] = useState([]);
  const [visitorsData, setVisitorsData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [coachesData, setCoachesData] = useState([]);
  const { height, width } = useWindowDimensions();

  function addHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }
  function subHoursToDate(date, hours) {
    return new Date(new Date(date).setHours(date.getHours() - hours));
  }
  const curr = addHoursToDate(new Date(), 5);
  const first = curr.getDate();
  const last = first - 6;

  const [firstday, setFirstDay] = useState(
    new Date(curr.setDate(first)).toISOString()
  );
  const [lastday, setLastDay] = useState(
    new Date(curr.setDate(last)).toISOString()
  );

  useEffect(() => {
    if (decodedToken.role === 'Customer') {
      navigate('/login');
    }
    if (decodedToken.role === 'Manager') {
      navigate('/app/employee_title');
    }
    if (decodedToken.role === 'Supervisor') {
      navigate('/app/class');
    }
  }, []);

  const localeMap = {
    en: enLocale,
    uz: uzLocale,
    ru: ruLocale,
  };

  const validationSchema = Yup.object().shape({
    locations: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      locations: 'all',
      begin_date: lastday,
      end_date: firstday,
    },
    validationSchema,
  });

  const getDataLocations = () => {
    setLoading(true);
    const query = `user_id=${decodedToken.user_id}`;
    locations
      .getAll(query)
      .then((res) => {
        if (
          res.results.filter((item) => item.location_name !== '').length !== 0
        ) {
          setLocationList(
            res.results.filter((item) => item.location_name !== '')
          );

          const locationIds = [];
          res.results.forEach((item) => {
            locationIds.push(item.location_id);
          });
          const query = `locations=${locationIds.join('&locations=')}`;
          // customerPackagesApi
          //   .getAll('count=4&' + query)
          //   .then((ress) => {
          //     setPackagesData(ress);
          //   })
          //   .catch((err) => console.log(err));

          salesApi
            .getAll(query + '&start=' + firstday + '&end=' + lastday)
            .then((ress) => {
              setSalesData(ress);
            })
            .catch((err) => console.log(err));

          membersApi
            .getAll(query + '&start=' + firstday + '&end=' + lastday)
            .then((ress) => {
              setMembersData(ress);
            })
            .catch((err) => console.log(err));

          customerClassesApi
            .getAll('count=4&' + query)
            .then((ress) => {
              setClassesData(ress);
            })
            .catch((err) => console.log(err));

          customerCoachesApi
            .getAll(query)
            .then((ress) => {
              setCoachesData(ress);
            })
            .catch((err) => console.log(err));

          customerVisitorsApi
            .getAll(query)
            .then((ress) => {
              setVisitorsData(ress);
            })
            .catch((err) => console.log(err));
        }
        setLoading(false);
      })
      .catch((err) => {
        // if (err.response.data.code === 'token_not_valid') {
        //   localStorage.removeItem('token');
        //   localStorage.removeItem('refresh_token');
        //   navigate('/login');
        // }
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (decodedToken.role === 'Admin') {
      getDataLocations();
    }
  }, [language]);

  const getData = (location, startDate, endDate) => {
    const first = new Date(endDate);
    const last = new Date(startDate);

    if (location === 'all') {
      const locationIds = [];
      locationList.forEach((item) => {
        locationIds.push(item.location_id);
      });
      const query = `${locationIds.join('&locations=')}`;
      salesApi
        .getAll(
          'locations=' +
            query +
            '&start=' +
            first.toISOString() +
            '&end=' +
            last.toISOString()
        )
        .then((ress) => {
          setSalesData(ress);
        })
        .catch((err) => console.log(err));
      membersApi
        .getAll(
          'locations=' +
            query +
            '&start=' +
            first.toISOString() +
            '&end=' +
            last.toISOString()
        )
        .then((ress) => {
          setMembersData(ress);
        })
        .catch((err) => console.log(err));
      customerClassesApi
        .getAll('count=4&locations=' + query)
        .then((ress) => {
          setClassesData(ress);
        })
        .catch((err) => console.log(err));
    } else {
      const query = `${'locations=' + location}`;
      salesApi
        .getAll(
          query + '&start=' + first.toISOString() + '&end=' + last.toISOString()
        )
        .then((ress) => {
          setSalesData(ress);
        })
        .catch((err) => console.log(err));

      membersApi
        .getAll(
          query + '&start=' + first.toISOString() + '&end=' + last.toISOString()
        )
        .then((ress) => {
          setMembersData(ress);
        })
        .catch((err) => console.log(err));

      customerClassesApi
        .getAll('count=4&' + query)
        .then((ress) => {
          setClassesData(ress);
        })
        .catch((err) => console.log(err));
    }
  };

  const getDataByLocationId = (id) => {
    setLoading(true);
    formik.setFieldValue('locations', id);
    getData(id, formik.values.begin_date, formik.values.end_date);
  };

  return (
    <>
      <Helmet>
        <title> {t('dashboard')}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%',
          backgroundColor: '#E5E8EC',
          p: 1,
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <Grid item xs={12} md={3} sm={12}>
              <Typography variant="h2" color="textPrimary" gutterBottom mt={2}>
                {t('dashboard')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sm={12}>
              <FormControl
                size="small"
                sx={{ width: '100%', backgroundColor: 'white' }}
              >
                <InputLabel id="locations">{t('location')}</InputLabel>
                <Select
                  labelId="locations"
                  label={t('location')}
                  className={classes.input}
                  fullWidth
                  name="locations"
                  value={formik.values.locations}
                  onBlur={formik.handleBlur}
                  onChange={(e) => getDataByLocationId(e.target.value)}
                >
                  <MenuItem key="all" value="all">
                    {t('all')}
                  </MenuItem>
                  {locationList.map((item, index) => (
                    <MenuItem key={`${index + 1}`} value={item.location_id}>
                      {item.location_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={5} sm={12}>
              <Grid container alignItems="center">
                <Grid item xs={12} md={5.5} sm={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.input}>
                      <DatePicker
                        label={t('begin_hour')}
                        name="begin_date"
                        openTo="day"
                        mask="__/__/____"
                        views={['year', 'month', 'day']}
                        // minDate={new Date()}
                        value={formik.values.begin_date}
                        onAccept={(e) => {
                          getData(
                            formik.values.locations,
                            e,
                            formik.values.end_date
                          );
                        }}
                        onChange={(e) => {
                          formik.setFieldValue('begin_date', e);
                        }}
                        error={
                          formik.touched.begin_date &&
                          Boolean(formik.errors.begin_date)
                        }
                        helperText={
                          formik.touched.begin_date && formik.errors.begin_date
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                {width < 900 ? (
                  <Grid item xs={0.5}>
                    <br />
                  </Grid>
                ) : (
                  <Typography variant="h5" color="textPrimary">
                    &nbsp; &nbsp;-&nbsp; &nbsp;
                  </Typography>
                )}

                <Grid item xs={12} md={5.5} sm={12}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[language]}
                  >
                    <Stack spacing={3} className={classes.input}>
                      <DatePicker
                        label={t('end_hour')}
                        name="end_date"
                        openTo="day"
                        mask="__/__/____"
                        views={['year', 'month', 'day']}
                        // maxDate={new Date()}
                        value={formik.values.end_date}
                        onAccept={(e) => {
                          getData(
                            formik.values.locations,
                            formik.values.begin_date,
                            e
                          );
                        }}
                        onChange={(e) => {
                          formik.setFieldValue('end_date', e);
                        }}
                        error={
                          formik.touched.end_date &&
                          Boolean(formik.errors.end_date)
                        }
                        helperText={
                          formik.touched.end_date && formik.errors.end_date
                        }
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4.5} sm={12}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} md={12} sm={12}>
                  <Packages rest={packagesData} />
                </Grid> */}
                <Grid item xs={12} md={12} sm={12}>
                  <Visitors rest={visitorsData} sx={{ height: '100%' }} />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Coaches rest={coachesData} sx={{ height: '100%' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7.5} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sm={12}>
                  <Sales rest={salesData} sx={{ height: '100%' }} />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Members rest={membersData} sx={{ height: '100%' }} />
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Classes rest={classesData} sx={{ height: '100%' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
