/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Snackbar,
  Stack,
  Divider,
  IconButton,
  TextField,
  Avatar,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import MuiAlert from '@mui/material/Alert';
import jwtDecode from 'jwt-decode';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TelegramLoginButton from 'react-telegram-login';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ForumIcon from '@mui/icons-material/Forum';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GroupsIcon from '@mui/icons-material/Groups';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Loading from '../../../components/Loading';
import { setSelectTests, setCoin } from '../../../redux/modules/store/actions';
import { setToken } from '../../../redux/modules/auth/actions';
import { useStyles } from './style';
import ModalWindow from '../../../components/Modals';
import SignUpModal from '../../../components/Modals/SignUpModal';
import AddSelectUnit from '../../../components/Cart/Modals/AddSelectUnit';
import topicTest from '../../../services/topic_tests';
import scienceTest from '../../../services/science_tests';
import testsApi from '../../../services/tests';
import authApi from '../../../services/auth';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ({ ...rest }) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [open, setOpen] = useState(false);

  const [scienceTestList, setScienceTestList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [testList, setTestList] = useState([]);

  const [selectTestList, setSelectTestList] = useState([]);

  const [modalAddSelectUnit, setModalAddSelectUnit] = useState(false);

  const [testSelectList, setTestSelectList] = useState([]);
  const [selectId, setSelectId] = useState('');
  const [selectName, setSelectName] = useState('');

  const language = useSelector((state) => state.langsReducer.language);
  const selectTests = useSelector((state) => state.storeReducer.select_tests);

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [testMakeAccess, setTestMakeAccess] = useState(false);

  const [countPages, setCountPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);

  const [modalTelegram, setModalTelegram] = useState(false);

  // const token = localStorage.getItem('token');
  // const decodedToken = jwtDecode(token);

  const selectTest = (item, status) => {
    if (selectId) {
      const data = new FormData();
      const arr = selectTestList;
      if (status === 1) {
        arr.push(item.test_id);
        data.append('tests', JSON.stringify(arr));
      } else {
        data.append(
          'tests',
          JSON.stringify(arr.filter((value) => value !== item.test_id))
        );
      }

      // data.append('name', values.name);

      data.append('status', 1);
      testsApi
        .updateTestSelectUnit(selectId, data)
        .then((res) => {
          dispatch(setSelectTests(uuidv4()));
          setSelectTestList(res.tests);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSnackbarMessage(t("Kerakli testlar to'plamini tanlang"));
      setOpen(true);
    }

    // if (localStorage.getItem('selectTests')) {
    //   const arr = JSON.parse(localStorage.getItem('selectTests'));
    //   if (arr.length > 30) {
    //     setSnackbarMessage(
    //       t('Tanlash limit 30 ta. Tanlov limitdan oshib ketdi')
    //     );
    //     setOpen(true);
    //   } else if (status === 1) {
    //     arr.push(item.test_id);
    //     localStorage.setItem('selectTests', JSON.stringify(arr));
    //   } else {
    //     localStorage.setItem(
    //       'selectTests',
    //       JSON.stringify(arr.filter((value) => value !== item.test_id))
    //     );
    //   }
    // } else {
    //   localStorage.setItem('selectTests', JSON.stringify([item.test_id]));
    // }
    // dispatch(setSelectTests(uuidv4()));

    // setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
  };

  const validationSchema = Yup.object().shape({
    science_tests: Yup.string().required(t('required')),
    topic_tests: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      science_tests: '',
      topic_tests: '',
    },
    validationSchema,
  });

  const getTestsByTopicId = (currentPage, id) => {
    // setTestList([]);
    setLoading(false);
    const query =
      `page=${currentPage}&page_size=${pageSize}&status=1&topic=` + id;
    testsApi
      .getAll(query)
      .then((res) => {
        setCountPages(res.count_pages);
        setTestList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setLoading(false);
  };

  const getTopicByScienceId = (id) => {
    setTestList([]);
    setTopicList([]);
    formik.setFieldValue('topic_tests', '');

    setLoading(false);
    const query = 'science_id=' + id;
    topicTest
      .getAllOpen(query)
      .then((res) => {
        setTopicList(res);
        if (res.length > 0) {
          const topicId = localStorage.getItem('topic_id');
          if (topicId !== null) {
            const arr = res.filter((item) => item.topic_test_id === topicId);
            if (arr.length > 0) {
              formik.setFieldValue('topic_tests', arr[0].name.uz);
              getTestsByTopicId(1, topicId);
            } else {
              formik.setFieldValue('topic_tests', res[0].name.uz);
              getTestsByTopicId(1, res[0].topic_test_id);
            }
          } else {
            formik.setFieldValue('topic_tests', res[0].name.uz);
            localStorage.setItem('topic_id', res[0].topic_test_id);
            getTestsByTopicId(1, res[0].topic_test_id);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setLoading(false);
  };

  const loadingData = () => {
    setLoading(true);
    scienceTest
      .getAll()
      .then((res) => {
        setScienceTestList(res);
        if (res.length > 0) {
          const scienceId = localStorage.getItem('science_id');
          if (scienceId !== null) {
            const arr = res.filter((item) => item.science_id === scienceId);
            if (arr.length > 0) {
              formik.setFieldValue('science_tests', arr[0].name.uz);
            }
            getTopicByScienceId(scienceId);
          } else {
            formik.setFieldValue('science_tests', res[0].name.uz);
            localStorage.setItem('science_id', res[0].science_id);
            getTopicByScienceId(res[0].science_id);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
    const tId = localStorage.getItem('topic_id');
    getTestsByTopicId(value, tId);
  };

  useEffect(() => {
    loadingData();

    // if (localStorage.getItem('selectTests')) {
    //   setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
    // }
    testsApi
      .getTestSelectUnit('page_size=1000')
      .then((res) => {
        setTestSelectList(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // usersApi
    //   .getOne(decodedToken.user_id)
    //   .then((res) => {
    //     setTestMakeAccess(res.test_make_access);
    //   })
    //   .catch((err) => {});
  }, [language]);

  useEffect(() => {
    if (localStorage.getItem('select_test_unit_id')) {
      const id = localStorage.getItem('select_test_unit_id');
      testsApi
        .getOneTestSelectUnit(id)
        .then((res) => {
          setSelectTestList(res.tests);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSelectTestList([]);
    }
    // if (localStorage.getItem('selectTests')) {
    //   setSelectTestList(JSON.parse(localStorage.getItem('selectTests')));
    // }
  }, [selectTests]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleTelegramResponse = (response) => {
    authApi
      .authTelegram(`id=${response.id}&name=${response.first_name}`)
      .then((res) => {
        console.log(res.data.error);
        if (!Object.is(res.data.error, null)) {
          dispatch(setToken(res.data));
          dispatch(setCoin(res.data.coin));
          setModalTelegram(false);
        }
      });
  };

  const addFavorite = (testId) => {
    setLoading(false);
    const data = { test: testId };
    testsApi
      .addFavorite(data)
      .then((res) => {
        setLoading(false);
        const topicId = localStorage.getItem('topic_id');
        getTestsByTopicId(page, topicId);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const selectOption = (testId, option) => {
    setLoading(false);

    testsApi
      .getSelectOption('testId=' + testId + '&option=' + option)
      .then((res) => {
        setLoading(false);
        const tId = localStorage.getItem('topic_id');
        getTestsByTopicId(page, tId);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleModalTelegram = () => {
    setModalTelegram(!modalTelegram);
  };

  const checkTestOwner = (option) => {
    if (localStorage.hasOwnProperty('token')) {
      const token = localStorage.getItem('token');
      if (jwtDecode(token).user_id === option.owner) {
        return true;
      }
    }
    return false;
  };

  const getId = () => {
    if (localStorage.hasOwnProperty('token')) {
      const token = localStorage.getItem('token');
      const text = 'ID: ' + jwtDecode(token).telegram_id;
      return text;
    }
    return '';
  };

  return (
    <>
      <Helmet>
        <title>{t('tests')}</title>
      </Helmet>
      <ModalWindow
        title={t('Eslatma')}
        modal={modalTelegram}
        handleModal={handleModalTelegram}
        maxWidth="sm"
        body={<SignUpModal handleTelegramResponse={handleTelegramResponse} />}
      />
      <ModalWindow
        title={t("Yangi tanlovlar to'plami")}
        modal={modalAddSelectUnit}
        handleModal={() => {
          setModalAddSelectUnit(false);
        }}
        maxWidth="md"
        body={
          <AddSelectUnit
            handleModal={() => {
              testsApi
                .getTestSelectUnit('page_size=1000')
                .then((res) => {
                  setTestSelectList(res);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                });
              setModalAddSelectUnit(false);
            }}
            yes={t('save')}
            cancel={t('cancel')}
            text={t('ask_clear')}
          />
        }
      />
      <Box
        sx={{
          backgroundColor: 'background.default',
          // height: '100vh',
          py: 3,
          px: 1,
        }}
      >
        <Container maxWidth={false}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: '100%' }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Stack>

          <Loading loading={loading} />
          <Typography
            variant="h2"
            color="textPrimary"
            gutterBottom
            mb={1}
            mt={1}
          >
            {t('tests')}
          </Typography>
          <Card sx={{ marginRight: 1, marginBottom: 1 }}>
            <CardContent>
              <Typography
                variant="h3"
                color="textPrimary"
                gutterBottom
                mb={1}
                mt={1}
              >
                {t("Har bir testni yuklab olish narxi 20 so'm")}
              </Typography>
            </CardContent>
          </Card>

          <Grid container spacing={1}>
            <Grid item xs={4} md={4} sm={4}>
              <TelegramLoginButton
                dataOnauth={handleTelegramResponse}
                botName={process.env.REACT_APP_TELEGRAM_BOT}
              />
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography variant="h5" color="textPrimary">
                {getId()}
              </Typography>
            </Grid>
            <Grid container my={2}>
              <Grid item xs={6} md={6} sm={6}>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: '#FFFFFF',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  startIcon={<GroupsIcon />}
                  onClick={() => {
                    if (localStorage.hasOwnProperty('token')) {
                      navigate('/app/tests/groups');
                    } else {
                      handleModalTelegram();
                    }
                  }}
                  // color="info"
                >
                  {t('Gruppalar')}
                </Button>
              </Grid>
              <Grid item xs={6} md={6} sm={6}>
                <Button
                  sx={{
                    backgroundColor: '#2A455F',
                    color: '#FFFFFF',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  startIcon={<SportsScoreIcon />}
                  onClick={() => {
                    if (localStorage.hasOwnProperty('token')) {
                      navigate('/tests/competition');
                    } else {
                      handleModalTelegram();
                    }
                  }}
                  // color="info"
                >
                  {t('Musobaqa')}
                </Button>
              </Grid>
            </Grid>
            {/* <Grid item xs={4} md={4} sm={4}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  if (testMakeAccess) {
                    if (testMakeAccess) {
                      navigate('/app/topic_tests/newmake');
                    } else {
                      setSnackbarMessage(
                        t(
                          "Kechirasiz, sizga buning uchun ruxsat berilmagan. Admin bilan bog'laning"
                        )
                      );
                      setOpen(true);
                    }
                  } else {
                    handleModalTelegram();
                  }
                }}
                // color="info"
              >
                {t('Mavzu')}
              </Button>
            </Grid> */}

            {/* <Grid item xs={4} md={4} sm={4}>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  if (testMakeAccess) {
                    if (testMakeAccess) {
                      navigate('/tests/newmake');
                    } else {
                      setSnackbarMessage(
                        t(
                          "Kechirasiz, sizga buning uchun ruxsat berilmagan. Admin bilan bog'laning"
                        )
                      );
                      setOpen(true);
                    }
                  } else {
                    handleModalTelegram();
                  }
                }}
                // color="info"
              >
                {t('Test')}
              </Button>
            </Grid> */}
          </Grid>

          <Grid container my={2}>
            <Grid item xs={11} md={11} sm={11}>
              <Autocomplete
                fullWidth
                size="small"
                id="combo-box3"
                freeSolo
                clearOnBlur
                className={classes.search}
                value={selectName}
                options={testSelectList}
                onChange={(event, newValue) => {
                  dispatch(setSelectTests(uuidv4()));
                  if (newValue) {
                    setSelectName(
                      newValue.name + ' - ' + newValue.tests.length
                    );
                    setSelectId(newValue.select_saved_id);
                    localStorage.setItem(
                      'select_test_unit_id',
                      newValue.select_saved_id
                    );
                    setSelectTestList(newValue.tests);
                  } else {
                    setSelectName();
                    setSelectId();
                    localStorage.removeItem('select_test_unit_id');
                    setSelectTestList([]);
                  }
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }

                  // Regular option
                  return option.name + ' - ' + option.tests.length;
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Grid container spacing={1}>
                      <Grid item xs={11} md={11} sm={11}>
                        <Typography align="left" color={colors.grey[700]}>
                          {option.name + ' - ' + option.tests.length}
                        </Typography>
                      </Grid>
                      {checkTestOwner(option) ? (
                        // eslint-disable-next-line react/jsx-indent
                        <Grid item xs={1} md={1} sm={1}>
                          <IconButton
                            size="small"
                            sx={{
                              color: '#2A455F',
                            }}
                            variant="contained"
                            onClick={() => {
                              if (localStorage.hasOwnProperty('token')) {
                                console.log();
                              } else {
                                handleModalTelegram();
                              }
                            }}
                            // color="info"
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      ) : null}
                    </Grid>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("Testlar to'plami")} />
                )}
              />
            </Grid>
            <Grid item xs={1} md={1} sm={1}>
              <IconButton
                sx={{
                  color: '#2A455F',
                }}
                variant="contained"
                onClick={() => {
                  if (localStorage.hasOwnProperty('token')) {
                    setModalAddSelectUnit(true);
                  } else {
                    handleModalTelegram();
                  }
                }}
                // color="info"
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sm={12} my={2}>
            <Autocomplete
              fullWidth
              size="small"
              id="combo-box1"
              freeSolo
              clearOnBlur
              className={classes.search}
              value={formik.values.science_tests}
              options={scienceTestList}
              onChange={(event, newValue) => {
                if (newValue) {
                  formik.setFieldValue('science_tests', newValue.name.uz);
                  localStorage.setItem('science_id', newValue.science_id);
                  getTopicByScienceId(newValue.science_id);
                }
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }

                // Regular option
                return option.name.uz;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name.uz}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} label={t('science')} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12} my={2}>
            <Autocomplete
              fullWidth
              size="small"
              id="combo-box1"
              freeSolo
              clearOnBlur
              className={classes.search}
              value={formik.values.topic_tests}
              options={topicList}
              onChange={(event, newValue) => {
                if (newValue) {
                  formik.setFieldValue('topic_tests', newValue.name.uz);
                  localStorage.setItem('topic_id', newValue.topic_test_id);
                  getTestsByTopicId(1, newValue.topic_test_id);
                }
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }

                // Regular option
                return option.name.uz;
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  <Grid container spacing={1}>
                    <Grid item xs={11} md={11} sm={11}>
                      <Typography align="left" color={colors.grey[700]}>
                        {option.name.uz}
                      </Typography>
                    </Grid>
                    {checkTestOwner(option) ? (
                      // eslint-disable-next-line react/jsx-indent
                      <Grid item xs={1} md={1} sm={1}>
                        <IconButton
                          size="small"
                          sx={{
                            color: '#2A455F',
                          }}
                          variant="contained"
                          onClick={() => {
                            if (localStorage.hasOwnProperty('token')) {
                              navigate(
                                '/app/topic_tests/edit/' + option.topic_test_id
                              );
                            } else {
                              handleModalTelegram();
                            }
                          }}
                          // color="info"
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    ) : null}
                  </Grid>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label={t('topic')} />
              )}
            />
          </Grid>

          <Grid container my={1}>
            {testList.length === 0 ? (
              <Typography
                my={1}
                variant="h4"
                align="left"
                color={colors.grey[700]}
              >
                {t('no_test_data')}
              </Typography>
            ) : null}
            {testList.map((item, i) => (
              <Grid key={item.test_id} item xs={12} md={6} sm={12}>
                <Card sx={{ marginRight: 1, marginBottom: 1 }}>
                  <Tooltip
                    title={
                      selectTestList.indexOf(item.test_id) < 0
                        ? t('selection')
                        : t('selected')
                    }
                    placement="top"
                    arrow
                  >
                    <IconButton
                      variant="filled"
                      onClick={() => {
                        selectTest(
                          item,
                          selectTestList.indexOf(item.test_id) < 0 ? 1 : 0
                        );
                      }}
                    >
                      {selectTestList.indexOf(item.test_id) < 0 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <HighlightOffIcon color="error" />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Muhokama" placement="top" arrow>
                    <IconButton
                      variant="filled"
                      onClick={() => {
                        navigate('/app/tests/forum/' + item.test_id);
                      }}
                    >
                      <ForumIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Sevimlilarga qo'shish" placement="top" arrow>
                    <IconButton
                      variant="filled"
                      onClick={() => {
                        if (localStorage.hasOwnProperty('token')) {
                          addFavorite(item.test_id);
                        } else {
                          handleModalTelegram();
                        }
                      }}
                    >
                      {item.favorite ? (
                        <FavoriteIcon color="error" />
                      ) : (
                        <FavoriteIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  {item.editable ? (
                    <Tooltip title="Tahrirlash" placement="top" arrow>
                      <IconButton
                        variant="filled"
                        onClick={() => {
                          navigate('/tests/' + item.test_id);
                        }}
                      >
                        <EditIcon color="success" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  <CardContent>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.question.replace(
                          'numberNN',
                          (page - 1) * pageSize + i + 1 + '. '
                        ),
                      }}
                    />
                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                          paddingRight: 1,
                        }}
                        variant={
                          item.select_option === 'a' ? 'contained' : 'outlined'
                        }
                        color={
                          item.select_option === 'a' ? 'primary' : 'warning'
                        }
                        startIcon={<Avatar>A</Avatar>}
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            selectOption(item.test_id, 'a');
                          } else {
                            handleModalTelegram();
                          }
                        }}
                      >
                        <div
                          style={{ paddingTop: 10 }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.option_a.replace('optionA', ''),
                          }}
                        />
                      </Button>
                      {item.is_click_option ? (
                        <>
                          <Typography
                            variant="h6"
                            align="right"
                            color={colors.grey[700]}
                          >
                            {item.perc_a}%
                          </Typography>
                        </>
                      ) : null}
                    </div>

                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                        }}
                        variant={
                          item.select_option === 'b' ? 'contained' : 'outlined'
                        }
                        color={
                          item.select_option === 'b' ? 'primary' : 'warning'
                        }
                        startIcon={<Avatar>B</Avatar>}
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            selectOption(item.test_id, 'b');
                          } else {
                            handleModalTelegram();
                          }
                        }}
                      >
                        <div
                          style={{ paddingTop: 10 }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.option_b.replace('optionB', ''),
                          }}
                        />
                      </Button>
                      {item.is_click_option ? (
                        <>
                          <Typography
                            variant="h6"
                            align="right"
                            color={colors.grey[700]}
                          >
                            {item.perc_b}%
                          </Typography>
                        </>
                      ) : null}
                    </div>
                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                        }}
                        variant={
                          item.select_option === 'c' ? 'contained' : 'outlined'
                        }
                        color={
                          item.select_option === 'c' ? 'primary' : 'warning'
                        }
                        startIcon={<Avatar>C</Avatar>}
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            selectOption(item.test_id, 'c');
                          } else {
                            handleModalTelegram();
                          }
                        }}
                      >
                        <div
                          style={{ paddingTop: 10 }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.option_c.replace('optionC', ''),
                          }}
                        />
                      </Button>
                      {item.is_click_option ? (
                        <>
                          <Typography
                            variant="h6"
                            align="right"
                            color={colors.grey[700]}
                          >
                            {item.perc_c}%
                          </Typography>
                        </>
                      ) : null}
                    </div>
                    <Divider size="medium" color="primary" />
                    <div>
                      <Button
                        fullWidth
                        disableRipple
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                        }}
                        variant={
                          item.select_option === 'd' ? 'contained' : 'outlined'
                        }
                        color={
                          item.select_option === 'd' ? 'primary' : 'warning'
                        }
                        startIcon={<Avatar>D</Avatar>}
                        onClick={() => {
                          if (localStorage.hasOwnProperty('token')) {
                            selectOption(item.test_id, 'd');
                          } else {
                            handleModalTelegram();
                          }
                        }}
                      >
                        <div
                          style={{ paddingTop: 10 }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item.option_d.replace('optionD', ''),
                          }}
                        />
                      </Button>
                      {item.is_click_option ? (
                        <>
                          <Typography
                            variant="h6"
                            align="right"
                            color={colors.grey[700]}
                          >
                            {item.perc_d}%
                          </Typography>
                        </>
                      ) : null}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          {testList.length > 0 ? (
            <Stack spacing={2}>
              <Pagination
                ullwidth="true"
                count={countPages}
                variant="outlined"
                shape="rounded"
                page={page}
                onChange={handleChange}
              />
            </Stack>
          ) : null}
        </Container>
      </Box>
    </>
  );
};
