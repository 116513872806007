export function dataSelector(dataResponse) {
  const filtered = dataResponse.results.map((item) => ({
    id: item.location_id,
    location_name: item.location_name,
    region: item.region,
    district: item.district,
    street: item.street,
    notes: item.notes,
    status: item.status === 1 ? '✅' : '❌',
    created_time: (new Date(item.createdAt).getDate() < 10 ? '0' + (new Date(item.createdAt).getDate()) : (new Date(item.createdAt).getDate())) + '/' + (new Date(item.createdAt).getMonth() < 10 ? '0' + (new Date(item.createdAt).getMonth() + 1) : (new Date(item.createdAt).getMonth() + 1)) + '/' + new Date(item.createdAt).getFullYear()
  }));
  return { total: dataResponse.count, data: filtered };
}
