import { service } from '.';

export default {
  getAll: (query) => service.get(`/users/?${query}`),
  getAllUserAuth: (query) => service.get(`/user_auth/?${query}`),
  getOneUserAuth: (id) => service.get(`/user_auth/${id}/`),
  checkName: (query) => service.get(`/users/validation?${query}`),
  getOne: (id) => service.get(`/users/${id}/`),
  getByLocationId: (id) => service.get(`/users/?locations=${id}`),
  create: (data) => service.post('/users/', data),
  update: (id, data) => service.patch(`/users/${id}/`, data),
  updateAuth: (id, data) => service.patch(`/user_auth/${id}/`, data),
  disable: (id) => service.patch(`/users/${id}/${0}`),
};
