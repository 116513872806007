export const byStatus = [
  {
    name: 'all',
    value: -1,
  },
  {
    name: 'true',
    value: 1,
  },
  {
    name: 'false',
    value: 0,
  },
];
