export function dataSelector(dataResponse, page, size) {
  const timeConvert = (time) => {
    if (time !== null) {
      const sp1 = time.split('T');
      const sp2 = sp1[1].split(':');
      const strTime = sp1[0] + ' ' + sp2[0] + ':' + sp2[1];
      return strTime;
    }
    return time;
  };
  const filtered = dataResponse.results.data.map((item, i) => ({
    index: i + 1 + page * size,
    id: item,
    locations: item.locations,
    name: item.user_id.name,
    phone: item.user_id.phone,
    unique_number: item.unique_number,
    gender: item.user_id.gender,
    customer: item,
    notes: item.user_id.notes,
    status: item.status,
    date_birth:
      (new Date(item.user_id.date_birth).getDate() < 10
        ? '0' + new Date(item.user_id.date_birth).getDate()
        : new Date(item.user_id.date_birth).getDate()) +
      '/' +
      (new Date(item.user_id.date_birth).getMonth() < 10
        ? '0' + (new Date(item.user_id.date_birth).getMonth() + 1)
        : new Date(item.user_id.date_birth).getMonth() + 1) +
      '/' +
      new Date(item.user_id.date_birth).getFullYear(),
    joined_date:
      (new Date(item.user_id.joined_date).getDate() < 10
        ? '0' + new Date(item.user_id.joined_date).getDate()
        : new Date(item.user_id.joined_date).getDate()) +
      '/' +
      (new Date(item.user_id.joined_date).getMonth() < 10
        ? '0' + (new Date(item.user_id.joined_date).getMonth() + 1)
        : new Date(item.user_id.joined_date).getMonth() + 1) +
      '/' +
      new Date(item.user_id.joined_date).getFullYear(),
    created_time: timeConvert(item.created_at),
  }));
  return { total: dataResponse.count, data: filtered };
}
